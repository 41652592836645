import React, { ReactNode, useEffect, useRef } from 'react';

import {
    AnchorStyled,
    BoxStyled,
    Center,
    Spinner,
} from './styles';

interface Props {
    children: ReactNode;
    className?: string;
    isVisible: boolean;
    close: () => void;
    width?: number;
    loading?: boolean;
    left?: number;
    right?: number;
    /**
     DOM-элемент(-ы), которые следует считать частью этого модуля
     и клик по которым не должен считаться кликом во вне
     */
    include?: Element | null;
}

export function Popover(props: Props) {
    const {
        width,
        left,
        right,
        loading,
        children,
        isVisible,
        close,
        include,
        className = '',
    } = props;
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!window || !document) {
            return () => undefined;
        }

        const checkIsChildElements = (targetElement: EventTarget) => {
            if (!rootRef.current) {
                return false;
            }
            if (rootRef.current.contains(targetElement as Node)) {
                return true;
            }

            if (include) {
                if (Array.isArray(include)) {
                    return include.some((element) => element && element.contains(targetElement));
                }
                return include.contains(targetElement as Node);
            }
            return false;
        };

        const clickHandler = (event: MouseEvent) => {
            if (!rootRef.current || !isVisible) {
                return;
            }

            if (event.target && !checkIsChildElements(event.target)) {
                close();
            }
        };

        const keyPressHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                close();
            }
        };

        document.addEventListener('mousedown', clickHandler);
        window.addEventListener('keydown', keyPressHandler);
        return () => {
            document.removeEventListener('mousedown', clickHandler);
            window.removeEventListener('keydown', keyPressHandler);
        };
    });

    const renderContent = () => {
        if (!isVisible) {
            return null;
        }

        return (
            <BoxStyled ref={rootRef} className={className} left={left} right={right} width={width}>
                {loading ? (
                    <Center>
                        <Spinner />
                    </Center>
                ) : children}
            </BoxStyled>
        );
    };

    return (
        <AnchorStyled>
            {renderContent()}
        </AnchorStyled>
    );
}
