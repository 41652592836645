import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const SheetBottomCornIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path d="M2.66895 16.7797V1.02812H17.4924V16.5703H5.30801C5.17051 16.5703 5.05332 16.6672 5.02832 16.8016C4.92207 17.3781 4.57363 18.8281 3.72988 18.8281C3.2752 18.8281 2.66895 18.2562 2.66895 16.7797Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90747 4.44062C4.90747 4.27387 5.04265 4.13869 5.20941 4.13869H14.7657C14.9324 4.13869 15.0676 4.27387 15.0676 4.44062C15.0676 4.60738 14.9324 4.74256 14.7657 4.74256H5.20941C5.04265 4.74256 4.90747 4.60738 4.90747 4.44062Z" fill="#B0BEC5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90747 6.72656C4.90747 6.55981 5.04265 6.42463 5.20941 6.42463H14.7657C14.9324 6.42463 15.0676 6.55981 15.0676 6.72656C15.0676 6.89332 14.9324 7.0285 14.7657 7.0285H5.20941C5.04265 7.0285 4.90747 6.89332 4.90747 6.72656Z" fill="#B0BEC5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90747 9.0125C4.90747 8.84575 5.04265 8.71056 5.20941 8.71056H14.7657C14.9324 8.71056 15.0676 8.84575 15.0676 9.0125C15.0676 9.17926 14.9324 9.31444 14.7657 9.31444H5.20941C5.04265 9.31444 4.90747 9.17926 4.90747 9.0125Z" fill="#B0BEC5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90747 11.2969C4.90747 11.1301 5.04265 10.9949 5.20941 10.9949H14.7657C14.9324 10.9949 15.0676 11.1301 15.0676 11.2969C15.0676 11.4636 14.9324 11.5988 14.7657 11.5988H5.20941C5.04265 11.5988 4.90747 11.4636 4.90747 11.2969Z" fill="#B0BEC5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90747 13.5828C4.90747 13.4161 5.04265 13.2809 5.20941 13.2809H9.29534C9.4621 13.2809 9.59728 13.4161 9.59728 13.5828C9.59728 13.7496 9.4621 13.8848 9.29534 13.8848H5.20941C5.04265 13.8848 4.90747 13.7496 4.90747 13.5828Z" fill="#B0BEC5" />
        <path d="M18.9001 16.4031H17.7782V1.06719C17.7782 0.823437 17.5798 0.625 17.336 0.625H2.6657C2.42195 0.625 2.22351 0.823437 2.22351 1.06719V16.6078C2.22195 17.3625 2.08758 19.375 4.45633 19.375H17.0891C18.8845 19.375 19.1438 17.4375 19.1766 16.6906C19.1845 16.5328 19.0579 16.4031 18.9001 16.4031ZM2.85008 16.6078V1.25156H17.1485V16.4016H5.27351C5.13914 16.4016 5.02508 16.4969 5.00008 16.6281C4.89695 17.1891 4.55633 18.6031 3.73445 18.6031C3.08914 18.6031 2.85008 18.0469 2.85008 16.6078Z" fill="#6FBFF0" />
    </EmojiIconSvg>
);
