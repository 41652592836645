import React from 'react';

import { IconSvg, IconComponent } from '.';

export const UserIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 12 12">
        <path d="M9.51875 10.95H2.66875C2.46875 10.95 2.26875 10.85 2.16875 10.7C2.01875 10.5 1.96875 10.3 2.01875 10.1C2.26875 9.15 3.61875 7 6.06875 7C8.51875 7 9.86875 9.1 10.1188 10.1C10.1688 10.3 10.1188 10.5 10.0188 10.65C9.91875 10.85 9.76875 10.95 9.51875 10.95Z" />
        <path d="M6.11875 6.65C4.66875 6.65 3.51875 5.5 3.51875 4.05C3.51875 2.6 4.66875 1.5 6.11875 1.5C7.56875 1.5 8.66875 2.65 8.66875 4.05C8.66875 5.45 7.51875 6.65 6.11875 6.65Z" />
    </IconSvg>
);
