/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    memo,
    useEffect,
    forwardRef,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useKeyPress } from 'hooks/useKeyPress';
import { Tabs } from 'components/tabs';

import { setSearchActiveTab } from '../../../actions';
import SearchResultsElements from './SearchResultsElements';

import { TabsWrapper } from './styled';
import { useDictionary } from 'hooks/useDictionary';
const SearchResults = forwardRef(({
    people,
    posts,
    instruments,
    products,
    log,
    searchInput,
    isInputFocused,
    cancelSearch,
    isLoading,
    inputRef,
    toggleTickerSubscribed,
}, resultsRef) => {
    const dic = useDictionary();
    const activeTab = useSelector(state => state.searchActiveTab);
    const dispatch = useDispatch();
    const tabs = ['instruments', 'people', 'posts', 'products'];

    useKeyPress({ key: 'ArrowRight', onKeyDown: () => incrementTab() });
    useKeyPress({ key: 'ArrowLeft', onKeyDown: () => decrementTab() });
    useKeyPress({ key: 'ArrowDown', onKeyDown: (e) => {
        if (inputRef.current && document.activeElement === inputRef.current) {
            e.preventDefault();
            inputRef.current?.blur?.();
        }
    }});

    const setActiveTab = useCallback(tab => dispatch(setSearchActiveTab(tab)), [dispatch]);

    const currentTabIndex = tabs.indexOf(activeTab);

    const incrementTab = () => {
        if (!isInputFocused) {
            if (currentTabIndex === tabs.length - 1) {
                setActiveTab(tabs[0]);
                return;
            }
            setActiveTab(tabs[currentTabIndex + 1]);
        }
    };

    const decrementTab = () => {
        if (!isInputFocused) {
            if (currentTabIndex === 0) {
                setActiveTab(tabs[tabs.length - 1]);
                return;
            }
            setActiveTab(tabs[currentTabIndex - 1]);
        }
    };

    return (
        <div
            ref={resultsRef}
            className="autotest__search-result"
        >
            <TabsWrapper>
                <Tabs
                    size="small"
                    selectedItemIndex={currentTabIndex}
                    onItemSelected={(i) => setActiveTab(tabs[i])}
                    items={[
                        { title: dic.word('wt_menu__search_limex__tabs_tickers'), autotestClass: 'autotest__search-tab-instruments' },
                        { title: dic.word('wt_menu__search_limex__tabs_users'), autotestClass: 'autotest__search-tab-users' },
                        { title: dic.word('wt_menu__search_limex__tabs_posts'), autotestClass: 'autotest__search-tab-blogposts' },
                        { title: dic.word('wt_menu__search_limex__tabs_market'), autotestClass: 'autotest__search-tab-market' },
                    ]}
                />
            </TabsWrapper>
            <SearchResultsElements {...{
                people,
                posts,
                instruments,
                products,
                log,
                activeTab,
                searchInput,
                cancelSearch,
                isLoading,
                toggleTickerSubscribed,
            }}
            />
        </div>
    );
});

SearchResults.propTypes = {
    people: PropTypes.array.isRequired,
    posts: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    log: PropTypes.shape({}).isRequired,
    instruments: PropTypes.array.isRequired,
    isPeopleLoading: PropTypes.bool.isRequired,
    isInstrumentsLoading: PropTypes.bool.isRequired,
    isPostsLoading: PropTypes.bool.isRequired,
    searchInput: PropTypes.string.isRequired,
    isInputFocused: PropTypes.bool.isRequired,
    cancelSearch: PropTypes.func.isRequired,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]).isRequired,
    toggleTickerSubscribed: PropTypes.func.isRequired,
};

export default memo(SearchResults);
