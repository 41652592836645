import React from 'react';

import { IconSvg, IconComponent } from '.';

export const TriangleExclamationIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8441 3.79678C10.4705 3.10567 9.48247 3.09545 9.09468 3.77869L3.24259 14.0895C2.86422 14.7562 3.34574 15.5831 4.11227 15.5831H15.5378C16.2952 15.5831 16.7776 14.7739 16.4175 14.1076L10.8441 3.79678ZM7.9351 3.12055C8.83993 1.52632 11.1453 1.55016 12.017 3.16276L17.5904 13.4736C18.4308 15.0282 17.305 16.9165 15.5378 16.9165H4.11227C2.3237 16.9165 1.20016 14.9869 2.083 13.4314L7.9351 3.12055Z" />
        <path d="M10.6579 10.6761C10.6408 11.1119 10.4101 11.3426 10.0085 11.3426C9.58122 11.3426 9.35051 11.1033 9.34197 10.6761L9.23088 6.53178C9.21379 6.09599 9.5385 5.77983 9.99992 5.77983C10.4443 5.77983 10.7861 6.10453 10.769 6.53178L10.6579 10.6761Z" />
        <path d="M10.9057 13.1285C10.9057 13.6155 10.487 13.983 10.0085 13.983C9.52141 13.983 9.10271 13.607 9.10271 13.1285C9.10271 12.6499 9.51286 12.274 10.0085 12.274C10.4955 12.274 10.9057 12.6414 10.9057 13.1285Z" />
    </IconSvg>
);
