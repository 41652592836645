import {
    Action, FeedRecord, FeedRecordSource, Lcid, Person, ThunkResultFn,
} from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { GlobalStateForFeed } from '../../types';

export type Tag = {
    id: number;
    type: number;
    name: string;
    url: string;
};

export type SubscribedTags = Array<string>;

export type FeedTagList = {
    tags: Array<Tag>;
    subscribedTags: SubscribedTags;
};

export type FetchRecordsPayloadData = {
    records: FeedRecord[],
    boundary: string,
    boundaryRecordId: string,
    tags?: FeedTagList,
};

type FetchRecordsSettings = {
    owner?: Partial<Person> | null,
    source: FeedRecordSource;
    lcid: Lcid;
    boundary: string,
};

export const FETCH_FEED_RECORDS_ACTION_TYPES = createRequestActionTypes('FETCH_FEED_RECORDS');
export function fetchRecords(settings: FetchRecordsSettings): ThunkResultFn<FetchRecordsPayloadData, unknown, GlobalStateForFeed> {
    return (dispatch, getState) => {
        const state = getState();
        const {
            records,
            boundaryRecordId,
        } = state.widgets.feed;

        const {
            owner,
            source,
            boundary,
            lcid,
        } = settings;

        const { id: ownerId = 0, type: ownerType = 0 } = owner || {};
        const existRecordIds = Object.values(records).map((record) => record.id);
        return thunkActions({
            types: FETCH_FEED_RECORDS_ACTION_TYPES,
            payload: {
                request: {
                    url: UrlProcessor.page('capi_activity_stream_activities')
                        .params({
                            owner_id: ownerId,
                            owner_type: ownerType,
                            source,
                        }).url(),
                    params: {
                        orderBy: 'rank',
                        enableRecommendations: true,
                        simpleFeed: true, // dg: Для новой ленты отключаем загрузку информации о причинах показа записей @since #WTT-9608
                        feedContext: 'limex', // dg: Указываем контекст загрузки ленты для кастомной конфигурации @since #WTT-10004
                        reqlcid: lcid, // dg: Загружаем ленту на текущем языке. Актуально для подмерживаемых записей, которые зависит от языка. @since #WTT-10352
                        boundary,
                        boundaryRecordId,
                        existRecordIds: JSON.stringify(existRecordIds),
                    },
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: {},
                    withCredentials: true,
                },
            },
        })(dispatch);
    };
}

export type FetchRecords = Action<typeof fetchRecords>;
