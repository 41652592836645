import React from 'react';

import { BellIcon } from 'components/icons2/BellIcon';

import * as Styled from './styled/ButtonBadge.styled';

export type ButtonBadgePropsType = {
    isShowBadge?: boolean;
    isActive?: boolean;
};

export const ButtonBadge = (props: ButtonBadgePropsType) => {
    const {
        isShowBadge = false,
        isActive = false,
    } = props;

    return (
        <Styled.Wrapper>
            <Styled.Button
                kind="ghost-secondary"
                size="small"
                icon={BellIcon}
                isActive={isActive}
            />
            <Styled.BadgeWrapper>
                <Styled.Badge
                    isShowBadge={isShowBadge}
                    isActive={isActive}
                />
            </Styled.BadgeWrapper>
        </Styled.Wrapper>
    );
};
