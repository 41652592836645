import React from 'react';

import { NotifyProps } from './types';
import {
    Centerer, Wrapper, Content, NotifyButton,
} from './styled';

export type { NotifyProps };

export function Notify(props: NotifyProps) {
    const {
        text,
        close,
        onMouseEnter,
        onMouseLeave,
        size,
        buttonProps,
    } = props;
    const handleButtonClick = () => buttonProps?.onClick?.(close);
    return (
        <Centerer>
            <Wrapper
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                size={size}
                buttonProps={buttonProps}
            >
                <Content>{text}</Content>
                {buttonProps && (
                    <NotifyButton onClick={handleButtonClick} kind="ghost-primary" size={size}>
                        {buttonProps.children}
                    </NotifyButton>
                )}
            </Wrapper>
        </Centerer>
    );
}

Notify.defaultProps = {
    text: '',
    size: 'medium',
    buttonProps: null,
};
