import React from 'react';

import * as Styled from './styled/ErrorContent.styled';

export type ErrorContentPropsType = {
    text: string;
    actionBtnText: string;
    fetchNotifications: () => void;
};

export const ErrorContent = (props: ErrorContentPropsType) => {
    const { text, actionBtnText, fetchNotifications } = props;

    return (
        <Styled.Wrapper>
            <Styled.Text>
                {text}
            </Styled.Text>
            <Styled.ButtonAction
                kind="ghost-secondary"
                onClick={fetchNotifications}
            >
                {actionBtnText}
            </Styled.ButtonAction>
        </Styled.Wrapper>
    );
};
