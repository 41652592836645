import styled, { css } from 'styled-components';

import {
    labelPrimary, labelSecondary, matchBreakpoints, mediaQuery, ResponsiveMap,
} from 'app/styled';
import {
    body17MediumFontStyle,
    body14MediumFontStyle,
    body14RegularFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';
import { Avatar } from 'components/avatar2';
import { UserBlockSize } from 'components/user-block/types';
import { UserName } from '../user-name2';

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: center;
    overflow: hidden;
`;

export const StyledUserName = styled(UserName)`
    text-decoration: none;
    flex: initial;
    text-overflow: ellipsis;

    & > a, & > span {
        color: ${labelPrimary};
    }
`;

export const SecondLine = styled.div`
    display: flex;
    align-items: center;
    color: ${labelSecondary};
`;

export const AvatarLink = styled.a`
    display: flex;
    align-items: center;
`;

export const AvatarWrapper = styled(Avatar)``;

const sizeMap = {
    regular: css`
        ${StyledUserName} {
            ${body17MediumFontStyle};
        }
        ${AvatarWrapper} {
            width: 40px;
            height: 40px;

            ${mediaQuery.lt480} {
                width: 32px;
                height: 32px;
            }
        }
        ${SecondLine} {
            ${body14RegularFontStyle}
        }
    `,
    small: css`
        ${StyledUserName} {
            ${body14MediumFontStyle};
        }
        ${AvatarWrapper} {
            width: 32px;
            height: 32px;
        }
        ${SecondLine} {
            ${caption12RegularFontStyle}
        }
    `,
    xsmall: css`
        ${StyledUserName} {
            ${body14MediumFontStyle};
        }
        ${AvatarWrapper} {
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }
        ${SecondLine} {
            ${caption12RegularFontStyle}
        }
        ${Details} {
            margin-left: 8px;
        }
    `,
};

export const Wrapper = styled.div<{ size: UserBlockSize | ResponsiveMap<UserBlockSize> }>`
    display: flex;
    overflow: hidden;
    ${({ size }) => matchBreakpoints(size, sizeMap)}
`;
