import styled, { css } from 'styled-components';
import { CSSLiteral, matchBreakpoints } from 'app/styled';
import {
    caption10MediumFontStyle,
    caption11MediumFontStyle,
    caption12MediumFontStyle,
} from 'services/typography';
import { CounterPropsType } from 'components/indicator/counter/index';
import { TabsSize } from 'components/tabs';

type CounterWrapperProps = Required<Pick<CounterPropsType, 'size'>>;
type CounterTextProps = Required<Pick<CounterPropsType, 'size'>>;

type SizeMap = Record<TabsSize, CSSLiteral>;

/** Tabs size-dependent CSS */
const sizeMapWrapper: SizeMap = {
    large: css`
       padding: 2px 4px;
    `,
    medium: css`
        padding: 1px 4px;
    `,
    small: css`
        padding: 0.5px 4px 1.5px;
    `,
};

const sizeMapText: SizeMap = {
    large: css`
       ${caption12MediumFontStyle};
    `,
    medium: css`
        ${caption11MediumFontStyle};
    `,
    small: css`
        ${caption10MediumFontStyle};
    `,
};

function setWrapperStylesBySize({ size }: CounterWrapperProps) {
    return matchBreakpoints(size, sizeMapWrapper);
}

function setTextStylesBySize({ size }: CounterTextProps) {
    return matchBreakpoints(size, sizeMapText);
}

export const Wrapper = styled.div<CounterWrapperProps>`
    display: inline-block;
    background-color: ${({ theme }) => theme.fillIn.primary.mid};
    border-radius: 4px;
    ${setWrapperStylesBySize};
`;

export const Text = styled.span<CounterTextProps>`
    display: block;
    color: ${({ theme }) => theme.staticColors.white};
    ${setTextStylesBySize};
`;
