import React from 'react';

import Dic from 'services/dictionary';
import { Button } from 'components/button2';
import { ShowMoreWrapper } from './styled';

interface Props {
    nonShownCommentsCount: number,
    onClick: () => void,
    isLoading: boolean,
}
export const ShowMore = (props: Props) => {
    const {
        nonShownCommentsCount, onClick, isLoading,
    } = props;
    if (nonShownCommentsCount <= 0) return null;

    return (
        <ShowMoreWrapper>
            <Button onClick={onClick} isDisabled={isLoading} kind="ghost-primary" size="tiny">
                {Dic.word('wt_feed__widget_comments__show_more', { count: nonShownCommentsCount })}
            </Button>
        </ShowMoreWrapper>
    );
};
