import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { PopupWindow2 } from 'components/wt-popup2/popup-window';

export const DesktopWrapper = styled.div`
    ${mediaQuery.lt768} {
        display: none;
    }
`;

const mobileCss = `
    display: none;
    ${mediaQuery.lt768} {
        display: block;
    }
`;

export const MobileWrapper = styled.div`
    ${mobileCss}
`;

export const MobilePopupWindow2 = styled(PopupWindow2)`
    ${mobileCss}
`;
