import { connect } from 'react-redux';

import { subscribe } from './actions';
import { ActionSubscribe as ActionSubscribeComponent, RenderProps } from './components';

const mapDispatchToProps = {
    subscribe,
};

export const ActionSubscribe = connect(
    null,
    mapDispatchToProps,
)(ActionSubscribeComponent);

export type { RenderProps as ActionSubscribeRenderProps };
