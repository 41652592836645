import styled from 'styled-components';
import SliderComponent from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { body17RegularFontStyle, h4MediumFontStyle } from 'services/typography';
import { SliderItemSettingsType, SliderSettingResponsiveInterface } from 'components/slider/index';

const arrowButtonWidth = 32;

export const getCssByItemSetting = (
    { item, variableWidth }:
        {item: SliderItemSettingsType, variableWidth?: boolean},
) => {
    const indent = item?.indent;
    const paddingTop = item?.paddingTop;
    const paddingBottom = item?.paddingBottom;
    const halfIndent = indent && indent / 2;

    return (halfIndent || paddingTop || paddingBottom) && `
${
    halfIndent
        ? `margin: ${variableWidth ? `0 0 0 -${halfIndent}` : `0 -${halfIndent}`}px;`
        : ''
};
        .slick-slide {
            > div {
                ${halfIndent ? `margin: 0 ${halfIndent}px;` : ''};
                ${paddingTop ? `padding-top:${paddingTop}px;` : ''};
                ${paddingBottom ? `padding-bottom:${paddingBottom}px;` : ''};
            }
        }
    `;
};

const getCssByResponsiveItemSetting = ({ responsive, variableWidth }: {responsive: SliderSettingResponsiveInterface[] | undefined, variableWidth?: boolean}) => {
    return (responsive
        ? (responsive.map((itemSettings: SliderSettingResponsiveInterface) => {
            if (typeof itemSettings?.settings === 'string') {
                return '';
            }

            const responsiveBreakpoint = itemSettings?.breakpoint;
            const responsiveItemSettings = itemSettings?.settings?.item;
            const responsiveItemVariableWidth = itemSettings?.settings?.variableWidth || variableWidth;

            return responsiveBreakpoint && responsiveItemSettings
                && `@media (max-width: ${responsiveBreakpoint}px) {
                ${getCssByItemSetting({
                item: responsiveItemSettings,
                variableWidth: responsiveItemVariableWidth,
            })};
                   }`;
        })) : '');
};

const getSlickPrevNextCSS = ({ indent, variableWidth } : {indent?: number, variableWidth?: boolean}) => {
    const halfIndent = indent ? indent / 2 : 0;
    const halfWidthArrow = arrowButtonWidth / 2;
    return `
        .slick-prev {
            left: ${halfWidthArrow + halfIndent}px;
        }

        .slick-next {
            right: -${variableWidth ? halfWidthArrow : (halfWidthArrow - halfIndent)}px;
        }
    `;
};

const getCssByResponsiveNextPrev = ({ responsive, variableWidth } : {responsive: SliderSettingResponsiveInterface[] | undefined, variableWidth?: boolean}) => {
    return (responsive
        ? (responsive.map((itemSettings: SliderSettingResponsiveInterface) => {
            if (typeof itemSettings?.settings === 'string') {
                return '';
            }

            const responsiveBreakpoint = itemSettings?.breakpoint;
            const responsiveItemIndent = itemSettings?.settings?.item?.indent;
            const responsiveItemVariableWidth = itemSettings?.settings?.variableWidth || variableWidth;

            return responsiveBreakpoint && responsiveItemIndent
                && `@media (max-width: ${responsiveBreakpoint}px) {
                        ${getSlickPrevNextCSS({
                indent: responsiveItemIndent,
                variableWidth: responsiveItemVariableWidth,
            })};
                   }`;
        })) : '');
};

export const Slider = styled(SliderComponent)<{item?: SliderItemSettingsType, width?: number, variableWidth?: boolean }>`
    ${({ width }) => (width ? `width: ${width}px;` : '')};

    ${({ item, variableWidth }) => item && getCssByItemSetting({ item, variableWidth })};
    ${({ responsive, variableWidth }) => getCssByResponsiveItemSetting({ responsive, variableWidth })};

    .carousel-slider {
        overflow: unset;
    }

    ${(props) => {
        const indent = props?.item?.indent;
        const variableWidth = props?.variableWidth;
        return getSlickPrevNextCSS({ indent, variableWidth });
    }};
    ${({ responsive, variableWidth }) => getCssByResponsiveNextPrev({ responsive, variableWidth })};

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        > div {
            box-sizing: border-box;
            height: 100%;
        }
    }

    .slick-dots {
        position: relative;
        bottom: 0;
        font-size: 14px;
        line-height: 14px;

        li {
            width: 8px;
            height: 2px;
            background: ${({ theme }) => theme.fillIn.secondary.muted};
            border-radius: 4px;
            margin: 0 4px 12px ;
            box-shadow: none;
            opacity: 1;
            transition: all 350ms;
            outline: none;
            pointer-events: none;

            &.slick-active {
                background: ${({ theme }) => theme.fillIn.primary.mid};
                width: 16px;
            }

            button:before {
                display: none;
            }
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ArrowWrapper = styled.div`
    z-index: 2;
    outline: 0;
    height: 100%;
    width: auto;
    cursor: default;
    transition: opacity 350ms;
    opacity: 1;

    &.slick-disabled {
        opacity: 0;
        pointer-events: none;
    }

    svg {
        color: #fff;
    }

    &:before {
        display: none;
    }
`;

export const ArrowWrapperRight = styled(ArrowWrapper)``;

export const ArrowWrapperLeft = styled(ArrowWrapper)``;

export const ButtonIcon = styled.button`
    position: absolute;
    cursor: pointer;
    outline: 0;
    right: 0;
    opacity: 1;
    margin: 0;
    padding: 4px;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
    border-radius: 32px;
    width: ${arrowButtonWidth}px;
    height: ${arrowButtonWidth}px;
    top: calc(50% - ${arrowButtonWidth}px/2);
    border: none;
`;

export const Title = styled.div`
    display: flex;
    align-items: inherit;

    h4 {
        ${h4MediumFontStyle};
        color: ${({ theme }) => theme.label.primary};
        margin: 0;
    }

    span {
        ${body17RegularFontStyle};
        color: ${({ theme }) => theme.label.placeholder};
        margin-left: 4px;
    }
`;
