import styled, { createGlobalStyle } from 'styled-components';

import { ifProp } from 'styled-tools';
import { mediaQuery } from 'app/styled';

import { ContextActions as ContextActionsComponent } from 'components/context-actions';

const headerHeight = 56;

export const ContextActionsGlobalStyle = createGlobalStyle<{isOpened: boolean}>`
    body, body.page, body.page.reactour-blocked {
        ${mediaQuery.lt768} {
          ${ifProp('isOpened', 'overflow: hidden', '')};
        }
    }
`;

export const ContextActions = styled(ContextActionsComponent)`
    .tippy-popper {
        top: 8px;
        ${mediaQuery.lt768} {
            top: ${headerHeight}px !important;
            width: 100%;
            left: 0;
            transform: none !important;
            max-width: none;
            height: calc(100vh - ${headerHeight}px);
            margin: 0;
            padding: 0;
            box-sizing: content-box;
        }
    }

    .tippy-tooltip {
        ${mediaQuery.lt768} {
            bottom: unset !important;
            top: 0;
            transform: unset !important;
            box-shadow: none;
            border-radius: unset;
            height: 100%;
        }
    }

    .tippy-content {
        ${mediaQuery.lt768} {
            height: 100%;
            > div {
                height: 100%;
                > div {
                    height: 100%;
                    padding: 0;
                }
            }
        }
    }
`;
