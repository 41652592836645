import {
    useCallback, useEffect, useRef, useState,
} from 'react';

type SetStateAction<S> = S | ((prevState: S) => S);

function useStateCallback<S>(initialState: S | (() => S)): [S, (value: SetStateAction<S>, callback?: (state: S) => void) => void] {
    const [state, _setState] = useState(initialState);

    const callbackRef = useRef<((state: S) => void) | null | undefined>(null);
    const isFirstCallbackCall = useRef(true);

    const setState = useCallback((setStateAction: SetStateAction<S>, callback?: (state: S) => void) => {
        callbackRef.current = callback;
        _setState(setStateAction);
    }, []);

    useEffect(() => {
        if (isFirstCallbackCall.current) {
            isFirstCallbackCall.current = false;
            return;
        }
        callbackRef.current?.(state);
    }, [state]);

    return [state, setState];
}

export default useStateCallback;
