import styled from 'styled-components';
import { prop, ifProp } from 'styled-tools';

import { matchBreakpoints } from 'app/styled';
import { body17RegularFontStyle, body14RegularFontStyle } from 'services/typography';
import { Button } from 'components/button2';
import { NotifyProps } from './types';

export const Content = styled.div`
    color: ${({ theme }) => theme.label.inverse};
    transition: all .5s linear;
`;

export const NotifyButton = styled(Button)`
    &:focus {
        box-shadow: none;
    }
`;

const buildSizes = ({ size }: Pick<NotifyProps, 'size'>) => {
    return matchBreakpoints(size, {
        medium: `
            ${body17RegularFontStyle};
            ${Content} {
                padding: 12px 24px;
            }
        `,
        small: `
            ${body14RegularFontStyle};
            ${Content} {
                padding: 10px 26px;
            }
        `,
    });
};

export const Wrapper = styled.div<Pick<NotifyProps, 'size' | 'buttonProps'>>`
    text-align: center;
    display: flex;
    background-color: ${({ theme }) => theme.fillIn.secondary.highest};
    border-radius: 8px;
    ${buildSizes};
    ${Content} {
        ${ifProp('buttonProps', 'padding-right: 0')};
    }
`;

export const Centerer = styled.div`
    bottom: 24px;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    z-index: 10100;
    ${Wrapper} {
        margin-left: -100%;
    }
`;
