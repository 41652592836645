import React, { useCallback, useState } from 'react';
import { ReactElementLike } from 'prop-types';
import * as Sentry from '@sentry/browser';

import { Id, GeneratorType, ActionResult } from 'types';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { ButtonIcon } from 'components/button-icon';
import { Subscribe, SubscribePayloadData } from '../actions';
import { SubscribeButton, Props as RenderProps } from './SubscribeButton';

interface DispatchProps {
    subscribe: Subscribe,
}
interface Props {
    data: {
        generatorId: Id,
        generatorType: GeneratorType,
    },
    isSubscribed: boolean,
    className: string,
    clickable?: boolean,
    buttonProps?: Parameters<typeof ButtonIcon>[0],
    customRender: ((props: RenderProps) => ReactElementLike) | null,
}
export const ActionSubscribe = (props: Props & DispatchProps) => {
    const {
        isSubscribed, clickable, subscribe, className, data: { generatorId, generatorType }, buttonProps,
        customRender: customRenderer,
    } = props;

    const csrf = useCSRFToken();
    const currentUser = useCurrentUser();
    const [isLoading, setIsLoading] = useState(false);

    const subscribeHandler = useCallback(() => {
        if (clickable) {
            const handleError = (action: ActionResult<SubscribePayloadData>) => {
                let errorMessage = Dic.word('server_error');
                if (action?.payload) {
                    const { payload: { data: { result } }, error: hardError, pureError } = action;
                    if (hardError) {
                        Sentry.captureException(pureError);
                        notify.error(errorMessage);
                    } else if ((result && !result.ok)) {
                        const { exception, error } = result;
                        if (exception || error) {
                            if (exception) {
                                errorMessage = exception.message || Dic.word(exception.message_key);
                            } else {
                                errorMessage = Dic.word(error);
                            }
                        }
                        Sentry.captureMessage(exception?.message || exception?.message_key || error || errorMessage);
                        notify.error(errorMessage);
                    }
                }
            };
            setIsLoading(true);
            subscribe({
                generatorId,
                generatorType,
                action: isSubscribed ? 'unsubscribe' : 'subscribe',
                csrf,
                currentUserId: currentUser.id,
            })
                .then(handleError)
                .catch(handleError)
                .finally(() => setIsLoading(false));
        }
    }, [clickable, subscribe, generatorId, generatorType, isSubscribed, csrf]);

    const subscribeAfterRegistration = TODOuseActionAfterAuthentication(subscribeHandler);

    const renderProps: RenderProps = {
        className,
        isSubscribed,
        onClick: subscribeAfterRegistration,
        clickable,
        isLoading,
        generatorType,
        buttonProps,
    };

    if (customRenderer) {
        return customRenderer(renderProps);
    }

    return (
        <SubscribeButton {...renderProps} />
    );
};
ActionSubscribe.defaultProps = {
    className: '',
    clickable: true,
    buttonProps: {},
    customRender: null,
};

export type { RenderProps };
