import React, {
    useRef, useEffect, ReactNode, ForwardedRef,
} from 'react';

import { useListCursor } from 'hooks/useListCursor';

import { ListWrapper, ItemDivWrapper, ItemHrefWrapper } from './styled';

export type Props<T extends { key: string | number }> = {
    items: T[];
    renderItem: (item: T, index?: number) => React.ReactNode;
    onSelect?: (item: T, index?: number, event?: KeyboardEvent) => void;
    maxHeight?: string;
    cursorElement?: EventTarget | null;
    focusFirst?: boolean;
    className?: string;
    itemHref?: (item: T, index?: number) => string;
    wrapperRef?: ForwardedRef<HTMLDivElement>,
    children?: ReactNode;
};

const isNotVisible = (ele: HTMLDivElement, container: HTMLDivElement) => {
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    return eleTop < containerTop ? -1 : eleBottom > containerBottom ? 1 : 0;
};

type ListType = <T extends { key: string | number }>(props: Props<T>, ref: ForwardedRef<HTMLDivElement>) => React.ReactElement;
export const List: ListType = (props, ref) => {
    const {
        items,
        renderItem,
        onSelect,
        cursorElement,
        maxHeight,
        focusFirst,
        className,
        itemHref,
        wrapperRef: wrapperRefProp,
        children = null,
    } = props;

    const [current, index] = useListCursor(items, {
        element: cursorElement || null,
        onSelect: (x, i, event) => onSelect && onSelect(x, i, event),
        focusFirst,
    });
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const setRefs = (element: HTMLDivElement) => {
        wrapperRef.current = element;
        if (typeof ref === 'function') ref(element);
        if (ref && typeof ref === 'object' && Object.keys(ref).length) ref.current = element;
        if (typeof wrapperRefProp === 'function') wrapperRefProp(element);
        if (wrapperRefProp && typeof wrapperRefProp === 'object') wrapperRefProp.current = element;
    };

    useEffect(() => {
        if (wrapperRef.current) {
            const selectedNode: Element | boolean = index !== null && wrapperRef.current?.children?.[index];
            if (selectedNode instanceof HTMLDivElement) {
                const nonVisibility = selectedNode && isNotVisible(selectedNode, wrapperRef.current);

                if (selectedNode && nonVisibility) {
                    wrapperRef.current.scrollTop = nonVisibility < 0
                        ? selectedNode.offsetTop
                        : selectedNode.offsetTop - wrapperRef.current.clientHeight + selectedNode.clientHeight;
                }
            }
        }
    }, [index]);

    return (
        <ListWrapper maxHeight={maxHeight} ref={setRefs} className={className}>
            {items.map((x, i) => (
                itemHref ? (
                    <ItemHrefWrapper
                        key={x.key}
                        current={!!(current && current.key === x.key)}
                        href={itemHref(x, i)}
                        onClick={() => onSelect && onSelect(x, i)}
                    >
                        {renderItem(x, i)}
                    </ItemHrefWrapper>
                ) : (
                    <ItemDivWrapper
                        key={x.key}
                        current={!!(current && current.key === x.key)}
                        onClick={() => onSelect && onSelect(x, i)}
                    >
                        {renderItem(x, i)}
                    </ItemDivWrapper>
                )))}
            {children}
        </ListWrapper>
    );
};

export const ListForwardedRefBuilder = <T extends { key: string | number }>() => React.forwardRef<HTMLDivElement, Props<T>>(List);
