import styled from 'styled-components';

import { body17RegularFontStyle } from 'services/typography';
import { Button } from 'components/button2';

export const Wrapper = styled.div`
    margin: 50px 16px;
`;

export const Text = styled.div`
    ${body17RegularFontStyle};
    color: ${({ theme }) => theme.label.placeholder};
    text-align: center;
`;

export const ButtonAction = styled(Button)`
    margin: 8px auto 0;
`;
