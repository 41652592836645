import { staticUrl } from './constants';

let loaded = false;
let loading = false;
const onLoadedCallbacks = [];

const initOrUpdateModule = (activeProject) => {
    // vls: если проекта нет, то пока не инициализируем WT User Metrics, т.к. не понятно для какого проекта сливать данные
    if (!activeProject) {
        return;
    }

    window.WT.userMetrics.setProject(activeProject);
};

const loadModule = () => {
    if (loading) {
        return;
    }

    loading = true;

    const script = document.createElement('script');
    script.src = `${staticUrl}/js/external/wt-user-metrics-module.min.js`;
    script.async = 'async';
    script.onerror = () => {
        loading = false;
        onLoadedCallbacks.splice(0, onLoadedCallbacks.length);
    };
    script.onload = () => {
        loaded = true;
        loading = false;
        onLoadedCallbacks.forEach(callback => callback());
        onLoadedCallbacks.splice(0, onLoadedCallbacks.length);
    };
    document.head.appendChild(script);
};

/**
 * @return {Promise<WT.userMetrics>}
 */
export async function wtUserMetricsGet() {
    if (loaded) {
        return window.WT.userMetrics;
    }

    return new Promise((resolve) => {
        onLoadedCallbacks.push(() => {
            resolve(window.WT.userMetrics);
        });
    });
}

export function wtUserMetricsLoadOrUpdate(activeProject) {
    if (loaded) {
        initOrUpdateModule(activeProject);
        return;
    }

    onLoadedCallbacks.push(() => initOrUpdateModule(activeProject));
    loadModule();
}
