import store from '../store';
import { lsProvider } from './localStorageProvider';
import { menuLogger } from './menuLogger';
import {
    setUser,
    logOuted,
} from '../actions';
import { getGlobalAuthState } from './globalAuthState';
import { globalAuthStaticUrl } from './constants';

import resolveCallBack from './resolveCallBack';

const _callbacksAreWaitingLoadingOfAuthModule = [];
let _loaded = false;

const metricsProcessPopupStartList = {};
const metricsProcessPopupSentList = {};

const sessionKeyAuthenticationOrRegistrationProcess = 'wt_menu_is_authentication_or_registration_process_in_wt_auth';
const globalAuthState = getGlobalAuthState();
export const wtAuthModuleIsAuthenticationOrRegistrationProcess = () => {
    return lsProvider.get(sessionKeyAuthenticationOrRegistrationProcess);
};
const wtAuthModuleSetAuthenticationOrRegistrationProcess = (process) => {
    if (process) {
        lsProvider.set(sessionKeyAuthenticationOrRegistrationProcess, true);
    } else {
        lsProvider.delete(sessionKeyAuthenticationOrRegistrationProcess);
    }
};

// dg: Преобразуем CamelCase в underscore_case
const normalizePopupIdForMetric = popupId => popupId.replace(/([A-Z]+)/g, (_, match) => `_${match.toLowerCase()}`).replace(/^_/, '');

const handleHideWtAuthPopup = (popupId, abandon) => {
    if (!popupId) {
        return;
    }

    if (metricsProcessPopupSentList[popupId]) {
        return;
    }

    if (!metricsProcessPopupStartList[popupId]) {
        menuLogger.error('Close popup without open');

        return;
    }

    const popupProcessTime = Date.now() - metricsProcessPopupStartList[popupId];
    window.WT.menu.metrics.addTimingForProject('onboarding', 'flow', popupProcessTime / 1000, [
        window.WT.menu.getConfig().currentState.activeProject,
        normalizePopupIdForMetric(popupId),
        abandon ? 'abandon' : 'process',
    ]);

    metricsProcessPopupSentList[popupId] = true;
};

const wtAuthModuleCall = (callback) => {
    if (window.WT.auth) {
        callback(window.WT.auth);
    } else {
        _callbacksAreWaitingLoadingOfAuthModule.push(callback);
    }
};

/**
 * @param {Object|undefined} result
 * @private
 */
const _updateUserToken = (result) => {
    if (result && result.token) {
        const { user } = store.getState();
        user.token = result.token;
        store.dispatch(setUser(user));
    }
};

let isPlannedShowUserAgreement = false;
let showUserAgreementAfterCloseCurrentPopup = false;
let userUpdatedAfterAgreedWithAgreementResolve = null;
const showUserAgreementIfNeed = (user) => {
    const pathsWithoutAgreementRequirement = [
        '/terms',
        '/privacy-policy',
    ];
    if (
        user.id &&
        typeof user.agreement !== 'undefined' &&
        !user.agreement &&
        !pathsWithoutAgreementRequirement.includes(window.location.pathname) &&
        !isPlannedShowUserAgreement
    ) {
        isPlannedShowUserAgreement = true;
        // vls: если сейчас показывается какой-то попап в WT Auth, то покажем UserAgreement, после его скрытия
        if (window.__GLOBAL_AUTH__ && globalAuthState.isActive) {
            showUserAgreementAfterCloseCurrentPopup = true;
        } else if (window.WT.auth && window.WT.auth.isInitialized() && window.WT.auth.getCurrentPopupId()) {
            showUserAgreementAfterCloseCurrentPopup = true;
        } else {
            window.WT.menu.showUserAgreement();
        }
    }

    if (user.id && user.agreement && userUpdatedAfterAgreedWithAgreementResolve) {
        userUpdatedAfterAgreedWithAgreementResolve();
    }
};

const _updateWtAuth = (auth, activeProjectData, activeProject, user) => {
    if (activeProjectData.wtAuthOptions) {
        auth.updateConfig({
            options: activeProjectData.wtAuthOptions,
        });
    }

    auth.updateConfig({
        options: {
            project: activeProject,
            csrf: user ? user.csrf || '' : '',
        },
    });

    if (user && user.token) {
        auth.updateConfig({
            options: {
                userToken: user.token,
            },
        });
    }

    if (user) {
        showUserAgreementIfNeed(user);
    }
};

let onProfileUpdated = null;
let originalStyle = null;

const _wtAuthModuleLoad = (user, activeProjectData, lcid, callbacks, activeProject, forceLoadingOfWtAuth, customScenario) => {
    if (!user && !window.__GLOBAL_AUTH__) {
        return;
    }

    const id = user ? user.id : undefined;

    showUserAgreementIfNeed(user || {});

    // vls: для аутентифицированных пользователей пока нет смысла грузить новые попапы аутентификации,
    // кроме случая, когда принудительно не хотим их загрузить
    if ((id && !forceLoadingOfWtAuth)) {
        // vls: если WT Auth загрузчик не грузится, то процесс регистарции/аутентификации не может идти, т.к.
        // пользователь уже аутентифицирован
        wtAuthModuleSetAuthenticationOrRegistrationProcess(false);

        return;
    }

    _loaded = true;
    const script = document.createElement('script');
    script.src = `${globalAuthStaticUrl}/globalauth/wt-auth.module.js`;
    script.async = 'async';
    script.onerror = () => {
        _loaded = false;
    };
    script.onload = () => {
        let registered = false;
        let authenticated = false;

        if (!window.WT.auth) {
            return;
        }

        const auth = window.WT?.auth;
        if (auth) {
            const socialNetworks = lcid === 'ru'
                ? ['facebook', 'vkontakte', 'twitter']
                : ['facebook', 'twitter', 'linkedin', 'stocktwits'];
            const { user } = store.getState();
            auth.init({
                options: {
                    lcid: lcid || user.lcid,
                    projectName: 'limex',
                    template: {
                        SignIn: {
                            socialNetworks,
                        },
                        SignUp: {
                            socialNetworks,
                        },
                        BrokerBind: {
                            socialNetworks,
                        },
                    },
                    callbacks: {
                        onAuthorizationStateChange: (visitor) => {
                            window.WT.menu.metrics.addEventForProject('traffic', 'login');
                            window.WT.menu.metrics.addEventForProject('onboarding', 'login_success', [window.WT.menu.getConfig().currentState.activeProject]);
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onAfterAuthentication', [true]);
                        },
                        onStart: () => {
                            const pageWrapper = document.querySelector('.page__in');
                            const element = pageWrapper || document.body;
                            /* Взял механизм из comon/static/www/react/sources/services/page/light.js */
                            if (element) {
                                if (!originalStyle) {
                                    originalStyle = {
                                        top: element.style.top,
                                        position: element.style.position,
                                        width: element.style.width,
                                    };
                                }
                                element.style.top = `-${window.pageYOffset}px`;
                                element.style.position = 'fixed';
                                if (!element.style.width) {
                                    element.style.width = '100%';
                                }
                                if (element === pageWrapper) {
                                    window.scrollTo(0, 0);
                                }
                            }
                            /* end */
                            globalAuthState.onStateChange({ isActive: true });
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onWtAuthIsShown', [
                                {
                                    popupId: globalAuthState.currentSceneName,
                                    previousPopupId: globalAuthState.previewsSceneName,
                                },
                                false
                            ]);
                        },
                        onFinish: () => {
                            /* Взял механизм из comon/static/www/react/sources/services/page/light.js */
                            const pageWrapper = document.querySelector('.page__in');
                            const element = pageWrapper || document.body;
                            const top = -parseInt(element.style.top, 10);
                            if (originalStyle) {
                                Object.assign(element.style, originalStyle);
                            }
                            window.scrollTo(0, top);
                            /* end */
                            const { callbacks: storeCallbacks } = store.getState();
                            globalAuthState.onStateChange({ isActive: false, currentScene: null });
                            resolveCallBack(storeCallbacks, 'onWtAuthIsClosed', [{
                                registered,
                                authenticated,
                            }]);
                        },
                        onSceneAppear: (scene) => {
                            globalAuthState.onStateChange({ currentScene: scene.name });
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onWtAuthIsShown', [
                                {
                                    popupId: globalAuthState.currentSceneName,
                                    previousPopupId: globalAuthState.previewsSceneName,
                                },
                                false
                            ]);
                        },
                        onAuthentication: (data) => {
                            authenticated = true;
                            window.WT.menu.metrics.addEventForProject('traffic', 'login');
                            window.WT.menu.metrics.addEventForProject('onboarding', 'login_success', [window.WT.menu.getConfig().currentState.activeProject]);
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onAfterAuthentication', []);
                        },
                        onRegistration: (data) => {
                            registered = true;
                            window.WT.menu.metrics.addEventForProject('traffic', 'registration');
                            window.WT.menu.metrics.addEventForProject('onboarding', 'registration_success', [window.WT.menu.getConfig().currentState.activeProject]);
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onAfterRegistration', []);
                        },
                        onLogout: () => {
                            store.dispatch(logOuted());
                            window.WT.menu.updateUser();
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onAfterLogout', []);
                        },
                        onTokenChange: (token) => {
                            const { callbacks: storeCallbacks } = store.getState();
                            resolveCallBack(storeCallbacks, 'onAfterTokenChange', []);
                        }
                    }
                }
            })
        }

        _callbacksAreWaitingLoadingOfAuthModule.forEach((callback) => {
            callback(window.WT.auth);
        });
        _callbacksAreWaitingLoadingOfAuthModule.splice(0, _callbacksAreWaitingLoadingOfAuthModule.length);
    };

    document.head.appendChild(script);
};

const _wtAuthModuleUpdate = (user, auth, activeProjectData, lcid, activeProject) => {
    if (lcid) {
        auth.updateConfig({
            options: {
                lcid,
            },
        });
    }

    _updateWtAuth(auth, activeProjectData, activeProject, user);
};

export function wtAuthModuleLoadOrUpdate(user, activeProjectData, lcid, callbacks, activeProject, forceLoadingOfWtAuth, customScenario) {
    if (_loaded) {
        wtAuthModuleCall((auth) => {
            _wtAuthModuleUpdate(user, auth, activeProjectData, lcid, activeProject);
        });

        return;
    }

    // vls: загрузка модуля WT Auth при необходимости
    _wtAuthModuleLoad(user, activeProjectData, lcid, callbacks, activeProject, forceLoadingOfWtAuth, customScenario);
}

export function wtAuthModuleFillProfile(successCallback, displayParams, hideSpinner) {
    wtAuthModuleCall((auth) => {
        auth.initIfNeed(!hideSpinner).then((auth) => {
            onProfileUpdated = successCallback;
            if (window.__GLOBAL_AUTH__) {
                if (displayParams.showIfNeeded) {
                    // ae: В старой версии WT Auth делал запрос на
                    // UrlProcessor.page('wt_auth_profile_check_if_need_show_and_mark_as_showed').path()
                    // и по результатам показывал или не показывал попап. Но новой версии это должен проверить пользователь
                    // Global Auth и по результатам принять решение о необходимости дозапроса данных
                    // Пока шапка этого делать не умеет, отключаю фичу. Фича описана в задаче WTT-8069
                    // Когда (если) надо будет сделать, рализацию можно скопировать из файла
                    // comon/static/www/comon-frontend/project/wt-auth/src/auth-services/profile/index.js#39
                    return;
                }
                const mode = displayParams.fillProfileType === 'registration' ? 'registration' : 'default';
                const required = Array.isArray(displayParams.requiredFields)
                    ? displayParams.requiredFields.reduce((acc, field) => {
                        if (
                            field === 'email'
                            || field === 'avatar'
                            || field === 'name'
                            || field === 'last-name'
                            || field ===  'phone'
                        ) {
                            acc.push(field)
                        }
                        return acc;
                    }, [])
                    : [];
                auth.show('userProfile', { mode , required  });
            } else {
                auth.show('FillProfile', displayParams);
            }
        });
    });
}

export default wtAuthModuleCall;
