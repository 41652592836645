import {
    RefObject,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';

/**
 * Executes a callback when the click event happens outside of the element's bounds
 *
 * @example
 * const elementRef = useRef(null);
 * useClickOutside(elementRef, (e) => {
 *   console.log('clicked outside of div');
 * })
 *
 * return (
 *   <div ref={elementRef} />
 * )
 */
export const useClickOutside = (
    elementRef: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    handler: (e: MouseEvent) => void,
) => {
    const handlerRef = useRef(handler);
    const isMouseDownOnElement = useRef(false);
    useLayoutEffect(() => {
        handlerRef.current = handler;
    });

    useEffect(() => {
        const isContains = (e: MouseEvent) => {
            if (Array.isArray(elementRef)) {
                return elementRef.every((ref) => ref.current && !ref.current.contains(e.target as HTMLElement));
            }
            return elementRef.current && !elementRef.current.contains(e.target as HTMLElement);
        };
        const handleMouseDown = (e: MouseEvent) => {
            if (isContains(e)) isMouseDownOnElement.current = true;
        };
        const handleMouseUp = (e: MouseEvent) => {
            if (isContains(e) && isMouseDownOnElement.current) {
                handlerRef.current(e);
            }
            isMouseDownOnElement.current = false;
        };

        document.addEventListener('mousedown', handleMouseDown, true);
        document.addEventListener('mouseup', handleMouseUp, true);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown, true);
            document.removeEventListener('mouseup', handleMouseUp, true);
        };
    }, [elementRef]);
};
