import styled from 'styled-components';

export const AnchorStyled = styled.div`
    position: relative;
`;

export const BoxStyled = styled.div<{ left?: string; top?: string; width?: string; maxWidth?: string }>`
    position: absolute;
    left: ${({ left }) => (left ?? 'auto')};
    top: ${({ top }) => (top ?? 'auto')};
    z-index: 999;
    overflow: hidden;
    width: ${({ width, maxWidth }) => {
        if (maxWidth) {
            return '100%';
        }
        if (width) {
            return width;
        }
        return 'auto';
    }};
    ${({ theme }) => `background: ${theme.bg.secondary};`}
    ${({ theme }) => `box-shadow: ${theme.shadow.popover};`}
    border-radius: 8px;
`;
