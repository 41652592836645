/** Represents an array of validation errors */
export class ValidationError {
    public readonly errors: { field: string, message: string; }[];

    constructor(errors: ValidationError['errors']) {
        // dmh: c бека иногда приходят массивы, а иногда - массивоподобные объекты
        // в виде { 0: ..., 1: ..., someProp: ... }
        // Конвертируем все в массив:
        this.errors = Object.keys(errors)
            .filter((key) => !isNaN(Number(key)))
            .map((key) => errors[Number(key)]);
    }

    public toString() {
        return `ValidationError: ${this.errors.toString()}`;
    }
}
