import React, { FC } from 'react';
import { AdsBlock } from 'widgets/ads-block';

import {
    AdWrapper, Chip, Headline, Image, DisplayLink, LinkWrapper, TextContent,
} from './styled';

export const AdsItem: FC = () => {
    return (
        <AdsBlock>
            {({
                url,
                headline,
                mainImg,
                icon,
                sponsoredText,
                displayUrl,
            }) => (
                <AdWrapper href={url} target="_blank" rel="noreferrer">
                    {mainImg && <Image alt="main_img" src={mainImg.img.url} />}
                    {sponsoredText && mainImg && <Chip>{sponsoredText.data.value}</Chip>}
                    {(headline || displayUrl) && (
                        <TextContent>
                            {headline && <Headline title={headline.title.text}>{headline.title.text}</Headline>}
                            {displayUrl && (
                                <LinkWrapper>
                                    {icon && <img src={icon.img.url} alt="icon" />}
                                    <DisplayLink>{displayUrl.data.value}</DisplayLink>
                                </LinkWrapper>
                            )}
                        </TextContent>
                    )}
                </AdWrapper>
            )}
        </AdsBlock>
    );
};
