export default function (text) {
    const div = document.createElement('div');
    div.innerHTML = text;

    [...div.querySelectorAll('a[data-mention-person-id], a[data-reply-to-person-id]')].forEach((link) => {
        div.replaceChild(document.createTextNode(`@${link.innerText}`), link);
    });

    return div.innerHTML;
}
