// TODO Вынести ссылки в конфиг

export const MENU_ITEMS = [
    {
        dicWord: 'wt_menu__menu_limex__feed_text',
        href: '/feed',
        className: 'reactour__step1 autotest__menu__feed',
    },
    {
        dicWord: 'wt_menu__menu_limex__shop_text',
        href: '/shop',
        className: 'reactour__step2 autotest__menu__marketplace',
    },
];
