import React, { SyntheticEvent } from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';
import { ButtonProps } from './types';
import {
    ButtonWrapper,
    ProcessingIconContainer,
    ButtonContentContainer,
    ButtonTextContainer,
} from './styled';

// MARK: - Types

/** A tag name of the element used to render the button */
type ButtonTagName = 'a' | 'button';

/** Props for buttons using an <a /> element */
export type LinkButtonProps = ButtonProps & {
    href: string;
    target?: string;
};

export type { ButtonProps };

/** Props for polymorphic button implementation component */
type ButtonImplementationProps<TagName extends ButtonTagName> = (ButtonProps &
    Partial<LinkButtonProps>) & {
    /** A tag name of the button element. Default - "button" */
    tagName: TagName;
};

// MARK: - Common Implementation Component

function ButtonImplementation<TagName extends ButtonTagName>({
    className,
    tagName,
    children: text,
    size = 'medium',
    kind = 'primary',
    type = 'standard',
    isProcessing,
    isDisabled,
    isPressed,
    icon: Icon,
    iconPos = 'left',
    onClick: onClickProp,
    block,
    href,
    target = '_self',
}: ButtonImplementationProps<TagName>) {
    const onClick = isDisabled || isProcessing ? preventDefaultHandler : onClickProp;
    const linkProps = tagName === 'a' ? { href, target } : {};
    return (
        <ButtonWrapper
            as={(tagName as unknown) as undefined}
            className={className}
            size={size}
            type={type}
            kind={kind}
            text={text}
            isDisabled={isDisabled}
            isProcessing={isProcessing}
            isPressed={isPressed}
            icon={Icon}
            iconPos={iconPos}
            onClick={onClick}
            block={block}
            {...linkProps}
        >
            <div tabIndex={-1}>
                {isProcessing && (
                    <ProcessingIconContainer>
                        <ProcessingIcon size={24} />
                    </ProcessingIconContainer>
                )}
                <ButtonContentContainer
                    style={{ visibility: isProcessing ? 'hidden' : 'visible' }}
                >
                    {Icon && <Icon size={24} />}
                    {text && <ButtonTextContainer>{text}</ButtonTextContainer>}
                </ButtonContentContainer>
            </div>
        </ButtonWrapper>
    );
}

// MARK: - Element-specific Components

export const Button = (props: ButtonProps) => (
    <ButtonImplementation {...props} tagName="button" />
);

export const LinkButton = (props: LinkButtonProps) => (
    <ButtonImplementation {...props} tagName="a" />
);

// MARK: - Helpers

function preventDefaultHandler(e: SyntheticEvent) {
    e.preventDefault();
}
