import React from 'react';

import { Person, Id } from 'types';

export type AvatarPropTypes = {
    person: Partial<Person> & {
        title?: string | null,
        photo?: string | null,
    },
    linkHref: string,
    target: string,
    src: string | null,
    alt: string,
    title: string,
    id: Id,
    size: string,
    bordered: boolean,
    onClickLink: (e: React.MouseEvent<HTMLElement>) => void,
    enabledUserTooltip: boolean,
    userTooltipOptions: {
        allowCurrentUser: boolean,
    },
    className: string,
    square: boolean,
    children: Element | string | null,
};

export const AvatarDefaultProps = {
    person: {},
    target: '_self',
    linkHref: '',
    src: '',
    alt: '',
    title: '',
    id: '',
    size: 'S',
    bordered: true,
    onClickLink: () => undefined,
    className: '',
    square: false,
    enabledUserTooltip: false,
    userTooltipOptions: {
        allowCurrentUser: false,
    },
    children: null,
};
