import { ThunkResultFn } from 'types';
import { createRequestActionTypes } from 'services/create-action-types';
import { GlobalStateForFeed } from '../types';
import {
    sendLikeGenerator, SendLikeGeneratorData, SendLikePayloadData, SendLikePayloadPass,
} from '../_shared/_sub/like/actionGenerators';

export const SEND_RATING_POST_ACTION_TYPES = createRequestActionTypes('FEED_RECORD_SAVE_RATING_POST');
export const sendPostRatingReaction = (
    data: SendLikeGeneratorData,
    pass: SendLikePayloadPass,
): ThunkResultFn<SendLikePayloadData, SendLikePayloadPass, GlobalStateForFeed> => {
    return sendLikeGenerator(SEND_RATING_POST_ACTION_TYPES, data, pass);
};
