import React, { MouseEventHandler, useMemo } from 'react';

import { CurrencyCode } from 'types/currency';
import { PriceDifferenceFormatter } from 'widgets/formatting/price-difference-formatter';
import { PriceFormatter } from 'widgets/formatting';
import {
    Wrapper, Actions, Side, Names, Ticker, TickerSuggestion, Name, NameSuggestion, Price, PriceValue, PriceDiff, Logo,
} from './styled/painted4-instrument';

interface Props {
    ticker: string,
    name: string,
    price: number | null,
    changePercent: number | null,
    currency: CurrencyCode | null,
    dcpl: number,
    percentDcpl: number,
    url: string,
    logoUrl?: string,
    className?: string,
    onClick?: MouseEventHandler,
    actions?: {
        subscribe: React.ReactNode,
    }
    isSuggestion?: boolean,
}
export const Painted4Instrument = (props: Props) => {
    const {
        ticker, name, price, changePercent, currency, dcpl, percentDcpl, actions, className, onClick, url, logoUrl, isSuggestion,
    } = props;
    const isGrow = useMemo(() => Boolean(changePercent && changePercent >= 0), [changePercent]);

    return (
        <Wrapper isGrow={isGrow} className={className} onClick={onClick}>
            <Side>
                {logoUrl && (
                    <Logo>
                        <a href={url} target="_blank" rel="noreferrer">
                            <img src={logoUrl} alt={ticker} />
                        </a>
                    </Logo>
                )}
                <Names hasLogo={Boolean(logoUrl)}>
                    {isSuggestion
                        ? (
                            <>
                                <TickerSuggestion>{ticker}</TickerSuggestion>
                                <NameSuggestion>{name}</NameSuggestion>
                            </>
                        )
                        : (
                            <>
                                <Ticker href={url} target="_blank" rel="noreferrer">{ticker}</Ticker>
                                <Name href={url} target="_blank" rel="noreferrer" title={name}>{name}</Name>
                            </>
                        )}
                </Names>
            </Side>
            <Side>
                <Price>
                    {(price && (
                        <PriceFormatter
                            price={Number(price)}
                            currency={currency}
                            minimumFractionDigits={dcpl}
                            maximumFractionDigits={dcpl}
                        >
                            {(localizedPrice) => (
                                <PriceValue>
                                    {localizedPrice}
                                </PriceValue>
                            )}
                        </PriceFormatter>

                    )) || null}
                    {(price && changePercent && (
                        <PriceDifferenceFormatter
                            price={Number(price)}
                            currency={currency}
                            minimumFractionDigits={dcpl}
                            maximumFractionDigits={dcpl}
                            percentMaximumFractionDigits={percentDcpl}
                            percentMinimumFractionDigits={percentDcpl}
                            percentage={Number(changePercent)}
                        >
                            {(priceDiffString) => (
                                <PriceDiff>
                                    {priceDiffString}
                                </PriceDiff>
                            )}
                        </PriceDifferenceFormatter>

                    )) || null}
                </Price>
                {actions && (
                    <Actions>
                        {actions.subscribe}
                    </Actions>
                )}
            </Side>
        </Wrapper>
    );
};

Painted4Instrument.defaultProps = {
    actions: null,
    logoUrl: '',
    className: '',
    onClick: () => {},
};
