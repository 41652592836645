import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import {
    matchBreakpoints,
    buttonResetStyle,
    anchorResetStyle,
} from 'app/styled';

import type { ButtonType, ButtonWrapperProps } from '../types';
import { typeStandard } from './typeStandard';
import { typeText } from './typeText';

const kitByType = (type: ButtonType) => ({
    standard: typeStandard,
    text: typeText,
}[type]);

const typeSpecificWrapperStyle = ({ type }: ButtonWrapperProps) => {
    return kitByType(type).wrapperStyle;
};

const setVariables = ({ size, type }: ButtonWrapperProps) => {
    return matchBreakpoints(size, kitByType(type).sizeMap);
};

const paddingLeftRightStyle = ({ type }: ButtonWrapperProps) => {
    return kitByType(type).paddingLeftRightStyle;
};

const colorsStyle = ({
    kind, isDisabled, isProcessing, type,
}: ButtonWrapperProps) => {
    return css`
        ${({ theme }) => {
        const stateMap = kitByType(type).getStateMap(theme, kind);
        if (isDisabled) {
            return stateMap.disabled;
        }

        if (isProcessing) {
            return stateMap.default;
        }

        return css`
            ${stateMap.default};

            &:hover {
                ${stateMap.hover};
            }

            &:focus {
                ${stateMap.focus}
            }

            &:active {
                ${stateMap.active}
            }
            ${ifProp('isPressed', stateMap.active)}
        `;
    }}
    `;
};

const contentContainerStyle = ({ type }: ButtonWrapperProps) => {
    return kitByType(type).contentContainerStyle;
};

// MARK: - Components

export const ButtonContentContainer = styled.span`
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    align-content: center;
    justify-items: stretch;
    align-items: center;
    white-space: nowrap;
`;

export const ButtonTextContainer = styled.span`
    grid-area: content;
`;

/** The HTML element of a button. Defaults to <button />, but intended to be overridden with "as" prop. */
export const ButtonWrapper = styled.button<ButtonWrapperProps>`
    // Style Resets
    ${buttonResetStyle}
    ${anchorResetStyle}

    ${typeSpecificWrapperStyle}

    ${setVariables}
    ${paddingLeftRightStyle}
    ${colorsStyle}

    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);

    & ${ButtonContentContainer} {
        ${contentContainerStyle};
    }

    ${({ isDisabled, isProcessing }) => (isDisabled || isProcessing) && `
        pointer-events: none;
    `}

    &, & * {
        outline: none;
    }

    // Static Styles
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    user-select: none;
    cursor: pointer;

    border-radius: 8px;

    & svg {
        grid-area: icon;
    }

    ${ifProp('block', css`
        flex-grow: 1;
    `)}
`;

export const ProcessingIconContainer = styled.span`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
`;
