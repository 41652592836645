/* eslint-disable react/jsx-pascal-case */
import React, {
    ReactChild, useCallback, useMemo, useState,
} from 'react';
import { ReactElementLike } from 'prop-types';
import { Placement } from 'tippy.js';

import Dic from 'services/dictionary';
import { staticAssetURL } from 'services/static-assets';
import getMobileDetect from 'services/detectDevice';
import { useLocaleId } from 'hooks/useLocaleId';
import { ContextActionCopyLink } from 'widgets/context-action-copy-link';
import { ShareIcon } from 'components/icons2/ShareIcon';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { ContextActions, MenuItem } from 'components/context-actions';
import { CopyColoredIcon } from 'components/icons2/CopyColoredIcon';

import { Title } from 'components/wt-popup2/title';
import { Props as PopupWindow2Props } from 'components/wt-popup2/popup-window';
import { shareServices } from './consts';
import { MobileWrapper, MobilePopupWindow2, DesktopWrapper } from './styled';

type ShareProps = {
    buttonText?: string | boolean,
    url?: string | null;
    title?: string;
    description?: string;
    image?: string | null;
    dropdownPlacement?: Placement;
    customRender?: ({ openPopup }: { openPopup: () => void }) => ReactElementLike;
    onClose?: () => void;
} & Pick<PopupWindow2Props, 'portalTo'>;

const _DefaultButtonContent = ({ buttonText, onClick }: {
    buttonText?: string | boolean,
    onClick?: () => void,
}) => {
    return (
        <ButtonIcon
            text={buttonText ?? Dic.word('wt_all__widget_share__default_text')}
            icon={{ component: ShareIcon }}
            kind={ButtonIconKinds.GhostPrimary}
            onClick={onClick}
        />
    );
};

type ShareWidgetTooltipProps = Pick<ShareProps, 'buttonText' | 'customRender' | 'dropdownPlacement'> & {
    shareItems: () => ReactChild[],
}

const _ShareWidgetTooltip = ({
    buttonText, customRender, dropdownPlacement,
    shareItems,
}: ShareWidgetTooltipProps) => {
    const buttonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup: () => {} });
        return <_DefaultButtonContent buttonText={buttonText} />;
    }, [customRender, buttonText]);

    return (
        <>
            <DesktopWrapper>
                <ContextActions
                    tippyContent={shareItems()}
                    kind={ButtonIconKinds.GhostSecondary}
                    placement={dropdownPlacement ?? 'bottom-start'}
                    buttonContent={buttonContent}
                    hideOnScroll={false}
                />
            </DesktopWrapper>

        </>
    );
};

type ShareWidgetPopupProps = Pick<ShareProps, 'buttonText' | 'customRender' | 'portalTo' | 'onClose'> & {
    shareItems: (closePopup: () => void) => ReactChild[],
}

const _ShareWidgetPopup = ({
    buttonText, customRender,
    portalTo, shareItems, onClose,
}: ShareWidgetPopupProps) => {
    const [isPopupShown, setIsPopupShown] = useState<null | boolean>(null);
    const closePopup = () => {
        setIsPopupShown(false);
        onClose && onClose();
    };
    const openPopup = () => setIsPopupShown(true);

    const buttonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup });
        return <_DefaultButtonContent buttonText={buttonText} onClick={openPopup} />;
    }, [customRender, buttonText]);

    return (
        <MobileWrapper>
            {buttonContent}
            {isPopupShown && (
                <MobilePopupWindow2 close={closePopup} portalTo={portalTo} bgCloseable>
                    <Title>{Dic.word('wt_all__widget_share__default_text')}</Title>
                    <div>{shareItems(closePopup)}</div>
                </MobilePopupWindow2>
            )}
        </MobileWrapper>
    );
};

export const ShareWidget = (props: ShareProps) => {
    const lcid = useLocaleId();
    const {
        url, title, description, image, customRender, onClose, buttonText,
    } = props;
    const showDefaultShare = !!navigator.share && getMobileDetect.isMobile();

    const openDefaultShare = useCallback(() => {
        navigator?.share({
            url: url || undefined,
            title,
            text: description,
        }).then(() => onClose && onClose()).catch((err) => {
            console.warn(err);
            onClose && onClose();
        });
    }, [description, onClose, title, url]);

    const defaultShareButtonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup: openDefaultShare });

        return <_DefaultButtonContent onClick={openDefaultShare} buttonText={buttonText} />;
    }, [buttonText, customRender, openDefaultShare]);

    const shareItems = useCallback((closePopup?: () => void) => {
        const items: ReactChild[] = [];
        // sob: обход встроенных блокировщиков всплывающих окон в мобильном сафари,
        // которые не позволяют открывать некоторые из ссылок
        const openItemLink = (link: string) => {
            if (getMobileDetect.isMobile() && getMobileDetect.isSafari()) {
                const safariWindow = window.open();
                if (safariWindow) safariWindow.location.href = link;
            } else {
                window.open(link);
            }
        };

        shareServices.forEach((service) => {
            if (!service.ruOnly || (service.ruOnly && lcid === 'ru')) {
                items.push(<MenuItem
                    key={service.title}
                    text={service.title}
                    icon={service.icon ? { component: service.icon } : undefined}
                    onClick={() => {
                        openItemLink(service.link({
                            url,
                            title: encodeURIComponent(title || ''),
                            description: encodeURIComponent(description || ''),
                            image: image || staticAssetURL('/images/banners/banner_light.png'),
                        }));
                        closePopup && closePopup();
                        onClose && onClose();
                    }}
                />);
            }
        });

        items.push(<ContextActionCopyLink
            key="copy"
            url={url || ''}
            icon={CopyColoredIcon}
            text={Dic.word('wt_all__share_dropdown__copy_link')}
            onClick={() => {
                closePopup && closePopup();
                onClose && onClose();
            }}
        />);

        return items;
    }, [description, image, lcid, title, url]);

    return (
        <>
            {showDefaultShare
                ? <>{ defaultShareButtonContent }</>
                : (
                    <>
                        <_ShareWidgetTooltip
                            shareItems={shareItems}
                            customRender={customRender}
                            {...props}
                        />
                        <_ShareWidgetPopup
                            shareItems={shareItems}
                            customRender={customRender}
                            {...props}
                        />
                    </>
                )}
        </>
    );
};
