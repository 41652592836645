import { connect } from 'react-redux';

import {
    GlobalStateHeaderNotifications,
} from 'widgets/header-notifications/types';

import {
    deleteNotification,
    fetchNotifications,
    readInStoreNotifications,
    readNotifications,
    addInStoreNotification,
} from './actions';

import { HeaderNotifications } from './components/index';

const mapStateToProps = (
    state: GlobalStateHeaderNotifications,
) => ({
    nextCursor: state?.widgets?.notifications?.nextCursor,
    readDate: state?.widgets?.notifications?.readDate,
    notifications: state?.widgets?.notifications?.notifications,
    isLoading: state?.widgets?.notifications?.isLoading,
    isError: state?.widgets?.notifications?.isError,
    totalCount: state?.widgets?.notifications?.totalCount,
    unreadCount: state?.widgets?.notifications?.unreadCount,
});

const mapDispatchToProps = {
    fetchNotifications,
    deleteNotification,
    readNotifications,
    readInStoreNotifications,
    addInStoreNotification,
};

export const HeaderNotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderNotifications);
