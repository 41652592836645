import React from 'react';

import Skeleton from './skeleton';
import { LoadingContainer, LoadingListWrapper} from './styled';

// выводим 8 элементов:
const items = [1, 2, 3, 4, 5, 6, 7, 8];

const Loading = () => (
    <LoadingContainer>
        {items.map((x) => (
            <div key={x}>
                <LoadingListWrapper>
                    <Skeleton />
                </LoadingListWrapper>
            </div>
        ))}
    </LoadingContainer>
);
export default Loading;
