class GlobalAuthState {
    constructor() {
        const queryParams = GlobalAuthState._getUrlQueryParams();
        this._isActive = !!queryParams['wt-auth-popup'];
        this._previewsScene = null;
        this._currentScene = null;
        this._resolvers = [];
    }

    get isActive() {
        return this._isActive;
    }

    get currentSceneName() {
        return this._currentScene;
    }

    get previewsSceneName() {
        return this._previewsScene;
    }

    onStateChange(state) {
        const { isActive, currentScene } = state;
        if (typeof isActive === 'boolean') {
            this._isActive = isActive;
        }
        if (currentScene && typeof currentScene === 'string') {
            this._previewsScene = this._currentScene;
            this._currentScene = currentScene;
        }
    }

    static _getUrlQueryParams() {
        const location = window ? window.location : null;
        const queryString = location ? location.search : null;

        if (!queryString) {
            return {};
        }
        const keyValuePairs = queryString.substr(1).split('&');
        return keyValuePairs.reduce((previousValue, currentValue) => {
            const [key, value] = currentValue.split('=');
            // eslint-disable-next-line no-param-reassign
            previousValue[key] = typeof decodeURIComponent === 'function' ? decodeURIComponent(value) : value;
            return previousValue;
        }, {});
    }
}

let singletonInstance;

export function getGlobalAuthState() {
    if (!singletonInstance) {
        singletonInstance = new GlobalAuthState();
    }
    return singletonInstance;
}
