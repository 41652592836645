import React from 'react';

import { List } from 'components/list';
import { Avatar } from 'components/avatar2';
import { Popover } from 'components/popover';
import { MentionUser, SuggestionProps } from '../types';
import { UsersItemWrapper } from './styled/Form';
import { insertText } from './utils';

type Props = SuggestionProps & {
    mentionList: MentionUser[],
    onSelect: (user: MentionUser) => void,
};

export const SuggestionMentions = (props: Props) => {
    const {
        comment, setComment, phrase, top, left, cursorElement, mentionList, onClose, onSelect,
    } = props;
    const visible = comment.autocompleteType === 'users';
    const filteredMentions = phrase
        ? mentionList.filter((x) => x.displayName?.toUpperCase?.()?.indexOf(phrase.toUpperCase()) !== -1)
        : mentionList;
    const handleSelect = (user: MentionUser) => {
        if (cursorElement) {
            const { text, callback } = insertText(comment.content ?? '', `@${user.displayName} `, comment.position, cursorElement);

            onSelect(user);
            setComment({
                ...comment,
                content: text,
                position: 0,
                autocompleteType: null,
            });
            callback();
        }
    };
    return (
        <Popover
            isVisible={visible}
            onClose={onClose}
            left={left}
            top={top}
            width="100%"
        >
            <List
                items={filteredMentions.map((mention) => ({ ...mention, key: mention.id }))}
                onSelect={handleSelect}
                cursorElement={cursorElement}
                focusFirst
                renderItem={(mention) => (
                    <UsersItemWrapper>
                        <Avatar src={mention.photo?.small} title={mention.displayName} alt={mention.displayName} />
                        {mention.displayName}
                    </UsersItemWrapper>
                )}
                maxHeight="195px"
            />
        </Popover>
    );
};
