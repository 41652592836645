import React from 'react';

export default () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_230_22569)">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z" fill="#D9E2F7" />
            <path d="M5.34115 31.9997V26.6663C5.33329 24.1663 5.87409 22.8364 9.33819 21.4404C12.0029 20.3666 12.8406 19.4138 12.98 19.2033C13.4655 18.4695 13.3017 16.647 12.8023 15.8923C11.47 15.7133 11.1147 13.2972 11.2035 12.7603C11.2746 12.3308 11.5884 12.3129 11.7364 12.4024C11.7364 12.4024 11.2068 10.5352 11.3811 8.82294C11.7091 5.60146 15.2005 5.33301 15.9999 5.33301C16.7993 5.33301 20.2908 5.60146 20.6188 8.82294C20.7931 10.5352 20.2635 12.4024 20.2635 12.4024C20.4115 12.3129 20.7253 12.3308 20.7964 12.7603C20.8852 13.2972 20.5299 15.7133 19.1976 15.8923C18.6981 16.647 18.5344 18.4695 19.0199 19.2033C19.1593 19.4138 19.997 20.3666 22.6617 21.4404C26.1258 22.8364 26.6587 24.1663 26.6587 26.6305V31.9997H5.34115Z" fill="#8896AF" />
        </g>
        <defs>
            <clipPath id="clip0_230_22569">
                <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
