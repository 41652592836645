import styled, { css } from 'styled-components';
import { switchProp, ifProp } from 'styled-tools';
import { size } from 'polished';

import { colors, media, margin } from 'app/styled';
import { LockIcon } from 'components/icons2/LockIcon';

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    box-sizing: border-box;
    margin-top: ${switchProp('margin', margin)};
`;

const fieldVerticalPadding = 21;
const fieldVerticalPaddingMobile = 16;
const fieldShared = css`
    font-size: 18px;
    ${media.forMobile`
        font-size: 14px;
    `}
`;
export const Field = styled.input`
    outline: none;
    background: ${switchProp('view', {
        regular: colors.white,
        protected: colors.selago,
    })};
    line-height: 22px;
    border: none;
    border-bottom: 1px solid ${colors.wildBlueYonder};
    color: ${colors.night};
    resize: none;
    box-sizing: border-box;
    // vls: фикс для FF что бы не подсвечивалась красная рамка у поля type=email при некорректном значении #WTT-8937
    box-shadow: 0 0;
    width: 100%;
    display: block;
    padding: ${fieldVerticalPadding}px 0 2px;
    ${ifProp({ view: 'protected', isError: true }, `
        border-color: ${colors.alizarinCrimson};
    `)};
    ${ifProp({ view: 'protected' }, `
        padding-right: 21px;
    `)};
    overflow: hidden;
    ${fieldShared}
    ${media.forMobile`
        line-height: 18px;
        padding-top: ${fieldVerticalPaddingMobile}px;
    `}
    ${ifProp('textIsInCenter', css`
        text-align: center;
    `)}
    ${ifProp('clipboard', `
        padding-right: 60px;
    `)}
    &::placeholder {
        color: ${ifProp('floatingLabel', 'transparent', colors.wildBlueYonder)};
    }

    &:hover, &:focus {
        outline: none;
        border-bottom: 1px solid ${colors.dodgerDarkBlue};
    }
`;

export const TextfieldLockerIcon = styled(LockIcon).attrs({
    width: '40',
    height: '40',
    className: 'verified-icon',
    xmlns: 'http://www.w3.org/2000/svg',
})`
    position: absolute;
    top: 24px;
    right: 2px;
    width: 18px;
    height: 18px;
    fill: ${colors.shipCove};
    ${ifProp({ view: 'protected', isError: true }, `
        fill: ${colors.alizarinCrimson};
    `)};
    ${media.forMobile`
        top: 16px;
    `}
    ${Field}:focus ~ &, ${Field}:hover ~ & {
        fill: ${colors.dodgerDarkBlue};
    }
`;

export const FloatingLabel = styled.label`
    position: absolute;
    top: 0;
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    color: ${colors.wildBlueYonder};
    transform: translate(0, ${fieldVerticalPadding - 1}px) scale(1);
    ${media.forMobile`
        transform: translate(0, ${fieldVerticalPaddingMobile - 1}px) scale(1);
    `}
    pointer-events: none;
    ${fieldShared}
    ${Field}:focus ~ &, ${Field}:not(:placeholder-shown) ~ & {
        transform: translate(0, 1px) scale(0.75);
        transform-origin: top left;
    }
`;

export const LettersCount = styled.div`
    font-size: 12px;
    text-align: right;
    margin-top: 9px;
    color: ${colors.wildBlueYonder};
`;

export const ClipboardWrapper = styled.div`
    position: absolute;
    right: 5px;
    top: 25px;
    color: ${colors.wildBlueYonder};
    font-size: 14px;
    cursor: pointer;
`;

const halfCBIconCss = `
    content: '';
    background: ${colors.wildBlueYonder};
    display: inline-block;
    border: 1px ${colors.white} solid;
    border-radius: 2px;
`;
export const ClipboardIcon = styled.span`
    margin-left: 3px;
    &:before {
        ${halfCBIconCss}
        ${size(10)}
        position: absolute;
    }
    &:after {
        ${halfCBIconCss}
        ${size(12)}
        position: relative;
        top: 3px;
        right: -3px;
    }
`;
