import React from 'react';

import { MentionUser, SuggestionProps } from '../types';
import { SuggestionCashtags } from './SuggestionCashtags';
import { SuggestionMentions } from './SuggestionMentions';
import { searchPhrase } from './utils';
import { getCaretPosition } from './caret-position';

type Props = Pick<SuggestionProps, 'comment' | 'setComment' | 'cursorElement'> & {
    mentionList: MentionUser[],
    onSelectMentionUser: (user: MentionUser) => void,
};

export const Suggestions = (props: Props) => {
    const {
        comment, setComment, cursorElement, mentionList, onSelectMentionUser,
    } = props;
    const phrase = cursorElement && searchPhrase(comment.content ?? '', comment.position, cursorElement);
    const autocompletePosition = cursorElement && {
        top: getCaretPosition(cursorElement, comment.position - 1).top + 32,
        left: 0,
    };
    const left = `${autocompletePosition?.left}px`;
    const top = `${autocompletePosition?.top}px`;
    const handleClose = () => setComment({
        ...comment,
        position: 0,
        autocompleteType: null,
    });
    return (
        <>
            <SuggestionMentions
                comment={comment}
                setComment={setComment}
                phrase={phrase}
                cursorElement={cursorElement}
                top={top}
                left={left}
                onClose={handleClose}
                mentionList={mentionList}
                onSelect={onSelectMentionUser}
            />
            <SuggestionCashtags
                comment={comment}
                setComment={setComment}
                phrase={phrase}
                cursorElement={cursorElement}
                top={top}
                left={left}
                onClose={handleClose}
            />
        </>
    );
};
