import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosClientConfig: AxiosRequestConfig = {
    baseURL: '/',
    responseType: 'json',
};

/** A unified HTTP client */
export const backendClient = axios.create(axiosClientConfig);

export type Response<T> = AxiosResponse<T>;

// MARK: - Errors

export const isCancelRequestError = axios.isCancel;
export const isHTTPError = axios.isAxiosError;

// MARK: - Cancellation

export type CancelCallback = (cancel: () => void) => void;

/**
 * Helper for getting an abort signal and a cancel function.
 *
 * @example
 * let cancel;
 *
 * const ac = withAbortController((cancelFn) => (cancel = cancelFn));
 * backendClient.post('...', data, { signal: ac.signal });
 *
 * cancel(); // cancels the request
 *
 */
export function withAbortController(cancelCb?: CancelCallback) {
    const abortController = new AbortController();
    cancelCb?.(() => abortController.abort());
    return abortController;
}

// MARK: - Interceptors

backendClient.interceptors.request.use((config) => {
    const userToken = (typeof window !== undefined && window.WT.menu?.getUserInfo()?.token) ?? undefined;
    if (userToken) {
        if (!config.headers) config.headers = {};
        config.headers['X-User-Token'] = userToken;
    }
    return config;
});

backendClient.interceptors.response.use((response: AxiosResponse<{ result?: Record<string, unknown> }>) => {
    if (response.data?.result && response.data?.result?.ok) {
        response.data = response.data?.result;
    }

    return response;
});
