import React, { useCallback } from 'react';

import Dic from 'services/dictionary';
import notify from 'services/notify';
import Clipboard from 'components/wt-popup/textfield/Clipboard';
import { MenuItem } from 'components/context-actions';
import { ShareIcon } from 'components/icons2/ShareIcon';
import { IconComponent } from 'components/icons2';

type Props = {
    url: string,
    icon?: IconComponent,
    text?: string,
    onClick?: () => void;
}
export const ContextActionCopyLink = (props: Props) => {
    const { url, icon, text, onClick } = props;

    const handleCopySuccess = useCallback(() => {
        notify.success(Dic.word('wt_feed__widget_author__context_copy_success'));
        onClick && onClick();
    }, [onClick]);

    return (
        <Clipboard
            onSuccess={handleCopySuccess}
            customRender={(ref: React.MutableRefObject<HTMLSpanElement>) => (
                <span ref={ref} data-clipboard-text={url}>
                    <MenuItem
                        icon={{ component: icon || ShareIcon }}
                        text={text || Dic.word('wt_feed__widget_author__context_action_copy_link')}
                    />
                </span>
            )}
        />
    );
};
