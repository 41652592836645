import React from 'react';

import { IconSvg, IconComponent } from '.';

export const DocTextMagnifyingGlassIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 20 20">
        <path d="M5.00977 3.17C4.91772 3.17 4.8431 3.24462 4.8431 3.33667V16.6623C4.8431 16.7543 4.91772 16.829 5.00977 16.829H8.34788V18.1623H5.00977C4.18134 18.1623 3.50977 17.4907 3.50977 16.6623V3.33667C3.50977 2.50825 4.18134 1.83667 5.00976 1.83667L14.9987 1.83667C15.8271 1.83667 16.4987 2.50824 16.4987 3.33667V10.0102H15.1654V3.33667C15.1654 3.24462 15.0908 3.17 14.9987 3.17L5.00977 3.17Z" />
        <path d="M6.85409 6.6881C6.85409 6.31991 7.15256 6.02143 7.52075 6.02143H12.5594C12.9276 6.02143 13.2261 6.31991 13.2261 6.6881C13.2261 7.05629 12.9276 7.35477 12.5594 7.35477H7.52075C7.15256 7.35477 6.85409 7.05629 6.85409 6.6881Z" />
        <path d="M7.52075 8.50556C7.15256 8.50556 6.85409 8.80404 6.85409 9.17223C6.85409 9.54042 7.15256 9.8389 7.52075 9.8389H12.5594C12.9276 9.8389 13.2261 9.54042 13.2261 9.17223C13.2261 8.80404 12.9276 8.50556 12.5594 8.50556H7.52075Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 14.5744C9.33333 12.5929 10.9397 10.9865 12.9212 10.9865C14.9027 10.9865 16.5091 12.5929 16.5091 14.5744C16.5091 15.3243 16.2791 16.0204 15.8857 16.596L17.9658 18.6761C18.2261 18.9364 18.2261 19.3586 17.9658 19.6189C17.7054 19.8793 17.2833 19.8793 17.023 19.6189L14.9429 17.5389C14.3673 17.9322 13.6711 18.1623 12.9212 18.1623C10.9397 18.1623 9.33333 16.5559 9.33333 14.5744ZM12.9212 12.3199C11.6761 12.3199 10.6667 13.3293 10.6667 14.5744C10.6667 15.8196 11.6761 16.829 12.9212 16.829C14.1664 16.829 15.1758 15.8196 15.1758 14.5744C15.1758 13.3293 14.1664 12.3199 12.9212 12.3199Z" />
    </IconSvg>
);
