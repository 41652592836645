import { FeedRecord, FeedRecordObjBlogpostMask } from 'types';
import { useIsOwner } from './useIsOwner';

export const useIsPremiumExtend = (record?: FeedRecord) => {
    const { obj } = record || {};
    const isOwner = useIsOwner(record);
    // eslint-disable-next-line no-bitwise
    const isPremium = obj ? ((obj?.blogpostType & FeedRecordObjBlogpostMask.PREMIUM) === FeedRecordObjBlogpostMask.PREMIUM) : false;
    const isPremiumGranted = obj?.isPremiumGranted || false;
    const isPremiumExtend = !isPremium || (isPremium && (isPremiumGranted || isOwner));
    return { isPremiumExtend, isPremium, isPremiumGranted };
};
