import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, body17RegularFontStyle } from 'services/typography';
import { Avatar } from 'components/avatar2';
import { Button } from 'components/button2';

export const AddFeedStatusContainer = styled.div`
    ${mediaQuery.lt480} {
        margin-bottom: 12px;
    }
`;

export const AddFeedStatusEditorContainer = styled.div<{ isActive: boolean; }>`
    ${body17RegularFontStyle};

    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme('bg.secondary')};
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 0;

    padding: 16px 24px;

    ${ifProp('isActive', css`
        background-color: ${theme('bg.secondary')};
        border: 1px solid ${theme('fillIn.primary.mid')};
        padding: 24px;
    `)}

    ${mediaQuery.lt480} {
        ${body14RegularFontStyle};
        padding: 8px 16px;
        margin-bottom: 8px;
    }
`;

export const AddFeedStatusAvatar = styled(Avatar)`
    height: 40px;
    width: 40px;

    ${mediaQuery.lt480} {
        height: 32px;
        width: 32px;
    }
`;

export const AddFeedStatusTopSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AddFeedStatusActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const AddFeedCancelButton = styled(Button)`
    margin-right: 12px;

    ${mediaQuery.lt480} {
        margin-right: 8px;
    }
`;

export const AddFeedStatusBottomSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: -8px;
`;

export const AddFeedStatusToolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const HideOnMobile = styled.div`
    ${mediaQuery.lt480} {
        display: none;
    }
`;

export const ShowOnMobileOnly = styled.div`
    display: none;

    ${mediaQuery.lt480} {
        display: block;
    }
`;

export const AddFeedStatusPlaceholder = styled.div`
    font-size: 17px;
    line-height: 24px;
    margin: 0 16px;
    color: ${theme('label.placeholder')};
`;


export const PostTypeWrapper = styled.div`
    ${body14RegularFontStyle}
`;

export const PostTypeSelectorDesktopWrapper = styled.div`
    ${body14RegularFontStyle}
    cursor: pointer;

    ${mediaQuery.lt768} {
        display: none;
    }
`;
export const PostTypeSelectorMobileWrapper = styled.div`
    ${body14RegularFontStyle}
    cursor: pointer;
    display: none;

    ${mediaQuery.lt768} {
        display: inline-flex;
    }
`;
