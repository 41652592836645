import styled from 'styled-components';

export const Content = styled.div`
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    margin-bottom: 12px;

    pre {
        white-space: pre-wrap;
    }

    .video_wrapper:not(.wrapper_video_provider_tiktok) {
        position: relative;
        padding-bottom: ${100 * (9 / 16)}%;
        object, embed, iframe, div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .blog-posts-item-preview__image, iframe {
        border-radius: 8px;
    }
`;
