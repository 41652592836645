import React from 'react';
import Dic from 'services/dictionary';
import { CloseIcon } from 'components/icons2/CloseIcon';
import { VerifiedAccountIcon } from 'components/icons2/VerifiedAccountIcon';
import {
    Container,
    Title,
    SubTitle,
    IconContainer,
    StyledButton,
    Content,
    Header,
    CloseButton,
} from './styled';

const ThankForRegistration = (
    { showButtonClose = false, onClose = () => false }: { showButtonClose?: boolean, onClose?: () => void },
) => {
    return (
        <Container>
            <Content>
                <IconContainer>
                    <VerifiedAccountIcon size={40} />
                </IconContainer>
                <Header>
                    <Title>
                        { Dic.word('wt_feed__component__thank_for_signup_button') }
                    </Title>
                    {
                        showButtonClose && (
                            <CloseButton onClick={() => onClose()}>
                                <CloseIcon />
                            </CloseButton>
                        )
                    }
                </Header>
                <SubTitle>
                    { Dic.word('wt_feed__component__thank_for_signup_subtitle') }
                </SubTitle>
            </Content>
            <StyledButton size="small" onClick={() => onClose()}>
                { Dic.word('wt_feed__component__thank_for_signup_button') }
            </StyledButton>
        </Container>
    );
};

export default ThankForRegistration;
