import styled from 'styled-components';
import { mediaQuery } from 'app/styled';
import { Button } from 'components/button2';

const Container = styled.div`
    background: ${({ theme: { bg } }) => bg.secondary};
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 8px;
`;

const Content = styled.div`
    ${mediaQuery.lt480} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const CloseButton = styled.button`
    border: 0;
    background: inherit;
    color: ${({ theme: { label } }) => label.secondary};
    cursor: pointer;
    &:active, :focus {
        outline: none;
    }
`;

const IconContainer = styled.div`
    display: none;
    color: ${({ theme }) => theme.fillIn.primary.mid};
    fill: ${({ theme }) => theme.fillIn.primary.mid};
    ${mediaQuery.lt480} {
        display: flex;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Title = styled.h3`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
    font-weight: bold;
    color: ${({ theme: { label } }) => label.primary};
    letter-spacing: -0.012em;
    margin-bottom: 8px;
    margin-top: 0;
    ${mediaQuery.lt480} {
        margin-top: 24px;
    }
`;

const SubTitle = styled.span`
    font-family: 'Inter var', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
    color: ${({ theme: { label } }) => label.secondary};
    width: 80%;
    ${mediaQuery.lt480} {
        text-align: center;
    }
`;

const StyledButton = styled(Button)`
    position: relative;
    margin-top: 16px;
    ${mediaQuery.lt768} {
        width: 100%;
        margin-bottom: 50px;
    }
    ${mediaQuery.lt480} {
        width: 100%;
        margin-bottom: 10px;
    }
`;

export {
    Container,
    Title,
    SubTitle,
    IconContainer,
    StyledButton,
    Content,
    Header,
    CloseButton,
};
