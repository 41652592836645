import styled, { createGlobalStyle } from 'styled-components';

import { body17RegularFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    background: ${({ theme: { bg } }) => bg.secondary};
    border-radius: 8px;
    & iframe {
        max-width: 100%;
    }
`;

export const redactorPaddingBottom = 65;
export const EditorGlobalStyle = createGlobalStyle`
    .codex-editor__redactor {
        padding-bottom: ${redactorPaddingBottom}px !important;
    }
    #cashTag div {
        outline: none;
    }
    .ce-block__content {
        word-wrap: break-word;
    }
    .ce-paragraph {
        white-space: pre-wrap;
        &&:empty::before {
            ${body17RegularFontStyle};
            color: ${({ theme }) => theme.label.placeholder};
        }
    }
    .codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
        opacity: 1 !important;
    }
    .codex-editor__loader {
        height: 105px;
        width: 100%;
    }
`;
