import { useCallback } from 'react';

import { sendGA } from 'services/google-analytics';
import { useLocaleId } from 'hooks/useLocaleId';
import { useAgo } from 'hooks/useAgo';

export const useTimeAgo = (date: { created: string }) => {
    const lcid = useLocaleId();
    const { timeAgo, parsedISO, localeTimeString } = useAgo(date.created, { lcid });

    const handleClickTimeAgo = useCallback(() => {
        sendGA('feedRecord', ['time-ago-click']);
    }, []);

    return { handleClickTimeAgo, timeAgo, parsedISO, localeTimeString };
};
