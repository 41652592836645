import { syncUser, syncUserLogout } from './syncUser';

const LOCAL_STORAGE_JUST_REG_KEY = 'limex__just_registered_user';

export function initializeMenu() {
    const whenPageCanReloadPromises: Promise<void>[] = [];
    const { menu, Page } = window.WT;

    if (menu) {
        menu.init({
            options: {
                project: 'limex',
                lang: window.__APP_LOCALE__,
                callbacks: [
                    {
                        key: 'langSwitchCallback',
                        fun(lcid: string) {
                            if (window.location.href.indexOf('?') > -1) {
                                window.location.href += `&lcid=${lcid}`;
                            } else {
                                window.location.href += `?lcid=${lcid}`;
                            }
                        },
                    },
                    {
                        key: 'onWtAuthIsClosed',
                        fun() {
                            if (whenPageCanReloadPromises.length > 0) {
                                Promise.all(whenPageCanReloadPromises).then(() => {
                                    window.location.reload();
                                });
                            }
                        },
                    },
                    {
                        key: 'onAfterAuthentication',
                        fun() {
                            whenPageCanReloadPromises.push(
                                menu.getUserInfoPromise()
                                    .then((data: { token: string | null }) => syncUser(data.token)),
                            );
                        },
                    },
                    {
                        key: 'onAfterRegistration',
                        fun() {
                            whenPageCanReloadPromises.push(
                                menu.getUserInfoPromise()
                                    .then((data: { token: string | null }) => {
                                        localStorage.setItem(LOCAL_STORAGE_JUST_REG_KEY, 'true');
                                        return syncUser(data.token);
                                    }),
                            );
                        },
                    },
                    {
                        key: 'onAfterLogout',
                        fun() {
                            syncUserLogout();
                            whenPageCanReloadPromises.push(new Promise<void>((resolve) => {
                                setTimeout(() => resolve(), 500);
                            }));
                        },
                    },
                    {
                        key: 'onAfterUserChange',
                        fun() {
                            menu.getUserInfoPromise().then((data: { token: string | null }) => {
                                syncUser(data.token)
                                    .then(() => window.location.reload());
                            });
                        },
                    },
                ],
            },
        });
        menu.show();

        menu.getUserInfoPromise().then((data) => {
            if (Page) {
                Page.onReady = function () {
                    Page.setCurrentUser?.(data, data.WTuser?.csrf ?? undefined);
                };
            }

            // dg: Если сессии различаются, то запускаем синхронизацию.
            if (data && data.WTuser && data.WTuser.spid && data.WTuser.spid !== window.__VISITOR_SESSION_PUBLIC_ID__) {
                syncUser(data.token);
            }

            if (data && data.id && (!data.avatar || !data.name || !data.lastname || !data.hasEmail)) {
                const lsKey = `fillProfileShownAt:${data.id}`;
                const fillProfileShownAt = parseInt(localStorage.getItem(lsKey) ?? '', 10);
                if (isNaN(fillProfileShownAt) || ((Date.now() - fillProfileShownAt) > 1000 * 60 * 60 * 24 * 30)) {
                    menu.fillProfile(
                        () => {},
                        ['name'],
                    );
                    localStorage.setItem(lsKey, `${Date.now()}`);
                }
            }
        });
    }

    /** Временная логика, пока аналогичное не научится делать GlobalAuth * */
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('show_registration') !== -1) {
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search.slice(1));
        params.delete('show_registration');
        url.search = params.toString();

        window.history.replaceState({}, document.title, url.pathname + url.search);

        if (menu) {
            menu.getUserInfoPromise().then((data) => {
                if (!(data && data.id > 0)) {
                    menu.registration();
                }
            });
        }
    }
    /** конец временной логики * */
}
