import styled from 'styled-components';
import { FeedRecordComment } from 'types';
import { ifProp } from 'styled-tools';
import { labelSecondary, mediaQuery } from 'app/styled';
import { body14MediumFontStyle, body17RegularFontStyle, buttonMediumFontStyle } from 'services/typography';
import { ContextActions } from 'components/context-actions';

import { imageStyle } from './Form';

export const Wrapper = styled.div<{ level: FeedRecordComment['level']; isMarked?: boolean }>`
    &:not(:first-child) {
        padding-top: 12px;
    }

    background-color: ${({ isMarked, theme }) => (isMarked ? theme.fillIn.secondary.overlay24 : theme.bg.secondary)};
    padding-left: ${({ level }) => level * 52 + 24}px;
    padding-right: 24px;

    border-radius: 8px;
    padding-top: 12px;
`;

export const Content = styled.div<{isFull?: boolean}>`
    margin-bottom: 4px;

    ${ifProp('isFull', `
        display: block;
    `, `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    `)};
`;

export const ShowFull = styled.a`
    ${buttonMediumFontStyle};
    margin-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
    ${mediaQuery.lt960} {
        margin-bottom: 8px;
        font-size: 14px;
    }
`;

export const Actions = styled.div`
    grid-area: actions;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ReplyFormWrapper = styled.div`
    grid-area: reply-form;
`;

export const Head = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const CommentContextActions = styled(ContextActions)`
    margin-left: 8px;
`;

export const TimeAgoLink = styled.a`
    color: ${labelSecondary};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

export const DeletedTimeAgo = styled.span`
    color: ${labelSecondary};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

export const ContextMenuWrapper = styled.div`
    width: 256px;
`;

export const LocalDeletedWrapper = styled.div<{ level: FeedRecordComment['level']; }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 24px 24px 24px;
    padding-left: ${({ level }) => level * 52 + 24}px;
    border-top: 1px solid ${({ theme }) => theme.divider.primary};
    border-bottom: 1px solid ${({ theme }) => theme.divider.primary};

    & + & {
        border-top: none;
    }

    ${body17RegularFontStyle}

    ${mediaQuery.lt480} {
        padding: 16px 24px 16px 24px;
        padding-left: ${({ level }) => level * 52 + 24}px;
    }
`;

export const LocalDeletedText = styled.div`
    margin-right: 0.5em;
    color: ${({ theme }) => theme.label.secondary};
`;

export const DeletedRestore = styled.div`
    color: ${({ theme }) => theme.label.accent};
    cursor: pointer;
`;

export const Image = styled.img`
    ${imageStyle};
    object-fit: contain;
    cursor: pointer;
`;

export const ImageWrapper = styled.div`
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;

    ${imageStyle}
`;

export const DeletedHead = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
`;

export const DeletedAvatar = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.fillIn.secondary.muted};

    ${mediaQuery.lt480} {
        width: 32px;
        height: 32px;
        margin-right: 12px;
    }
`;

export const DeletedDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DeletedText = styled.div`
    ${body14MediumFontStyle};

    color: ${({ theme }) => theme.label.secondary};
`;
