import React from 'react';

import { IconSvg, IconComponent } from './index';

export const TwitterColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z" fill="#1DA1F3" />
        <path d="M18.3558 8.48597C18.3623 8.63997 18.3623 8.78697 18.3623 8.94097C18.3688 13.61 15.0674 19 9.03006 19C7.24939 19 5.50122 18.447 4 17.411C4.25995 17.446 4.5199 17.46 4.77985 17.46C6.25508 17.46 7.69131 16.928 8.85459 15.941C7.45085 15.913 6.21608 14.926 5.78716 13.484C6.28107 13.589 6.78798 13.568 7.26889 13.421C5.74167 13.099 4.64338 11.65 4.63688 9.96297C4.63688 9.94897 4.63688 9.93497 4.63688 9.92097C5.0918 10.194 5.6052 10.348 6.1251 10.362C4.68887 9.32597 4.24045 7.26096 5.11129 5.64396C6.78148 7.85596 9.23802 9.19297 11.8765 9.33997C11.6101 8.11497 11.974 6.82696 12.8253 5.95896C14.1446 4.62195 16.2242 4.69195 17.472 6.11296C18.2063 5.95896 18.9147 5.66496 19.5581 5.25196C19.3111 6.07096 18.7977 6.76396 18.1154 7.20496C18.7652 7.12096 19.4021 6.93196 20 6.65196C19.5581 7.36596 18.9992 7.98196 18.3558 8.48597Z" fill="white" />
    </IconSvg>
);
