import React, { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { MenuPerson } from 'types';
import { useDictionary } from 'hooks/useDictionary';

import { UserMenu } from '../user-menu';

import * as Styled from './styles';

interface Props {
    callbacks: unknown[];
    user: MenuPerson;
    isUserLoggedIn: boolean;
    onFetchUser: (changeLoaduserState?: boolean) => void;
}

export function MobileMenu(props: Props) {
    const {
        callbacks,
        user,
        isUserLoggedIn,
        onFetchUser,
    } = props;
    const dic = useDictionary();
    const [isOpened, setOpened] = useState(false);
    const buttonRef = useRef(null);
    const theme = useTheme();

    const closePopover = () => {
        setOpened(false);
    };

    const togglePopover = () => {
        setOpened((v) => !v);
    };

    return (
        <div>
            <Styled.IconButton
                isOpened={isOpened}
                name={isOpened ? 'close' : 'hamburger'}
                color={isOpened ? `${theme.label.primary}` : `${theme.label.secondary}`}
                size={isOpened ? 14 : 18}
                onClick={togglePopover}
                ref={buttonRef}
            />

            <Styled.Popover isVisible={isOpened} right={-15} close={closePopover} include={buttonRef.current}>
                <Styled.HeaderOnlyMobile>
                    <Styled.HeaderOnlyMobileTitle>
                        {dic.word('wt_menu__limex_project__mobile_header_title__menu')}
                    </Styled.HeaderOnlyMobileTitle>
                    <Styled.IconButton
                        isOpened={isOpened}
                        name="close"
                        color={`${theme.label.secondary}`}
                        size={14}
                        onClick={closePopover}
                    />
                </Styled.HeaderOnlyMobile>
                <Styled.Overlay onClick={closePopover} />
                <Styled.Box>
                    <Styled.GroupTitle tabIndex={-1}>
                        {dic.word('wt_menu__limex__mobile_group_title__navigation')}
                    </Styled.GroupTitle>

                    <Styled.MainMenu />
                    {
                        !isUserLoggedIn && (
                            <UserMenu
                                mode="mobile"
                                callbacks={callbacks}
                                user={user}
                                onFetchUser={onFetchUser}
                            />
                        )
                    }
                </Styled.Box>
            </Styled.Popover>
        </div>
    );
}
