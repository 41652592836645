import { setLanguage, setServerLanguage } from '../actions';
import { menuLogger } from '../services/menuLogger';
import store from '../store';

export const setLang = (locale: string | unknown) => {
    if (typeof locale !== 'string') {
        menuLogger.error('This method accepts only strings');
        return;
    }
    const localeToUse = locale.toLowerCase() === 'ru' ? 'ru' : 'en';
    store.dispatch(setLanguage(localeToUse));
    store.dispatch(setServerLanguage(localeToUse));
};
