import React, { useCallback } from 'react';
import noop from 'lodash/noop';

import {
    Id,
    FeedRecordObj,
    FeedRecordStats,
    FeedRecordObjTypes,
    FeedRecordData,
} from 'types';
import { sendGA } from 'services/google-analytics';
import { numberToHumanString } from 'services/numberToHumanString';
import Dic from 'services/dictionary';
import { useHideComments } from 'hooks/useHideComments';
import { ShareWidget } from 'widgets/share';

import { ModerationAILabel } from '../../moderation-components/ModerationAILabel';
import { ModerationEditItemButton } from '../../moderation-components/ModerationEditItemButton';
import { LikePost } from '../../like-post';
import {
    Wrapper,
    Voters,
    Voter,
    Group,
    CommentButton,
    ViewsCount,
    CommentText,
} from './styled';

const buttonsSize = { default: 'L', lt960: 'M' } as const;

interface Props {
    recordId: Id;
    recordData?: FeedRecordData;
    obj: FeedRecordObj;
    stats: FeedRecordStats;
    url?: string;
    onClickComments?: () => void;
}
export const Footer = (props: Props) => {
    const {
        recordId,
        recordData,
        obj,
        stats,
        url,
        onClickComments = noop,
    } = props;
    const {
        id,
        type,
        author,
        stats: {
            likes: { positive, userChoice },
            viewsCount,
        },
        data: {
            statsUsers: { voters },
        },
        isUserCommented,
    } = obj;

    const hideComments = useHideComments(author.id);

    const media = obj?.media?.[0]?.source;

    const commentsCount = type === FeedRecordObjTypes.POST
        ? obj.commentsCount
        : stats.commentsCount;

    const handleCommentsClick = useCallback(() => {
        onClickComments();
        sendGA('feedRecord', ['comments-icon-click']);
    }, [onClickComments]);

    return (
        <Wrapper className="adm__post-activity">
            <Group>
                {voters.length > 0 && (
                    <Voters>
                        {voters.map(({ user }) => (
                            <Voter
                                src={user.image}
                                linkHref={user.url}
                                key={user.id}
                            />
                        ))}
                    </Voters>
                )}
                <LikePost
                    recordId={recordId}
                    objId={id}
                    objType={type}
                    userChoice={userChoice}
                    count={positive}
                    size={buttonsSize}
                />
                {!hideComments && (
                    <CommentButton
                        text={
                            commentsCount
                        || <CommentText>{Dic.word('wt_feed__post_comments__comment_label')}</CommentText>
                        }
                        onClick={handleCommentsClick}
                        size={buttonsSize}
                        pressed={!!isUserCommented}
                    />
                )}
                <ShareWidget
                    url={url}
                    title={obj.title || obj.displayTitle || obj.shortContent}
                    description={obj.shortContent}
                    image={media}
                    buttonText={false}
                />
                <ModerationEditItemButton id={obj.id} type="record" />
                <ModerationAILabel recordData={recordData} />
            </Group>
            <Group>
                {!!viewsCount && (
                    <ViewsCount
                        text={numberToHumanString(viewsCount, 1)}
                        size={buttonsSize}
                        className="autotest__feed-item__views-count"
                    />
                )}
                {/* <BookmarkButton onClick={inDevelopment} /> */}
                {/* <ShareButton onClick={inDevelopment} /> */}
            </Group>
        </Wrapper>
    );
};

Footer.defaultProps = {
    onClickComments: noop,
};
