import {
    projectsLinks,
    projectsTemplateLinks,
} from '../services/constants.js';

export default {
    projects: {
        limex: {
            headerHeight: 64,
            adaptiveHeader: true,
            hideInProjectMenu: true,
            /*
            Вот это все тут ни для чего, просто потому что шапка ломается беэ этого,
            даже если не эти данные нигде не используются
             */
            logotypeName: () => '',
            url: projectsLinks.limexLink,
            urlTemplate: projectsTemplateLinks.limexTemplateLink,
            rightMenu: [],
            /*
            Конец заглушки с настройками
             */
        },
    },
};
