import { useLayoutEffect, useRef, useState } from 'react';
import { FeedRecord } from 'types';

import { sentryClient, SentryError } from 'services/sentry';

export const useCheckFeedLength = <Props extends {
    boundary?: string;
    records?: FeedRecord[];
}>(
        props: Props & {
            isLoading: boolean,
        },
    ) => {
    const { records = [], boundary, isLoading } = props;
    const [isFeedLengthError, setFeedLengthError] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    useLayoutEffect(() => {
        if (records.length && timeout.current) {
            clearTimeout(timeout.current);
            return () => {};
        }
        timeout.current = setTimeout(() => {
            if (!records.length && (boundary || boundary === undefined) && !isLoading) {
                setFeedLengthError(true);
                sentryClient.captureException(
                    new SentryError(
                        'Подозрительно пустая лента',
                        'CheckFeedLength',
                    ),
                    (scope) => {
                        scope.setContext('props', props);
                        return scope;
                    },
                );
            }
        }, 10000);
        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, [records, boundary, isLoading]);
    return {
        isFeedLengthError,
    };
};
