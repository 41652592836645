import { instrumentResultMapper } from './instrumentResultMapper';
/**
 * @file Finam issues search service JavaScript API managing object. jQuery.
 */

/**
 * Error callback
 * @callback ErrorCallback
 * @param {String} errorText - Error description
 */

/**
 * Success callback
 * @callback SuccessCallback
 * @param {Array} data - Recieved data from service API
 */

/**
 * Creates a stub manager object that doesn't log analytics to GA
 * @class
 */
export function GoogleAnalyticsStubManager() {
    const self = this;

    /**
     * Log search query to GA
     * @public
     * @function
     * @param {String} query - Searched query to log
     */
    this.registerQuery = function (query) {};
    /**
     * Log issue FinamID resolve to GA
     * @public
     * @function
     * @param {Integer} id - Resolved FinamID to log
     * @param {Integer} position - Resolved position index to log
     */
    this.registerResolve = function (id, position) {};
    /**
     * Log search request execution time to GA
     * @public
     * @function
     * @param {Integer} period - Request execution time to log
     */
    this.registerTime = function (period) {};

    return this;
}
window.GoogleAnalyticsStubManager = GoogleAnalyticsStubManager;
/**
 * Creates a manager object to log analytics to GA
 * @class
 * @augments GoogleAnalyticsStubManager
 * @param {Function} gaReference - Reference to Google Analytics(GA)
 */
export function GoogleAnalyticsManager(gaReference) {
    const self = this;
    this.prototype = GoogleAnalyticsStubManager;

    if (!gaReference || (typeof gaReference !== 'function')) {
        throw new Error('Invalid GA reference passed to analytics manager.');
        return;
    }
    /**
     * Google Analytics(GA) reference
     * @private
     * @type Function
     */
    this.gaReference = gaReference;
    /**
     * Log search query to GA
     * @public
     * @function
     * @param {String} query - Searched query to log
     */
    this.registerQuery = function (query) {
        self.gaReference('send', 'event', 'search', 'query', query);
    };
    /**
     * Log issue FinamID resolve to GA
     * @public
     * @function
     * @param {Integer} id - Resolved FinamID to log
     * @param {Integer} position - Resolved position index to log
     */
    this.registerResolve = function (id, position) {
        self.gaReference('send', 'event', 'search', 'resolve', id);
        self.gaReference('send', 'event', 'search', 'row', position);
    };
    /**
     * Log search request execution time to GA
     * @public
     * @function
     * @param {Integer} period - Request execution time to log
     */
    this.registerTime = function (period) {
        self.gaReference('send', 'timing', 'search', 'request_time', period);
    };

    return this;
}
window.GoogleAnalyticsManager = GoogleAnalyticsManager;
/**
 * Creates a manager object to log analytics to GA (Legacy mode)
 * @class
 * @augments GoogleAnalyticsManager
 * @param {Object} gaReference - Reference to Google Analytics(GA)
 */
export function GoogleAnalyticsLegacyManager(gaReference) {
    const self = this;
    this.prototype = GoogleAnalyticsManager;
    if (!gaReference || !('push' in gaReference)) {
        throw new Error('Invalid GA reference passed to legacy analytics manager.');
        return;
    }
    /**
     * Google Analytics(GA) reference
     * @private
     * @type Object
     */
    this.gaReference = gaReference;
    /**
     * Log search query to GA
     * @public
     * @function
     * @param {String} query - Searched query to log
     */
    this.registerQuery = function (query) {
        self.gaReference.push(['_trackEvent', 'search', 'query', query]);
    };
    /**
     * Log issue FinamID resolve to GA
     * @public
     * @function
     * @param {Integer} id - Resolved FinamID to log
     * @param {Integer} position - Resolved position index to log
     */
    this.registerResolve = function (id, position) {
        self.gaReference.push(['_trackEvent', 'search', 'resolve', id]);
        self.gaReference.push(['_trackEvent', 'search', 'row', position]);
    };
    /**
     * Log search request execution time to GA
     * @public
     * @function
     * @param {Integer} period - Request execution time to log
     */
    this.registerTime = function (period) {
        self.gaReference.push(['_trackEvent', 'search', 'request_time', period]);
    };

    return this;
}
window.GoogleAnalyticsLegacyManager = GoogleAnalyticsLegacyManager;
/**
 * Creates Issue object with data from service API
 * @class
 * @param {Object} [json] - Configuration object for Issue
 * @param {Integer} json.finam_id - Issue FinamID
 * @param {String} json.ticker - Issue ticker code
 * @param {String} json.ename - Issue name
 * @param {Integer} [json.decp] - Issue dcpl
 * @param {String} [json.exch_name_short] - Issue exchange name
 * @param {Integer} json.exchange_id - Issue exchange id
 * @param {String} [json.morning_star_exchange] - Issue morning star exchange name
 * @param {Float} json.last - Issue last price
 * @param {Float} json.pct_change - Issue price change
 * @param {String} json.value_sum - Issue value sum
 */
export function Issue(json) {
    /**
	  * Issue GUID for forced resolving
	  * @public
	  * @type String
	  */
    this.guid = null;
    if (json) {
        /**
		  * Issue FinamID
		  * @public
		  * @type Integer
		  */
        this.finamId = json.finamId;
        /**
		  * Issue ticker code
		  * @public
		  * @type String
		  */
        this.ticker = json.ticker;
        /**
		  * Issue name
		  * @public
		  * @type String
		  */
        this.name = json.ename;
        /**
		  * Issue dcpl
		  * @public
		  * @type Integer
		  */
        this.dcpl = json.dcpl;
        /* if ('decp' in json) {
			this.dcpl = json.decp;
		} else if (console) {
			console.warn('=== Warning! Issue [' + json.finam_id + '] has no defined dcpl');
		} */
        /**
		  * Issue exchange name
		  * @public
		  * @type String
		  */
        this.exchangeName = ('exchangeName' in json ? json.exchangeName : null);
        /**
		  * Issue exchange id
		  * @public
		  * @type Integer
		  */
        this.exchangeId = json.exchange_id;
        /**
		  * Issue Morning Star exchange name
		  * @public
		  * @type String
		  */
        this.morningStarExchange = ('morning_star_exchange' in json ? json.morning_star_exchange : null);
        /**
		  * Issue last price
		  * @public
		  * @type Float
		  */
        this.last = json.last;
        /**
		  * Issue price change
		  * @public
		  * @type Float
		  */
        this.change = json.change;
        /**
		  * Issue currency
		  * @public
		  * @type Float
		  */
        this.currency = json.currency;
        /**
		  * Issue value sum
		  * @public
		  * @type String
		  */
        this.valueSum = json.value_sum;
        /**
         * Issue changeSign
         * @public
         * @type Integer
         */
        this.changeSign = json.changeSign;
        /**
         * Issue changeContext
         * @public
         * @type Integer
         */
        this.changeContext = json.changeContext;
    }

    const self = this;

    /**
	  * Creates Issue object copy
	  * @public
	  * @function
	  * @returns {Issue} Issue copy
	  */
    this.copy = function () {
        const result = new Issue();
        result.guid = self.guid;
        result.finamId = self.finamId;
        result.ticker = self.ticker;
        result.name = self.name;
        result.dcpl = self.dcpl;
        result.exchangeName = self.exchangeName;
        result.exchangeId = self.exchangeId;
        result.morningStarExchange = self.morningStarExchange;
        result.currency = self.currency;
        result.last = self.last;
        result.change = self.change;
        result.valueSum = self.valueSum;
        result.changeSign = self.changeSign;
        result.changeContext = self.changeContext;
        return result;
    };

    /**
	  * Creates Issue object protected copy (with GUID instead of FinamID)
	  * @public
	  * @function
	  * @param {String} guid - Issue assigned GUID
	  * @returns {Issue} Issue protected copy
	  */
    this.protectedCopy = function (guid) {
        const result = new Issue();
        result.guid = guid;
        result.finamId = null;
        result.ticker = self.ticker;
        result.name = self.name;
        result.dcpl = self.dcpl;
        result.exchangeName = self.exchangeName;
        result.exchangeId = self.exchangeId;
        result.morningStarExchange = self.morningStarExchange;
        result.currency = self.currency;
        result.last = self.last;
        result.change = self.change;
        result.valueSum = self.valueSum;
        result.changeSign = self.changeSign;
        result.changeContext = self.changeContext;
        return result;
    };
}
window.Issue = Issue;
/**
  * Creates IssueStorage object to manage Issues
  * @class
  */
export function IssueStorage() {
    /**
	  * Inner storage object. [guid] -> [Issue]
	  * @private
	  * @type Object
	  */
    this._store = {};
    /**
	  * Inner issue GUID ordered list
	  * @private
	  * @type Array
	  */
    this._order = [];

    const self = this;

    /**
	  * Creates Issue GUID
	  * @private
	  * @function
	  * @returns {String} GUID
	  */
    const _generateGUID = function () {
	    let d = new Date().getTime();
	    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
	        const r = (d + Math.random() * 16) % 16 | 0;
	        d = Math.floor(d / 16);
	        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	    });
	    return uuid;
    };

    /**
	  * Insert new object in Issue storage
	  * @private
	  * @function
	  * @param {Object} json - Search service data object
	  * @returns {Issue} Issue protected copy
	  */
    const _insert = function (json) {
        let guid = _generateGUID();
        while (guid in self._store) {
            guid = _generateGUID();
        }
        const issue = new Issue(json);
        self._store[guid] = issue;
        self._order.push(guid);

        return issue.protectedCopy(guid);
    };

    /**
	  * Cleans Issue storage
	  * @private
	  * @function
	  */
    this.clean = function () {
        self._store = {};
        self._order = [];
    };

    /**
	  * Insert new object or array of objects in Issue storage
	  * @public
	  * @function
	  * @param {Object|Array} json - Search service data object or array of such objects
	  * @returns {Array} Array of Issue protected copies
	  */
    this.insert = function (json) {
        const result = [];
        if (json instanceof Array) {
            for (const idx in json) {
                if (!json.hasOwnProperty(idx)) {
                    continue;
                }
                result.push(_insert(json[idx]));
            }
        } else {
            result.push(_insert(json));
        }
        return result;
    };

    /**
	  * Get sorted array of Issues stored in manager
	  * @public
	  * @function
	  * @returns {Array} Ordered array of Issue protected copies
	  */
    this.get = function () {
        const result = [];
        for (const idx in self._order) {
            if (!self._order.hasOwnProperty(idx)) {
                continue;
            }
            const guid = self._order[idx];
            result.push(self._store[guid].protectedCopy(guid));
        }
        return result;
    };

    /**
	  * Resolves Issue GUID
	  * @public
	  * @function
	  * @param {String} guid - Issue GUID to resolve
	  * @returns {Issue} Copy of original Issue
	  */
    this.resolveGuid = function (guid) {
        return self._store[guid].copy();
    };

    /**
	  * Resolves Issue position
	  * @public
	  * @function
	  * @param {String} guid - Issue GUID to resolve
	  * @returns {Integer} Position of Issue
	  */
    this.resolvePosition = function (guid) {
        return self._order.indexOf(guid);
    };
}
/**
  * Creates Search manager working with search service API
  * @class
  * @param {Object} config - Configuration object for Search manager
  * @param {String} [config.url] - Search service API URL
  * @param {Object} config.ga - Google Analytics(GA) global object
  * @param {Boolean} [config.legacy=false] - Is Google Analytics use legacy API
  * @param {String} [config.queryParamName] - Search service API query parameter name
  * @param {String} [config.limitParamName] - Search service API limit parameter name
  * @throws {Error} Throws error if one of config required parameters is absent or invalid
  */
export function Search(config) {
    const self = this;

    this.urlExternalSearch = '//txprd-wt.just2trade.com/grpc-json/txscreener/v1/search';

    this.url = config.url || this.urlExternalSearch;
    this.word = config.queryParamName || 'query';
    this.limit = config.limitParamName || 'limit';
    this.lang = 'lang';
    this.isInternalSearch = this.url !== this.urlExternalSearch;

    /**
	  * Inner issues storage manager
	  * @private
	  * @type IssueStorage
	  */
    this._storage = new IssueStorage();

    /**
	  * Is Google Analytics use legacy API
	  * @private
	  * @type Boolean
	  */
    this._legacyGAMode = (('legacy' in config) && config.legacy);

    /**
	 * Need to skip Google Analytics object
	 * @private
	 * @type Boolean
	 */
    this._skipGa = (('skipGa' in config) && config.skipGa);

    /**
	  * Inner analytics manager
	  * @private
	  * @type GoogleAnalyticsManager
	  */
    this._manager = null;

    if (!(this._skipGa || ('ga' in config))) {
        throw new Error('Invalid Google Analytics(GA) reference');
    } else if (!this._skipGa) {
        this._manager = (this._legacyGAMode ? new GoogleAnalyticsLegacyManager(config.ga) : new GoogleAnalyticsManager(config.ga));
    } else {
        this._manager = new GoogleAnalyticsStubManager();
    }

    const _buildURL = (query, limit, lang, timestamp) => {
        const { url } = self;
        let separator = '?';
        if (url.indexOf(separator) !== -1) {
            separator = '&';
        }
        if (!this.isInternalSearch) {
            const params = new URLSearchParams({
                ...((query.length <= 2) && { sort_by_score: true }),
                ...(Boolean(limit) && { limit }),
            });

            const paramsString = params.toString();
            return `${url}/${encodeURIComponent(query)}${paramsString ? `${separator}${paramsString}` : ''}`;
        }
        const wordForLimit = self.limit;
        const wordForLang = self.lang;
        const wordForQuery = self.word;
        const params = new URLSearchParams({
            ...({ [wordForQuery]: encodeURI(query) }),
            ...(Boolean(limit) && { [wordForLimit]: limit }),
            ...(Boolean(lang) && { [wordForLang]: lang }),
            ...(Boolean(timestamp) && { timestamp }),
        });

        const paramsString = params.toString();
        return `${url}${separator}${paramsString}`;
    };

    /**
     * Sends search request to service API and parses answer. Logs query and execution time to GA
     * @public
     * @function
     * @param {String} query - Search API query parameter value. Must be fully cleaned and escaped
     * @param {Integer} limit - Search API limit parameter value
     * @param {String} lang - Search API language parameter value
     */
    this.sendRequest = function sendRequest(query, limit, lang) {
        self._storage.clean();
        self._manager.registerQuery(query);

        const startTime = new Date().getTime();

        const url = _buildURL(query, limit, lang, startTime);
        self.request = new XMLHttpRequest();

        const deferred = new Promise(((resolve, reject) => {
            self.resolveAjax = resolve;
            self.rejectAjax = reject;
            self.request.onreadystatechange = function () {
                if (self.request.readyState === 4) {
                    const endTime = new Date().getTime();
                    self._manager.registerTime(endTime - startTime);

                    if (self.request.status >= 200 && self.request.status < 300) {
                        const data = typeof self.request.responseText === 'object'
                            ? self.request.responseText
                            : JSON.parse(self.request.responseText);

                        if (data.item && !self.isInternalSearch) {
                            data.issues = instrumentResultMapper(data.item, lang);
                        }

                        data.issues && data.issues.forEach(function (item, i) { // eslint-disable-line
                            // dz: разные поиски имеют разный формат, потому нужны мапперы
                            if (self.isInternalSearch) {
                                data.issues[i] = this.mapFieldsInternal(item);
                            } else {
                                data.issues[i] = this.mapFieldsExternal(item);
                            }
                        }, self);

                        data.issues = self._storage.insert(data.issues);
                        data.issues.time = startTime;
                        self.resolveAjax(data.issues);
                    } else {
                        self.rejectAjax(self.request.statusText);
                    }
                }
            };
            self.request.open('GET', url, true);
            self.request.send();
        }));

        deferred.catch((error) => {
            self.request.abort();

            return error;
        });

        return deferred;
    };

    this.mapFieldsInternal = function (item) {
        item.dcpl = item.dcpl <= 0 ? 2 : item.dcpl;
        item.ename = item.name;
        item.finamId = +item.issueId;
        item.changeSign = item.change == 0 ? 0 : (item.change < 0 ? -1 : 1);
        item.exchangeName = item.mic;
        item.changeContext = item.change == 0 ? 'stable' : (item.change < 0 ? 'loss' : 'profit');
        item.last = this.formatDecimal(+item.last, +item.dcpl);
        item.change = this.formatDecimal(+item.change, 2);

	    return item;
    }.bind(this);

    this.mapFieldsExternal = function (item) {
        const { last, chg } = item.quote;

        item.dcpl = item.decimal_place <= 0 ? 2 : item.decimal_place;
        item.ename = item.name;
        item.finamId = +item.id;
        item.changeSign = chg === 0 ? 0 : (item.quote.chg < 0 ? -1 : 1);
        item.exchangeName = item.mic;
        item.changeContext = chg === 0 ? 'stable' : (chg < 0 ? 'loss' : 'profit');
        item.last = last === null || last === undefined
            ? last
            : this.formatDecimal(+last, +item.decimal_place);
        item.change = chg === null || chg === undefined
            ? chg
            : this.formatDecimal(+chg, 2);

        return item;
    }.bind(this);

    this.formatDecimal = function (number, precision) {
        let preformatted;

        preformatted = number.toFixed(precision);

        // ak: replace dash with 'minus'
        return preformatted.replace('-', '−');
    };

    /**
	  * Resolves actual Issue copy by GUID. Logs FinamID and position to GA
	  * @public
	  * @function
	  * @param {String} guid - Issue GUID to resolve
	  * @returns {Issue} Actual Issue copy
	  */
    this.resolveGuid = function (guid) {
        const item = self._storage.resolveGuid(guid);
        if (item) {
            const position = self._storage.resolvePosition(guid);
            self._manager.registerResolve(item.finamId, position);
        }
        return item;
    };

    /**
	  * Gets current Issues list
	  * @public
	  * @function
	  * @returns {Array} Array of Issues protected copies
	  */
    this.get = function () {
        return self._storage.get();
    };
}
window.Search = Search;
