import { useRef } from 'react';
import throttle from 'lodash/throttle';

export type IntersectionOpts = {
    intersectionParams?: IntersectionObserverInit,
    throttleDelay?: number,
    throttleParams?: {
        leading?: boolean | undefined;
        trailing?: boolean | undefined;
    },
    wrapObserve?: (arg0: () => void) => void,
};

const defaultOpts:Required<IntersectionOpts> = {
    intersectionParams: {
        rootMargin: '0px',
        threshold: 0.2,
    },
    throttleDelay: 1000,
    throttleParams: {
        leading: true,
        trailing: false,
    },
    wrapObserve: (observe) => observe(),
};

export const useIntersection = (callback: IntersectionObserverCallback, _opts: IntersectionOpts) => {
    const opts = { ...defaultOpts, ..._opts };

    const throttledCallback = throttle(callback, opts.throttleDelay, opts.throttleParams);

    const observerRef = useRef<IntersectionObserver | null>(null);

    return {
        observeIntersection: (element: HTMLElement | null) => {
            if (!element) return;
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
            observerRef.current = new IntersectionObserver(throttledCallback, opts.intersectionParams);
            opts.wrapObserve(() => {
                observerRef.current?.observe(element);
            });
        },
        observerRef,
    };
};
