import React from 'react';

import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import {
    body14MediumFontStyle,
    body14RegularFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';

import { ButtonIcon as ButtonIconComponent } from 'components/button-icon';

export const NotificationContainer = styled.div<{
    isLoading: boolean,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    width?: number,
}>`
    padding: 12px 8px 12px 12px;
    box-sizing: border-box;

    ${({ width }) => width && `width: ${width}px`}}

    &:hover {
        ${({ isLoading, onClick }) => {
        if (isLoading) {
            return 'cursor: wait;';
        }

        if (onClick) {
            return 'cursor: pointer;';
        }
        return 'cursor: default';
    }};
    }

    &:active {
        ${({ isLoading, theme, onClick }) => {
        if (isLoading) {
            return `
                    cursor: wait;
                `;
        }
        if (onClick) {
            return `
                    background-color: ${theme.fillIn.secondary.overlay16};
                    cursor: pointer;
                `;
        }
        return '';
    }
};
    }

    &:focus {
        box-shadow: inset ${({ theme }) => theme.focusOutline.accent};
        outline: none;
    }

`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const IndicatorColumn = styled.div`
    margin: 16px 12px 0 0;
`;

export const Indicator = styled.span<{isActive?: boolean}>`
    display: block;
    width: 8px;
    height: 8px;
    background: ${({ theme, isActive }) => (isActive ? theme.fillIn.primary.mid : 'transparent')};
    border-radius:8px;
`;

export const AvatarColumn = styled.div`
    margin: 4px 12px 0 0;
`;

export const AvatarWrapper = styled.div`
    position: relative;
`;

export const AvatarContentWrapper = styled.div<{withBorder: boolean}>`
    height: 32px;
    width: 32px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay24};
    border-radius: 8px;
    overflow: hidden;

    ${({ withBorder, theme }) => withBorder && `
        border: 1px solid ${theme.fillIn.secondary.overlay16};
    `}
`;

export const AvatarContent = styled.div<{backgroundColor?: string}>`
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`};
    height: 100%;
    width: 100%;
`;

export const AvatarImg = styled.img<{isLoaded:boolean, isError: boolean}>`
    display: ${({ isError }) => (isError ? 'none' : 'block')};
    cursor: ${({ isLoaded }) => (isLoaded ? 'inherit' : 'wait')};
    opacity: ${({ isError, isLoaded }) => ((!isLoaded || isError) ? '0' : '1')};
    transition: opacity 300ms;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const AvatarIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

export const AvatarBadge = styled.div`
    position: absolute;
    right: -4px;
    bottom: -4px;
`;

export const AvatarBadgeIconWrapper = styled.div<{backgroundColor?: string}>`
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    position: relative;
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.fillIn.secondary.muted};
    border: 1px solid ${({ theme }) => theme.bg.secondary};
    border-radius: 20px;
    line-height: 20px;

    > svg {
        width: 100%;
        text-align: center;
    }
`;

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const TitleWrapper = styled.div`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};
    word-break: break-word;
`;

export const MessageWrapper = styled.div`
    margin-bottom: 4px;
    word-break: break-word;
`;

export const Message = styled.span<{lineClamp?: number}>`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    ${({ lineClamp }) => lineClamp && `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${lineClamp};
        overflow: hidden;
    `};

`;

export const EventTimeText = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const ActionButtonWrapper = styled.div`
    padding: 4px 0;
    margin-top: 4px;
`;

export const ButtonAction = styled(ButtonIconComponent)`
    width: 100%;
`;

export const ButtonColumn = styled.div<{isHiddenTabletAndMore: boolean}>`
    margin: 0 0 0 8px;
    transition: opacity 350ms;
    opacity: ${({ isHiddenTabletAndMore }) => (isHiddenTabletAndMore ? '0' : '1')};

    ${mediaQuery.lt960} {
        opacity: 1;
    }
`;

export const ShowFull = styled.a`
    ${body14RegularFontStyle};
    display: inline-block;
`;
