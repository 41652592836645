import styled from 'styled-components';

export const List = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #8297BE;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        color: #171819;
    }
`;

export const ListItem = styled.li`
    padding: 10px 8px;
    margin: 0;
`;

export const Link = styled.a<{ isActive: boolean; }>`
    text-decoration: none;
    font-family: Inter,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
    cursor: pointer;
    color: ${({ isActive, theme }) => (isActive ? theme.label.accent : theme.label.secondary)};

    &:hover {
        color: ${({ isActive, theme }) => (isActive ? theme.label.accent : theme.label.secondaryActive)};
    }
`;
