import React from 'react';

import { Issue } from 'services/issuesearch/types';
import { CashtagSuggestion } from 'widgets/cashtag-suggestion';
import { redactorPaddingBottom } from './editor/style';

type Props = {
    params: {
        resetSuggest?: boolean,
        searchTicker?: string,
        callback?: (ticker: string) => void,
        cashtagElement?: HTMLElement,
    }
};

export const CashtagsSuggest = (props: Props) => {
    const { params } = props;
    const {
        searchTicker, resetSuggest, cashtagElement, callback,
    } = params;

    const visible = searchTicker && !resetSuggest && cashtagElement;
    const handleSelect = (issue: Issue, cursor?: number, event?: KeyboardEvent) => {
        if (visible && (event instanceof KeyboardEvent)) {
            event?.stopPropagation();
        }
        callback?.(issue.ticker);
    };
    const top = cashtagElement ? `${cashtagElement.offsetTop + cashtagElement.offsetHeight - redactorPaddingBottom + 5}px` : undefined;

    return (
        <CashtagSuggestion
            searchLimit={5}
            searchTicker={searchTicker}
            isVisible={!!visible}
            top={top}
            onSelect={handleSelect}
            cursorElement={window}
        />
    );
};
