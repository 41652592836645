import React from 'react';
import { useAgo } from 'hooks/useAgo';
import { useLocaleId } from 'hooks/useLocaleId';

type NotificationTimeAgoPropsType = {
    timestamp: string;
}

const NotificationTimeAgo = (props:NotificationTimeAgoPropsType) => {
    const { timestamp } = props;

    const locale = useLocaleId();
    const { timeAgo: eventTimeText } = useAgo(timestamp, { lcid: locale });

    return (
        <span>{eventTimeText}</span>
    );
};

export default NotificationTimeAgo;
