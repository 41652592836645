const isElementActive = (currentUrl, elementUrl) => {
    if (!elementUrl) {
        return false;
    }

    const re = new RegExp('(?:https?://)?[^/]*(/[^?#]*).*');

    return currentUrl
        .replace('#!/', '')
        .replace(re, '$1')
        .indexOf(
            elementUrl
                .replace('#!/', '')
                .replace(re, '$1'),
        ) !== -1;
};

export default isElementActive;
