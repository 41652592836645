import React from 'react';

import Dic from 'services/dictionary';
import { ItemWrapper } from '../styled';
import { Activity } from '../../../activity';

type Props = {
    visible: boolean
}
export const Loading = ({ visible }: Props) => {
    return (
        <ItemWrapper style={{ display: visible ? 'block' : 'none' }}>
            <Activity>
                <div style={{ margin: '0 auto', width: '10%' }}>
                    {Dic.word('wt_feed__widget_feed__loading')}
                </div>
            </Activity>
        </ItemWrapper>
    );
};
