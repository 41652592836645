import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { Activity } from 'widgets/feed/activity';
import { Button } from 'components/button2';
import { FeedItem } from '../../../FeedItem';

export const StyledActivity = styled(Activity)`
    background: ${({ theme }) => theme.bg.tertiary};
    border: none;
    padding: 24px 8px 16px;
    ${mediaQuery.lt960} {
        padding: 16px 4px 8px;
    }
`;

export const MoreNewsButton = styled(Button).attrs({
    size: { default: 'medium', lt960: 'small' },
    kind: 'ghost-primary',
})`
    margin: 8px auto 0;
    ${mediaQuery.lt960} {
        margin-top: 4px;
    }
`;

export const InstrumentWrapper = styled.div`
    margin-bottom: 16px;
    ${mediaQuery.lt960} {
        margin-bottom: 12px;
    }
`;

export const getStyledFeedItem = () => styled(FeedItem)`
    & + & {
        margin-top: 8px;
    }

    ${mediaQuery.lt960} {
        & + & {
            margin-top: 4px;
        }
    }
`;
