import styled, { createGlobalStyle } from 'styled-components';
import { TourProvider } from '@reactour/tour';

import { mediaQuery } from 'app/styled';
import { CloseButton } from 'components/wt-popup/popup-window/styled';

export const StyledCloseButton = styled(CloseButton)`
    top: 10px;
    right: 10px;
`;

export const StyledTourProvider = styled(TourProvider)`
    border-radius: 2px;
`;

export const TourGlobalStyle = createGlobalStyle`
   .reactour-blocked {
       // !!! это исключение. В др местах используем mediaQuery
       @media (min-width: 1200px) {
           overflow: hidden;
           width: 100%;
           height: 100%;
       }
   }

    .reactour__popup, .reactour__mask {
        ${mediaQuery.lt1200} {
            display: none;
        }
    }
`;
