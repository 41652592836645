import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react';

import {
    Id,
    FeedRecord,
    FeedRecordObjTypes,
} from 'types';
import Dic from 'services/dictionary';
import { useToggle } from 'hooks/useToogle';
import { Skeleton } from 'widgets/feed/comments/components/Skeleton';
import { useUrlHashIdComment } from 'widgets/feed/comments/hooks/useUrlHashIdComment';
import { MenuItem } from 'components/context-actions';

import { RootObject } from '../types';
import {
    FetchComments, SaveComment, GetMentionList, DeleteComment, RestoreComment,
    CommentsFilters, commentsFilters, ChangeCommentsFilter,
} from '../actions';
import { useMentionList } from '../hooks/useMentionList';
import { getCommentsAndPath } from '../../helpers';

import { Comment } from './Comment';
import { CommentForm } from './Form';
import { ShowMore } from './ShowMore';
import {
    Wrapper,
    FilterActions,
    FilterWrapper,
    FilterButton,
    FilterIcon,
    CommentFormWrapper,
} from './styled';

export interface StateProps {
    record?: FeedRecord,
}

export interface DispatchProps {
    fetchComments: FetchComments,
    saveComment: SaveComment,
    deleteComment: DeleteComment,
    restoreComment: RestoreComment,
    getMentionList: GetMentionList,
    changeCommentsFilter: ChangeCommentsFilter
}

export interface OwnProps {
    recordId: Id,
    showFilters?: boolean,
    placeholder?: string,
    showComments?: boolean,
}

interface Props extends StateProps, DispatchProps, OwnProps {}

const COMMENTS_PAGE_SIZE = 20;

export const Comments = (props: Props) => {
    const {
        recordId,
        showComments = true,
        record,
        showFilters = true,
        placeholder,
        fetchComments,
        saveComment,
        getMentionList,
        deleteComment,
        restoreComment,
        changeCommentsFilter,
    } = props;

    const {
        obj, stats, comments: recordComments,
    } = record ?? {};

    const { comments = [] } = record ? getCommentsAndPath({ obj, comments: recordComments }) : {};

    const { hashCommentId: pinnedCommentId } = useUrlHashIdComment();

    const [openedFormId, setOpenedFormId] = useState<string | null>(null);
    const [isFilterVisible, setFilterVisibility] = useState(false);
    const [loading, toggleLoading] = useToggle(false);
    const [filterUpdating, toggleFilterUpdating] = useToggle(false);

    const rootObject = useMemo((): RootObject => ({
        //  пока не испоьзуем многообразие типов активностей
        //  если будут проблемы с выдачей с бека - подключить useActivityType
        rootObjectType: 'blog',
        rootObjectId: obj?.id ?? '',
    }), [obj]);

    const [isInitial, setInitial] = useState(showFilters);
    const [commentsFilterState, setCommentsFilter] = useState<CommentsFilters>(commentsFilters.rated);
    const [commentsPage, setCommentsPage] = useState(1);
    const commentsFilter = showFilters ? commentsFilterState : commentsFilters.recent;
    const handleShowMoreClick = useCallback(() => setInitial(false), []);

    const { mentionList, fetchMentionList } = useMentionList(getMentionList, rootObject);

    const { rootObjectId, rootObjectType } = rootObject;
    const { commentsCount = 0 } = (obj && obj.type === FeedRecordObjTypes.POST) ? obj : stats ?? {};
    const [nonShownCommentsCount, setNonShownCommentsCount] = useState(commentsCount);

    const loadNewComments = useCallback(() => {
        toggleLoading();
        fetchComments({
            filter: commentsFilter,
            objectTypeString: rootObjectType,
            objectId: rootObjectId,
            page: commentsPage,
            pageSize: COMMENTS_PAGE_SIZE,
        }, {
            recordId,
        }).then(() => {
            setCommentsPage(commentsPage + 1);
            setNonShownCommentsCount(nonShownCommentsCount - COMMENTS_PAGE_SIZE);
            toggleLoading();
            handleShowMoreClick();
        });
    }, [
        commentsFilter,
        commentsPage,
        commentsCount,
        fetchComments,
        nonShownCommentsCount,
        handleShowMoreClick,
        recordId,
        rootObjectId,
        rootObjectType,
        toggleLoading,
    ]);

    useEffect(() => {
        if (showComments) {
            loadNewComments();
        }
    }, [showComments]);

    const updateFilter = async (filter: CommentsFilters) => {
        await setCommentsFilter(filter);
        await toggleLoading();
        await toggleFilterUpdating();
        await setCommentsPage(1);
        await changeCommentsFilter(recordId);
        await fetchComments({
            filter,
            objectTypeString: rootObjectType,
            objectId: rootObjectId,
            page: 1,
            pageSize: COMMENTS_PAGE_SIZE,
        }, {
            recordId,
        }).then(() => {
            setNonShownCommentsCount(commentsCount - COMMENTS_PAGE_SIZE);
            setCommentsPage(commentsPage + 1);
            toggleLoading();
            toggleFilterUpdating();
            handleShowMoreClick();
        });
    };

    if (!record) {
        return null;
    }

    return (
        <>
            {showComments && (
                <Wrapper>
                    <CommentFormWrapper>
                        <CommentForm
                            recordId={recordId}
                            rootObject={rootObject}
                            saveComment={saveComment}
                            commentsFilter={commentsFilter}
                            fetchMentionList={fetchMentionList}
                            mentionList={mentionList}
                            openedFormId={openedFormId}
                            setOpenedFormId={setOpenedFormId}
                            onDismiss={() => setOpenedFormId(null)}
                            placeholder={placeholder}
                        />
                    </CommentFormWrapper>
                    {!!obj?.commentsCount && comments.length + nonShownCommentsCount <= obj?.commentsCount && showFilters && (
                        <FilterActions
                            buttonContent={
                                (
                                    <FilterButton active={isFilterVisible}>
                                        {Dic.word(`wt_feed__widget_comments__filter_${
                                            commentsFilter === commentsFilters.default
                                                ? commentsFilters.latestByDate
                                                : commentsFilter
                                        }`)}
                                        <FilterIcon />
                                    </FilterButton>
                                )
                            }
                            tippyContent={
                                (
                                    <FilterWrapper>
                                        <MenuItem
                                            key={commentsFilters.rated}
                                            text={Dic.word(`wt_feed__widget_comments__filter_${commentsFilters.rated}`)}
                                            onClick={() => updateFilter(commentsFilters.rated)}
                                        />
                                        <MenuItem
                                            key={commentsFilters.recent}
                                            text={Dic.word(`wt_feed__widget_comments__filter_${commentsFilters.recent}`)}
                                            onClick={() => updateFilter(commentsFilters.recent)}
                                        />
                                        {/* commentsFilters.default это и есть commentsFilters.recent наоборот */}
                                        <MenuItem
                                            key={commentsFilters.latestByDate}
                                            text={Dic.word(`wt_feed__widget_comments__filter_${commentsFilters.latestByDate}`)}
                                            onClick={() => updateFilter(commentsFilters.default)}
                                        />
                                    </FilterWrapper>
                                )
                            }
                            onVisibleChange={({ isVisible }) => setFilterVisibility(isVisible)}
                        />
                    )}
                    <div>
                        {(comments).map((comment) => (
                            <Comment
                                key={comment.id}
                                recordId={recordId}
                                comment={comment}
                                comments={comments}
                                forceLevel={isInitial ? 0 : null}
                                rootObject={rootObject}
                                pinned={String(pinnedCommentId) === String(comment.id)}
                                saveComment={saveComment}
                                deleteComment={deleteComment}
                                restoreComment={restoreComment}
                                fetchMentionList={fetchMentionList}
                                mentionList={mentionList}
                                openedFormId={openedFormId}
                                setOpenedFormId={setOpenedFormId}
                                isMarked={record.publishedId === comment.id}
                                placeholder={placeholder}
                                commentsFilter={commentsFilter}
                            />
                        ))}
                        {(!!obj?.commentsCount && (comments.length < obj.commentsCount) && loading)
                        && <Skeleton count={nonShownCommentsCount} />}
                        {/* TODO sob: refactor component state management in https://jira.finam.ru/browse/WTT-10871 */}
                        {filterUpdating && <Skeleton count={commentsCount} />}
                    </div>
                    {!loading && (
                        <ShowMore
                            nonShownCommentsCount={nonShownCommentsCount}
                            isLoading={loading}
                            onClick={loadNewComments}
                        />
                    )}
                </Wrapper>
            )}
        </>
    );
};
