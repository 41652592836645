import React from 'react';

import { IconSvg, IconComponent } from '.';

export const PenToSquareIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.00008 4.00008C4.4478 4.00008 4.00008 4.4478 4.00008 5.00008V15.0001C4.00008 15.5524 4.4478 16.0001 5.00008 16.0001H15.0001C15.5524 16.0001 16.0001 15.5524 16.0001 15.0001V10.3149C16.0001 9.94669 16.2986 9.64821 16.6667 9.64821C17.0349 9.64821 17.3334 9.94669 17.3334 10.3149V15.0001C17.3334 16.2887 16.2887 17.3334 15.0001 17.3334H5.00008C3.71142 17.3334 2.66675 16.2887 2.66675 15.0001V5.00008C2.66675 3.71142 3.71142 2.66675 5.00008 2.66675H9.42372C9.79191 2.66675 10.0904 2.96522 10.0904 3.33341C10.0904 3.7016 9.79191 4.00008 9.42372 4.00008H5.00008Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.99875 10.402C7.02739 10.2822 7.08865 10.1727 7.17575 10.0856L14.2582 3.00316C14.6813 2.58009 15.3672 2.58009 15.7903 3.00316L16.9688 4.18167C17.3918 4.60474 17.3918 5.29067 16.9688 5.71373L9.88633 12.7962C9.80146 12.881 9.69526 12.9414 9.57894 12.9709L7.24722 13.5628C7.02102 13.6202 6.78127 13.5551 6.61511 13.3913C6.44894 13.2274 6.38055 12.9886 6.4348 12.7616L6.99875 10.402ZM9.41493 11.382L15.8492 4.9477L15.0242 4.12274L8.58997 10.557L9.41493 11.382Z" />
    </IconSvg>
);
