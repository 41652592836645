import { Id, FeedRecord, FeedRecordObjTypes } from 'types';
import { Cancelation } from 'services/create-action-types';
import { CancelationData } from 'services/create-action-types/types';

const recordStoreIndex = (id: Id) => `_${id}`;
export { recordStoreIndex as index };

export const getCommentsAndPath = (record: Partial<FeedRecord>) => {
    let comments;
    let path;
    if (!record.comments && !record.obj) throw new Error('Неверное использование getCommentsAndPath');
    if (record.obj && record.obj.type === FeedRecordObjTypes.POST) {
        comments = record.obj.comments;
        path = 'obj.comments';
    } else {
        comments = record.comments || [];
        path = 'comments';
    }
    return { comments, path };
};

type CancelItem<Prev> = {
    cancelation: CancelationData,
    prevSpecialState: Prev,
}
export class CancelActionProcessor<Prev> {
    private items: {
        [actionId: string]: CancelItem<Prev> | null
    } = {}

    public cancelOrBuild(actionId: string, prevSpecialState: Prev): CancelItem<Prev> {
        let item = this.items[actionId];
        if (item) {
            if (!item.cancelation.outdated) Promise.all([item.cancelation.source.cancel()]);
            item.cancelation = Cancelation();
        } else {
            item = {
                cancelation: Cancelation(),
                prevSpecialState,
            };
            this.items[actionId] = item;
        }
        return item;
    }

    public clear(actionId: string) {
        this.items[actionId] = null;
    }
}
