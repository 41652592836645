import React from 'react';
import PropTypes from 'prop-types';
import {
    Window,
    Wrapper,
    Body,
    Content,
    CloseContainer,
    CloseButton,
    CloseIcon,
    BackButton,
    BackIcon,
} from './styled';

export class PopupWindow extends React.PureComponent {
    windowRef = React.createRef();

    // когда PopupWindow2 будет независимым от PopupWindow, то вместо этого следует заиспользовать useClickOutside
    handleWindowClick = (e) => {
        const { onClose, bgCloseable } = this.props;
        if (bgCloseable && e.target === this.windowRef.current) onClose(e);
    }

    render() {
        const {
            showInModal,
            children,
            closeButton,
            closeButtonClassName,
            mobileHeight,
            desktopHeight,
            onClose,
            popupClassName,
            bodyClassName,
            backButton,
            backButtonClassName,
            onBack,
            bgCloseable
        } = this.props;
        if (!showInModal) {
            return (
                <Content>
                    {children}
                </Content>
            );
        }

        const heights = [desktopHeight, mobileHeight];

        return (
            <Window heights={heights} onMouseDown={this.handleWindowClick} ref={this.windowRef}>
                <Wrapper heights={heights} className={popupClassName}>
                    {(closeButton || backButton) && (
                        <CloseContainer heights={heights}>
                            {backButton && (
                                <BackButton className={backButtonClassName} onClick={onBack}>
                                    <BackIcon />
                                </BackButton>
                            )}
                            {closeButton && (
                                <CloseButton
                                    onClick={onClose}
                                    className={closeButtonClassName}
                                >
                                    <CloseIcon />
                                </CloseButton>
                            )}
                        </CloseContainer>
                    )}
                    <Body heights={heights} className={bodyClassName}>
                        <Content>
                            {children}
                        </Content>
                    </Body>
                </Wrapper>
            </Window>
        );
    }
}

PopupWindow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    closeButton: PropTypes.bool,
    closeButtonClassName: PropTypes.string,
    onClose: PropTypes.func,
    backButton: PropTypes.bool,
    backButtonClassName: PropTypes.string,
    mobileHeight: PropTypes.number,
    desktopHeight: PropTypes.number,
    onBack: PropTypes.func,
    popupClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    showInModal: PropTypes.bool,
    bgCloseable: PropTypes.bool,
};

PopupWindow.defaultProps = {
    closeButton: true,
    onClose: () => {},
    closeButtonClassName: '',
    backButton: false,
    backButtonClassName: '',
    // vls: 0 - высота по-умолчанию
    mobileHeight: 0,
    // vls: 0 - высота по-умолчанию
    desktopHeight: 0,
    onBack: () => {},
    popupClassName: '',
    bodyClassName: '',
    showInModal: true,
    bgCloseable: false,
};
