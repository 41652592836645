import { useCallback } from 'react';

import { useFeedItemContext } from 'context/feed-item';

export const useEditRecord = () => {
    const [{ isEditing }, setContextSlice] = useFeedItemContext();

    const setIsEditing = useCallback((newVal: boolean) => {
        setContextSlice({ isEditing: newVal });
    }, [setContextSlice]);

    return { isEditing, setIsEditing };
};
