import { FeedRecord } from 'types';

const getDefaultShowFull = (record: FeedRecord) => {
    const { obj } = record;
    if (!obj) return true;
    let textLength = 0;
    if (obj.hasOwnProperty('contentTextLength')) {
        textLength = +(obj.contentTextLength || 0);
    } else {
        textLength = +obj.content.length;
    }
    let mediaLength = 0;
    if (obj.media) {
        mediaLength = Object.values(obj.media).reduce((sum, item) => sum + item.count, 0);
    }
    if (mediaLength === 1 && textLength < 300) {
        return true;
    }
    if (mediaLength === 0 && textLength < 200) {
        return true;
    }
    return false;
};

export const getFeedItemContextDefaultValue = (record: FeedRecord, showFull?: boolean) => {
    const { id } = record;
    return {
        id,
        showFull: showFull || getDefaultShowFull(record),
        isDeleted: false,
        isEditing: false,
    };
};
