import React, {useState, useLayoutEffect} from 'react';

import {
    AvatarDefaultProps,
    AvatarPropTypes,
} from './prop-types';
import {
    Wrapper, ImgAvatar, SvgDefault, Link,
} from './styled';

const DefaultAvatar = (
    <SvgDefault xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100">
        <path d="M0.02961 100V80C0.000151498 70.625 2.02815 65.6376 15.0185 60.4027C25.0111 56.3758 28.1526 52.8032 28.6752 52.0134C30.4958 49.2618 29.8819 42.4275 28.0089 39.5973C23.0126 38.9262 21.6803 29.8658 22.0134 27.8523C22.2799 26.2416 23.4567 26.1745 24.0119 26.5101C24.0119 26.5101 22.0259 19.5084 22.6796 13.0872C23.9094 1.00671 37.0024 0 40.0001 0C42.9978 0 56.0908 1.00671 57.3206 13.0872C57.9743 19.5084 55.9883 26.5101 55.9883 26.5101C56.5435 26.1745 57.7203 26.2416 57.9868 27.8523C58.3199 29.8658 56.9876 38.9262 51.9913 39.5973C50.1183 42.4275 49.5044 49.2618 51.325 52.0134C51.8476 52.8032 54.9891 56.3758 64.9817 60.4027C77.972 65.6376 79.9706 70.625 79.9706 79.8658V100H0.02961Z" />
    </SvgDefault>
);

export const Avatar = React.forwardRef<HTMLDivElement | HTMLAnchorElement, AvatarPropTypes>((props: AvatarPropTypes, ref) => {
    const {
        linkHref, target, person, className, size, onClickLink, children, alt, src, title, bordered, square,
    } = props;

    const [showImg, setShowImg] = useState(document.readyState === 'complete');

    useLayoutEffect(() => {
        if (!showImg) {
            const lazyLoadImg = () => setShowImg(true);
            window.addEventListener('load', lazyLoadImg);
            return () => window.removeEventListener('load', lazyLoadImg);
        }
        return undefined;
    }, []);

    const isLink = () => Boolean(linkHref || person?.url);

    const wrapLink = (element: React.ReactElement) => {
        if (!isLink()) return element;
        return (
            <Link
                className={`${className} profile-info__item-avatar autotest__profile-info__item-avatar`}
                href={linkHref || person?.url}
                target={target}
                onClick={onClickLink}
                size={size}
                square={square}
                bordered={bordered}
            >
                <>
                    {element}
                    {children}
                </>
            </Link>
        );
    };

    const finalSrc = src || person?.photo || person?.image || '';
    const finalAlt = alt || person?.displayName;
    const Img = showImg ? (
        <ImgAvatar src={finalSrc} alt={finalAlt} />
    ) : <ImgAvatar className="lazy-load-img" data-src={finalSrc} alt={finalAlt} />;

    return wrapLink(
        <Wrapper
            ref={ref}
            title={title || person?.title || ''}
            size={size}
            className={`${className} autotest__profile_header_avatar`}
            bordered={bordered}
            square={square}
        >
            {finalSrc ? Img : DefaultAvatar}
        </Wrapper>,
    );
});

Avatar.defaultProps = AvatarDefaultProps;
