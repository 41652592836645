import { createContext, useContext } from 'react';

export type WatchlistContextValue = {
    /** Токен для авторизации вотчлиста Финам */
    watchlistToken: string;
};

const watchlistContextDefaultValue: WatchlistContextValue = {
    watchlistToken: '',
};

const WatchlistContext = createContext<WatchlistContextValue>(watchlistContextDefaultValue);

export const WatchlistContextProvider = WatchlistContext.Provider;

export const useWatchlistContext = () => {
    return useContext(WatchlistContext);
};
