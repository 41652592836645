import styled from 'styled-components';
import { buttonResetStyle, anchorResetStyle, mediaQuery } from 'app/styled';
import { body14MediumFontStyle, body14RegularFontStyle } from 'services/typography';
import { Avatar } from 'components/avatar2';
import { Button } from 'components/button2';

export const UserDropdownAvatarButton = styled.button`
    ${buttonResetStyle}
`;

export const UserDropdownRelativeReference = styled.div`
    position: relative;
`;

export const UserDropdownAbsoluteContainer = styled.div`
    position: absolute;
    right: 0;
    top: 24px;

    // TODO: dmh: используем захардкоженное значение, чтобы совпадало с 359dfc25194a7c11863b0c35cd95027f29c7e323
    // См. https://jira.finam.ru/browse/WTT-10166
    @media (max-width: 320px) {
        display: none;
    }
`;

export const UserDropdownContainer = styled.div`
    width: 320px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg.secondary};
    box-shadow: ${({ theme }) => theme.shadow.popover};
    overflow: hidden;
`;

export const Divider = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.divider.primary};
    margin: 8px 0 7px 0;
`;

export const AvatarRow = styled.div`
    ${body14MediumFontStyle}

    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -8px;
    user-select: none;
`;

export const AvatarRowFullName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const ButtonRow = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-top: -8px;
`;

export const UserMenuAvatar = styled(Avatar)`
    height: 32px;
    width: 32px;
`;

export const UserDropdownAvatar = styled(Avatar)`
    margin-right: 8px;
    height: 32px;
    width: 32px;
    flex-shrink: 0;
`;

export const MenuItemsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.label.secondary};

    @media(max-width: 768px) {
        flex-direction: column;
        padding: 16px;
    }
`;

export const MenuMobileButton = styled(Button)`
    margin: 0 0 12px 0;
    width: 100%;

    &:last-child {
        margin: 0;
    }
`;

export const MenuItemIconContainer = styled.span`
    margin-right: 12px;
    color: ${({ theme }) => theme.label.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MenuAnchorItem = styled.a`
    ${anchorResetStyle}
    ${body14RegularFontStyle}

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
    box-sizing: border-box;
    height: 40px;

    &:hover {
        color: inherit;
        background-color: ${({ theme }) => theme.fillIn.primary.overlay16};
    }

    &:focus {
        outline: none;
    }

    &:active {
        background-color: ${({ theme }) => theme.fillIn.positive.overlay32};
        color: ${({ theme }) => theme.label.accent};
        border: none;

        & ${MenuItemIconContainer} {
            color: ${({ theme }) => theme.label.accent};
        }
    }
`;

export const MobileUserDropDownContainer = styled.div`
    display: none;
    pointer-events: none;
    // TODO: dmh: используем захардкоженное значение, чтобы совпадало с 359dfc25194a7c11863b0c35cd95027f29c7e323
    // См. https://jira.finam.ru/browse/WTT-10166
    @media (max-width: 768px) {
        display: initial;
        pointer-events: initial;
    }
`;

export const DesktopButtonsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const MobileButtonsRow = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
`;

export const LoginButton = styled(Button)`
    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
      box-shadow: ${({ theme }) => theme.focusOutline.accent};
    }

    &:hover {
        color: ${({ theme }) => theme.label.secondaryActive};
    }
`;
