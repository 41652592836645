import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { hamburger } from './svg/hamburger';
import { close } from './svg/close';

export const ButtonStyled = styled.span< { size: number; }>`
    display: inline-block;
    position: relative;
    width: ${({ size }) => `${size}px`};
    padding-top: 100%;

    & > svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;

export interface IconProps {
    name: 'hamburger' | 'close';
    size?: number;
    color?: string;
}

export const Icon = forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
    const {
        name,
        size = 16,
        color = 'black',
        ...otherProps
    } = props;

    const renderSvg = () => {
        switch (name) {
            case 'close':
                return close({ color });
            case 'hamburger':
                return hamburger({ color });
            default:
                return null;
        }
    };

    const __html = renderSvg();
    return (
        <ButtonStyled
            size={size}
            {...otherProps /* eslint-disable-line react/jsx-props-no-spreading */}
            ref={ref}
            dangerouslySetInnerHTML={__html ? { __html } : undefined}
        />
    );
});
