import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import menuReducer from '../reducers';
import { userCache } from '../services/handleUserCaching';
import callbacks from '../configs/callbacks';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    raceLimit: 55,
}) : compose;

const getUserFromStorage = () => (window.__GLOBAL_AUTH__ ? null : userCache.get());

const initialState = {
    deflcid: 'en',
    lcid: null,
    searchActiveTab: 'instruments',
    callbacks,
    user: getUserFromStorage(),
};

const store = createStore(
    menuReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
);

export default store;
