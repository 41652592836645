import styled from 'styled-components';
import { ifNotProp } from 'styled-tools';

import { ButtonIcon } from 'components/button-icon';
import { ButtonIconStar } from 'components/button-icon-star';

type StyledSubscribeProps = { clickable: boolean };
export const StyledUserSubscribe = styled(ButtonIcon)<StyledSubscribeProps>`
    ${ifNotProp('clickable', 'cursor: default;')};
`;

export const StyledInstrumentSubscribe = styled(ButtonIconStar)<StyledSubscribeProps>`
    margin: 0 8px;
`;
