import React from 'react';

import { FeedRecordObj } from 'types';
import { ContentPremiumNotGranted } from 'widgets/feed/content-premium-not-granted';
import { GalleryInitFunction } from 'components/gallery/Gallery';

import { ContentHtmlParsed } from '../content-html-parsed';
import { useActivityContentType } from './useActivityContentType';
import { Wrapper } from './styled';

interface Props {
    obj: FeedRecordObj,
    showComments?: (force?: boolean) => void,
    onClickAnyImgInPost?: GalleryInitFunction,
    isPremiumGranted?: boolean,
    isPremium?: boolean,
    isOwner?: boolean,
}
export const ActivityContent = (props: Props) => {
    const {
        obj,
        onClickAnyImgInPost,
        showComments,
        isPremiumGranted = false,
        isPremium = false,
        isOwner = false,
    } = props;

    const activityContentType = useActivityContentType(obj);

    const getContentComponentByType = () => {
        switch (activityContentType) {
            default:
                return (
                    <ContentHtmlParsed obj={obj} onClickAnyImgInPost={onClickAnyImgInPost} showComments={showComments} />
                );
        }
    };

    return (
        <Wrapper>
            {(!isOwner && isPremium && !isPremiumGranted)
                ? <ContentPremiumNotGranted obj={obj} />
                : getContentComponentByType()}
        </Wrapper>
    );
};
