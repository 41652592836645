import { createElement, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { CurrentUser, Lcid } from 'types';

import { PageContextProvider } from 'context/page';
import { CurrentUserProvider, guestUser } from 'context/current-user';
import { LocaleProvider } from 'context/locale';

import UrlProcessor from 'services/url-processor';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import { User } from 'services/user';
import ProjectConfig from 'services/config';
import WtLogger from 'services/utils/wt-logger';
import { sendGA } from 'services/google-analytics';
import { themes, ThemeName } from 'services/theme';
import { sentryClient } from 'services/sentry';
import { mswLoader } from 'services/msw/loader';
import type { MockHandler, MockServiceWorker } from 'services/msw/browser';
import { NotifyProcess } from 'services/notify/processor';
import { ErrorBoundary } from 'widgets/error-boundary';
import { UserInfo } from 'widgets/limex-menu/types/interfaces';

// MARK: - Types

export type InitialPageSettings<
    ServerData,
    InitialSettingsExtension extends Record<string, unknown>
> = {
    /** Текущий пользователь */
    currentUser: CurrentUser | null;

    /** Текущая локаль */
    lcid: Lcid;

    /** Название текущей цветовой темы */
    themeName: ThemeName,

    pageContext: {
        /** CSRF токен при загрузке страницы */
        csrf: string;

        /** Конфиги для Google Analytics */
        googleAnalytics: {
            trackingId: string;
            googleAnalyticsId: string;
        };

        // данные для получения рекламных блоков
        ads: {
            // IP-адрес текущего юзера
            ip: string;
            // url-encode user-agent текущего юзера (для рекламного блока не нашлось подходящего способа кодирования на фронте)
            ua: string;
        }
    };
    /**
     * Настройки чтобы завелся сервис ProjectConfig и все сервисы, которые от него зависят.
     * ProjectConfig больше не поддерживаем
     */
    pconfig?: {
        /** Настройки для real-time обновлений инструмента */
        charts: {
            server: Record<string, unknown>;
            serverSlow: Record<string, unknown>;
        };

        /** Текущая локаль */
        lcid: Lcid;
    };

    /** Серверные данные, необходимые для инциализации страницы */
    data: ServerData;
} & InitialSettingsExtension;

type EmptyExtension = Record<string, unknown>;
type RequiredLegacyConfig = Required<
    InitialPageSettings<never, EmptyExtension>
>['pconfig'];

type InitialPageSettingsWithLegacyConfig<
    ServerData,
    InitialSettingsExtension extends Record<string, unknown>
> = InitialPageSettings<ServerData, InitialSettingsExtension> & {
    pconfig: RequiredLegacyConfig;
};

/** Page configuration object */
export type InitPageConfiguration<
    ServerData = unknown,
    InitialSettingsExtension extends Record<string, unknown> = EmptyExtension
> = {
    mountPoint: () => Element | null;
    urlMap: unknown;
    dicwords: unknown;
    googleAnalyticsId?: string;
    extendLegacyConfig?: (
        initialData: InitialPageSettingsWithLegacyConfig<
            ServerData,
            InitialSettingsExtension
        >
    ) => Record<string, unknown>;
    mockHandlers?: () => Promise<{ handlers: MockHandler[] }>;
};

/** Public page interface */
export type Page<
    ServerData,
    InitialSettingsExtension extends Record<string, unknown> = EmptyExtension
> = {
    readonly initialSettings?: InitialPageSettings<
        ServerData,
        InitialSettingsExtension
    >;

    readonly logger: WtLogger;

    /** Optional callback for when the page is first rendered into the DOM */
    onReady?: () => void;

    /** Initializes and renders the page */
    saveAndRenderIfNeeded(): void;

    /** Sets current user (viewer) and re-renders the page */
    setCurrentUser(user: CurrentUser | UserInfo | null, csrf?: string): void;

    /** Sets current locale and re-renders the page */
    setLocale(lcid: Lcid): void;

    /** Sets current theme and re-renders the page */
    setTheme(themeName: ThemeName): void;
};

// MARK: - Main Function

/**
 * Returns an object used to initialize the page and render the React application.
 *
 * Note, that you __must__ define `WT.Page.initialSettings` on the HTML side for the initialization to work.
 *
 * @example
 * // index.html
 * <script>
 * if (!window.WT) window.WT = {};
 * if (!WT.Page) WT.Page = {};
 *
 * WT.Page.initialSettings = {
 *   currentUser: { ... },
 *   pageContext: {
 *     csrf: '',
 *     googleAnalytics: {
 *       trackingId: '...',
 *       googleAnalyticsId: '...'
 *     },
 *   },
 *   pconfig: {
 *     lcid: '...',
 *   },
 *     data: {}
 * };
 *
 * if (WT.Page.saveAndRenderIfNeeded) {
 *   WT.Page.saveAndRenderIfNeeded();
 * }
 * </script>
 *
 * // page/index.ts
 * const page = createPage<MarketpaceData>('Marketplace', {
 *   mountPoint: () => document.getElementById('marketplacePage'),
 *   urlMap,
 *   dicwords,
 * }, () => {
 *   return (
 *     <ReduxProvider store={store}>
 *       <MarketplaceApp />
 *     </ReduxProvider>
 *   );
 * });
 *
 * page.saveAndRenderIfNeeded();
 */
export function createPage<
    ServerData,
    InitialSettingsExtension extends Record<string, unknown> = EmptyExtension
>(
    pageId: string,
    configuration: InitPageConfiguration<ServerData, InitialSettingsExtension>,
    renderRootNode: (
        initialSettings: InitialPageSettings<
            ServerData,
            InitialSettingsExtension
        >
    ) => ReactElement,
): Page<ServerData, InitialSettingsExtension> {
    type ThisPage = Page<ServerData, InitialSettingsExtension>;
    type ThisPageSettings = InitialPageSettings<
        ServerData,
        InitialSettingsExtension
    >;

    const {
        urlMap,
        dicwords,
        extendLegacyConfig,
        googleAnalyticsId = pageId,
        mountPoint: getMountPoint,
        mockHandlers: getMockHandlers,
    } = configuration;

    // This is the outer state of the page.
    // I.e. "props" of the page
    let pageSettings: ThisPageSettings | undefined;

    let pageContextSliceSetter: (context: Record<string, unknown>) => void;

    const createThemeContext = (reactElement: ReactElement) => {
        const { themeName = 'light' } = pageSettings || {};
        const theme = themes[themeName];
        return createElement(ThemeProvider, { theme }, reactElement);
    };

    const initServices = ({ pageContext, currentUser, lcid }: ThisPageSettings) => {
        if (process.env.USE_SENTRY) {
            // Initialize Sentry
            sentryClient.init();
            sentryClient.setContext('currentUser', currentUser);
            sentryClient.setContext('lcid', { lcid });
            sentryClient.performInitialPageChecks(currentUser ? new User(currentUser) : guestUser, lcid);
        }
        // Initialize URLs
        UrlProcessor.setUrlMap(urlMap);

        // Initialize dictionary
        Dic.setDicwords(dicwords);

        // Initialize notifications
        const notifyMountPoint = document.createElement('div');
        document.body.appendChild(notifyMountPoint);
        const notifyProcess = new NotifyProcess(notifyMountPoint, 10000, createThemeContext);
        notify.setCustomHandler(
            (message: string) => {
                notifyProcess.show(message);
            },
        );

        // Initialize Google Analytics
        const { trackingId } = pageContext.googleAnalytics;
        pageContext.googleAnalytics.googleAnalyticsId = googleAnalyticsId;
        sendGA.init({ GA_COUNTER_NAME: googleAnalyticsId, trackingId }, logger);
    };

    const setLegacyConfig = (initialSettings: ThisPageSettings) => {
        const { pconfig } = initialSettings;
        if (!pconfig) return;

        const initialSettingsWithLegacyConfig = initialSettings as InitialPageSettingsWithLegacyConfig<
            ServerData,
            InitialSettingsExtension
        >;

        const legacyConfig = {
            mainGroupDomain: process.env.MAIN_DOMAIN,
            lcid: pconfig.lcid,
        };

        if (extendLegacyConfig) {
            Object.assign(
                legacyConfig,
                extendLegacyConfig(initialSettingsWithLegacyConfig),
            );
        }

        ProjectConfig.setConfig(legacyConfig);
    };

    const initMsw = async () => {
        if (!process.env.IS_LOCAL || process.env.BUILD_ENVIRONMENT !== 'dev') return Promise.resolve();
        let handlers: MockHandler[] | undefined = [];
        if (process.env.MOCK_SERVICE_WORKER_ENABLED) {
            handlers = ((await getMockHandlers?.()) ?? {}).handlers;
        }
        const { rest } = await mswLoader();
        return await startMockServiceWorkerIfNeeded([
            ...(handlers || []),
            rest.get('https://www.google-analytics.com/:some', (req, res, ctx) => {
                return res(
                    ctx.status(200),
                    ctx.text('1x'),
                );
            }),
        ]);
    };

    const render = () => {
        if (!pageSettings) return;

        const { pageContext, lcid } = pageSettings;
        const defaultUser = guestUser;
        defaultUser.update({ lcid: pageSettings.lcid });

        const currentUser = pageSettings.currentUser ? new User(pageSettings.currentUser) : defaultUser;

        const rootElement = createElement(ErrorBoundary, {},
            createElement(
                LocaleProvider,
                { value: lcid },
                createElement(
                    CurrentUserProvider,
                    { value: currentUser },
                    createThemeContext(
                        createElement(
                            PageContextProvider,
                            {
                                initialValue: pageContext,
                                setContextSliceSetter: (contextSliceSetter) => { pageContextSliceSetter = contextSliceSetter; },
                            },
                            renderRootNode(pageSettings),
                        ),
                    ),
                ),
            ));

        const mountPoint = getMountPoint();
        ReactDOM.render(rootElement, mountPoint);
    };

    let alreadyInitialized = false;
    const logger = WtLogger.register({ name: pageId });

    let onReadyCallback: (() => void) | undefined;

    if (module.hot) {
        module.hot.accept('services/theme', () => {
            render();
        });
    }

    return {
        logger,
        async saveAndRenderIfNeeded() {
            const { initialSettings, onReady } = (window.WT?.Page ?? {}) as ThisPage;

            if (!window.WT) window.WT = {};
            if (!window.WT.Page) window.WT.Page = {};

            // If there's no saveAndRenderIfNeeded defined on the window.WT.Page,
            // it means that the saveAndRenderIfNeeded() was first called from the js bundle side.
            // We need to copy it to the window.WT.Page, so the HTML side knows, that the js bundle has loaded.
            if (!window.WT.Page.saveAndRenderIfNeeded) {
                window.WT.Page.saveAndRenderIfNeeded = this.saveAndRenderIfNeeded.bind(this);
            }

            // If initialSettings is defined here, it means that either:
            // - the initial data from the server came before the js bundle;
            // - or, it's a second call from the html side after the data is ready.
            if (initialSettings) {
                if (alreadyInitialized) {
                    throw new Error(
                        'Attempted to initialize WT.Page twice.'
                            + 'WT.Page must be initialized only once.'
                            + 'Check your page initialization setup.',
                    );
                }

                // Save onReady callback possibly defined on the HTML side
                onReadyCallback = onReady;

                // Copy initialSettings defined on the HTML side, and reassign
                // window.WT.Page with the newly created instance of page object
                Object.assign(this, { initialSettings });
                window.WT.Page = this;

                initServices(initialSettings);
                setLegacyConfig(initialSettings);

                // Initialize pageSettings
                pageSettings = initialSettings;

                await initMsw();

                render();
                alreadyInitialized = true;

                onReadyCallback?.();
            }
        },
        setLocale(lcid) {
            if (!pageSettings) return;
            if (!lcid) return;

            const { currentUser } = pageSettings;
            const newCurrentUser = new User({ ...(currentUser || guestUser), lcid });

            sentryClient.setContext('lcid', { lcid });
            sentryClient.setContext('currentUser', newCurrentUser);
            pageSettings = {
                ...pageSettings,
                currentUser: newCurrentUser,
                lcid: newCurrentUser.lcid,
            };

            ProjectConfig.setConfig({ lcid });

            render();
        },
        setCurrentUser(currentUser, csrf) {
            if (!pageSettings) return;

            sentryClient.setContext('currentUser', currentUser);
            pageSettings = {
                ...pageSettings,
                pageContext: { ...pageSettings.pageContext, csrf },
                currentUser: currentUser ? new User(currentUser) : null,
            };

            // dg: Если страница уже отрендерена, то изменить контекст страницы можно только через специальный сеттер
            if (pageContextSliceSetter) {
                pageContextSliceSetter({ csrf });
            }

            if (currentUser) {
                this.setLocale(currentUser.lcid);
            }
        },
        setTheme(themeName: ThemeName) {
            if (!pageSettings) return;

            pageSettings = {
                ...pageSettings,
                themeName,
            };

            render();
        },
        set onReady(callback: () => void) {
            if (alreadyInitialized) {
                callback();
                return;
            }

            onReadyCallback = callback;
        },
    };
}

let serviceWorker: MockServiceWorker | undefined;

async function startMockServiceWorkerIfNeeded(handlers: MockHandler[] = []) {
    if (!serviceWorker) {
        const { startMockServiceWorker } = await mswLoader();
        serviceWorker = await startMockServiceWorker(handlers);
    }

    return Promise.resolve();
}
