import { OKColoredIcon } from 'components/icons2/OKColoredIcon';
import { FBColoredIcon } from 'components/icons2/FBColoredIcon';
import { TwitterColoredIcon } from 'components/icons2/TwitterColoredIcon';
import { TelegramColoredIcon } from 'components/icons2/TelegramColoredIcon';
import { VKColoredIcon } from 'components/icons2/VKColoredIcon';
import { YandexMessengerColoredIcon } from 'components/icons2/YandexMessengerColoredIcon';
import { RedditColoredIcon } from 'components/icons2/RedditColoredIcon';
import { WhatsappColoredIcon } from 'components/icons2/WhatsappColoredIcon';

import { ShareService } from './types';

export const shareServices: ShareService[] = [
    {
        title: 'ВКонтакте',
        icon: VKColoredIcon,
        link: ({
            url, title, image,
        }) => `https://vk.com/share.php?url=${url}&title=${title}&image=${image}`,
        ruOnly: true,
    }, {
        title: 'Twitter',
        icon: TwitterColoredIcon,
        link: ({
            url, title,
        }) => `https://twitter.com/intent/tweet?text=${title}&url=${url}`,
    }, {
        title: 'Facebook',
        icon: FBColoredIcon,
        link: ({ url }) => `https://www.facebook.com/sharer.php?u=${url}`,
    }, {
        title: 'Я.Мессенджер',
        icon: YandexMessengerColoredIcon,
        link: ({ url, title }) => `https://yandex.ru/chat?text=${title}%20${url}#/forward`,
        ruOnly: true,
    }, {
        title: 'Одноклассники',
        icon: OKColoredIcon,
        link: ({
            url, title, description, image,
        }) => `https://connect.ok.ru/offer?url=${url}&title=${title}&description=${description}&imageUrl=${image}`,
        ruOnly: true,
    }, {
        title: 'WhatsApp',
        icon: WhatsappColoredIcon,
        link: ({ url, title }) => `https://api.whatsapp.com/send?text=${title}%20${url}`,
    }, {
        title: 'Telegram',
        icon: TelegramColoredIcon,
        link: ({ url, title }) => `https://t.me/share/url?url=${url}&text=${title}`,
    }, {
        title: 'Reddit',
        icon: RedditColoredIcon,
        link: ({ url, title }) => `https://www.reddit.com/submit?url=${url}&title=${title}`,
    },
];
