import React, {
    SyntheticEvent,
    useRef,
    useEffect,
} from 'react';

import {
    TabsListItem, TabsListItemAnchor, TabTitleContainer, StyledCounter,
} from './styled/Item';
import { TabProps } from './props';

export const Tab = (props: TabProps) => {
    const {
        title,
        type,
        kind,
        autotestClass,
        isActive = false,
        icon: Icon,
        onClick: onClickProp,
        count,
        size,
        onMount,
        iconPos = 'left',
        // TODO: href
        // href = '',
    } = props;

    const onClick = isActive ? preventDefaultHandler : onClickProp;

    const ref = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        onMount?.(ref, props);
    }, []);

    return (
        <TabsListItem
            type={type}
            kind={kind}
            isActive={isActive}
            size={size}
            ref={ref}
        >
            <TabsListItemAnchor
                tabIndex={0}
                type={type}
                kind={kind}
                isActive={isActive}
                onClick={onClick}
                icon={Icon}
                title={title}
                className={autotestClass}
                iconPos={iconPos}
                count={count}
            >
                {Icon && <Icon size={24} />}
                {title && <TabTitleContainer>{title}</TabTitleContainer>}
                {count && <StyledCounter size={size}>{count}</StyledCounter>}
            </TabsListItemAnchor>
        </TabsListItem>
    );
};

// MARK: - Helpers

function preventDefaultHandler(e: SyntheticEvent) {
    e.preventDefault();
}
