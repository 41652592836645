import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const BitcoinIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.16675 5C4.16675 4.53976 4.53984 4.16666 5.00008 4.16666H11.6667C12.5508 4.16666 13.3987 4.51785 14.0238 5.14297C14.6489 5.7681 15.0001 6.61594 15.0001 7.5C15.0001 8.38405 14.6489 9.2319 14.0238 9.85702C13.9744 9.9064 13.9236 9.95407 13.8715 10C13.9236 10.0459 13.9744 10.0936 14.0238 10.143C14.6489 10.7681 15.0001 11.6159 15.0001 12.5C15.0001 13.3841 14.6489 14.2319 14.0238 14.857C13.3986 15.4821 12.5508 15.8333 11.6667 15.8333H5.00008C4.53984 15.8333 4.16675 15.4602 4.16675 15C4.16675 14.5398 4.53984 14.1667 5.00008 14.1667H11.6667C12.1088 14.1667 12.5327 13.9911 12.8453 13.6785C13.1578 13.3659 13.3334 12.942 13.3334 12.5C13.3334 12.058 13.1578 11.634 12.8453 11.3215C12.5327 11.0089 12.1088 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 10.4602 10.8334 10C10.8334 9.53976 11.2065 9.16666 11.6667 9.16666C12.1088 9.16666 12.5327 8.99107 12.8453 8.67851C13.1578 8.36595 13.3334 7.94203 13.3334 7.5C13.3334 7.05797 13.1578 6.63405 12.8453 6.32149C12.5327 6.00893 12.1088 5.83333 11.6667 5.83333H5.00008C4.53984 5.83333 4.16675 5.46023 4.16675 5Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.66675 4.16666C7.12699 4.16666 7.50008 4.53976 7.50008 5V15C7.50008 15.4602 7.12699 15.8333 6.66675 15.8333C6.20651 15.8333 5.83341 15.4602 5.83341 15V5C5.83341 4.53976 6.20651 4.16666 6.66675 4.16666Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.83341 10C5.83341 9.53976 6.20651 9.16666 6.66675 9.16666H11.6667C12.127 9.16666 12.5001 9.53976 12.5001 10C12.5001 10.4602 12.127 10.8333 11.6667 10.8333H6.66675C6.20651 10.8333 5.83341 10.4602 5.83341 10Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.50008 1.66666C7.96032 1.66666 8.33342 2.03976 8.33342 2.5V5C8.33342 5.46023 7.96032 5.83333 7.50008 5.83333C7.03984 5.83333 6.66675 5.46023 6.66675 5V2.5C6.66675 2.03976 7.03984 1.66666 7.50008 1.66666Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8334 1.66666C11.2937 1.66666 11.6667 2.03976 11.6667 2.5V5C11.6667 5.46023 11.2937 5.83333 10.8334 5.83333C10.3732 5.83333 10.0001 5.46023 10.0001 5V2.5C10.0001 2.03976 10.3732 1.66666 10.8334 1.66666Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.50008 14.1667C7.96032 14.1667 8.33342 14.5398 8.33342 15V17.5C8.33342 17.9602 7.96032 18.3333 7.50008 18.3333C7.03984 18.3333 6.66675 17.9602 6.66675 17.5V15C6.66675 14.5398 7.03984 14.1667 7.50008 14.1667Z" fill="#9CCC65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8334 14.1667C11.2937 14.1667 11.6667 14.5398 11.6667 15V17.5C11.6667 17.9602 11.2937 18.3333 10.8334 18.3333C10.3732 18.3333 10.0001 17.9602 10.0001 17.5V15C10.0001 14.5398 10.3732 14.1667 10.8334 14.1667Z" fill="#9CCC65" />
    </EmojiIconSvg>
);
