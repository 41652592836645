import { useState, useCallback } from 'react';

import { useToggle } from 'hooks/useToogle';
import { MentionUser, RootObject } from '../types';
import { GetMentionList, GetMentionListPayloadData } from '../actions';

export const useMentionList = (getMentionList: GetMentionList, rootObject: RootObject) => {
    const [wasRequest, toggleWasRequest] = useToggle(false);
    const [mentionList, setMentionList] = useState<MentionUser[]>([]);

    const fetchMentionList = useCallback(() => {
        if (!wasRequest && !mentionList.length) {
            toggleWasRequest();
            getMentionList(rootObject.rootObjectId, rootObject.rootObjectType).then((action) => {
                setMentionList(action.payload.data.data);
            });
        }
    }, [rootObject, wasRequest, mentionList.length]);

    return { mentionList, fetchMentionList };
};
