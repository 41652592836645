import React from 'react';

import * as Styled from './styled/EmptyContent.styled';

export type EmptyContentPropsType = {
    children: string;
};

export const EmptyContent = (props: EmptyContentPropsType) => {
    const { children } = props;
    return (
        <Styled.Wrapper>
            <Styled.Text>
                {children}
            </Styled.Text>
        </Styled.Wrapper>
    );
};
