import { ActionResult, FeedRecord } from 'types';
import { useFeedItemContext } from 'context/feed-item';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { DeleteFeedRecord, DeleteFeedRecordPayloadData } from '../actions';

export const useDeleteRecord = (record: FeedRecord, deleteFeedRecord?: DeleteFeedRecord) => {
    const csrf = useCSRFToken();
    const [{ isDeleted }, setContextSlice] = useFeedItemContext();
    const onDelete = () => {
        if (record.obj?.id && deleteFeedRecord) {
            deleteFeedRecord(record.obj.id, csrf).then(() => {
                setContextSlice({ isDeleted: true });
            }).catch((action: ActionResult<DeleteFeedRecordPayloadData>) => {
                const { payload: { data } } = action;
                if (data.errors) {
                    notify.error(data.errors[0].message);
                } else {
                    notify.error(Dic.word('wt_feed__error_message__delete__post'));
                }
            });
        }
    };

    const onRestore = () => {
        setContextSlice({ isDeleted: false });
    };

    return { isDeleted, onDelete, onRestore };
};
