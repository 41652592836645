import React, {
    FC, memo, useCallback, useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import { MenuPerson, Lcid } from 'types';
import { MenuState } from '../types/interfaces';
import { fetchUser, setLanguage, processBuidMetrics } from '../actions';

import { wtAuthModuleLoadOrUpdate } from '../services/wtAuthModule.js';

import { Header } from '../limex/header';
import store from '../store';

import { initOptionsUsed } from '../utils/initMountPointAndAnalytics';
import { menu } from '..';
import { wtUserMetricsGet, wtUserMetricsLoadOrUpdate } from '../services/wtUserMetrics';
import { LimexFixedMenu } from './wtMenuHeaderPlateDumbComponents';

export interface Props {
    options?: {
        lang?: string;
    };
}

const WtMenuPlate = memo((props: Props) => {
    const {
        options,
    } = props;

    const dispatch = useDispatch();

    const activeLanguage = useSelector<MenuState, string>((state) => state.lcid);
    const callbacks = useSelector<MenuState, unknown[]>((state) => state.callbacks);
    const user = useSelector<MenuState, MenuPerson>((state) => state.user);

    const onFetchUser = useCallback((changeLoaduserState?: boolean) => {
        dispatch(fetchUser(changeLoaduserState));
    }, [dispatch]);

    React.useEffect(() => {
        // always fetch for user on initial
        onFetchUser(false);
    }, []);

    React.useEffect(() => {
        const language = options?.lang ?? '';
        wtAuthModuleLoadOrUpdate(user, {}, language, callbacks, null, false);
    }, [user, callbacks, options?.lang]);

    // vls: инициализируем модуль WT User Metrics
    React.useEffect(() => {
        wtUserMetricsLoadOrUpdate('limex');
    }, []);

    React.useEffect(() => {
        // dg: Пока нет данных о юзере, мы не можем отправить запрос, потому что нужен токен авторизации
        if (user) {
            dispatch(processBuidMetrics());
        }
    }, [!!user, user?.id, dispatch]);

    // vls: связываем global BUID с WT пользователем
    React.useEffect(() => {
        const userId = user?.id;
        if (userId) {
            wtUserMetricsGet().then((userMetrics) => {
                userMetrics.linkUser(userId, 'whotrades');
            });
        }
    }, [user?.id]);

    React.useEffect(() => {
        if (!user) {
            return;
        }

        let locale;
        if (user.lcid === 'en' || user.lcid === 'ru') {
            locale = user.lcid;
        } else {
            locale = Cookie.get('lcid') || 'en';
        }

        if (locale !== activeLanguage) {
            dispatch(setLanguage(user.lcid));
        }
    }, [activeLanguage, dispatch, user]);

    return (
        <LimexFixedMenu headerHeight={64} id="menuPlateWrapper">
            <Header
                callbacks={callbacks}
                onFetchUser={onFetchUser}
                activeLanguage={activeLanguage as Lcid}
            />
        </LimexFixedMenu>
    );
});

export interface LimexMenuProps {
    login?: boolean;
}

export const LimexMenu: FC<LimexMenuProps> = memo(({ login = false }) => {
    const mountPoint = document.getElementById('wt-menu-module');

    useEffect(() => {
        if (login) {
            menu.login();
        }
    }, [login]);

    return mountPoint && createPortal(
        <Provider store={store}>
            <WtMenuPlate options={{ lang: initOptionsUsed.options?.lang }} />
        </Provider>,
        mountPoint,
    );
});
