import React from 'react';

import { AvatarDefaultProps } from 'components/avatar/prop-types';
import { StyledCommonAvatar } from './styled';
import { AvatarProps } from './interfaces';

export const Avatar = (props: AvatarProps) => {
    const {
        responsiveSize, ...restProps
    } = props;
    return (
        <StyledCommonAvatar
            responsiveSize={responsiveSize || {}}
            {...restProps}
        />
    );
};

Avatar.defaultProps = {
    ...AvatarDefaultProps,
    square: true,
    bordered: false,
};
