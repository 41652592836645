import googleAnalyticsService from '../services/googleAnalytics';
import projectsConfig from '../configs/projectsConfig';
import store from '../store';
import { screenSizes } from '../services/constants';
import detectDevice from '../services/detectDevice';
import { initBusinessMetrics } from './initBusinessMetrics';

const mountPoint = document.getElementById('wt-menu-module');

if (!mountPoint) {
    throw new Error('Cant find da mount point! Add mount point with id=wt-menu-module to you dom');
}

mountPoint.style.cssText = `left: 0; top: 0; height: 80px; width: 100%; min-width: ${detectDevice.isMobile() ? '0px' : screenSizes.minimumWidth}; z-index: 302; background-color: #fff; color: rgba(0,0,0,0.87); box-sizing: border-box; position: relative; box-shadow: -1px 1px 8px 1px rgba(51,1,51,0.36)`;

export const hide = () => {
    mountPoint.style.height = 'unset';
    mountPoint.style.display = 'none';
};

export const show = () => {
    mountPoint.style.display = 'block';
    mountPoint.style.position = 'relative';
    mountPoint.style.background = 'transparent';
    mountPoint.style['box-shadow'] = 'none';
};

export let initOptionsUsed: Options = {};

type Options = {
    options?: {
        headerHeight?: number;
        ga?: unknown;
        lang?: string;
    }
}

export const initMountPointAndAnalytics = (options: Options = {}) => {
    const storageVisiabilityId = 'wt-menu-module-visiability';
    if (!localStorage.getItem(storageVisiabilityId) || localStorage.getItem(storageVisiabilityId) === 'false') {
        hide();
    }

    if (!options.options) {
        options.options = {};
    }

    /* Для переопределения захардкоженых выше стилей корневого элемента * */
    const projectOptions = projectsConfig.projects.limex;
    if (projectOptions) {
        const { headerHeight } = projectOptions;
        const adaptiveHeader = !!projectOptions.headerHeight;
        if (typeof headerHeight === 'number') {
            mountPoint.style.height = `${headerHeight}px`;
        }
        if (adaptiveHeader) {
            mountPoint.style.minWidth = 'auto';
        }
    }
    /***/

    // @ts-ignore
    googleAnalyticsService.registerGoogleAnalytics(
        () => options.options?.ga || window[window.GoogleAnalyticsObject],
    );

    // dg: Сливаем метрики о новом посетителе
    initBusinessMetrics('limex'); // eslint-disable-line no-use-before-define

    initOptionsUsed = options;
};

export const getConfig = () => ({
    options: initOptionsUsed,
    currentState: store ? store.getState() : null,
    projectsConfig,
});
