export const getUserInfoPromise = () => new Promise((resolve) => {
    window.WT.menu?.auth?.(() => {
        window.WT?.auth?.getWtUserProvider?.()
            .then((provider) => {
                provider.getData()
                    .then((userData) => {
                        resolve({
                            ...userData.rawData,
                            id: userData.rawData.id,
                            country: userData.rawData.country,
                            lcid: userData.rawData.lcid,
                            fullname: userData.rawData.fullname,
                            agreement: userData.rawData.agreement,
                            name: userData.rawData.name,
                            brokerLinks: userData.rawData.brokerLinks,
                            token: userData.rawData.token,
                            avatar: userData.rawData.user_pic,
                            chatData: userData.rawData.chat_signed_data,
                            WTuser: userData.rawData,
                        });
                    });
            });
    });
});
