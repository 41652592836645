import { useEffect } from 'react';

import { FeedRecord } from 'types';
import { sendGA } from 'services/google-analytics';

let feedPacksCount = 0;

export const useSendGAPacksCount = (initialRecords: FeedRecord[]) => {
    const sendGAPacksCount = (isFetchedData: boolean) => {
        if (!isFetchedData) return;
        feedPacksCount++;
        sendGA('feedRecords', ['fetched', feedPacksCount]);
    };
    useEffect(() => {
        sendGAPacksCount(!!initialRecords.length);
    }, []);
    return { sendGAPacksCount, feedPacksCount };
};
