import { createAction } from '@reduxjs/toolkit';

import {
    Id, ThunkResultFn, FeedRecordComment, Action,
} from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { RootObject, ParentObject, MentionUser } from './types';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

export const commentsFilters = {
    default: 'showDefaultComments',
    unread: 'showUnreadComments',
    recent: 'showLatestComments',
    rated: 'showMostLikedComments',
    owners: 'showOwnersComments',
    parents: 'showParentalComments',
    latestByDate: 'latest_by_date',
    from: 'fromComment',
    fromAll: 'fromCommentAll',
    between: 'betweenComments',
};

export type CommentsFilters = typeof commentsFilters[keyof typeof commentsFilters];

export const PUBLISHED_COMMENT_UNMARK = 'widgets/PUBLISHED_COMMENT_UNMARK';
const unmarkComment = createAction<{ recordId: Id; commentId: Id }>(PUBLISHED_COMMENT_UNMARK);

export const CHANGE_COMMENTS_FILTER = 'widgets/CHANGE_COMMENTS_FILTER';
export function changeCommentsFilter(recordId: Id) {
    return {
        type: CHANGE_COMMENTS_FILTER,
        payload: {
            data: { recordId }
        }
    }
}

export type ChangeCommentsFilter = Action<typeof changeCommentsFilter>;
export type ChangeCommentsFilterData = {
    recordId: Id,
}

export type FetchCommentsPayloadData = {
    comments: FeedRecordComment[],
}
export type FetchCommentsPayloadPass = {
    recordId: Id,
}
export const FETCH_COMMENTS_ACTION_TYPES = createRequestActionTypes('FEED_RECORD_FETCH_COMMENTS');
export function fetchComments(
    options: {
        objectTypeString: string,
        objectId: Id,
        page?: number,
        pageSize?: number,
        filter?: CommentsFilters,
        reverse?: boolean,
        pinnedCommentId?: Id,
        commentId?: Id,
        prevCommentId?: Id,
        filterPersonId?: Id,
        startDate?: string | number,
    },
    pass: FetchCommentsPayloadPass,
): ThunkResultFn<FetchCommentsPayloadData> {
    const {
        objectTypeString, objectId, page, pageSize, filter, reverse, pinnedCommentId, commentId, prevCommentId,
        filterPersonId, startDate = '',
    } = options;
    const queryArgs = {
        trueJSON: true,
        page: page || DEFAULT_PAGE,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
        filter: filter || commentsFilters.rated,
        reverse: reverse || false,
        pinnedCommentId: pinnedCommentId || '',
        commentId: commentId || '',
        prevCommentId: prevCommentId || '',
        filterPersonId: filterPersonId || '',
        startDate: startDate || '',
    };
    const params = { objectTypeString, object_id: objectId };
    return thunkActions({
        types: FETCH_COMMENTS_ACTION_TYPES,
        payload: {
            request: {
                url: UrlProcessor
                    .page('ng_comments_list')
                    .params(params)
                    .queryArgs(queryArgs)
                    .path(),
                data: {},
                withCredentials: true,
            },
            pass,
        },
    });
}
export type FetchComments = Action<typeof fetchComments>;

export type SaveCommentPayloadData = {
    comment: FeedRecordComment,
}
export type SaveCommentPayloadPass = FetchCommentsPayloadPass & { commentsFilter?: CommentsFilters };
export type SaveCommentRequestData = {
    comment: {
        action: 'add' | 'edit',
        text: string,
        image: string,
        replyUserId: Id | null,
        replyUserName: string | null,
        id?: Id,
        rootObjectId?: RootObject['rootObjectId'],
        rootObjectType?: RootObject['rootObjectType'],
        parentObjectId?: ParentObject['parentObjectId'],
        parentObjectType?: ParentObject['parentObjectType'],
    }
}
export const SAVE_COMMENT_ACTION_TYPES = createRequestActionTypes('FEED_RECORD_SAVE_COMMENT');
export function saveComment(
    comment: SaveCommentRequestData['comment'],
    pass: SaveCommentPayloadPass,
): ThunkResultFn<SaveCommentPayloadData, SaveCommentPayloadPass> {
    return async (dispatch: any) => {
        const res = await thunkActions({
            types: SAVE_COMMENT_ACTION_TYPES,
            payload: {
                request: {
                    url: UrlProcessor.page('ng_comments_add').path(),
                    params: {
                        trueJSON: true,
                    },
                    method: 'POST',
                    data: {
                        comment,
                    },
                    withCredentials: true,
                },
                pass,
            },
        })(dispatch);

        const [, SAVE_COMMENT_SUCCESS] = SAVE_COMMENT_ACTION_TYPES;
        if (res.type === SAVE_COMMENT_SUCCESS && res.meta?.previousAction?.payload?.request?.data?.comment?.action === 'add') {
            setTimeout(
                () => dispatch(unmarkComment({
                    recordId: res.payload?.pass?.recordId,
                    commentId: res.payload?.data?.comment?.id,
                })),
                3000,
            );
        }

        return res;
    };
}
export type SaveComment = Action<typeof saveComment>;

export type DeleteCommentPayloadPass = { commentId: Id; recordId: Id };
export const DELETE_COMMENT_ACTION_TYPES = createRequestActionTypes('FEED_RECORD_DELETE_COMMENT');
export function deleteComment(id: Id, csrf: string, pass: DeleteCommentPayloadPass): ThunkResultFn<never, DeleteCommentPayloadPass> {
    return thunkActions({
        types: DELETE_COMMENT_ACTION_TYPES,
        payload: {
            request: {
                url: UrlProcessor
                    .page('limex_api_comment_manage')
                    .param('commentId', id)
                    .path(),
                data: { csrf },
                method: 'DELETE',
                withCredentials: true,
            },
            pass,
        },
    });
}
export type DeleteComment = Action<typeof deleteComment>;

export type RestoreCommentPayloadPass = { commentId: Id; recordId: Id };
export const RESTORE_COMMENT_ACTION_TYPES = createRequestActionTypes('FEED_RECORD_RESTORE_COMMENT');
export function restoreComment(id: Id, pass: RestoreCommentPayloadPass): ThunkResultFn<never, RestoreCommentPayloadPass> {
    return thunkActions({
        types: RESTORE_COMMENT_ACTION_TYPES,
        payload: {
            request: {
                url: UrlProcessor
                    .page('ng_comments_restore')
                    .param('commentId', id)
                    .path(),
                params: {
                    trueJSON: true,
                },
                method: 'POST',
                withCredentials: true,
            },
            pass,
        },
    });
}
export type RestoreComment = Action<typeof restoreComment>;

export type GetMentionListPayloadData = {
    data: Array<MentionUser>,
}
export const getMentionList = (
    objectId: RootObject['rootObjectId'],
    objectType: RootObject['rootObjectType'],
): ThunkResultFn<GetMentionListPayloadData> => {
    return thunkActions({
        types: [],
        payload: {
            request: {
                url: UrlProcessor
                    .page('mention_list_json')
                    .params({
                        object_type: objectType,
                        object_id: objectId,
                    })
                    .url(),
                method: 'POST',
                data: {},
                withCredentials: true,
            },
        },
    }, false);
};
export type GetMentionList = Action<typeof getMentionList>;
