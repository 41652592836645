import React from 'react';

import { IconSvg, IconComponent } from '.';

export const TrashIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M12.8 9.49995C12.8 9.05812 12.4418 8.69995 12 8.69995C11.5581 8.69995 11.2 9.05812 11.2 9.49995L11.2 18.5C11.2 18.9418 11.5581 19.3 12 19.3C12.4418 19.3 12.8 18.9418 12.8 18.5V9.49995Z" />
        <path d="M8.45558 8.70118C8.89672 8.67667 9.27421 9.01443 9.29872 9.45557L9.79872 18.4556C9.82323 18.8967 9.48548 19.2742 9.04433 19.2987C8.60318 19.3232 8.22569 18.9855 8.20118 18.5443L7.70118 9.54433C7.67667 9.10318 8.01443 8.72569 8.45558 8.70118Z" />
        <path d="M16.2987 9.54433C16.3232 9.10318 15.9855 8.72569 15.5443 8.70118C15.1032 8.67667 14.7257 9.01443 14.7012 9.45557L14.2012 18.4556C14.1767 18.8967 14.5144 19.2742 14.9556 19.2987C15.3967 19.3232 15.7742 18.9855 15.7987 18.5443L16.2987 9.54433Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8 3.99995V5.21021L18.8712 5.21021C18.9291 5.20082 18.9889 5.1977 19.0499 5.20151C19.0763 5.20316 19.1024 5.20608 19.128 5.21021H21.0127C21.4545 5.21021 21.8127 5.56838 21.8127 6.01021C21.8127 6.45203 21.4545 6.81021 21.0127 6.81021H19.7509L18.9156 20.1746C18.8234 21.6503 17.5996 22.7999 16.121 22.7999H7.87885C6.40028 22.7999 5.17654 21.6503 5.08431 20.1746L4.24903 6.81021H2.98799C2.54616 6.81021 2.18799 6.45203 2.18799 6.01021C2.18799 5.56838 2.54616 5.21021 2.98799 5.21021L4.8719 5.21021C4.89753 5.20608 4.9236 5.20316 4.95005 5.20151C5.01103 5.1977 5.07085 5.20082 5.12873 5.21021H8.19995V3.99995C8.19995 2.45355 9.45355 1.19995 11 1.19995H13C14.5463 1.19995 15.8 2.45355 15.8 3.99995ZM9.79995 3.99995C9.79995 3.33721 10.3372 2.79995 11 2.79995H13C13.6627 2.79995 14.2 3.33721 14.2 3.99995V5.21021L9.79995 5.21021V3.99995ZM5.85215 6.81021L18.1477 6.81021L17.3187 20.0748C17.2792 20.7072 16.7547 21.2 16.121 21.2H7.87885C7.24518 21.2 6.72072 20.7072 6.68119 20.0748L5.85215 6.81021Z" />
    </IconSvg>
);
