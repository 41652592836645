import React from 'react';

import { ResponsiveMap } from 'app/styled';

import * as Styled from './styled';

export type CounterSize = 'large' | 'medium' | 'small';

export type CounterPropsType = {
    children: string | number;
    size?: CounterSize | ResponsiveMap<CounterSize>,
    className?: string,
}

export const Counter = (props: CounterPropsType) => {
    const {
        children,
        size = 'medium',
        className,
    } = props;

    return (
        <Styled.Wrapper
            size={size}
            className={className}
        >
            <Styled.Text
                size={size}
            >
                {children}
            </Styled.Text>
        </Styled.Wrapper>
    );
};
