import { connect } from 'react-redux';

import { GlobalStateForFeed } from 'widgets/feed/types';
import { index } from '../helpers';
import { Comments as CommentsComponent, OwnProps } from './components';
import {
    fetchComments, saveComment, getMentionList, deleteComment, restoreComment, changeCommentsFilter
} from './actions';

const mapDispatchToProps = {
    fetchComments,
    saveComment,
    getMentionList,
    deleteComment,
    restoreComment,
    changeCommentsFilter
};

export const Comments = connect(
    (state: GlobalStateForFeed, ownProps: OwnProps) => {
        const record = state.widgets.feed.records[index(ownProps.recordId)];

        return {
            record,
        };
    },
    mapDispatchToProps,
)(CommentsComponent);
