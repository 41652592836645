import { useState } from 'react';
import { useLoadMoreExternal, makeInitialState } from './useLoadMoreExternal';

// MARK: - Types

export type UseLoadMoreSettings<Page> = {
    /** A page to show on the first render */
    initialPage?: Page;

    /** A function that checks if the next page exists using the information from the last page */
    hasNextPageFn: (lastPage?: Page) => boolean;

    /** An async function that resolves with the next page using the information from the last page */
    fetchNextPageFn: (lastPage?: Page) => Promise<Page | undefined>;
};

// MARK: - Hook

/**
 * Handles the "load more items" (aka "infinite list") logic using an internal state.
 *
 * NB! This hook purposely handles only the loading logic itself,
 * and provides a `loadNextPage` function that can be used to load the next page.
 * It's up to you to decide when to call this function to fetch the next page - e.g. on button click, on scroll, etc.
 * The only exception to this is the initial mount -`loadNextPage` will be called for you if the initial state is empty.
 *
 * @example
 * const [loadMoreState, loadMore] = useLoadMore<Page>({
 *   initialPage: props.initialPage,
 *   hasNextPageFn: (prevPage) => !prevPage || !!prevPage.nextCursor,
 *   fetchNextPageFn: (prevPage) => props.fetchPage(prevPage.nextCursor),
 * });
 *
 * const { pages, isLoading, error, hasNextPage } = loadMoreState;
 *
 * return (
 *  <>
 *     {pages.map(page => ...)}
 *     <button onClick={loadMore}>Load more</button>
 *  </>
 * )
 */
export const useLoadMore = <Page>(settings: UseLoadMoreSettings<Page>) => {
    const [state, setState] = useState(() => makeInitialState(settings));
    const loadMore = useLoadMoreExternal(state, setState, settings);
    return [state, loadMore] as const;
};
