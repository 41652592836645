import { Action, FeedRecordObj, ThunkResultFn } from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { PublisherFn } from '../../add-status';

export type UpdateFeedRecordPayloadData = {
    post: FeedRecordObj,
}

export const UPDATE_FEED_RECORD_ACTION_TYPES = createRequestActionTypes('UPDATE_FEED_RECORD');
export const updateFeedRecord = (...args: Parameters<PublisherFn>): ThunkResultFn<UpdateFeedRecordPayloadData> => {
    const [html, json, { csrf, id }] = args;
    return thunkActions({
        types: UPDATE_FEED_RECORD_ACTION_TYPES,
        payload: {
            request: {
                method: 'POST',
                url: UrlProcessor.page('limex_api_blogpost_manage').param('postId', id).path(),
                data: {
                    json,
                    html,
                    csrf,
                },
                withCredentials: true,
            },
        },
    });
};
export type UpdateFeedRecord = Action<typeof updateFeedRecord>;
