import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { mediaQuery } from 'app/styled';
import { Button } from 'components/button2';

const Wrapper = styled.div<{ locatedInModal: boolean }>`
    background: ${({ theme: { bg } }) => bg.secondary};
    ${ifProp({ locatedInModal: true }, `
        padding: 24px 24px 5px 24px;
        margin-bottom: 0;
    `, `
        padding: 24px;
        margin-bottom: 20px;
        border-radius: 8px;
    `)};
    ${mediaQuery.lt480} {
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: space-between;
    }
`;

const CloseButton = styled.button`
    border: 0;
    background: inherit;
    color: ${({ theme: { label } }) => label.secondary};
    cursor: pointer;
    &:active, :focus {
        outline: none;
    }
`;

const ShowContainer = styled.div`
    ${mediaQuery.lt768} {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        & ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const Container = styled.div<{ locatedInModal: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;
    ${mediaQuery.lt768} {
        ${ifProp({ locatedInModal: true }, `
            width: 100%;
        `, `
            width: 768px;
            position: relative;
        `)};
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Title = styled.h3`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-style: normal;
    font-weight: bold;
    color: ${({ theme: { label } }) => label.primary};
    letter-spacing: -0.012em;
    margin-bottom: 8px;
    margin-top: 0;
    ${ifProp({ locatedInModal: true }, `
        width: 75%;
    `)};
    ${mediaQuery.lt480} {
        width: auto;
        font-size: 18px;
    }
`;

const SubTitle = styled.span`
    font-family: 'Inter var', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.004em;
    color: ${({ theme: { label } }) => label.secondary};
    margin-bottom: 22px;
`;

const StyledButton = styled(Button)<{ locatedInModal: boolean }>`
    position: relative;
    margin-top: 16px;
    outline: none;
    ${ifProp({ locatedInModal: true }, `
          width: 100%;
    `)};
    ${mediaQuery.lt768} {
        width: 100%;
    }
`;

const SkipButton = styled(StyledButton)`
    ${mediaQuery.lt480} {
       display: flex;
    }
`;

export {
    Container, StyledButton, Title, SubTitle, Wrapper, Header, SkipButton, ShowContainer, CloseButton,
};
