import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { labelSecondary } from 'app/styled';
import { postSidePadding } from '../styled';

export const InstrumentWrapper = styled.div`
    flex-shrink: 0;
`;

const multiline = `
    flex-wrap: wrap;
    ${InstrumentWrapper} {
        margin-bottom: 8px;
    }
`;
export const Wrapper = styled.div<{ showAllInstruments: boolean, multilineable: boolean }>`
    ${postSidePadding};
    margin-top: 20px;
    display: flex;
    overflow-x: hidden;
    ${ifProp('multilineable', multiline)}
    ${ifProp('showAllInstruments', multiline)}
`;

export const More = styled.div`
    display: flex;
    align-items: center;
    color: ${labelSecondary};
    cursor: pointer;
    flex-shrink: 0;
`;
