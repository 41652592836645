import {
    ActionRequest, ActionResult, ActionWithPayload, FeedRecord, FeedRecordObjBlogpostMask, FeedRecordType,
} from 'types';
import { mergeByPath } from 'services/utils/merge';
import { FeedState } from '../types';
import { index } from '../helpers';
import {
    FETCH_FEED_RECORDS_ACTION_TYPES,
    FetchRecordsPayloadData,
    INSERT_FEED_RECORDS_ACTION,
    InsertRecordPayloadData,
    SET_TO_INIT_FEED_RECORDS_ACTION,
    UPDATE_FEED_RECORD_ACTION_TYPES,
    UpdateFeedRecordPayloadData,
} from './actions';

const [, FETCH_FEED_RECORDS_SUCCESS] = FETCH_FEED_RECORDS_ACTION_TYPES;
const [, UPDATE_FEED_RECORD_SUCCESS] = UPDATE_FEED_RECORD_ACTION_TYPES;

const mapRecordsById = (records: FeedRecord[] = []): Pick<FeedState, 'records'>['records'] => {
    return records.reduce((acc, record) => {
        return { ...acc, [index(record.id)]: record };
    }, {});
};

export const emptyFeedState = {
    records: {},
    boundary: '',
    boundaryRecordId: '',
    tags: {
        tags: [],
        subscribedTags: [],
    },
};

export const filterPage = (page: FetchRecordsPayloadData) => {
    const { records = [] } = page;

    const filteredRecords = records.filter((record) => {
        const { obj } = record;
        const blogpostType = obj?.blogpostType ?? 0;
        // eslint-disable-next-line no-bitwise
        const isForecastLike = (blogpostType & FeedRecordObjBlogpostMask.FORECAST) === FeedRecordObjBlogpostMask.FORECAST;
        const isStatusLike = record.type === FeedRecordType.PUBLISH_STATUS;
        const isRecommendations = record.type === FeedRecordType.RECOMMENDATIONS;

        return (isStatusLike || isRecommendations) && !isForecastLike;
    });

    return {
        ...page,
        records: filteredRecords,
    };
};

let adsId = 1;
const buildAds = (records: FeedRecord[]): FeedRecord[] => {
    const perChunk = 4;
    return records.reduce((result: FeedRecord[], record, numberIndex) => {
        if (((numberIndex + 1) % perChunk) === 0) {
            return [...result, record, {
                id: `ads${adsId++}`,
                type: FeedRecordType.ADS,
            }];
        }
        return [...result, record];
    }, []);
};

export const handleFetchFeedRecords = (state: FeedState, page: FetchRecordsPayloadData): FeedState => {
    const {
        records = [],
        boundary,
        boundaryRecordId,
        tags,
    } = filterPage(page);
    const newRecordsList = [...Object.values(state.records), ...buildAds(records)];

    return {
        ...state,
        records: mapRecordsById(newRecordsList),
        boundary,
        boundaryRecordId,
        tags: tags ?? state.tags,
    };
};

type AvailActionsData = FetchRecordsPayloadData | UpdateFeedRecordPayloadData;
export default (
    state: FeedState,
    action:
        ActionRequest
        | ActionResult<AvailActionsData>
        | ActionWithPayload<Record<string, never>>
        | ActionWithPayload<InsertRecordPayloadData>, // | UpdateFeedRecordPayloadData>
) => {
    switch (action.type) {
        case FETCH_FEED_RECORDS_SUCCESS: {
            const { payload } = action as ActionResult<FetchRecordsPayloadData>;
            return handleFetchFeedRecords(state, payload.data);
        }
        case INSERT_FEED_RECORDS_ACTION: {
            const { records } = state;
            const { records: insertingRecords, pos } = action.payload as InsertRecordPayloadData;
            const newRawRecords = Object.values(records);
            const filteredInsertingRecords = insertingRecords.filter((insertingRecord) => {
                return !newRawRecords.find((record) => record.id === insertingRecord.id);
            });
            newRawRecords.splice(pos, 0, ...filteredInsertingRecords);
            return {
                ...state,
                records: mapRecordsById(newRawRecords),
            };
        }

        case UPDATE_FEED_RECORD_SUCCESS: {
            const { records } = state;
            const { payload: { data: { post } } } = action as ActionResult<UpdateFeedRecordPayloadData>;
            const recordId = Object.values(records).find((record) => record.obj?.id === post.id)?.id;
            if (!recordId) return state;
            // как минимум список инструметнов надо перезаписать из пришедших данных
            return mergeByPath(state, `records.${index(recordId)}`, { obj: post }, { rewriteArrays: true });
        }

        case SET_TO_INIT_FEED_RECORDS_ACTION: {
            return emptyFeedState;
        }
        default:
            return state;
    }
};
