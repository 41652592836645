import { ActionRequest, ActionResult, ActionWithPayload } from 'types';
import { NotificationsState } from './types';

import {
    FETCH_NOTIFICATIONS_ACTIONS_TYPES,
    READ_NOTIFICATIONS_ACTIONS_TYPES,
    DELETE_NOTIFICATION_ACTIONS_TYPES,
    READ_IN_STORE_NOTIFICATIONS_ACTION,
    ADD_IN_STORE_NOTIFICATION_ACTION,
    FetchNotificationsPayloadData,
    _FetchNotificationsPayloadPassWithSpecialState,
    DeleteNotificationPayloadData,
    _DeleteNotificationPayloadPassWithSpecialState,
    ReadNotificationsPayloadData,
    FetchNotificationsActionArgs,
    ReadNotificationsActionArgs,
    DeleteNotificationActionArgs,
    ReadInStoreNotificationPayloadData,
    AddInStoreNotificationPayloadData,
} from './actions';

const initialState: NotificationsState = {
    isError: false,
    unreadCount: 0,
    totalCount: 0,
    notifications: null,
    nextCursor: null,
    readDate: '',
    isLoading: false,
};

const [
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
] = FETCH_NOTIFICATIONS_ACTIONS_TYPES;

const [
    READ_NOTIFICATIONS_REQUEST,
    READ_NOTIFICATIONS_SUCCESS,
    READ_NOTIFICATIONS_FAILURE,
] = READ_NOTIFICATIONS_ACTIONS_TYPES;

const [
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
] = DELETE_NOTIFICATION_ACTIONS_TYPES;

type AvailActionRequest = FetchNotificationsActionArgs
    | ReadNotificationsActionArgs
    | DeleteNotificationActionArgs;

type AvailActionsResponseData = FetchNotificationsPayloadData
    | ReadNotificationsPayloadData
    | DeleteNotificationPayloadData;

// NOT REQUEST
type AvailActionsData =
    | ReadInStoreNotificationPayloadData
    | AddInStoreNotificationPayloadData;

type AvailActionsPass = _FetchNotificationsPayloadPassWithSpecialState
    | _DeleteNotificationPayloadPassWithSpecialState;

type AvailAction = ActionRequest<AvailActionRequest, AvailActionsPass>
    | ActionResult<AvailActionsResponseData, AvailActionsPass>
    | ActionWithPayload<AvailActionsData>;

export default (state:NotificationsState = initialState, action: AvailAction) => {
    switch (action.type) {
        // FETCH
        case FETCH_NOTIFICATIONS_REQUEST: {
            const {
                payload: {
                    pass,
                },
            } = action as ActionRequest<FetchNotificationsPayloadData, _FetchNotificationsPayloadPassWithSpecialState>;
            const combineNotifications = pass?.combineNotifications || false;

            return {
                ...state,
                notifications: combineNotifications ? state.notifications : initialState.notifications,
                isLoading: true,
                isError: false,
            };
        }
        case FETCH_NOTIFICATIONS_SUCCESS: {
            const {
                payload: {
                    data: {
                        notifications,
                        nextCursor,
                        readDate,
                        unreadCount,
                        totalCount,
                    },
                    pass,
                },
            } = action as ActionResult<FetchNotificationsPayloadData, _FetchNotificationsPayloadPassWithSpecialState>;
            const combineNotifications = pass?.combineNotifications || false;

            return {
                ...state,
                nextCursor,
                notifications: combineNotifications ? [...(state?.notifications || []), ...(notifications || [])] : notifications,
                readDate: readDate || state.readDate,
                unreadCount: unreadCount || state.unreadCount,
                totalCount: totalCount || state.totalCount,
                isError: false,
                isLoading: false,
            };
        }
        case FETCH_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                isError: true,
            };
        }

        // DELETE
        case DELETE_NOTIFICATION_REQUEST: {
            const {
                payload: {
                    pass: {
                        id: requestNotificationId,
                    },
                },
            } = action as ActionResult<DeleteNotificationPayloadData, _DeleteNotificationPayloadPassWithSpecialState >;

            return {
                ...state,
                notifications: state?.notifications?.filter((item) => item.id !== requestNotificationId) || [],
                totalCount: (state.totalCount > 0) ? (state.totalCount - 1) : 0,
                isError: false,
            };
        }
        case DELETE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
            };
        }
        case DELETE_NOTIFICATION_FAILURE: {
            return {
                ...state,
                isError: true,
            };
        }
        // READ
        case READ_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
            };
        }
        case READ_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                isError: false,
            };
        }
        case READ_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                isError: true,
            };
        }
        // BLOCK WITHOUT REQUEST IN API
        case ADD_IN_STORE_NOTIFICATION_ACTION: {
            const {
                payload: notification,
            } = action as AddInStoreNotificationPayloadData;

            return {
                ...state,
                notifications: [notification, ...(state?.notifications || []).filter((item) => item.uniqueKey !== notification.uniqueKey)],
                unreadCount: state.unreadCount + 1,
                readDate: notification?.timestamp || state.readDate,
            };
        }
        case READ_IN_STORE_NOTIFICATIONS_ACTION: {
            const notifications = state?.notifications;
            const isReadNotifications = notifications
                ? notifications.map((item) => {
                    if (!item.readDate) {
                        return { ...item, readDate: Date.now() };
                    }
                    return item;
                })
                : initialState.notifications;

            return {
                ...state,
                unreadCount: 0,
                notifications: isReadNotifications,
            };
        }

        default:
            return state;
    }
};
