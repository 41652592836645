import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { Lcid } from 'types';
import { User } from 'services/user';
import { isHTTPError } from 'services/backend-client';

export class SentryError extends Error {
    constructor(message: string, errorName: string) {
        super(message);
        this.name = errorName;
    }
}

const checks = {
    checkSyncWithWtMenu: (currentUser: User | null, lcid: Lcid) => {
        const langPromise = window.WT.menu?.getLang?.();
        if (langPromise) {
            langPromise.then((menuLcid) => {
                if (lcid !== menuLcid) {
                    Sentry.captureException(
                        new SentryError(`Не сходятся языки. На странице: ${lcid}, в меню: ${menuLcid}`, 'CheckSyncWithWtMenu'),
                    );
                }
            });
        }

        const userPromise = window.WT.menu?.getUserInfoPromise?.();
        if (userPromise) {
            userPromise.then((menuUserData) => {
                const isAuthInMenu = (menuUserData?.id || 0) > 0;
                if (isAuthInMenu !== currentUser?.isAuth) {
                    Sentry.captureException(
                        new SentryError(
                            `Не сходится авторизованность. На странице:${currentUser?.isAuth ? '' : 'НЕ'} авторизован, в меню: ${isAuthInMenu ? '' : 'НЕ'} авторизован`,
                            'CheckSyncWithWtMenu',
                        ),
                        (scope) => {
                            scope.setFingerprint(['CheckSyncWithWtMenu']);
                            scope.setContext('menuUserData', menuUserData);
                            scope.setContext('currentUser', currentUser ? Object.keys(currentUser).reduce((acc, key) => ({
                                ...acc,
                                [key]: JSON.stringify(currentUser[key]),
                            }), {}) : currentUser);
                            return scope;
                        },
                    );
                }
            });
        }
    },
};

export const sentryClient = {
    init: () => {
        Sentry.init({
            dsn: process.env.SENTRY_DNS,
            integrations: [
                new Integrations.BrowserTracing(),
            ],
            attachStacktrace: true,

            tracesSampleRate: 1.0,
            /** Если ошибка это AxiosError, добавляем ответ от сервера в секцию extra отправляемого события */
            beforeSend(event, hint) {
                const error = hint?.originalException;
                if (isHTTPError(error)) {
                    const { response } = error;
                    if (response) {
                        if (!event.extra) {
                            event.extra = {};
                        }
                        event.extra.response = response;
                    }
                }
                return event;
            },
        });
    },

    performInitialPageChecks: (currentUser: User | null, lcid: Lcid) => {
        checks.checkSyncWithWtMenu(currentUser, lcid);
    },

    setContext: Sentry.setContext,

    captureException: Sentry.captureException,

    SentryError,

    checks,
};

window.WT.sentryClient = sentryClient;
