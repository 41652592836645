const getIds = (data, { dataset }) => {
    const ret = data; // линтер зануда...
    const id = dataset.mentionPersonId || dataset.replyToPersonId || null;
    const name = dataset.mentionPersonNickname || dataset.replyToPersonName || null;
    if (id && name) {
        // ret[id] = name; // задел на будущее, если надо будет по айди имя доставать
        ret[name] = id;
    }
    return ret;
};

export default function (text) {
    const div = document.createElement('div');
    div.innerHTML = text;

    // извлекаем из ссылок на профили от упоминаний айди и имена
    const linkCollection = [...div.getElementsByTagName('a')];
    return linkCollection.reduce(getIds, {});
}
