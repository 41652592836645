import { useEffect } from 'react';

import { FeedOwner } from 'context/feed';
import { sendGA } from 'services/google-analytics';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { useLoadMore } from 'hooks/useLoadMore';
import { useLocaleId } from 'hooks/useLocaleId';
import { FeedPropsInterface, StateProps } from '../types';
import { useSendGAPacksCount } from './useSendGaPacksCount';
import { useCheckFeedLength } from './useCheckFeedLength';

const hasNextPageFn = (prevPage?: StateProps) => !prevPage || !!prevPage.boundary;

export const useFetchRecords = ({
    source,
    owner,
    props,
}: {
    source: 'feed',
    owner: FeedOwner;
    props: FeedPropsInterface;
}) => {
    const { records, boundary: initialBoundary, fetchRecords } = props;
    const lcid = useLocaleId();
    const { sendGAPacksCount, feedPacksCount } = useSendGAPacksCount(records || []);
    const [loadMoreState, loadMore] = useLoadMore<StateProps>({
        initialPage: initialBoundary ? { boundary: initialBoundary } : undefined,
        hasNextPageFn,
        fetchNextPageFn,
    });

    function fetchNextPageFn() {
        return fetchRecords({
            source, owner, lcid, boundary: loadMoreState.pages.slice(-1)?.[0]?.boundary || initialBoundary || '',
        })
            .then(({ payload }) => {
                const { boundary, records: fetchedRecords = [] } = payload?.data || {};
                sendGAPacksCount(!!fetchedRecords.length);
                return boundary === undefined ? undefined : { boundary };
            });
    }

    const { isLoading, error } = loadMoreState;
    const { isFeedLengthError } = useCheckFeedLength({
        ...props, isLoading, error, source, owner,
    });
    const {
        sentinelElement,
        checkIfListEndReached,
    } = useInfiniteScroll({
        onListEndReached: () => {
            if (!isFeedLengthError && !isLoading) {
                sendGA('feedRecords', ['fetch-by-intersection', feedPacksCount + 1]);
                loadMore();
            }
        },
        threshold: 1000,
    });

    useEffect(() => {
        // dmh: Запускаем проверку условия на необходимость дозагрузки следующей страницы,
        // после загрузки очередной страницы
        if (!isLoading && !error) {
            checkIfListEndReached();
        }
    }, [checkIfListEndReached, error, isLoading]);

    return {
        sentinelElement,
        isLoading,
        isFeedLengthError,
    };
};
