import styled, { css } from 'styled-components';

import {
    matchBreakpoints,
    listResetStyle,
    mediaQuery,
} from 'app/styled';

import {
    buttonLargeFontStyle,
    buttonMediumFontStyle,
    buttonSmallFontStyle,
} from 'services/typography';

import type { TabsProps, TabsType } from '../props';
import { TabsListItem, kitByType } from './Item';
import { SizeMap } from './index';

type TabsWrapperProps = Required<Pick<TabsProps, 'type' | 'size'>>;

/** Tabs size-dependent CSS */
const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --divider-width: 3px;
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --divider-width: 3px;
    `,
    small: css`
        ${buttonSmallFontStyle};
        --divider-width: 2px;
    `,
};

function setVariables({ size }: TabsWrapperProps) {
    return matchBreakpoints(size, sizeMap);
}

function typeSpecificContainerStyles({ type }: { type: TabsType }) {
    const { tabsContainerStyles } = kitByType(type);
    return tabsContainerStyles;
}

export const TabsListWrapper = styled.ul<TabsWrapperProps>`
    ${listResetStyle};
    ${setVariables};
    ${typeSpecificContainerStyles};


    position: relative;
    display: inline-flex;
    user-select: none;

    ${mediaQuery.lt768} {
        overflow-y: auto;
        scrollbar-width: none;  /* Firefox */
        ::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }
    }

    & ${TabsListItem}:first-child {
        margin-left: 0;
    }

    & ${TabsListItem}:last-child {
        margin-right: 0;
    }

    color: ${({ theme }) => theme.label.tertiary};

    & ${TabsListItem} {
        cursor: pointer;
        position: relative;
    }
`;
