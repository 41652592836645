import { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';

// dmh: Эти цвета используются только в компонентах, использующих старую цветовую схему.
// Новые темы находится в comon/static/www/react/sources/services/theme
// @since WTT-9836
export const colors = {
    transparent: 'transparent',
    white: '#fff',
    waxwork: '#f6f7f8',
    whiteSmote: '#eee',
    selago: '#f5f8fE',
    lavender: '#e6eef9',
    lavenderBlue: '#d8e5ff',
    gainsboro: '#e0e0e0',
    bridesmaid: '#fae1dd',
    solitude: '#e9eef2',
    snowDrift: '#e8e8e7',
    titanWhite: '#d7d4dd',
    linkWater: '#c5d1db',
    veryLightGrey: '#ccc',
    silver: '#c4c4c4',
    darkGray: '#aaa',
    mischka: '#abadb0',
    nobel: '#999',
    grey: '#7f7f7f',
    dimGray: '#666',
    paynesGrey: '#3f3f4c',
    grape: '#433f4c',
    nightRider: '#333',
    night: '#17181A',
    black: '#000',
    midnightBlue: '#122f5e',
    charcoal: '#444444',
    bahamaBlue: '#1f4e73',
    mariner: '#3b5998',
    navyBlue: '#0852c1',
    persianBlue: '#4425e3',
    ceruleanBlue: '#304fbd',
    lightSlateBlue: '#775dff',
    royalBlue: '#2b78e4',
    dodgerDarkBlue: '#387cff',
    dodgerBlue: '#2596ff',
    mayaBlue: '#85afff',
    lightGrey: '#d3d3d3',
    wildBlueYonder: '#8297be',
    hawkesBlue: '#e8effd',
    mortar: '#555',
    cello: '#3e596b',
    jellyBean: '#45668e',
    frostedMint: '#eefff0',
    teaGreen: '#b9f0B0',
    sushi: '#74bb36',
    fem: '#53b760',
    fruitSalad: '#428f4c',
    darkTangerine: '#fbad18',
    sundown: '#f6a7a7',
    burntSienna: '#e66c54',
    sunsetOrange: '#ff4f53',
    lightMoon: '#e7e6eB',
    darkMoon: '#c9c7d1',
    darkBlue: '#3374ff',
    red: '#e83f41',
    alizarinCrimson: '#ED1F24',
    cinnabar: '#ea4335',
    harleyDavidsonOrange: '#c0371c',
    fireEngineRed: '#bf191d',
    mangoTango: '#cc8b00',
    lightCoral: '#f27979',
    orange: '#ffa500',
    orangeLight: '#ffae00',
    tangerineYellow: '#ffc700',
};

export const media = {
    forMobile: (...args) => _media.lessThan('small')(...args),
    forTablet: (...args) => _media.lessThan('medium')(...args),
    forLaptop: (...args) => _media.lessThan('large')(...args),
    forDesktop: (...args) => _media.greaterThan('large')(...args),
};

export const margin = {
    none: 0,
    halfDefault: '4px',
    semiDefault: '7px',
    default: '8px',
    defaultAndHalf: '12px',
    normal: '16px',
    big: '24px',
    veryBig: '32px',
    huge: '85px',
};

const _media = generateMedia({
    small: '640px',
    medium: '979px',
    large: '1200px',
});

export const media2 = {
    forMobile: (...args) => _media.lessThan('small')(...args),
    forTablet: (...args) => _media.lessThan('medium')(...args),
    forLaptop: (...args) => _media.lessThan('large')(...args),
    forDesktop: (...args) => _media.greaterThan('large')(...args),
};

export const feedPageMedia = (() => {
    const mediaPoints = [480, 768, 960, 1200, 1400];
    const _feedPageMedia = generateMedia(
        mediaPoints.reduce(
            (result, point) => ({ ...result, [point]: `${point}px` }),
            {},
        ),
    );
    return {
        lt480: (...args) => _feedPageMedia.lessThan(480)(...args),
        lt768: (...args) => _feedPageMedia.lessThan(768)(...args),
        lt960: (...args) => _feedPageMedia.lessThan(960)(...args),
        lt1200: (...args) => _feedPageMedia.lessThan(1200)(...args),
        lt1400: (...args) => _feedPageMedia.lessThan(1400)(...args),
        gt1400: (...args) => _feedPageMedia.greaterThan(1400)(...args),
    };
})();

export const buildByResponsiveSize = (dependantOfSizeFn) => (props) => {
    const { responsiveSize } = props;
    if (Object.keys(responsiveSize).length) {
        return css`
            ${responsiveSize.common ? dependantOfSizeFn({ ...props, size: responsiveSize.common }) : ''}
            ${responsiveSize.lt768 ? feedPageMedia.lt960`${dependantOfSizeFn({ ...props, size: responsiveSize.lt768 })}` : ''}
            ${responsiveSize.lt960 ? feedPageMedia.lt960`${dependantOfSizeFn({ ...props, size: responsiveSize.lt960 })}` : ''}
            ${responsiveSize.lt1200 ? feedPageMedia.lt960`${dependantOfSizeFn({ ...props, size: responsiveSize.lt1200 })}` : ''}
            ${responsiveSize.lt1400 ? feedPageMedia.lt960`${dependantOfSizeFn({ ...props, size: responsiveSize.lt1400 })}` : ''}
            ${responsiveSize.gt1400 ? feedPageMedia.lt960`${dependantOfSizeFn({ ...props, size: responsiveSize.gt1400 })}` : ''}
        `;
    }
    return '';
};
