import { DependencyList, useMemo } from 'react';

import Dic from 'services/dictionary';
import { useLocaleId } from './useLocaleId';

/**
 * Provides with a dictionary reference that will change with the locale -
 * so, it can be used as a dependency in `useCallback`, `useMemo`, `useEffect`, etc.
 *
 * @example
 * const dic = useDictionary();
 *
 * // words will change only when locale changes
 * const words = useMemo(() => [
 *   dic.word("some1"),
 *   dic.word("some2")
 * ], [dic]);
 */
export const useDictionary = () => {
    const lcid = useLocaleId();
    const [dic] = useMemo(() => [Dic.clone(lcid), lcid], [lcid]);
    return dic;
};

export const useDicWord = (key: string, params: Record<string, string | number> | number[]) => {
    const dic = useDictionary();
    const lcid = useLocaleId();
    return useMemo(() => dic.word(key, params, lcid), [params, lcid]);
};

/*
 * @example
 * const dic = useDictionary();
 *
 * // words will change only when locale or deps changes
 * const words = useDicMemo((dic) => [
 *   dic.word("some1"),
 *   dic.word("some2", { myVar })
 * ], [myVar]);
 */
export function useDicMemo<T>(factory: (dic: typeof Dic) => T, deps: DependencyList | undefined = []): T {
    const dic = useDictionary();
    const lcid = useLocaleId();
    return useMemo<T>(() => factory(dic), [lcid, ...deps]);
}
