import { fetchUser, setUser } from '../actions';
import store from '../store';

export const updateUser = (user: unknown) => {
    if (user) {
        store.dispatch(setUser(user));
    } else {
        store.dispatch(fetchUser());
    }
};
