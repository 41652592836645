import React, { ReactNode } from 'react';

import * as Styled from './styled/GroupBlock.styled';

export type GroupBlockPropsType = {
    children: ReactNode;
    title: string;
};

export const GroupBlock = (props: GroupBlockPropsType) => {
    const {
        title,
        children,
    } = props;

    return (
        <Styled.GroupBlock>
            <Styled.GroupTitleWrapper>
                <Styled.Title>
                    { title }
                </Styled.Title>
            </Styled.GroupTitleWrapper>
            <Styled.Content>
                { children }
            </Styled.Content>
        </Styled.GroupBlock>
    );
};
