import { connect } from 'react-redux';
import Tags from './Tags/Tags';
import { bulkSubscription } from './actions';

const mapDispatchToProps = {
    onBulkSubscription: bulkSubscription,
};

export const TagsComponent = connect(
    null,
    mapDispatchToProps,
)(Tags);
