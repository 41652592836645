import styled from 'styled-components';
import Tippy from '@tippy.js/react';

export const StyledTippy = styled(Tippy)`
    ${({ theme }) => `
        background: ${theme.bg.secondary};
        color: ${theme.label.primary};
        box-shadow: ${theme.shadow.popover};
    `}
    padding: 0;
    text-align: left;
    border-radius: 8px;
`;
