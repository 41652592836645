import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body17MediumFontStyle } from 'services/typography';
import { ContextActions } from 'components/context-actions';
import { ShevronDownSmallIcon } from 'components/icons2/ShevronDownSmallIcon';

export const Wrapper = styled.div`
    margin-top: 16px;
`;

export const ShowMoreWrapper = styled.div`
    margin-top: 16px;
    margin-left: 12px;
`;

export const FilterActions = styled(ContextActions)`
`;

export const FilterWrapper = styled.div`
    width: 256px;
`;

export const FilterButton = styled.div<{ active?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 24px 8px 24px;
    margin-bottom: 10px;
    ${body17MediumFontStyle}
    color: ${({ theme, active }) => (active ? theme.label.accent : theme.label.secondary)};

    &:hover {
        color: ${({ theme, active }) => (active ? theme.label.accent : theme.label.secondaryActive)};
    }

    ${mediaQuery.lt480} {
        padding: 6px 24px 6px 24px;
        margin-bottom: 8px;
    }
`;

export const FilterIcon = styled(ShevronDownSmallIcon)`
    margin-left: 8px;
`;

export const CommentFormWrapper = styled.div`
    margin-right: 24px;
    margin-left: 24px;
`;
