import set from 'lodash/set';
import get from 'lodash/get';

export const replaceKey = (obj, oldKey, newKey) => {
    if (!Object.prototype.hasOwnProperty.call(obj, oldKey)) return obj;
    const newObject = {};
    delete Object.assign(newObject, obj, { [newKey]: obj[oldKey] })[oldKey];
    return newObject;
};

const replaceKeys = (obj, keysMap, context) => {
    let result = { ...(context ? get(obj, context) : obj) };
    result = Object.keys(keysMap).reduce((acc, oldKey) => replaceKey(acc, oldKey, keysMap[oldKey]), { ...result });
    if (context) {
        return set({ ...obj }, context, result);
    }
    return result;
};

function toCamelCase(str) {
    // Lower cases the string
    return str.toLowerCase()
        .replace(/[-_]+/g, ' ')
        .replace(/[^\w\s]/g, '')
        .replace(/ (.)/g, ($1) => $1.toUpperCase())
        .replace(/ /g, '');
}

export const shallowCamelizeKeys = (obj) => {
    const keysMap = Object.keys(obj).reduce((acc, oldKey) => ({ ...acc, [oldKey]: toCamelCase(oldKey) }), {});
    return replaceKeys(obj, keysMap);
};

export default replaceKeys;
