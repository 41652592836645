import styled, { DefaultTheme, css } from 'styled-components';
import { prop } from 'styled-tools';

export const ListWrapper = styled.div<{ maxHeight?: string }>`
    position: relative;
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    max-height: ${prop('maxHeight', 'auto')};
    overflow-y: auto;
    background: ${({ theme }) => theme.bg.secondary};
`;

const itemStyle = ({ theme, current }: { theme: DefaultTheme, current?: boolean }) => `
    cursor: pointer;
    background: ${current ? theme.fillIn.secondary.overlay16 : 'transparent'};
    box-sizing: border-box;
    padding: 12px 16px;

    &:hover {
        background: ${theme.fillIn.secondary.overlay16};
    }

    &:active {
        background: ${theme.fillIn.secondary.overlay24};
    }

    &:not(:first-child) {
        box-shadow: 0 -1px 0 0 ${theme.divider.primary};
    }
`;

export const ItemDivWrapper = styled.div<{ current?: boolean }>`
    ${itemStyle}
`;

export const ItemHrefWrapper = styled.a<{ current?: boolean }>`
    display: block;
    ${itemStyle}
`;
