import React, {
    memo,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import authPopup from 'services/auth-popup';

import { subscribeToTicker } from './backend';
import { formatCurrency, ChangeDelta } from './filters';
import {
    TickersResultDelta,
    TickersResultPrice,
    TickersResultExchange,
    TickersResultDescription,
    TickersResultValue,
    TickersResultTicker,
    TickersResultListWrapper,
    LoadingIcon,
    FavoriteWrapper,
    StyledStarFillIcon,
    StyledStarIcon,
} from './styled';
import { instrumentExchangeAcronymsMap } from '../../../services/constants';

import store from '../../../store';

function initState(initialInstrument) {
    return { instrument: initialInstrument };
}

function instrumentsLocalReducer(localState, action) {
    switch (action.type) {
        case 'UPDATE_QUOTE': {
            const { quote } = action.payload;
            return {
                ...localState,
                instrument: {
                    ...localState.instrument,
                    quote,
                },
            };
        }
        default:
            return localState;
    }
}

export const TickerElement = memo((props) => {
    const {
        instrument,
        lcid,
        toggleTickerSubscribed,
    } = props;

    const [localState, localDispatch] = useReducer(instrumentsLocalReducer, instrument, initState);
    const handleQuoteUpdate = useCallback((quote) => localDispatch({
        type: 'UPDATE_QUOTE',
        payload: {
            quote,
        },
    }), [localDispatch]);

    const localInstrumentData = localState.instrument;
    const {
        id,
        quote,
        ticker,
        name,
        currency,
        decimal_place,
        subscribed,
        exchangeName,
    } = localInstrumentData;

    const [isSubscribed, setIsSubscribed] = useState(subscribed);
    const [loadingOrSubscribed, setLoadingOrSubscribed] = useState(null);

    const change = quote.chg || quote.changePercent || 0;
    const price = quote.price
        ? quote.price
        : quote.last;

    const subscribeToRealTimeInstrumentInfo = useCallback((id, handleQuoteUpdate) => {
        if (window.WT.quotes && window.WT.quotes._instance) {
            window.WT.quotes._instance.subscribeToRealTimeInstrumentInfo(id, handleQuoteUpdate);
        }
    }, []);

    const unsubscribeFromRealTimeInstrumentInfo = useCallback((id, handleQuoteUpdate) => {
        if (window.WT.quotes && window.WT.quotes._instance) {
            window.WT.quotes._instance.unsubscribeFromRealTimeInstrumentInfo(id, handleQuoteUpdate);
        }
    }, []);

    const handleSubscribeToTicker = async () => {
        const { user } = store.getState();

        if(user.id) {
            setLoadingOrSubscribed('loading');

            const res = await subscribeToTicker({
                instrumentId: instrument.id,
                isSubscribed,
                csrf: user.csrf
            });

            setLoadingOrSubscribed(res.status === 200 && res.data?.status ? res.data.status.subscription : isSubscribed);

            setIsSubscribed(!isSubscribed);
            toggleTickerSubscribed(instrument.id)
        } else {
            authPopup.login();
        }
    };

    useEffect(
        () => {
            subscribeToRealTimeInstrumentInfo(id, handleQuoteUpdate);
            return () => unsubscribeFromRealTimeInstrumentInfo(id, handleQuoteUpdate);
        },
        [
            id,
            handleQuoteUpdate,
            subscribeToRealTimeInstrumentInfo,
            unsubscribeFromRealTimeInstrumentInfo,
        ],
    );

    return (
        <TickersResultListWrapper className="autotest__search-instruments-result-item">
            <TickersResultDescription>
                <TickersResultTicker>{ticker}</TickersResultTicker>
                <TickersResultExchange>
                    {instrumentExchangeAcronymsMap[exchangeName] || exchangeName}
                    {' '}
                    &bull;
                    {' '}
                    {name || ' - '}
                </TickersResultExchange>
            </TickersResultDescription>
            <TickersResultPrice>
                <TickersResultValue>
                    {
                        price
                            ? formatCurrency(
                                price,
                                currency,
                                decimal_place,
                                lcid,
                            )
                            : '-'
                    }
                </TickersResultValue>
                <TickersResultDelta><ChangeDelta change={change} last={quote.last} currency={currency} /></TickersResultDelta>
            </TickersResultPrice>
            <FavoriteWrapper onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (loadingOrSubscribed !== 'loading') {
                    handleSubscribeToTicker();
                }
            }}>
                    {loadingOrSubscribed === 'loading'
                        ? <LoadingIcon size={16} />
                        : isSubscribed
                            ? <StyledStarFillIcon size={16} />
                            : <StyledStarIcon size={16} />}
            </FavoriteWrapper>
        </TickersResultListWrapper>
    );
});

TickerElement.propTypes = {
    instrument: PropTypes.shape({}).isRequired,
    lcid: PropTypes.string.isRequired,
    toggleTickerSubscribed: PropTypes.func.isRequired,
};
