import styled from 'styled-components';

import { mediaQuery, labelSecondary } from 'app/styled';
import { body16MediumFontStyle } from 'services/typography';

export const FeedWrapper = styled.div`
    width: 100%;
`;

const itemsSpaceBetween = (space: number) => `
    & + & {
        margin-top: ${space}px;
    }
`;

export const ItemWrapper = styled.div`
    ${itemsSpaceBetween(24)}
    ${mediaQuery.lt1400} {
        ${itemsSpaceBetween(20)}
    }
    ${mediaQuery.lt1200} {
        ${itemsSpaceBetween(16)}
    }
    ${mediaQuery.lt768} {
        ${itemsSpaceBetween(8)}
    }
`;

export const ItemsList = styled.div``;

export const ErrorBlockWrapper = styled.div`
    ${body16MediumFontStyle};
    width: 100%;
    height: 150px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme: { label, bg } }) => `
        color: ${label.inactive};
        background: ${bg.secondary};
    `}
`;
