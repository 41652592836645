import React from 'react';

import { staticUrl } from '../../services/constants';
import * as Styled from './Logo.styles';

type Props = {
    lang?: string,
}

export function Logo({ lang }: Props) {
    const lcid = lang && ['ru', 'en'].includes(lang) ? lang : 'en';

    return (
        <Styled.LogoLink href="/?gam=logo" lcid={lcid} className="autotest__menu__logo">
            <img src={`${staticUrl}/images/external/wt-menu/limex_en.svg`} style={{ height: '100%' }} />
        </Styled.LogoLink>
    );
}
