import styled from 'styled-components';

import { animations, mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';
import { BoxStyled } from 'components/popover/styled';

import { LoadingRingIcon } from 'components/icons2/LoadingRingIcon';
import { TimesIcon } from 'components/icons2/TimesIcon';

export const SearchStyled = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background: ${({ theme }) => theme.fillIn.secondary.overlay16};
    padding: 10px 14px 10px 14px;
    height: 40px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.bg.secondary};

    caret-color: ${({ theme }) => theme.label.accent};

    input::placeholder {
        color: ${({ theme }) => theme.label.placeholder};
    }

    form {
        display: flex;
        width: 100%;
        align-items: center;
        & > *:not(:last-child) { margin-right: 11px; }
    }

    &:hover {
        background: ${({ theme }) => theme.fillIn.secondary.overlay24};
    }

    &:focus-within {
        border: 1px solid  ${({ theme }) => theme.fillIn.primary.mid};
        box-shadow: ${({ theme }) => theme.focusOutline.accent};
        background:  ${({ theme }) => theme.bg.secondary};
    }

    ${mediaQuery.lt768} {
        display: none;
    }
`;

export const SearchStyledMobile = styled.div`
    display: none;
    align-items: center;

    & > *:not(:last-child) { margin-right: 16px; }

    box-sizing: border-box;
    background: ${({ theme }) => theme.bg.secondary};
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.divider.primary};
    height: 56px;
    padding: 16px 24px;
    width: 100%;

    form {
        display: flex;
        align-items: center;
        & > *:not(:last-child) { margin-right: 16px; }
    }

    input::placeholder {
        color: ${({ theme }) => theme.label.placeholder};
    }

    caret-color: ${({ theme }) => theme.label.accent};

    ${mediaQuery.lt768} {
        display: flex;
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    position: relative;
    flex-grow: 1;
`;

export const SearchMagnifierBox = styled.div`
    display: flex;
    color: ${({ theme }) => theme.label.placeholder};

`;

export const SearchMagnifierBoxMobile = styled.div`
    display: flex;
    color: ${({ theme }) => theme.label.placeholder};
`;

export const CloseIcon = styled(TimesIcon)`
    display: flex;
    cursor: pointer;
    margin-left: auto;

    color: ${({ theme }) => theme.label.secondary};
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;

export const SearchInput = styled.input`
    width: 100%;
    background-color: transparent;
    outline: none;
    border: 0;
    box-shadow: none;
    padding: 0;
    height: 20px;
    box-sizing: border-box;

    color: ${({ theme }) => theme.label.primary};

    ${body14RegularFontStyle}
`;

export const ResultsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;

    ${BoxStyled} {
        top: calc(100% + 22px);
        z-index: 999;
        overflow: hidden;
    
        ${mediaQuery.lt768} {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            border-radius: 0;
            box-shadow: none;
        }
    }
`;

export const LoadingIcon = styled(LoadingRingIcon)`
    animation: ${animations.spin} 0.5s infinite ease-in-out;
`;
