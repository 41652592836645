import React from 'react';
import { StepType } from '@reactour/tour/dist/types';
import { StylesObj as TourStylesObj } from '@reactour/tour/dist/styles';
import { StylesObj as MaskStylesObj } from '@reactour/mask/dist/styles';

import Dic from 'services/dictionary';

export type TourProps = {
    children: React.ReactElement | React.ReactElement[];
    autoopenSteps: number[],
};

export const styles: TourStylesObj & MaskStylesObj = {
    navigation: (base) => ({ ...base, display: 'none' }),
    controls: (base) => ({ ...base, justifyContent: 'flex-end' }),
};

export const ReactourSelectors = [0, 1, 2, 3, 4, 5].map((num) => `reactour__step${num}`);

const noMask: Partial<StepType> = {
    position: 'center',
    styles: {
        ...styles,
        maskArea: (base) => ({ ...base, ...styles.maskArea?.(base), width: 0 }),
    },
};

const selectorClass = (num: number) => `.${ReactourSelectors[num]}`;

const dctContent = (step: number) => () => <div dangerouslySetInnerHTML={{ __html: Dic.word(`wt_all__widget_tour__step_${step}_content`) }} />;

const dctBtnText = (step: number) => Dic.word(`wt_all__widget_tour__step_${step}_btn_next`);

type StepBtn = () => { text?: string, onClick?: () => void, toRunNextStep?: boolean };
type StepSettings = { reopen: boolean };
export const steps: Array<StepType & { btn?: StepBtn } & { settings?: StepSettings }> = [
    {
        selector: selectorClass(0),
        content: dctContent(0),
        btn: () => ({ text: dctBtnText(0) }),
        styles: {
            maskArea: (base) => ({ ...base, height: 64 }),
        },
    },
    { selector: selectorClass(1), content: dctContent(1) },
    {
        selector: selectorClass(2),
        content: dctContent(2),
        btn: () => ({ text: dctBtnText(2), onClick: () => location.pathname = '/marketplace', toRunNextStep: false }),
    },
    { selector: selectorClass(3), content: dctContent(3), ...noMask },
    {
        selector: selectorClass(4),
        highlightedSelectors: [selectorClass(4)],
        mutationObservables: [selectorClass(4)],
        content: dctContent(4),
        settings: { reopen: true },
        position: 'right',
    },
    {
        selector: selectorClass(5), content: dctContent(5), ...noMask, btn: () => ({ text: dctBtnText(5) }),
    },
];

export const LS_KEY = 'reactour-step';

export enum ReactourSteps {
    step0,
    step1,
    step2,
    step3,
    step4,
    step5,
}
