// создает ref-объект на основе входящих данных
// помогает работать с замыканием переменных (например в листенерах)
import { MutableRefObject, useLayoutEffect, useRef } from 'react';

import useStateCallback from 'hooks/useStateCallback';

export const useNonClosableRef = <T>(value: T): MutableRefObject<T> => {
    const ref = useRef<T>(value);
    useLayoutEffect(() => {
        ref.current = value;
    });
    return ref;
};

type SetStateAction<S> = S | ((prevState: S) => S);

export const useNonClosableRefState = <T>(initialValue: T): [
    MutableRefObject<T>,
    T,
    (value: SetStateAction<T>, callback?: (state: T) => void) => void,
] => {
    const [val, setVal] = useStateCallback<T>(initialValue);
    const ref = useNonClosableRef(val);
    return [ref, val, setVal];
};
