import React, { useMemo, useEffect } from 'react';
import { FeedRecord } from 'types';
import Dic from 'services/dictionary';
import { useToggle } from 'hooks/useToogle';
import { useFeedContext } from 'hooks/useFeedContext';
import { useHideComments } from 'hooks/useHideComments';
import { useUrlHashIdComment } from 'widgets/feed/comments/hooks/useUrlHashIdComment';
import { GalleryInitFunction } from 'components/gallery/Gallery';

import { Activity } from '../../../activity';
import { useDeleteRecord } from '../../hooks/useDeleteRecord';
import { DeleteFeedRecord, UpdateFeedRecord } from '../../actions';
import { useEditRecord } from '../../hooks/useEditRecord';
import { usePublisherFn } from '../../hooks/usePublisherFn';
import { AddFeedStatus } from '../../../add-status';
import { useIsPremiumExtend } from '../../hooks/useIsPremiumExtend';
import { DeletedRecordWrapper, PublishStatusContainer } from './styled';

interface Props {
    record: FeedRecord,
    updateFeedRecord?: UpdateFeedRecord,
    deleteFeedRecord?: DeleteFeedRecord,
    onClickAnyImgInPost?: GalleryInitFunction,
    pinned?: boolean,
    highlighted?: boolean,
}

export const PublishStatus = ({
    record, onClickAnyImgInPost, highlighted, deleteFeedRecord, updateFeedRecord, pinned = false,
}: Props) => {
    const [showComments, toggleShowComments] = useToggle(false);
    const { isDeleted, onDelete, onRestore } = useDeleteRecord(record, deleteFeedRecord);
    const { isEditing, setIsEditing } = useEditRecord();
    const [{ owner }] = useFeedContext();
    const { isPremiumExtend } = useIsPremiumExtend(record);
    const hideComments = useHideComments(record?.obj?.author?.id);
    const handleUpdate = usePublisherFn(updateFeedRecord);

    const authorProps = useMemo(() => ({ onDelete }), [record, deleteFeedRecord]);
    const htmlParsedContentProps = useMemo(() => ({ onClickAnyImgInPost }), [onClickAnyImgInPost]);
    const footerProps = useMemo(() => ({ onClickComments: toggleShowComments }), [toggleShowComments]);

    const { hashCommentId: pinnedCommentId } = useUrlHashIdComment();

    useEffect(() => {
        if (pinned && !!pinnedCommentId) {
            toggleShowComments();
        }
    }, []);

    return (
        <PublishStatusContainer highlighted={highlighted} data-blogpost-id={record.obj?.id}>
            {!isDeleted && !isEditing && (
                <Activity
                    record={record}
                    author={authorProps}
                    title={isPremiumExtend}
                    instruments={isPremiumExtend}
                    htmlParsedContent={htmlParsedContentProps}
                    footer={(isPremiumExtend && footerProps) || undefined}
                    comments={isPremiumExtend && !hideComments && showComments}
                />
            )}
            {isEditing && (
                <AddFeedStatus
                    owner={owner}
                    objId={record.obj?.id}
                    initRawJson={record.obj?.json || ''}
                    publisherFn={handleUpdate}
                    placeholder={Dic.word('wt_feed__timeline_status__real_placeholder')}
                    onActiveChange={(isActive) => {
                        if (!isActive) setIsEditing(false);
                    }}
                    autofocus={false}
                    isActive
                />
            )}
            {isDeleted && (
                <DeletedRecordWrapper>
                    {Dic.word('wt_feed__deleted_record__deleted_post')}
                </DeletedRecordWrapper>
            )}
        </PublishStatusContainer>
    );
};

// Код ниже - задействовать когда (если) на бекенде появится эндпоинт восстановления поста
//
// &nbsp;
// <DeletedRecordRestore onClick={onRestore}>
//     {Dic.word('wt_feed__deleted_record__restore')}
// </DeletedRecordRestore>
