import React from 'react';
import { CloseProps } from '@reactour/tour/dist/components/Close';
import { BtnFnProps } from '@reactour/tour/dist/types';

import Dic from 'services/dictionary';
import { StyledCloseButton } from 'widgets/tour/styled';
import { CloseIcon } from 'components/wt-popup/popup-window/styled';
import { Button } from 'components/button2';

import { LS_KEY, steps } from './constants';

export const Close = (props: CloseProps) => {
    const { onClick } = props;
    return (
        <StyledCloseButton
            onClick={onClick}
            className="autotest__reactour__close-btn"
        >
            <CloseIcon />
        </StyledCloseButton>
    );
};

export const NextButton = (props: BtnFnProps) => {
    const { setCurrentStep, currentStep, setIsOpen } = props;
    const { btn, settings } = steps[currentStep];
    const {
        text = Dic.word('wt_all__widget_tour__step_shared_btn_next'),
        onClick = () => {
            // do nothing.
        },
        toRunNextStep = true,
    } = btn?.() || {};
    const { reopen = false } = settings || {};

    return (
        <Button
            onClick={() => {
                const newStep = currentStep + 1;
                localStorage.setItem(LS_KEY, `${newStep}`);
                if (toRunNextStep) {
                    if (reopen) setIsOpen(false);
                    setCurrentStep(newStep);
                    if (reopen && newStep <= steps.length) setIsOpen(true);
                }
                onClick();
            }}
            className="autotest__reactour__next-btn"
        >
            {text}
        </Button>
    );
};

export const PrevButton = () => null;
