import { injectReducer } from 'app/store2';
import { InitialData, FeedState } from './types';
import { FeedContainer } from './container';
import reducer from './reducer';

export const injectFeedReducer = (rawInitialState: InitialData) => {
    injectReducer('widgets.feed', reducer(rawInitialState));
};

export type { InitialData, FeedState };

export default FeedContainer;
