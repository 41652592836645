const getMobileDetect = (userAgent) => {
    const isAndroid = () => Boolean(userAgent.match(/Android/i));
    const isIos = () => Boolean(userAgent.match(/iPhone|iPod/i));
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
    const isIPad = () => Boolean(userAgent.match(/iPad/i));
    const isTablet = () => Boolean(isIPad() || Boolean(userAgent.match(/tablet/i)));
    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows()) && !isTablet();
    const isDesktop = () => !isMobile() && !isTablet();
    const isIE = () => userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    return {
        isMobile,
        isDesktop,
        isAndroid,
        isIos,
        isTablet,
        isIE,
    };
};

export default getMobileDetect(navigator.userAgent);
