/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */

import store from '../store';
import { socketUrl } from './constants';
import { menuLogger } from './menuLogger';

const getSocketMessageData = (message) => {
    let data;
    let messageData;

    if (typeof message === 'object') {
        if (typeof message === 'object') {
            data = message.data;
        } else {
            data = JSON.parse(message.data);
        }
    } else {
        messageData = JSON.parse(message);
        if (typeof messageData === 'object') {
            data = messageData.data;
        } else {
            data = JSON.parse(messageData.data);
        }
    }

    return data;
};


let eventQuery = [];

const processSocketEventMessage = (event, user) => {
    const message = JSON.parse(event.data)[2];

    if (!(typeof message === 'object' || typeof message === 'string')) {
        return;
    }

    const { channel } = message;
    const messageObj = getSocketMessageData(message);

    if (channel !== user.channel.user) {
        return;
    }
    const existingEventsNames = eventQuery.map(existingEvent => existingEvent.name);
    if (messageObj.data_type === 'notification') {
        const action = eventQuery.find(existingEvent => existingEvent.name === 'notification').action;
        if (action && typeof action === 'function') {
            action([messageObj]);
        }
    } else if (existingEventsNames.indexOf(messageObj.event) !== -1) {
        const action = eventQuery.find(existingEvent => existingEvent.name === messageObj.event).action;
        if (action && typeof action === 'function') {
            action(messageObj);
        }
    }
};


const wtSocket = {
};


let notificationsSocket = null;

const socketStateCodes = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};


wtSocket.init = () => {
    const user = store.getState().user;

    if (!user || !user.channel) {
        return;
    }

    if (
        notificationsSocket
    && (
        notificationsSocket.readyState === socketStateCodes.CONNECTING
      || notificationsSocket.readyState === socketStateCodes.OPEN
    )
    ) {
        return;
    }
    try {
        notificationsSocket = new WebSocket(socketUrl);
        notificationsSocket.onopen = (() => {
            notificationsSocket.send(JSON.stringify([5, user.channel.user]));
            notificationsSocket.send(JSON.stringify([5, user.channel.general_lcid]));
        });

        notificationsSocket.onmessage = ((event) => {
            menuLogger.debug('WebSocket message received');
            processSocketEventMessage(event, user);
        });

        notificationsSocket.onclose = ((event) => {
            if (event.code !== 4000 && event.reason !== 'Manual') {
                menuLogger.debug('Notifications Socket closed, reconnecting...');
                setTimeout(() => {
                    wtSocket.init();
                }, 5000);
            }
        });
    } catch (error) {
        menuLogger.error(error);
    }
};

wtSocket.add = (eventAction = { name: 'default', action: () => {} }) => {
    if (eventQuery.length > 0 && eventQuery.find(existingEvent => existingEvent.name === eventAction.name)) {
        return;
    }
    eventQuery.push(eventAction);
};

wtSocket.remove = (eventAction = { name: 'default', action: () => {} }) => {
    eventQuery = eventQuery.filter(existinEvent => existinEvent.name !== eventAction.name);
};

wtSocket.destroy = () => {
    if (notificationsSocket) {
        notificationsSocket.close(4000, 'Manual');
    }
};


export default wtSocket;
