/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { searchResultClickLog } from '../../../actions';

import NoResult from './NoResult';
import Loading from './Loading';
import {
    ProductsResultDescriptionWrapper,
    ProductsResultImageWrapper,
    ProductsResultTitleWrapper,
    ProductsResultListWrapper,
    ProductsResultTypeWrapper,
    StyledList,
} from './styled';
import { useDictionary } from 'hooks/useDictionary';
const ProductsResult = ({
    log,
    products,
    searchInput,
    cancelSearch,
    isLoading,
}) => {
    const dic = useDictionary();
    const dispatch = useDispatch();

    const handleProductClick = useCallback((product) => {
        window.dispatchEvent(new CustomEvent('wtSearchPostClick', { detail: { event, postModel: { id: product.productId } } }));
        cancelSearch();
        dispatch(searchResultClickLog({
            id: product.productId,
            type: 'product',
            log_id: log && log.log_id,
            timestamp: new Date().getTime(),
            query: searchInput,
        }));
    }, [
        log && log.log_id,
        cancelSearch,
        dispatch,
        searchInput,
    ]);

    const productType = (product) => (product.productType === 'product'
        ? dic.word('wt_menu__search_limex__market_product')
        : product.productType === 'course'
            ? dic.word('wt_menu__search_limex__market_course')
            : dic.word('wt_menu__search_limex__market_service'));

    if (isLoading && products.length < 1) return <Loading />;

    return (
        products.length < 1 ? <NoResult input={searchInput} /> : (
            <StyledList
                items={products.map((item) => ({ ...item, key: item.productId }))}
                cursorElement={window}
                className="autotest__search-products-result"
                itemHref={(product) => product.url}
                onSelect={(product) => handleProductClick(product)}
                renderItem={(product) => (
                    <ProductsResultListWrapper
                        data-id={product.name}
                        className="autotest__search-products-result-item"
                    >
                        <ProductsResultImageWrapper
                            title={product.name}
                        >
                            {product.image?.xsmall && <img src={product.image?.xsmall} />}
                        </ProductsResultImageWrapper>
                        <ProductsResultDescriptionWrapper>
                            <ProductsResultTitleWrapper>{product.title}</ProductsResultTitleWrapper>
                            <ProductsResultTypeWrapper>{productType(product)}</ProductsResultTypeWrapper>
                        </ProductsResultDescriptionWrapper>
                    </ProductsResultListWrapper>
                )}
            />
        )
    );
};

ProductsResult.propTypes = {
    products: PropTypes.array.isRequired,
    searchInput: PropTypes.string.isRequired,
    log: PropTypes.shape({}).isRequired,
    cancelSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ProductsResult;
