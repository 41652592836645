import React, { ReactElement } from 'react';

import { IconSvg, IconProps } from '.';

interface Props extends IconProps {
    onClick?: React.MouseEventHandler;
}

export const CircleXMarkIcon: (props: Props) => ReactElement = (props) => (
    <IconSvg {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00001 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33334 8.00001 1.33334C4.31811 1.33334 1.33334 4.3181 1.33334 8C1.33334 11.6819 4.31811 14.6667 8.00001 14.6667ZM6.3771 5.62285C6.16882 5.41457 5.83113 5.41457 5.62285 5.62285C5.41457 5.83113 5.41457 6.16881 5.62285 6.37709L7.24573 7.99997L5.62285 9.62285C5.41457 9.83113 5.41457 10.1688 5.62285 10.3771C5.83113 10.5854 6.16882 10.5854 6.3771 10.3771L7.99998 8.75422L9.62285 10.3771C9.83113 10.5854 10.1688 10.5854 10.3771 10.3771C10.5854 10.1688 10.5854 9.83113 10.3771 9.62285L8.75423 7.99997L10.3771 6.37709C10.5854 6.16881 10.5854 5.83113 10.3771 5.62285C10.1688 5.41457 9.83113 5.41457 9.62285 5.62285L7.99998 7.24572L6.3771 5.62285Z"
        />
    </IconSvg>
);
