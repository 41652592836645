// MAIN_DOMAIN as a global is deprecated.
// All global variables are on the process.env.*
// @ts-ignore
// eslint-disable-next-line max-classes-per-file
const mainDomain = typeof MAIN_DOMAIN !== 'undefined' ? MAIN_DOMAIN : process.env.MAIN_DOMAIN;

const connectionSocketUrl = `wss://${mainDomain.replace(/^(?:https?:\/\/)/img, '')}/websockets`;

type WebSocketData = Parameters<WebSocket['send']>[0];

class WebSocketTransport {
    private readonly socketUrl: string | URL;

    private readonly onMessage: (event: MessageEvent) => void;

    private readonly onReconnect: () => void;

    // @ts-ignore
    private websocket: WebSocket;

    private messageQueue: WebSocketData[] = [];

    constructor(url: string | URL, params: { onMessage: (event: MessageEvent) => void, onReconnect: () => void }) {
        this.socketUrl = url;
        this.onMessage = params.onMessage;
        this.onReconnect = params.onReconnect;
        this.reconnect(true);
    }

    public send(data: any) {
        const message = JSON.stringify(data);
        if (this.isConnected()) {
            this.websocket.send(message);
        } else {
            this.messageQueue.push(message);
        }
    }

    private isConnected() {
        return this.websocket.readyState === WebSocket.OPEN;
    }

    private reconnect(init = false) {
        this.websocket = new WebSocket(this.socketUrl);
        this.websocket.addEventListener('open', () => this.onConnect(), { once: true });
        this.websocket.addEventListener('message', (event: Event) => { event instanceof MessageEvent && this.onMessage(event); });
        this.websocket.addEventListener('close', () => this.reconnect(), { once: true });
        if (!init) {
            this.onReconnect();
        }
    }

    private onConnect() {
        this.messageQueue.forEach((data) => this.websocket.send(data));
        this.messageQueue = [];
    }
}

const MESSAGE_TYPE_SUBSCRIBE = 5;
const MESSAGE_TYPE_PUBLISH = 7;
const MESSAGE_TYPE_NOTIFICATION = 8;

class WebSocketClient {
    private transport: WebSocketTransport;

    private channels: { [name: string]: Channel } = {};

    constructor(socketUrl: string | URL) {
        this.transport = new WebSocketTransport(socketUrl, {
            onMessage: (event: MessageEvent) => this.onMessage(event),
            onReconnect: () => this.onReconnect(),
        });
    }

    public getChannel(name: string) {
        if (!this.channels[name]) {
            this.channels[name] = new Channel();
            this.subscribe(name);
        }

        return this.channels[name];
    }

    public subscribe(channel: string) {
        this.transport.send([MESSAGE_TYPE_SUBSCRIBE, channel]);
    }

    public publish(data: any) {
        this.transport.send([MESSAGE_TYPE_PUBLISH, '', JSON.stringify(data)]);
    }

    private onMessage(event: MessageEvent) {
        const [messageType, channel, data] = JSON.parse(event.data);

        if (messageType !== MESSAGE_TYPE_NOTIFICATION) {
            return;
        }

        if (!this.channels[channel]) {
            return;
        }

        this.channels[channel].trigger(data.data);
    }

    private onReconnect() {
        Object.keys(this.channels).forEach((channel) => this.subscribe(channel));
    }
}

class Channel {
    private callbacks: { [name: string]: ((data: any) => void)[] } = {};

    public on(eventName: string, callback: (data: any) => void) {
        if (!this.callbacks[eventName]) {
            this.callbacks[eventName] = [];
        }

        this.callbacks[eventName].push(callback);
    }

    public off(eventName: string, callback: (data: any) => void) {
        if (!this.callbacks[eventName]) {
            return;
        }

        this.callbacks[eventName] = this.callbacks[eventName].filter((cb) => cb !== callback);
    }

    public trigger(eventData: any) {
        if (this.callbacks['*']) {
            this.callbacks['*'].forEach((cb) => cb(eventData));
        }

        const { event, data } = eventData;

        if (!event) {
            return;
        }

        if (this.callbacks[event]) {
            this.callbacks[event].forEach((cb) => cb(data));
        }
    }
}

const socket = new WebSocketClient(connectionSocketUrl);

export { socket };
