import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { h3SemiboldFontStyle, body17SemiboldFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    ${h3SemiboldFontStyle};
    max-width: 90%;
    padding: 4px 0;
    ${mediaQuery.lt768} {
        ${body17SemiboldFontStyle};
        padding: 0;
    }
`;
