import { forceLoadWtAuth } from './forceLoadWtAuth';

export const forceShowWtAuthPopup = (popup: string) => {
    forceLoadWtAuth<{ show:(popupName: string) => void; }>((auth) => {
        if (popup === 'SignOut') {
            auth.show('logout');
        } else if (popup === 'UserAgreement') {
            auth.show('terms');
        } else {
            console.log('GLOBAL_AUTH: not processed screen', popup);
        }
    }, true);
};
