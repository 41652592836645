import { Id } from '../shared';

export enum GeneratorType {
    USER = 1,
    GROUP= 2,
    INSTRUMENT = 5,
    PREMIUM_POST = 36,
}

export interface FeedRecordGenerator {
    id: Id,
    type: GeneratorType,
    isFollowable: boolean,
    url: string,
    data: {
        subscribed: boolean,
    }
}
