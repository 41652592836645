/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

import { CurrencySigns } from 'services/utils/currencies';

export { formatPriceCurrencyByLocale as formatCurrency } from 'services/formatPriceCurrencyByLocale';

export const ChangeDelta = ({ change, last, currency }) => {
    const noChange = (
        <span className="noChange">
            -
        </span>
    );

    if (!change && change !== 0) {
        return noChange;
    }
    const format = () => {
        const total = Math.abs((last * (change / 100))).toFixed(2);

        if (isNaN(total)) {
            return noChange;
        }

        // eslint-disable-next-line no-mixed-operators
        return (
            <>
                {!change && ' ' || (change > 0 ? '+' : '-')}
                {total}
                {CurrencySigns[currency]}
                {' '}
                (
                {Math.abs(change).toFixed(2)}
                %)
            </>
        );
    };
    const CSS_CLASS = {
        1: 'positiveChange',
        '-1': 'negativeChange',
        0: 'noChange',
    };
    return (
        <span className={CSS_CLASS[Math.sign(change)]}>
            {format()}
        </span>
    );
};

ChangeDelta.propTypes = {
    change: PropTypes.number.isRequired,
    last: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]).isRequired,
    ]),
};

ChangeDelta.defaultProps = {
    last: 0,
};

const languages = {
    en, ru,
};

export const formatdate = (timestamp, lcid) => format(new Date(timestamp * 1000), 'dd MMMM yyyy', { locale: languages[lcid] });
