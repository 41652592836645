import React, { lazy, useState, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector, Provider } from 'react-redux';

import { MenuPerson, Lcid } from 'types';
import store from 'app/store2';
import { LocaleProvider } from 'context/locale';
import { themes } from 'services/theme';
import { Button } from 'components/button2';
import { MagnifierIcon } from 'components/icons2/MagnifierIcon';

import { Logo } from '../logo';
import { Search } from '../search';

import { MainMenu } from '../main-menu';
import { MobileMenu } from '../mobile-menu';
import { UserMenu } from '../user-menu';

import { MenuState } from '../../types/interfaces';
import Notifications from '../../../header-notifications';
import * as Styled from './styles';

export interface Props {
    callbacks: unknown[];
    onFetchUser: (changeLoaduserState?: boolean) => void;
    activeLanguage: Lcid;
}

export function Header(props: Props) {
    const {
        callbacks,
        onFetchUser,
        activeLanguage,
    } = props;
    const user = useSelector<MenuState, MenuPerson>((state) => state.user);
    const [isSearchResultsVisible, setSearchResultsVisible] = useState(false);
    const isUserLoggedIn = !!user && !!user.id;
    const isUserReady = user?.id !== undefined;

    return (
        <ThemeProvider theme={themes.light}>
            <Styled.Header>
                <Styled.Container className="reactour__step0">
                    <Styled.LogoColumn>
                        <Logo lang={activeLanguage} />
                    </Styled.LogoColumn>

                    <Styled.MainMenuColumn>
                        <MainMenu />
                    </Styled.MainMenuColumn>

                    <Styled.SearchColumn>
                        <Search
                            isSearchResultsVisible={isSearchResultsVisible}
                            setSearchResultsVisible={setSearchResultsVisible}
                        />
                    </Styled.SearchColumn>

                    <Styled.HeaderSeparator />

                    <Styled.SearchButtonColumn>
                        <Button kind="ghost-secondary" size="small" icon={MagnifierIcon} onClick={() => setSearchResultsVisible(true)} />
                    </Styled.SearchButtonColumn>
                    {isUserLoggedIn && (
                        <Styled.NotificationsColumn>
                            <Provider store={store}>
                                <LocaleProvider value={activeLanguage}>
                                    <Suspense fallback={<></>}>
                                        {
                                            // TODO после разработки нового меню получать user channel в виджете HeaderNotifications
                                        }
                                        <Notifications userChannel={user.channel.user} />
                                    </Suspense>
                                </LocaleProvider>
                            </Provider>
                        </Styled.NotificationsColumn>
                    )}
                    <Styled.UserMenuColumn isUserLoggedIn={isUserLoggedIn}>
                        {isUserReady && (
                            <UserMenu
                                callbacks={callbacks}
                                user={user}
                                onFetchUser={onFetchUser}
                            />
                        )}
                    </Styled.UserMenuColumn>

                    <Styled.HamburgerColumn>
                        <MobileMenu
                            user={user}
                            callbacks={callbacks}
                            isUserLoggedIn={isUserLoggedIn}
                            onFetchUser={onFetchUser}
                        />
                    </Styled.HamburgerColumn>
                </Styled.Container>
            </Styled.Header>
        </ThemeProvider>
    );
}
