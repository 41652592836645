import React, { useEffect } from 'react';

import { TourStarter, useOpenTour } from './TourStarter';
import { Close, PrevButton, NextButton } from './Buttons';
import {
    TourProps,
    steps,
    styles,
    ReactourSteps,
    ReactourSelectors,
    LS_KEY,
} from './constants';
import { StyledTourProvider, TourGlobalStyle } from './styled';

export { ReactourSteps };
export { useOpenTour, ReactourSelectors };

export const Tour = (props: TourProps) => {
    const { children, autoopenSteps } = props;

    const finishStep = steps.length;

    useEffect(() => {
        return () => {
            document.body.classList.remove('reactour-blocked');
        };
    }, []);

    return (
        <>
            <TourGlobalStyle />
            <StyledTourProvider
                steps={steps}
                components={{ Close }}
                nextButton={NextButton}
                prevButton={PrevButton}
                styles={styles}
                className="autotest__reactour__popup reactour__popup"
                onClickMask={() => {
                    // do nothing
                }}
                afterOpen={() => {
                    document.body.classList.add('reactour-blocked');
                }}
                beforeClose={() => {
                    document.body.classList.remove('reactour-blocked');
                }}
                disableFocusLock
                disableInteraction
                position="bottom"
                onClickClose={({ setIsOpen, setCurrentStep }) => {
                    localStorage.setItem(LS_KEY, `${finishStep}`);
                    setCurrentStep(finishStep);
                    setIsOpen(false);
                }}
            >
                <TourStarter autoopenSteps={autoopenSteps}>{children}</TourStarter>
            </StyledTourProvider>
        </>
    );
};
