/**
 * A no-op tagged template literal. Useful for syntax highlighting in IDEs.
 * @example
 *
 * const css = taggedTemplateNoop;
 *
 * const style = css`
 *   color: green;
 * `; // can be highlighted in IDE with CSS syntax
 *
 * const sql = taggedTemplateNoop;
 *
 * const query = sql`
 *   select * from users;
 * `; // can be highlighted in IDE with SQL syntax
 */
export function taggedTemplateNoop(strings: TemplateStringsArray, ...keys: string[]) {
    const lastIndex = strings.length - 1;
    return strings
        .slice(0, lastIndex)
        .reduce((p, s, i) => p + s + keys[i], '')
      + strings[lastIndex];
}
