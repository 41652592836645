import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colors, media } from 'app/styled';
import { TimesIcon } from 'components/icons2/TimesIcon';
import { Back } from './back';

const baseBodyHeight = [
    // vls: desktop
    563,
    // vls: mobile
    540,
];
const getBaseBodyHeight = (props, isMobile) => {
    const index = isMobile ? 1 : 0;
    let height = props.heights[index];

    if (!height) {
        height = baseBodyHeight[index];
    }

    return height;
};
const screenGreaterThanMinHeight = (styles) => (css`
  @media (min-height: ${(props) => getBaseBodyHeight(props, true)}px) {
    ${media.forMobile`
        ${styles}
    `}
  }
  @media (min-height: ${(props) => getBaseBodyHeight(props)}px) {
      ${media.forDesktop`
          ${styles}
      `}
  }
`);

export const Window = styled.div`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 10000;
    overflow: auto;
    background: ${rgba(colors.black, 0.5)};
    ${screenGreaterThanMinHeight(`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `)}
`;

export const Wrapper = styled.div`
    background-color: white;
    width: 640px;
    position: relative;
    max-width: 100%;
    margin: auto;
    height: ${(props) => getBaseBodyHeight(props) + 113}px;
    max-height: 100%;
    min-height: ${(props) => getBaseBodyHeight(props) + 20}px;
    border-radius: 2px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
    ${media.forMobile`
        width: 100%;
        min-width: 328px;
        min-height: ${(props) => getBaseBodyHeight(props, true) + 16}px;
        height: 100vh;
        border-radius: 0;
    `}
`;

const bodySize = css`
    height: ${(props) => getBaseBodyHeight(props)}px;
    width: 520px;
    ${media.forMobile`
        height: ${(props) => getBaseBodyHeight(props, true)}px;
        width: 312px;
    `}
`;

const buttonIconColor = css`
    fill: ${rgba(colors.paynesGrey, 0.6)};
    &:hover {
        fill: ${colors.grape};
    }
    &:active {
        fill: ${colors.persianBlue};
    }
`;

export const Body = styled.div`
    display: flex;
    z-index: 100;
    ${bodySize}
`;

export const CloseContainer = styled.div`
    position: absolute;
    ${bodySize}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-self: stretch;
    color: ${colors.nightRider};
    background-color: white;
    // помните, прежде чем трогать тут шрифт, следует учесть что это корень wt-auth
    font: normal 12px Tahoma, sans-serif;;
    * {
        box-sizing: border-box;
    }
`;

export const CloseIcon = styled(TimesIcon)`
    ${buttonIconColor}
`;

export const BackIcon = styled(Back)`
    ${buttonIconColor}
`;

export const CloseButton = styled.button`
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: 0;
    padding: 0;
    cursor: pointer;
    z-index: 105;
    &:hover ${CloseIcon} {
        color: ${colors.grape};
    }
    &:active ${CloseIcon} {
        color: ${colors.persianBlue};
    }
`;

export const BackButton = styled.button`
    outline: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 105;
    border: 0;
    background: 0;
    padding: 0;
    cursor: pointer;
    &:hover ${CloseIcon} {
        color: ${colors.grape};
    }
    &:active ${CloseIcon} {
        color: ${colors.persianBlue};
    }
`;
