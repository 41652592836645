/* eslint-disable */
// по https://jira.finam.ru/browse/WTT-8652 переключили на новый сервис там инструменты
// возвращаются в другом формате
// тут мапим результат запроса для дальнейшего отображения
// что приходит
// https://git.finam.ru/projects/SER/repos/proto-repo/browse/grpc-reference/src/main/proto/proto/reference/security.proto
// формат данных, которые ожмлает отображение
// cfi: "ESVUFR"
// cfi_category: "EQUITIES"
// city: "NEW YORK"
// continent: ""
// country: "US"
// currency: "USD"
// cusip: "30303M102"
// decimal_place: "4"
// group_id: []
// id: "181868"
// indexes: []
// isin: "US30303M1027"
// lot_size: "1"
// market_capitalization: "627303939701"
// mic: "XNGS"
// name: "Facebook, Inc."
// quote: {
    // chg: 0.988127154346996
    // close: 261.1
    // last: 263.68
    // last_time: "1611146197069"
    // price: 261.1
    // usd_multiplier: 1
    // volume: 54070
    // w52high: 304.67
    // w52low: 137.19
// }
// chg: 0.988127154346996
// close: 261.1
// last: 263.68
// last_time: "1611146197069"
// price: 261.1
// usd_multiplier: 1
// volume: 54070
// w52high: 304.67
// w52low: 137.19
// regime: "USM"
// shares_outstanding: "2402542856"
// ticker: "FB"
// weight: 3
/* eslint-enable */
export const instrumentResultMapper = (instruments, lcid, logger = { error: () => null }) => {
    if (!instruments) {
        return null;
    }
    const lang = {
        ru: 1,
        en: 2,
    };
    const currencyCodes = {
        643: 'RUB',
        840: 'USD',
        978: 'EUR',
        392: 'JPY',
        156: 'CNY',
    };

    const decodePrice = (price, scale) => price / (10 ** scale);
    const nameBuilder = (ticker, mic) => `${ticker}:${mic}`;
    let mappedInstruments = null;
    try {
        mappedInstruments = instruments
            .filter(instrument => (
                instrument
                    // TODO Этих полей всегда нет, и возвращается всегда пустой массив, надо разобраться с форматом
                && typeof instrument.security?.common?.ticker !== 'undefined'
                && typeof instrument.security?.common?.securityId !== 'undefined'
                ))
            .map((instrument) => {
                const { security: { common } } = instrument;
                return {
                    id: common.securityId,
                    name: common.names[lang[lcid]] || common.names[2] || nameBuilder(common.ticker, common.mic),
                    mic: common.mic,
                    ticker: common.ticker,
                    currency: currencyCodes[common.quoteCurrency],
                    decimal_place: common.decimals,
                    quote: {
                        price: decodePrice(
                            instrument.quote?.price?.num,
                            instrument.quote?.price?.scale,
                        ),
                        chg: instrument.quote?.priceChangePercent ? decodePrice(
                            instrument.quote?.priceChangePercent?.num,
                            instrument.quote?.priceChangePercent?.scale,
                        ) : null,
                        last: instrument.quote?.last ? decodePrice(
                            instrument.quote?.last?.num,
                            instrument.quote?.last?.scale,
                        ) : null,
                    },
                };
            });
    } catch (error) {
        logger.error(`Search mapping error ${error}`);
    }
    return mappedInstruments;
};
