import {
    whotradesLoguotRequest,
    windowReload,
} from '../actions';

import wtAuthModuleCall from '../services/wtAuthModule';
import googleAnalyticsService from '../services/googleAnalytics';
import { getGlobalAuthState } from '../services/globalAuthState';

const globalAuthState = getGlobalAuthState();

const callbacks = {
    onAfterAuthentication: (isBackgroundTab) => {
        // if true reload window
        // vls: если WT Auth попап открыт, то перезагрузка будет реализована в рамках события onWtAuthIsClosed
        if (window.__GLOBAL_AUTH__ && isBackgroundTab) {
            windowReload('onAfterAuthentication');
        } else if (isBackgroundTab && (!window.WT.auth || !window.WT.auth.isInitialized() || !window.WT.auth.getCurrentPopupId())) {
            windowReload('onAfterAuthentication');
        }
    },
    onAfterAnalysisResult: (result) => {
        window.open(result.diagnosisUrl, '_blank');
    },
    onAfterLogout: () => {
        windowReload('onAfterLogout');
    },
    onWtAuthIsClosed: (params) => {
        // vls: скорее всего, временное решение, пока стороние сайты сами не будут управлять поведением после
        // аутентификации/регистрации
        if (params.registered || params.authenticated) {
            windowReload('onWtAuthIsClosed');
        }
    },
    clickLoginButton: (displayParams = [], context = undefined, contextParams = {}, { store }) => {
        wtAuthModuleCall((auth) => {
            if (window.__GLOBAL_AUTH__) {
                return;
            }
            auth.initIfNeed(true).then((auth) => {
                // vls: если сразу после инициализации попап открыт, то значит он открылся автоматически и контекст
                // должен остаться старый
                if (!auth.getCurrentPopupId()) {
                    auth.setContext(context, contextParams);
                }
            });
        });

        wtAuthModuleCall((auth) => {
            if (window.__GLOBAL_AUTH__) {
                if (!globalAuthState.isActive) {
                    auth.show('authorization');
                }
            }
        });
    },
    onWtAuthIsShown: () => {},
    clickRegisterButton: (displayParams = {}, context = undefined) => {
        wtAuthModuleCall((auth) => {
            auth.initIfNeed(true).then((auth) => {
                if (window.__GLOBAL_AUTH__) {
                    if (globalAuthState.isActive) {
                        return;
                    }
                    const CONTEXT2REASON_MAP = {
                        'feed-settings-panel': 'settings',
                        'feed-recommendations': 'settings',
                        joinMessenger: 'messenger',
                        'direct-messenger': 'messenger',
                        addActivity: 'social',
                        sysAddActivityUnclosed: 'social',
                        post_comment: 'social',
                        replyComment: 'social',
                        addComment: 'social',
                        comment: 'social',
                        like: 'vote',
                        dislike: 'vote',
                        share: 'vote',
                        forecastBtnAgree: 'vote',
                        forecastBtnDisagree: 'vote',
                    };
                    if (context in CONTEXT2REASON_MAP) {
                        auth.show('unauthorizedUser', { mode: CONTEXT2REASON_MAP[context] });
                    } else if (displayParams && displayParams.email) {
                        auth.show('registrationEmail', { email: displayParams.email });
                    } else {
                        auth.show('registration');
                    }
                }
            });
        });
    },
    clickLogoutButton: (from, { store }) => {
        if (from === 'wtMenu') {
            googleAnalyticsService.sendEvent('wt-menu', 'click-logout');
        }

        store.dispatch(whotradesLoguotRequest());
    },
    onUserMenuVisibleChanged: ({ isVisible }) => {
        // Класс добавляется по просьбе коллег (WTT-9996), но в дальнейшем,
        // вероятно, следует переложить это на них и давать только коллбек
        const menuRootElement = document.getElementById('wt-menu-module');
        if (!menuRootElement) {
            return;
        }
        if (isVisible) {
            menuRootElement.classList.add('user-menu-is-visible');
        } else {
            menuRootElement.classList.remove('user-menu-is-visible');
        }
    },
};
export default callbacks;
