import { useToggle } from 'hooks/useToogle';

export const useCollapse = (
    fieldRef: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null | undefined>,
    initialValue: boolean,
    fetchMentionList: () => void,
) => {
    const [collapsed, toggleCollapsed] = useToggle(initialValue);

    const close = () => {
        toggleCollapsed(true);
        fieldRef.current?.blur();
    };

    const open = () => {
        toggleCollapsed(false);
        fetchMentionList();
        if (fieldRef?.current) {
            fieldRef.current.focus();
            const valueLength = fieldRef.current.value.length;
            fieldRef.current.setSelectionRange(valueLength, valueLength);
        }
    };

    return {
        collapsed, open, close,
    };
};
