import { diff } from 'deep-object-diff';

const compareObjects = (firstObject, secondObject) => {
    const diffArr = Object.keys(diff(firstObject, secondObject));
    return {
        areEqual: !diffArr.length > 0,
        diff: diffArr,
    };
};

export default compareObjects;