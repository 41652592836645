import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { CloseIcon } from 'components/icons2/CloseIcon';

import { NotificationAvatar } from './NotificationAvatar';

import * as Styled from './styled';

import { NotificationPropsType } from './types';

import DetailsSkeletonSvg from './DetailsSkeleton.svg';

export const Notification = (props: NotificationPropsType) => {
    const {
        title,
        message,
        eventTimeText,
        actionButton,
        avatar,
        avatarBadge,
        isUnread = false,
        isLoading = false,
        className,
        width,
        // ---Actions---
        onClick: notificationOnClick,
        remove,
    } = props;

    const [isHover, setIsHover] = useState(false);
    const theme = useTheme();
    const isActiveShowFull = false;
    // в дизайне есть, но пока не используем
    // какие ограничения по строке?

    const haveNotificationClick = !isLoading && notificationOnClick;

    const notificationOnMouseEnter = () => !isLoading && setIsHover(true);
    const notificationOnMouseLeave = () => !isLoading && setIsHover(false);
    const actionButtonPressed = typeof actionButton?.pressed === 'boolean' ? actionButton?.pressed : true;

    return (
        <Styled.NotificationContainer
            className={['autotest__notification', className].filter((item) => item).join('')}
            onClick={haveNotificationClick ? notificationOnClick : undefined}
            isLoading={isLoading}
            tabIndex={0}
            onMouseEnter={notificationOnMouseEnter}
            onMouseLeave={notificationOnMouseLeave}
            width={width}
        >
            <Styled.Wrapper tabIndex={-1}>
                <Styled.IndicatorColumn>
                    <Styled.Indicator isActive={isUnread && !isLoading} />
                </Styled.IndicatorColumn>

                <Styled.AvatarColumn>
                    <Styled.AvatarWrapper>
                        <Styled.AvatarContentWrapper
                            withBorder={isLoading ? false : avatar?.type === 'image'}
                        >
                            {!isLoading && avatar && (
                                <Styled.AvatarContent
                                    backgroundColor={
                                        (avatar && avatar.type === 'icon' && avatar?.backgroundColor)
                                        || undefined
                                    }
                                >
                                    <NotificationAvatar avatar={avatar} />
                                </Styled.AvatarContent>
                            )}
                        </Styled.AvatarContentWrapper>
                        {!isLoading && avatarBadge && (
                            <Styled.AvatarBadge>
                                <Styled.AvatarBadgeIconWrapper
                                    backgroundColor={(avatarBadge && avatarBadge?.backgroundColor) || undefined}
                                >
                                    {
                                        typeof avatarBadge.Icon === 'function'
                                            ? React.createElement(
                                                avatarBadge.Icon, {
                                                    size: 12,
                                                    fill: theme.bg.secondary,
                                                }, null,
                                            )
                                            : avatarBadge.Icon
                                    }
                                </Styled.AvatarBadgeIconWrapper>
                            </Styled.AvatarBadge>
                        )}
                    </Styled.AvatarWrapper>
                </Styled.AvatarColumn>

                <Styled.ContentColumn>
                    {isLoading
                        ? (<DetailsSkeletonSvg />)
                        : (
                            <>
                                {title && (
                                    <Styled.TitleWrapper>
                                        {typeof title === 'string' ? <span>{title}</span> : title}
                                    </Styled.TitleWrapper>
                                )}

                                {message?.text && (
                                    <Styled.MessageWrapper>
                                        <Styled.Message
                                            lineClamp={message?.lineClamp || undefined}
                                        >
                                            {message.text}
                                        </Styled.Message>
                                        {
                                            isActiveShowFull && (
                                                <Styled.ShowFull
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="autotest__notification__show-full-btn"
                                                >
                                                    Показать полностью
                                                </Styled.ShowFull>
                                            )
                                        }
                                    </Styled.MessageWrapper>
                                )}

                                {eventTimeText && (
                                    <Styled.EventTimeText>
                                        {
                                            typeof eventTimeText === 'string'
                                                ? <span>{eventTimeText}</span>
                                                : eventTimeText
                                        }
                                    </Styled.EventTimeText>
                                )}

                                {actionButton && (
                                    <Styled.ActionButtonWrapper>
                                        <Styled.ButtonAction
                                            pressed={actionButtonPressed}
                                            text={actionButton.text}
                                            title={actionButton?.title}
                                            size="M"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                actionButton?.onClick?.(e);
                                            }}
                                            kind={ButtonIconKinds.FillSecondary}
                                            icon={actionButton?.icon}
                                            className={`autotest__notification__action-btn${
                                                actionButton?.className ? ` ${actionButton?.className}` : ''
                                            }`}
                                        />
                                    </Styled.ActionButtonWrapper>
                                )}
                            </>
                        )}
                </Styled.ContentColumn>

                {!isLoading && !!remove && (
                    <Styled.ButtonColumn isHiddenTabletAndMore={!isHover}>
                        <ButtonIcon
                            kind={ButtonIconKinds.GhostPrimary}
                            size="M"
                            icon={{ component: CloseIcon }}
                            onClick={(e) => {
                                e.stopPropagation();
                                remove();
                            }}
                            className="autotest__notification__delete-btn"
                        />
                    </Styled.ButtonColumn>
                )}
            </Styled.Wrapper>
        </Styled.NotificationContainer>
    );
};
