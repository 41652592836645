import React, {
    useCallback,
    useMemo,
    useState,
    useEffect,
    useRef,
    useLayoutEffect,
} from 'react';
import { createPortal } from 'react-dom';
import { differenceInHours, parseISO } from 'date-fns';

import { FeedRecordComment, FeedRecordObjTypes, Id } from 'types';
import Dic from 'services/dictionary';
import textContent from 'services/utils/text-content';
import mentionLinksToText from 'services/utils/mention-links-to-text';
import { sendGA } from 'services/google-analytics';
import { deletedUserTagToText } from 'services/utils/deleted-user-tag-to-text';
import { useAgo } from 'hooks/useAgo';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLocaleId } from 'hooks/useLocaleId';
import { useScrollToPosition } from 'hooks/useScrollToPosition';
import { Button } from 'components/button2';
import { UserBlock } from 'components/user-block';
import { ButtonIconKinds, MenuItem } from 'components/context-actions';
import Gallery from 'components/gallery/Gallery';
import { MentionUser, RootObject } from '../types';
import {
    SaveComment, DeleteComment, RestoreComment, CommentsFilters,
} from '../actions';
import { LikeComment } from '../../like-comment';
import { ModerationEditItemButton } from '../../moderation-components/ModerationEditItemButton';

import { CommentForm } from './Form';
import { ReplyComment } from './ReplyComment';
import {
    ContextMenuWrapper,
    LocalDeletedWrapper,
    LocalDeletedText,
    DeletedRestore,
    Actions,
    Content,
    TimeAgoLink,
    DeletedTimeAgo,
    Wrapper,
    Head,
    CommentContextActions,
    Image,
    ImageWrapper,
    DeletedText,
    DeletedDescription,
    DeletedAvatar,
    DeletedHead,
    ShowFull,
} from './styled/Comment';
import {useCSRFToken} from "hooks/useCSRFToken";

interface Props {
    recordId: Id,
    comment: FeedRecordComment,
    comments: FeedRecordComment[],
    forceLevel: FeedRecordComment['level'] | null,
    rootObject: RootObject,
    saveComment: SaveComment,
    deleteComment: DeleteComment,
    restoreComment: RestoreComment,
    mentionList: MentionUser[],
    fetchMentionList: () => void,
    openedFormId: string | null,
    setOpenedFormId: (x: string | null) => void,
    isMarked?: boolean,
    pinned?: boolean,
    placeholder?: string,
    commentsFilter: CommentsFilters,
}
export const Comment = (props: Props) => {
    const {
        recordId,
        forceLevel,
        comment,
        rootObject,
        saveComment,
        deleteComment,
        restoreComment,
        fetchMentionList,
        mentionList,
        comments,
        openedFormId,
        setOpenedFormId,
        isMarked,
        pinned,
        placeholder,
        commentsFilter,
    } = props;
    const {
        content, author, id, stats: { likes }, date: { created }, href, level, image, realLikeCount, mpath,
    } = comment;

    const lcid = useLocaleId();
    const currentUser = useCurrentUser();
    const csrf = useCSRFToken();

    const { timeAgo, localeTimeString } = useAgo(created, { lcid });
    const [isEditing, setIsEditing] = useState(false);
    const [isFull, setIsFull] = useState(false);

    const finishEditing = () => {
        setIsEditing(false);
        setOpenedFormId(null);
    };

    const currentFormId = comment?.mpath || recordId.toString();
    const replyFormVisible = openedFormId && (openedFormId === currentFormId);

    useEffect(() => {
        if (!replyFormVisible) {
            setIsEditing(false);
        }
    }, [replyFormVisible]);

    const userCanEditComment = () => {
        const isSameCurrentUserAndCommentAuthor = author && `${currentUser.id}` === `${author.id}`;
        const hasDifferenceInHoursNowAndCreatedDateComment = differenceInHours(Date.now(), parseISO(created)) < 1;

        return isSameCurrentUserAndCommentAuthor && hasDifferenceInHoursNowAndCreatedDateComment;
    };

    const editButtonHandler: React.MouseEventHandler = (e) => {
        e.preventDefault();
        setIsEditing(true);
        setOpenedFormId(currentFormId);
        setIsFull(true);
    };

    const deleteButtonHandler: React.MouseEventHandler = () => {
        deleteComment(id, csrf, { commentId: id, recordId });
    };

    const commentForRender = useMemo(() => {
        return {
            ...comment,
            content: textContent(mentionLinksToText(comment.content)),
            rawContent: comment.content,
        };
    }, [comment]);

    const handleClickTimeAgo = useCallback(() => {
        sendGA('feedRecord', ['comment-time-ago-click']);
    }, []);

    const contentRef = useRef<HTMLDivElement>(null);

    const handleClickShowFull = useCallback(() => {
        if (contentRef.current) {
            contentRef.current.style['-webkit-line-clamp'] = '';
            setIsFull(true);
        }
    }, []);

    /** Чтобы избежать лишних рендеров, вычисляем количество строк для показа и видимость кнопки "Показать полностью"
     * в useLayoutEffect который выполняется один раз на маунте компонента. Отслеживать изменения ширины колонки или текста
     * комментария для пересчета этих параметров считаю излишним */
    useLayoutEffect(() => {
        if (contentRef.current) {
            const contentWidth = contentRef.current.getBoundingClientRect().width;
            /** В дизайне на ширине колонки 712 комментарий сокращается до 5 строк.
             * При уменьшении ширины количество строк пропорционально растет */
            contentRef.current.style['-webkit-line-clamp'] = Math.round((712 * 5) / contentWidth).toString();
            /** Проверяем нужно ли показывать кнопку "Показать полностью" */
            const isTextClamped = contentRef.current.scrollHeight > contentRef.current.clientHeight;
            if (!isTextClamped) {
                setIsFull(true);
            }
        }
    }, []);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const wrapperOffsetTop = wrapperRef?.current?.offsetTop || null;

    const scrollToPosition = useScrollToPosition(wrapperOffsetTop);
    // Проскролливаемся к закрепленной записи
    useEffect(() => {
        if (pinned && mpath) {
            setOpenedFormId(mpath);
            scrollToPosition();
        }
    }, []);

    useLayoutEffect(() => {
        if (isMarked && wrapperRef.current) {
            const wrapper = wrapperRef.current;
            /** Timeout чтобы новый коммент успел схлопнуться если они длинный (см. useLayoutEffect выше) */
            setTimeout(() => {
                wrapper.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }, 0);
        }
    }, [isMarked]);

    const commentLevel = forceLevel ?? (Math.min(level, 1) as FeedRecordComment['level']);
    const isComment = (Math.min(level, 1) as FeedRecordComment['level']) === 0;

    const [showGallery, setShowGallery] = useState(false);
    const handleShowGallery = useCallback(() => {
        setShowGallery(true);
    }, []);
    const handleHideGallery = useCallback(() => {
        setShowGallery(false);
    }, []);

    if (comment.isLocalDeleted || comment.isDeleted) {
        const hasChild = comments?.some((x) => Number(x.parentId) === Number(comment.id));

        if (comment.isLocalDeleted) {
            return (
                <LocalDeletedWrapper level={commentLevel}>
                    <LocalDeletedText>
                        {
                            isComment
                                ? `${Dic.word('wt_feed__widget_comments__label_deleted')}.`
                                : `${Dic.word('wt_feed__widget_comments__label_answer_deleted')}.`
                        }
                    </LocalDeletedText>
                    <DeletedRestore onClick={() => restoreComment(id, { commentId: id, recordId })}>
                        {Dic.word('wt_feed__widget_comments__label_restore')}
                    </DeletedRestore>
                </LocalDeletedWrapper>
            );
        }

        if (hasChild && isComment) {
            return (
                <Wrapper level={commentLevel}>
                    <DeletedHead>
                        <DeletedAvatar />
                        <DeletedDescription>
                            <DeletedText>
                                {Dic.word('wt_feed__widget_comments__label_deleted')}
                            </DeletedText>
                            <DeletedTimeAgo>
                                {timeAgo}
                            </DeletedTimeAgo>
                        </DeletedDescription>

                    </DeletedHead>
                </Wrapper>
            );
        }
    }

    if (comment.isDeleted) {
        return null;
    }

    return (
        <Wrapper level={commentLevel} isMarked={isMarked} ref={wrapperRef} data-comment-id={id}>
            <Head>
                <UserBlock
                    user={author || { displayName: Dic.word('wt_feed__widget_comments__user_deleted') }}
                    secondLine={(
                        <TimeAgoLink href={href} target="_blank" onClick={handleClickTimeAgo} title={localeTimeString}>
                            {timeAgo}
                        </TimeAgoLink>
                    )}
                />
                {(userCanEditComment() && (
                    <CommentContextActions
                        kind={ButtonIconKinds.GhostSecondary}
                        tippyContent={
                            (
                                <ContextMenuWrapper>
                                    <MenuItem
                                        key="edit"
                                        text={Dic.word('wt_feed__widget_comments__label_edit')}
                                        onClick={editButtonHandler}
                                    />
                                    <MenuItem
                                        key="delete"
                                        text={Dic.word('wt_feed__widget_comments__label_delete')}
                                        onClick={deleteButtonHandler}
                                    />
                                </ContextMenuWrapper>
                            )
                        }
                    />
                ))}
            </Head>
            {isEditing ? (
                <CommentForm
                    recordId={recordId}
                    saveComment={saveComment}
                    comment={commentForRender}
                    currentComment={commentForRender}
                    onSave={finishEditing}
                    onDismiss={finishEditing}
                    fetchMentionList={fetchMentionList}
                    mentionList={mentionList}
                    openedFormId={openedFormId}
                    setOpenedFormId={setOpenedFormId}
                    placeholder={placeholder}
                    commentsFilter={commentsFilter}
                />
            ) : (
                <>
                    <Content
                        isFull={isFull}
                        ref={contentRef}
                        dangerouslySetInnerHTML={{ __html: deletedUserTagToText(content) }}
                    />
                    {!isFull && (
                        <ShowFull
                            onClick={handleClickShowFull}
                        >
                            {Dic.word('wt_feed__widget_content_html__see_full')}
                        </ShowFull>
                    )}
                    {image && (
                        <ImageWrapper>
                            <>
                                <Image src={image} onClick={handleShowGallery} />
                                {showGallery && image && createPortal(<Gallery imgList={[{ index: 0, src: image }]} numSlide={0} onClose={handleHideGallery} />, document.body)}
                            </>
                        </ImageWrapper>
                    )}
                    {!comment.isDeleted && (
                        <Actions className="adm__comment-activity">
                            <div>
                                <LikeComment
                                    objId={id}
                                    objType={FeedRecordObjTypes.COMMENT}
                                    recordId={recordId}
                                    userChoice={likes.userChoice}
                                    count={realLikeCount}
                                    size="M"
                                />
                                <ModerationEditItemButton id={comment.id} type="comment" />
                            </div>
                            <div>
                                <Button
                                    kind="ghost-secondary"
                                    size={{ default: 'small', lt480: 'tiny' }}
                                    onClick={() => setOpenedFormId(currentFormId)}
                                >
                                    {Dic.word('wt_feed__widget_comments__reply_comment')}
                                </Button>
                            </div>
                        </Actions>
                    )}
                    {replyFormVisible && (
                        <ReplyComment
                            recordId={recordId}
                            rootObject={rootObject}
                            saveComment={saveComment}
                            comment={comment}
                            fetchMentionList={fetchMentionList}
                            mentionList={mentionList}
                            openedFormId={openedFormId}
                            setOpenedFormId={setOpenedFormId}
                        />
                    )}
                </>
            )}
        </Wrapper>
    );
};
