import React, { ReactNode, useMemo } from 'react';

import { Lcid } from 'types';
import { CurrencyCode } from 'types/currency';
import { useLocaleId } from 'hooks/useLocaleId';

export type PriceFormatterProps = {
    price: number;
    currency: CurrencyCode | null;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    children: (localizedPrice: string) => ReactNode;
};

/**
 * Provides a locale-formatted price string via children render prop.
 *
 * @example
 * <PriceFormatter price={31} currency="USD">
 *    {(localizedPrice) => <div>Price: {localizedPrice}</div>}
 * </PriceFormatter>
 */
export const PriceFormatter = ({
    children,
    price,
    currency,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
}: PriceFormatterProps) => {
    const lcid = useLocaleId();

    const localizedPrice = useMemo(
        () => (currency
            ? formatPriceCurrencyByLocale({
                price,
                currency,
                locale: lcid,
                minimumFractionDigits,
                maximumFractionDigits,
            })
            : `${price}`),
        [currency, lcid, maximumFractionDigits, minimumFractionDigits, price],
    );

    return <>{children(localizedPrice)}</>;
};

type FormattingSettings = {
    price: number;
    currency: string;
    locale: Lcid;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
};

export function formatPriceCurrencyByLocale({
    price,
    currency,
    locale,
    minimumFractionDigits,
    maximumFractionDigits,
}: FormattingSettings) {
    try {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
        });

        const formattedString = formatter.format(price);
        if (locale === 'ru' && currency === 'RUB') {
            formattedString.replace('RUB', '₽');
        }

        return formattedString;
    } catch (error) {
        return price ? [price, currency].filter((val) => val).join(' ') : '';
    }
}
