import { css } from 'styled-components';

import { CSSLiteral } from 'app/styled';
import { TabProps, TabsSize } from '../props';

export type TabsListItemProps = Pick<TabProps, 'isActive' | 'type' | 'kind' | 'iconPos' | 'icon' | 'count' | 'title' | 'size'>;
export type TabsState = 'common' | 'hover' | 'active'; // | 'activeFocused' | 'inactiveFocused';
export type StateMap = Record<TabsState, CSSLiteral<TabsListItemProps>>;
export type SizeMap = Record<TabsSize, CSSLiteral>;

export function paddingLeftRightStyle({ icon, title, iconPos }: TabsListItemProps) {
    const hasIconAndText = icon && title;
    const hasOnlyIcon = icon && !title;
    const hasOnlyText = !icon && title;

    if (hasIconAndText) {
        const [first, second] = iconPos === 'left' ? ['left', 'right'] : ['right', 'left'];
        return css`
            padding-${first}: calc(var(--padding-horizontal) - var(--padding-icon-compensation));
            padding-${second}: var(--padding-horizontal);
        `;
    }

    if (hasOnlyIcon || hasOnlyText) {
        return css`
            padding-left: var(--padding-horizontal);
            padding-right: var(--padding-horizontal);
        `;
    }

    return undefined;
}
