import UrlProcessor from 'services/url-processor';
import ProjectConfig from 'services/config';

export class User {
    constructor(data) {
        this.update(data);
    }

    get url() {
        // sob: DEPRECATED! is used for WT only
        if (this.id && !window.WT?.Page) {
            return UrlProcessor
                .page('groups_item_activity_stream_people_timeline')
                .params({ person_id: this.id })
                .url();
        }
        return this._url || (this.id ? `/profile/${this.id}` : '');
    }

    set url(value) {
        this._url = value;
    }

    get smallImage() {
        if (this.image) {
            return this.image.replace('medium.jpeg', 'small.jpeg');
        }

        return null;
    }

    get isAuth() {
        return (this._isAuth ?? Boolean(this.id));
    }

    set isAuth(value) {
        this._isAuth = value;
    }

    isSame(idOrUser) {
        // [uU]ser\.id\}?`? [\!\=]
        return Boolean(this.id && (+this.id === +idOrUser || +this.id === +idOrUser.id));
    }

    update(data) {
        if (!data) {
            return;
        }

        const displayName = data.displayName || data.fullname || data.name;
        const image = data.image || data.avatar;
        const id = data.id || null;
        const isVerifiedAuthor = data.isVerifiedAuthor || data.WTuser?.isVerifiedAuthor;

        Object.assign(this, {...data, id, displayName, image, isVerifiedAuthor});
    }
}

export const user = new User(ProjectConfig.user);
