export const storageProvider = storageType => ({
    set(key, value, ttl = null) {
        const dateNow = new Date();
        const item = {
            value,
            expire: ttl ? dateNow.getTime() + (ttl * 1000) : null,
        };
        storageType.setItem(key, JSON.stringify(item));
    },
    get(key) {
        const itemJson = storageType.getItem(key);
        if (!itemJson) {
            return null;
        }

        const item = JSON.parse(itemJson);
        // if no ttl was set no need to worry about it
        if (!item.expire) {
            return item.value;
        }

        const dateNow = new Date();

        if (dateNow.getTime() > item.expire) {
            this.delete(key);
            return null;
        }

        return item.value;
    },
    delete(key) {
        storageType.removeItem(key);
    },
});
