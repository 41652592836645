import { useCallback, useEffect } from 'react';

import {StatsResult, FeedRecordSource, Id, FeedRecord} from 'types';
import { socket } from 'services/webscokets';
import { sendGA } from 'services/google-analytics';
import statsViewedProcessor from '../services/stats-viewed';

const sendMethod = (userId: Id, userSign: string, source: FeedRecordSource, stats: StatsResult) => {
    const data = {
        personId: userId,
        ps: userSign,
        method: 'recordStats',
        params: { source, stats },
    };
    socket.publish(data);
    sendGA('feedRecords', ['send-stats', JSON.stringify({ source, stats })]);
};

export const useStats = ({ userId, userSign, source }: { userId: Id, userSign: string, source: FeedRecordSource; }, isAuth: boolean) => {
    useEffect(() => {
        if (!isAuth) return () => {};
        statsViewedProcessor.startSend((data: StatsResult) => {
            sendMethod(userId, userSign, source, data);
        });
        return () => statsViewedProcessor.stopSend();
    }, [isAuth]);

    return {
        onIntersection: useCallback((item: IntersectionObserverEntry, record: FeedRecord) => {
            if (!isAuth) return;
            if (item.isIntersecting) {
                statsViewedProcessor.add(record.id);
            } else {
                statsViewedProcessor.delete(record.id);
            }
        }, [isAuth]),
        onRecordShowFull: useCallback((recordId: Id) => {
            if (!isAuth) return;
            statsViewedProcessor.onRecordShowFull(recordId);
        }, [isAuth]),
    };
};
