import React, { useRef } from 'react';

import { Tab } from './Tab';
import { TabsListWrapper } from './styled/Tabs';
import { TabsProps, TabsItem, TabsSize } from './props';

export type { TabsProps, TabsItem, TabsSize };

export function Tabs({
    type = 'standard',
    kind = 'primary',
    size = 'large',
    className = '',
    items,
    selectedItemIndex,
    selectedItemId,
    onItemSelected,
    scrollToActiveElementOnMobile = true,
}: TabsProps) {
    const tabListRef = useRef<HTMLUListElement | null>(null);

    return (
        <TabsListWrapper
            type={type}
            size={size}
            className={className}
            ref={tabListRef}
        >
            {items.map((item, index) => {
                const {
                    title,
                    id,
                    icon,
                    count,
                    href,
                    onClick,
                    iconPos,
                    autotestClass,
                    hidden,
                } = item;

                if (hidden) return null;

                const isActive = (selectedItemIndex === index)
                    || (selectedItemId !== undefined && selectedItemId === id);

                return (
                    <Tab
                        id={id}
                        key={title || id}
                        type={type}
                        kind={kind}
                        isActive={isActive}
                        onMount={(tabElement, tabItemProps) => {
                            if (tabItemProps.isActive && scrollToActiveElementOnMobile) {
                                const scrollToPosX = tabElement?.current?.offsetLeft;
                                if (scrollToPosX) tabListRef?.current?.scrollTo?.({ left: scrollToPosX });
                            }
                        }}
                        icon={icon}
                        title={title}
                        count={count}
                        href={href}
                        autotestClass={autotestClass}
                        size={size}
                        iconPos={iconPos}
                        onClick={(e) => {
                            onItemSelected?.(index, id);
                            onClick?.(e);
                        }}
                    />
                );
            })}
        </TabsListWrapper>
    );
}
