import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import urlMap from 'url-map';
import store from 'app/store2';
import { FeedContextValue } from 'context/feed';
import { WatchlistContextProvider, WatchlistContextValue } from 'context/watchlist';
import { shallowCamelizeKeys } from 'services/utils/replace-keys';

import {
    createPage,
    InitPageConfiguration,
} from 'services/init-page';

import LimexMenu from 'widgets/limex-menu';
import { WidgetsRawData } from './types';
import { FeedPage as FeedPageComponent, initPageWidgetsData } from './page';

type FeedPageExtension = {
    feedContext: FeedContextValue,
    watchlistContext: WatchlistContextValue
};

const configuration: InitPageConfiguration<WidgetsRawData> = {
    mountPoint: () => document.getElementById('feedPage'),
    mockHandlers: () => {
        return import(/* webpackChunkName: "api-mocks" */ './api-mocks')
            .then(({ getHandlers }) => getHandlers())
            .then((handlers) => ({ handlers }));
    },
    urlMap,
    dicwords: process.env.DICWORDS,
    extendLegacyConfig: (initialSettings) => {
        if (!initialSettings.pconfig.charts) {
            throw new Error('initialSettings.pconfig.charts isn\'t set');
        }

        const { server, serverSlow } = initialSettings.pconfig.charts;

        return {
            charts: {
                server: shallowCamelizeKeys(server),
                slowServer: shallowCamelizeKeys(serverSlow),
            },
        };
    },
};

const page = createPage<WidgetsRawData, FeedPageExtension>('FeedPage', configuration, (initialSettings) => {
    initPageWidgetsData({ feed: initialSettings.data.feed });

    return (
        <>
            <LimexMenu />
            <ReduxProvider store={store}>
                <WatchlistContextProvider value={initialSettings.watchlistContext}>
                    <FeedPageComponent feedContext={initialSettings.feedContext} />
                </WatchlistContextProvider>
            </ReduxProvider>
        </>
    );
});

page.saveAndRenderIfNeeded();
