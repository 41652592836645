import React from 'react';
import PropTypes from 'prop-types';

import { realTimeInstrumentInfoLoad } from 'services/real-time-instrument-info/loader';
import { PERCENT_DCPL, STYLE } from '../constants';
import { InstrumentUI } from './instrument-ui';

export default class Instrument extends React.Component {
    constructor(props) {
        super(props);

        const isReady = (props.initPrice !== undefined && props.initChangePercent !== undefined && props.initDcpl !== undefined && props.initTicker !== undefined)
            || props.forceReady;

        this.state = {
            isReady,
            ticker: props.initTicker,
            price: props.initPrice,
            changePercent: props.initChangePercent,
            dcpl: props.initDcpl,
        };

        this._autoUpdateCb = null;
    }

    componentDidMount() {
        this._mounted = true;
        this._subscribeToRealTimeInstrumentUpdates();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { autoUpdate, finamId, actions } = this.props;
        if ((finamId === nextProps.finamId) && (autoUpdate === nextProps.autoUpdate && (actions === nextProps.actions))) {
            const { changePercent: changePercentRaw, price: priceRaw, dcpl } = this.state;

            const price = priceRaw.toFixed(dcpl);
            const changePercent = changePercentRaw.toFixed(PERCENT_DCPL);

            const nextPrice = nextState.price.toFixed(nextState.dcpl);
            const nextChangePercent = nextState.changePercent.toFixed(PERCENT_DCPL);

            if (price === nextPrice && changePercent === nextChangePercent) {
                // vls: только цена и процент ялвяются динамическими для рендеринга компонета
                return false;
            }
        }

        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.finamId !== this.props.finamId
            || prevProps.autoUpdate !== this.props.autoUpdate
        ) {
            this._unsubscribeFromRealTimeInstrumentUpdates();
            this._subscribeToRealTimeInstrumentUpdates();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
        this._unsubscribeFromRealTimeInstrumentUpdates();
    }

    _subscribeToRealTimeInstrumentUpdates() {
        const { autoUpdate, finamId } = this.props;
        if (autoUpdate) {
            this._autoUpdateCb = (quote) => {
                if (!this._mounted) {
                    return;
                }

                const state = {
                    dcpl: quote.dcpl,
                    price: quote.last,
                    changePercent: quote.lastChangePercent,
                };

                if (!this.state.ticker) {
                    // vls: тикер не может измениться во время автообновлений, так что заполняем его только, если отсутвует
                    state.ticker = `${CASH_TAG}${quote.ticker}`;
                }

                if (!this.state.isReady) {
                    state.isReady = true;
                }

                this.setState(state);
            };
            realTimeInstrumentInfoLoad().then(({ subscribeToRealTimeInstrumentInfo }) => {
                subscribeToRealTimeInstrumentInfo(finamId, this._autoUpdateCb);
            });
        }
    }

    _unsubscribeFromRealTimeInstrumentUpdates() {
        if (this._autoUpdateCb) {
            realTimeInstrumentInfoLoad().then(({ unsubscribeFromRealTimeInstrumentInfo }) => {
                const { finamId } = this.props;
                unsubscribeFromRealTimeInstrumentInfo(finamId, this._autoUpdateCb);
                this._autoUpdateCb = null;
            });
        }
    }

    render() {
        if (this.props.style === STYLE.CUSTOMIZED) return customizedRender(this.state, PERCENT_DCPL, this.props);
        const {
            changePercent, ticker, price, dcpl, isReady,
        } = this.state;

        if (!isReady) {
            return '';
        }

        const {
            url, logoUrl, name, classes, className, onClick, style, finamId, showPrice, willClose, as, tooltipEnabled,
            customizedRender, autoUpdate, currency, actions, isSuggestion,
        } = this.props;

        return (
            <InstrumentUI
                style={style}
                customizedRender={customizedRender}
                changePercent={changePercent}
                ticker={ticker}
                price={price}
                dcpl={dcpl}
                as={as}
                tooltipEnabled={tooltipEnabled}
                url={url}
                logoUrl={logoUrl}
                finamId={finamId}
                currency={currency}
                willClose={willClose}
                title={name}
                onClick={onClick}
                classes={classes}
                className={className}
                showPrice={showPrice}
                autoUpdate={autoUpdate}
                actions={actions}
                isSuggestion={isSuggestion}
            />
        );
    }
}

Instrument.propTypes = {
    finamId: PropTypes.number,
    currency: PropTypes.string,
    autoUpdate: PropTypes.bool,
    style: PropTypes.number,
    initTicker: PropTypes.string,
    initPrice: PropTypes.number,
    initChangePercent: PropTypes.number,
    initDcpl: PropTypes.number,
    showPrice: PropTypes.bool,
    name: PropTypes.string,
    classes: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    customizedRender: PropTypes.func,
    forceReady: PropTypes.bool,
    onClick: PropTypes.func,
    willClose: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]), // alp: flag for instrument tooltip, tooltip will be close after some action
    as: PropTypes.string,
    tooltipEnabled: PropTypes.bool,
    actions: PropTypes.shape({
        subscribe: PropTypes.element,
    }),
    isSuggestion: PropTypes.bool,
};

Instrument.defaultProps = {
    style: null,
    finamId: undefined,
    currency: '',
    autoUpdate: true,
    initTicker: undefined,
    initPrice: undefined,
    initChangePercent: undefined,
    initDcpl: undefined,
    showPrice: true,
    name: '',
    classes: '',
    className: '',
    onClick: undefined,
    url: '',
    logoUrl: '',
    customizedRender: undefined,
    forceReady: false,
    willClose: '',
    as: 'a',
    tooltipEnabled: true,
    actions: null,
    isSuggestion: false,
};
