import React from 'react';

import { ButtonIconKinds, Props as ButtonIconProps } from 'components/button-icon';
import { StarFillIcon } from 'components/icons2/StarFillIcon';
import { StarIcon } from 'components/icons2/StarIcon';
import { StyledButtonIcon } from './styled';

type Props = Pick<ButtonIconProps, 'onClick' | 'pressed' | 'className'>;

export const ButtonIconStar = (props: Props) => {
    const { onClick, className, pressed } = props;
    return (
        <StyledButtonIcon
            className={className}
            onClick={onClick}
            icon={{ component: pressed ? StarFillIcon : StarIcon }}
            size="S"
            kind={ButtonIconKinds.GhostPrimary}
        />
    );
};
