import React, { useEffect, useRef } from 'react';

import {
    AnchorStyled,
    BoxStyled,
} from './styled';

export type Props = {
    width?: string;
    left?: string;
    top?: string;
    include?: null | HTMLElement | HTMLElement[];
    isVisible: boolean;
    onClose?: () => void;
    children?: any;
};

export const Popover = (props: Props) => {
    const {
        width,
        left,
        children,
        isVisible,
        onClose,
        include,
        top,
    } = props;
    const rootRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!window || !document) {
            return () => undefined;
        }

        const checkIfHasChildElements = (targetElement: any) => {
            if (!rootRef.current) {
                return false;
            }
            if (rootRef.current.contains(targetElement)) {
                return true;
            }

            if (include) {
                if (Array.isArray(include)) {
                    return include.some((element) => element && element.contains(targetElement));
                }
                return include.contains(targetElement);
            }
            return false;
        };

        const clickHandler = (event: Event) => {
            if (!rootRef.current || !isVisible) {
                return;
            }

            if (!checkIfHasChildElements(event.target)) {
                onClose?.();
            }
        };

        const keyPressHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                onClose?.();
            }
        };

        document.addEventListener('mousedown', clickHandler);
        window.addEventListener('keydown', keyPressHandler);
        return () => {
            document.removeEventListener('mousedown', clickHandler);
            window.removeEventListener('keydown', keyPressHandler);
        };
    });

    const renderContent = () => {
        if (!isVisible) {
            return null;
        }

        return (
            <BoxStyled left={left} top={top} width={width}>
                {children}
            </BoxStyled>
        );
    };

    return (
        <AnchorStyled ref={rootRef}>
            {renderContent()}
        </AnchorStyled>
    );
};
