import React, {
    useEffect, useLayoutEffect, useRef,
} from 'react';
import { useTour } from '@reactour/tour';

import {
    TourProps,
    LS_KEY,
    steps,
} from './constants';

const OPEN_TOUR_TIMEOUT = 500;

export const useOpenTour = () => {
    const { setIsOpen } = useTour();
    const open = () => {
        setIsOpen(true);
    };
    return () => {
        if (document.readyState === 'complete') {
            open();
        } else {
            window.addEventListener('load', open);
        }
    };
};

export const TourStarter = ({ children, autoopenSteps }: TourProps) => {
    const {
        currentStep,
        setCurrentStep,
        setIsOpen,
    } = useTour();

    const initialStep = useRef(parseInt(localStorage.getItem(LS_KEY) || '0', 10));
    const openTour = useOpenTour();
    const openTourIncludedStepsRef = useRef(() => {
        // nothing
    });
    openTourIncludedStepsRef.current = () => {
        if (autoopenSteps.includes(currentStep)) {
            openTour();
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;

        function resizeHandler() {
            setIsOpen(false);
            clearTimeout(timer);
            timer = setTimeout(() => {
                openTourIncludedStepsRef?.current();
            }, OPEN_TOUR_TIMEOUT);
        }

        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    useLayoutEffect(() => {
        setCurrentStep(initialStep.current);
    }, []);

    useEffect(() => {
        if (autoopenSteps.includes(initialStep.current)) {
            openTour();
        }
        return () => {
            setIsOpen(false);
        };
    }, []);

    useEffect(() => {
        // dmh: закрыть тур, если мы ушли за последний шаг
        if (currentStep === steps.length) {
            setIsOpen(false);
        }
    }, [currentStep, setIsOpen]);

    return (
        <>
            {children}
        </>
    );
};
