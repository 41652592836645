import { useEffect } from 'react';
import { useWebsocketChannel } from 'hooks/useWebsocketChannel';

type PropsType = {
    channelName?: string,
    eventName: string,
    callback: (args?: any) => void,
};

export const useWebsocketListener = ({
    channelName,
    eventName,
    callback,
}:PropsType) => {
    const socketChannel = useWebsocketChannel(channelName);

    useEffect(() => {
        socketChannel.on(eventName, callback);
        return () => {
            socketChannel.off(eventName, callback);
        };
    },
    [socketChannel, eventName, callback]);
};
