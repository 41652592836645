import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { searchResultClickLog } from '../../../actions';
import { formatdate } from './filters';
import NoResult from './NoResult';
import Loading from './Loading';
import {
    PostsResultDateWrapper,
    PostsResultDescriptionWrapper,
    PostsResultImageWrapper,
    PostsResultTitleWrapper,
    PostsResultListWrapper,
    StyledList,
} from './styled';

const PostsResult = ({
    log,
    posts,
    searchInput,
    cancelSearch,
    isLoading,
}) => {
    const lcid = useSelector(({ lcid }) => lcid);
    const dispatch = useDispatch();

    const handlePostClick = useCallback((post) => {
        window.dispatchEvent(new CustomEvent('wtSearchPostClick', { detail: { event, postModel: { id: post.id } } }));
        cancelSearch();
        dispatch(searchResultClickLog({
            id: post.id,
            type: 'blogpost',
            log_id: log && log.log_id,
            timestamp: new Date().getTime(),
            query: searchInput,
        }));
    }, [
        log && log.log_id,
        cancelSearch,
        dispatch,
        searchInput,
    ]);

    return (
        isLoading && posts.length < 1
            ? <Loading />
            : posts.length < 1
                ? <NoResult input={searchInput} />
                : (
                    <StyledList
                        items={posts.map((item) => ({ ...item, key: item.id }))}
                        cursorElement={window}
                        className="autotest__search-blogposts-result"
                        itemHref={(post) => post.url}
                        onSelect={(post) => handlePostClick(post)}
                        renderItem={(post) => (
                            <PostsResultListWrapper
                                data-id={post.id}
                                className="autotest__search-blogposts-result-item"
                            >
                                <PostsResultImageWrapper
                                    title={post.title}
                                >
                                    {post.mainPhoto && <img src={post.mainPhoto} />}
                                </PostsResultImageWrapper>
                                <PostsResultDescriptionWrapper>
                                    <PostsResultTitleWrapper>{post.title}</PostsResultTitleWrapper>
                                    <PostsResultDateWrapper>{formatdate(post.date, lcid)}</PostsResultDateWrapper>
                                </PostsResultDescriptionWrapper>
                            </PostsResultListWrapper>
                        )}
                    />
                )
    );
};

PostsResult.propTypes = {
    posts: PropTypes.array.isRequired,
    searchInput: PropTypes.string.isRequired,
    log: PropTypes.shape({}).isRequired,
    cancelSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default PostsResult;
