import { css } from 'styled-components';

import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import {
    ButtonKind, ButtonWrapperProps, SizeMap, StateMap,
} from '../types';

/** Button kind-dependent CSS */
const getStateMap = (theme: Theme, kind: ButtonKind): StateMap => {
    const {
        label, fillIn,
    } = theme;
    const primary = {
        default: `
            color: ${label.accent};
            --focus-line-display: none;
        `,
        hover: `
            color: ${label.accentHigh};
        `,
        focus: `
            color: ${label.accent};
            --focus-line-display: block;
            --focus-line-color: ${fillIn.primary.overlay24};
        `,
        active: `
            color: ${label.accentHighest};
        `,
        disabled: `
            color: ${label.inactive};
            --focus-line-display: none;
        `,
    };
    const kindMap: Record<ButtonKind, StateMap> = {
        primary,
        secondary: {
            default: `
                color: ${label.secondary};
                --focus-line-display: none;
            `,
            hover: `
                color: ${label.secondaryActive};
            `,
            focus: `
                color: ${label.secondary};
                --focus-line-display: block;
                --focus-line-color: ${fillIn.primary.overlay24};
            `,
            active: `
                color: ${label.primary};
            `,
            disabled: `
                color: ${label.inactive};
                --focus-line-display: none;
            `,
        },
        cancel: {
            default: `
                color: ${label.negative};
                --focus-line-display: none;
            `,
            hover: `
                color: ${label.negativeHigh};
            `,
            focus: `
                color: ${label.negative};
                --focus-line-display: block;
                --focus-line-color: ${fillIn.negative.overlay24};
            `,
            active: `
                color: ${label.negativeHighest};
            `,
            disabled: `
                color: ${label.inactive};
                --focus-line-display: none;
            `,
        },
        'ghost-primary': primary,
        'ghost-secondary': primary,
        outline: primary,
        static: primary,
    };
    return kindMap[kind];
};

const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --padding-vertical: 16px;
        --padding-horizontal: 0;
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --padding-vertical: 12px;
        --padding-horizontal: 0;
    `,
    small: css`
        ${buttonSmallFontStyle};
        --padding-vertical: 8px;
        --padding-horizontal: 0;
    `,
    tiny: css`
        ${buttonSmallFontStyle};
        --padding-vertical: 4px;
        --padding-horizontal: 0;
    `,
};

const paddingLeftRightStyle = () => {
    return `
        padding-left: var(--padding-horizontal);
        padding-right: var(--padding-horizontal);
    `;
};

const contentContainerStyle = () => css<ButtonWrapperProps>`
    grid-template-areas: "content";
    position: relative;
    &:after {
        display: var(--focus-line-display);
        background-color: var(--focus-line-color);
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -2px;
    }
`;

export const typeText = {
    wrapperStyle: css<ButtonWrapperProps>``,
    sizeMap,
    paddingLeftRightStyle,
    getStateMap,
    contentContainerStyle,
};
