import { connect } from 'react-redux';

import { Footer as FooterUI } from './components';

const mapDispatchToProps = {
};

export const Footer = connect(
    null,
    mapDispatchToProps,
)(FooterUI);
