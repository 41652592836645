import React, { useState } from 'react';

import { mediaQuery } from 'app/styled';
import { FeedContextValue } from 'context/feed';
import { useLocaleId } from 'hooks/useLocaleId';
import { Tour, ReactourSteps } from 'widgets/tour';
import FeedContainer, { injectFeedReducer, InitialData } from 'widgets/feed';
import { GlobalStyle } from 'components/global-style';
import { Button } from 'components/button2';
import { FeedColumn, SidebarColumn, Wrapper } from './styled';

const Watchlist = React.lazy(() => import(/* webpackChunkName: "watchlist" */ '../widgets/watchlist'));

const autoopenSteps = [ReactourSteps.step0, ReactourSteps.step1, ReactourSteps.step2];
const mobileThreshold = parseInt(mediaQuery.lt768.match(/\d+/)?.[0] ?? '0', 10);

type Props = {
    feedContext: FeedContextValue,
}
export const FeedPage = ({ feedContext }: Props) => {
    const lcid = useLocaleId();

    const [shortView, setShortView] = useState(window.innerWidth <= mobileThreshold);

    return (
        <Tour autoopenSteps={autoopenSteps}>
            <GlobalStyle />
            <Wrapper>
                <FeedColumn>
                    <FeedContainer feedContext={feedContext} showTickerNews showAdds />
                </FeedColumn>
                <SidebarColumn>
                    <React.Suspense fallback={<div style={{ margin: '0 auto' }}><Button isProcessing kind="ghost-secondary" /></div>}>
                        <Watchlist key={lcid} shortView={shortView} onShortViewChanged={setShortView} />
                    </React.Suspense>
                </SidebarColumn>

            </Wrapper>
        </Tour>
    );
};

export const initPageWidgetsData = ({ feed }: { feed: InitialData; }) => {
    injectFeedReducer(feed);
};
