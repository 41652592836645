import React from 'react';

import { IconSvg, IconComponent } from './index';

export const WhatsappColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z" fill="#2CB742" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.663 5.47185C16.1623 3.87646 14.1613 3 12.0305 3C7.64296 3 4.06874 6.78047 4.06492 11.4212C4.06492 12.9076 4.4315 14.3535 5.12649 15.6339L4 20L8.22339 18.8287C9.38807 19.4992 10.6979 19.8546 12.0305 19.8546H12.0344C16.422 19.8546 19.9962 16.0741 20 11.4293C19.9962 9.17962 19.1675 7.0632 17.663 5.47185ZM12.0305 18.4288C10.8391 18.4288 9.67446 18.0896 8.65871 17.4514L8.41814 17.2979L5.91313 17.9926L6.58138 15.4077L6.42482 15.1411C5.76038 14.0264 5.41289 12.7379 5.41289 11.4172C5.41289 7.56403 8.38377 4.42172 12.0344 4.42172C13.8024 4.42172 15.4635 5.15277 16.716 6.47351C17.9647 7.79829 18.652 9.55524 18.652 11.4253C18.6482 15.2906 15.6773 18.4288 12.0305 18.4288ZM15.6621 13.1863C15.4635 13.0813 14.4859 12.5723 14.3026 12.4996C14.1193 12.431 13.9857 12.3946 13.8558 12.6047C13.7222 12.8147 13.3403 13.2913 13.2258 13.4286C13.1112 13.57 12.9928 13.5861 12.7943 13.4811C12.5957 13.3761 11.9542 13.154 11.1943 12.435C10.6024 11.8776 10.2053 11.187 10.0869 10.977C9.97231 10.7669 10.0754 10.6538 10.1747 10.5488C10.2625 10.4559 10.3733 10.3024 10.4726 10.1813C10.5718 10.0601 10.6062 9.97125 10.6711 9.82989C10.736 9.68853 10.7055 9.56736 10.6558 9.46234C10.6062 9.35733 10.2091 8.31932 10.0411 7.89926C9.88067 7.48729 9.71647 7.54383 9.59427 7.5398C9.47971 7.53172 9.34606 7.53172 9.21241 7.53172C9.07876 7.53172 8.86492 7.58422 8.68162 7.79425C8.49833 8.00428 7.98663 8.51319 7.98663 9.5512C7.98663 10.5892 8.70072 11.5868 8.8 11.7282C8.89928 11.8696 10.2014 13.9941 12.1986 14.9069C12.6721 15.125 13.0425 15.2542 13.3327 15.3512C13.81 15.5127 14.2415 15.4885 14.5852 15.436C14.9671 15.3754 15.7613 14.9271 15.9294 14.4343C16.0936 13.9416 16.0936 13.5215 16.0439 13.4326C15.9943 13.3438 15.8606 13.2913 15.6621 13.1863Z" fill="white" />
    </IconSvg>
);
