import { MenuAction, MenuState } from '../types/interfaces';

export default function menuReducer(state: MenuState, action: MenuAction) {
    switch (action.type) {
        case 'SET_USER_FETCHING_STATUS': {
            const { isUserFetching } = action;
            return { ...state, isUserFetching };
        }
        case 'SET_LAST_USER_FETCH_TIME': {
            const { time } = action;
            return {
                ...state,
                lastUserFetchTime: time,
            };
        }
        case 'SET_USER_REFETCH_RESET_TIME': {
            const { time } = action;
            return {
                ...state,
                userRefetchResetTimeSeconds: time,
            };
        }
        case 'SET_LANGUAGE': {
            const user = state.user ? {
                ...state.user,
                lcid: action.language,
            } : state.user;

            return {
                ...state,
                ...{
                    lcid: action.language,
                    user,
                },
            };
        }
        case 'SET_USER_FETCH_RETRY_COUNT': {
            const { userFetchRetryCount } = action;
            return { ...state, userFetchRetryCount };
        }
        case 'SET_USER_LOADING': {
            const isUserLoading = action.loadingStatus;
            return {
                ...state,
                isUserLoading,
            };
        }
        case 'SET_SEARCH_ACTIVE_TAB': {
            const { searchActiveTab } = action;
            return {
                ...state,
                searchActiveTab,
            };
        }
        case 'SET_USER': {
            const { user } = action;
            return { ...state, user };
        }
        case 'SET_WT_USER': {
            const { WTuser } = action;
            return { ...state, WTuser };
        }
        default: return state;
    }
}
