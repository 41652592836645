import { Action } from 'types';
import { Dispatch } from 'redux';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { ValidationError } from 'services/validation-error';

type Generator = { id: number, type: number };

type Props = {
    csrf: string,
    generators: Generator[],
};

export const TAGS_ACTION_TYPES = createRequestActionTypes('TAGS');

export const bulkSubscription = ({ csrf, generators }: Props) => {
    const urlKey = 'limex_activity_subscription_batch_add';
    const url = UrlProcessor.page(urlKey).path();

    const thunk = thunkActions({
        types: TAGS_ACTION_TYPES,
        payload: {
            request: {
                method: 'POST',
                url,
                data: {
                    csrf,
                    generators,
                },
                withCredentials: true,
            },
            pass: {
                csrf,
                generators,
            },
        },
    });

    return async (dispatch: Dispatch) => {
        const action = await thunk(dispatch);
        const { errors } = action.payload.data;

        if (errors) {
            throw new ValidationError(errors);
        }

        return action;
    };
};

export type TBulkSubscription = Action<typeof bulkSubscription>;
