import styled, { css } from 'styled-components';
import { mediaQuery } from 'app/styled';
import { Popover as PopoverComponent } from '../popover';
import { MainMenu as MainMenuComponent } from '../main-menu';
import { IconButton as IconButtonComponent } from '../icon';

const headerHeightMobile = '56px';

export const Box = styled.div`
    box-sizing: border-box;
    max-width: 320px;
    right: 0;
    left: unset;
    margin: 0 0 0 auto;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: ${({ theme }) => theme.bg.secondary};
    width: 100%;
    @media (min-width: 321px) {
        padding-top: 8px;
    }
`;

export const Notifications = styled.div`
    display: none;

    @media (max-width: 959px) {
        display: block;
    }
`;

export const Messages = styled.div`
    display: none;

    @media (max-width: 959px) {
        display: block;
    }
`;

export const ActionButton = styled.div`
    display: none;
    padding-top: 16px;

    ${mediaQuery.lt768} {
        display: block;
    }
`;

export const Popover = styled(PopoverComponent)`
    border-radius: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: ${headerHeightMobile};
    width: 100%;
    height: 100%;
    box-shadow: none;
    background: none;
    @media (max-width: 320px) {
        top: 0;
    }
`;

export const HeaderOnlyMobile = styled.div`
    height: ${headerHeightMobile};
    background: ${({ theme }) => theme.bg.secondary};
    display: flex;
    justify-content: space-between;

    padding: 8px 16px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.divider.primary};

    @media (min-width: 321px) {
        display: none;
    }
`;

export const HeaderOnlyMobileTitle = styled.span`
    font-family: 'Inter var', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.014em;
    color: ${({ theme }) => theme.label.primary};
`;

export const IconButton = styled(IconButtonComponent)<{ isOpened: boolean; }>`
    height: 40px;
    width: 40px;
    padding: 0;
    background-color: ${({ isOpened, theme }) => (isOpened ? `${theme.fillIn.secondary.muted}` : 'transparent')};
    border-radius: 8px;

    margin-left: 8px;

    @media (max-width: 320px) {
        background-color: transparent;
        ${({ isOpened, theme }) => isOpened && css`
            svg {
                fill: ${theme.label.secondary} !important;
            }
        `}
    }
`;

export const MainMenu = styled(MainMenuComponent)`

    .MainMenuListItem {
        display: flex;
        width: 100%;
        padding: 0;
        a {
            padding: 12px 16px;
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 24px;
            color: ${({ theme }) => theme.label.primary};
            letter-spacing: -0.01em;

            &:hover {
                background: ${({ theme }) => theme.fillIn.primary.overlay16};
            }
        }

        &.active {
            background-color: ${({ theme }) => theme.fillIn.positive.overlay32};

            a {
                color:  ${({ theme }) => theme.label.accent};
            }
        }

    }
`;

export const Overlay = styled.div`
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
    cursor: pointer;
    z-index: 1;
`;

export const GroupTitle = styled.span`
    display: block;
    font-family: 'Inter var', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.label.secondary};
    padding: 14px 16px 10px;
`;
