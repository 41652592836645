import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { reduceBreakpoints, ResponsiveMap } from 'app/styled';
import {
    body14RegularFontStyle,
    body17RegularFontStyle,
    caption12MediumFontStyle,
} from 'services/typography';
import { Icon, TagProps, TagSize } from 'components/tag/types';
import { CircleXMarkIcon } from 'components/icons2/CircleXMarkIcon';
import { IconComponent } from 'components/icons2';

const getPaddingContainer = ({
    Icon: IconLeft,
    IconRight,
    hasClose,
    onlyIcon,
    size,
}: {
    Icon?: IconComponent,
    IconRight?: IconComponent,
    hasClose?: boolean,
    onlyIcon?: boolean,
    size: TagSize,
}) => {
    let topBottom = 4;
    let right = 8;
    let left = 8;

    switch (size) {
        case 'small':
            topBottom = 4;
            if (onlyIcon) {
                left = 4;
                right = 4;
            } else {
                left = IconLeft ? 4 : 8;
                right = (IconRight || hasClose) ? 4 : 8;
            }
            break;
        case 'medium':
            topBottom = 6;

            if (onlyIcon) {
                left = 4;
                right = 4;
            } else {
                left = IconLeft ? 8 : 12;
                right = (IconRight || hasClose) ? 8 : 12;
            }

            break;
        case 'large':
            topBottom = 8;
            if (onlyIcon) {
                left = 8;
                right = 8;
            } else {
                left = IconLeft ? 12 : 16;
                right = (IconRight || hasClose) ? 12 : 16;
            }
            break;
        default:
            break;
    }

    return `padding: ${topBottom}px ${right}px ${topBottom}px ${left}px;`;
};

export const Container = styled.div<
    Required<Pick<TagProps, 'size'>>
    & Pick<
    TagProps,
        'IconRight'
        | 'Icon'
        | 'isActive'
    > & {
    hasClose: boolean,
    onlyIcon: boolean,
}>`
    ${({
        size, Icon: IconLeft, IconRight, hasClose, onlyIcon,
    }) => reduceBreakpoints(size,
        (_size) => getPaddingContainer(
            {
                Icon: IconLeft,
                IconRight,
                hasClose,
                onlyIcon,
                size: _size,
            },
        ))};
    border-radius: 20px;
    cursor: pointer;
    background: ${
    ifProp({ isActive: true }, (
        { theme: { fillIn } },
    ) => fillIn.positive.overlay32, ({ theme: { bg } }) => bg.primary)
};
    display: flex;
    align-items: center;
`;

const getIconWrapperSvgCssByTagSize = (size: TagSize):string => {
    let result = 24;

    if (size === 'small') {
        result = 16;
    }

    return `
        width: ${result}px;
        height: ${result}px;
    `;
};

export const IconWrapper = styled.div<
    Required<Pick<TagProps, 'size'>>
    & Pick<TagProps, 'size'> & {
        pos: 'left' | 'right',
        onlyIcon: boolean,
    }
>`
    display: inline-block;

    & svg {
        display: block;
        ${({ size }) => reduceBreakpoints(size,
        (_size) => getIconWrapperSvgCssByTagSize(_size))};

    }

`;

const textCssHandler = ({
    Icon: IconLeft,
    IconRight,
    hasClose,
    size,
} : {
    Icon?: IconComponent,
    IconRight?: IconComponent,
    hasClose?: boolean,
    size?:TagSize
}) => {
    let topBottom = 0;
    let right = 8;
    let left = 8;

    let font = body17RegularFontStyle;

    switch (size) {
        case 'small':
            topBottom = 0;
            left = IconLeft ? 4 : 0;
            right = (IconRight || hasClose) ? 4 : 0;
            font = caption12MediumFontStyle;
            break;
        case 'medium':
            topBottom = 2;
            left = IconLeft ? 4 : 0;
            right = (IconRight || hasClose) ? 4 : 0;
            font = body14RegularFontStyle;
            break;
        case 'large':
            topBottom = 0;
            left = IconLeft ? 8 : 0;
            right = (IconRight || hasClose) ? 8 : 0;
            font = body17RegularFontStyle;
            break;
        default:
            break;
    }
    return `
        ${font};
        padding: ${topBottom}px ${right}px ${topBottom}px ${left}px;
    `;
};

export const Text = styled.span<
    Required<Pick<TagProps, 'size'>> &
    Pick<
        TagProps,
        'Icon'
        | 'IconRight'
    > & {
    hasClose?: boolean,
    onlyIcon?: boolean,
}>`
    color: ${({ theme: { label } }) => label.primary};
    white-space: nowrap;

    ${(
        {
            size, IconRight, Icon: IconLeft, hasClose,
        },
    ) => reduceBreakpoints(size,
        (_size) => textCssHandler({
            size: _size,
            Icon: IconLeft,
            IconRight,
            hasClose,
        }))
};
`;

const iconCloseCssHandler = (
    sizeTag: TagSize,
    IconRight?: Icon,
): string => {
    let result = 0;
    if (sizeTag === 'large' && IconRight) {
        result = 4;
    }

    return `margin-left: ${result}`;
};

export const IconClose = styled(CircleXMarkIcon)<
    Pick<TagProps, 'IconRight'> & {
        sizeTag: TagSize | ResponsiveMap<TagSize>,
    }
>`
    cursor: pointer;
    ${(
        {
            sizeTag, IconRight,
        },
    ) => reduceBreakpoints(sizeTag,
        (_size) => iconCloseCssHandler(
            _size,
            IconRight,
        ))
};
`;
