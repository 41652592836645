import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';

import EditorJS, { EditorConfig, OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import edjsHTML from 'editorjs-html';
import debounce from 'lodash/debounce';

import authPopup from 'services/auth-popup';

import { useCSRFToken } from 'hooks/useCSRFToken';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDicMemo } from 'hooks/useDictionary';
import { CashtagsSuggest } from '../CashtagsSuggest';

import { useCashtags } from '../../hooks/useCashtags';
import { Wrapper, EditorGlobalStyle } from './style';
import { uploadFile } from './services';

type Props = {
    updateState: (value: string) => void;
    updateJson: (value: OutputData) => void;
    editorStateJson?: OutputData | null;
    autofocus?: boolean;
    placeholder?: string;
};

const Editor = forwardRef(({
    updateState, updateJson, editorStateJson, autofocus, placeholder,
}: Props, ref) => {
    const container = useRef(null);

    const csrfToken = useCSRFToken();
    const currentUser = useCurrentUser();

    const [editorJs, setEditorJs] = useState<null | InstanceType<typeof EditorJS>>(null);

    const updateHandler = debounce(async (api) => {
        const state = await api.saver.save();

        const edjsParser = edjsHTML();
        const htmlArray: string[] = edjsParser.parse(state);

        const resultString = htmlArray.flat();
        const json = state;

        updateState(resultString.join(''));
        updateJson(json);
    }, 10);

    const {
        resetSuggest,
        searchTicker,
        onSetTicker,
        insertCashtagSymbol,
        handleCashtagOnEditorChange,
    } = useCashtags(editorJs, updateHandler);

    const editorOptionsI18n = useDicMemo((dic) => ({
        messages: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': dic.word('wt_feed__editor__localization__click_to_tune'),
                        'or drag to move': dic.word('wt_feed__editor__localization__or_drag_to_move'),
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': dic.word('wt_feed__editor__localization__convert__to__placeholder'),
                    },
                },
                toolbar: {
                    toolbox: {
                        Add: dic.word('wt_feed__editor__localization__add_placeholder'),
                        Filter: dic.word('wt_feed__editor__localization__filter_placeholder'),
                    },
                },
            },
            toolNames: {
                Text: dic.word('wt_feed__editor__localization__text__placeholder'),
                Heading: dic.word('wt_feed__editor__localization__heading__placeholder'),
                List: dic.word('wt_feed__editor__localization__list__placeholder'),
                Image: dic.word('wt_feed__editor__localization__image__placeholder'),
                Warning: dic.word('wt_feed__editor__localization__warning__placeholder'),
                Checklist: dic.word('wt_feed__editor__localization__checklist__placeholder'),
                Quote: dic.word('wt_feed__editor__localization__quote__placeholder'),
                Code: dic.word('wt_feed__editor__localization__code__placeholder'),
                Delimiter: dic.word('wt_feed__editor__localization__delimiter__placeholder'),
                'Raw HTML': dic.word('wt_feed__editor__localization__raw_html__placeholder'),
                Table: dic.word('wt_feed__editor__localization__table__placeholder'),
                Link: dic.word('wt_feed__editor__localization__link__placeholder'),
                Marker: dic.word('wt_feed__editor__localization__marker__placeholder'),
                Bold: dic.word('wt_feed__editor__localization__bold__placeholder'),
                Italic: dic.word('wt_feed__editor__localization__italic__placeholder'),
                InlineCode: dic.word('wt_feed__editor__localization__inlinecode__placeholder'),
            },
            tools: {
                warning: {
                    Title: dic.word('wt_feed__editor__localization__warning_title__placeholder'),
                    Message: dic.word('wt_feed__editor__localization__warning_message__placeholder'),
                },
                link: {
                    'Add a link': dic.word('wt_feed__editor__localization__add_a_link__placeholder'),
                },
                stub: {
                    'The block can not be displayed correctly.': dic.word('wt_feed__editor__localization__stub__placeholder'),
                },
                list: {
                    Ordered: dic.word('wt_feed__editor__localization__ordered__placeholder'),
                    Unordered: dic.word('wt_feed__editor__localization__unordered__placeholder'),
                },
                image: {
                    'Select an Image': dic.word('wt_feed__editor__localization__select_an_image__placeholder'),
                    'With background': dic.word('wt_feed__editor__localization__with_background__placeholder'),
                    'Stretch image': dic.word('wt_feed__editor__localization__stretch_image__placeholder'),
                    'With border': dic.word('wt_feed__editor__localization__with_border__placeholder'),
                    Caption: dic.word('wt_feed__editor__localization__caption__placeholder'),
                },
            },
            blockTunes: {
                delete: {
                    Delete: dic.word('wt_feed__editor__localization__delete__placeholder'),
                },
                moveUp: {
                    'Move up': dic.word('wt_feed__editor__localization__moveup__placeholder'),
                },
                moveDown: {
                    'Move down': dic.word('wt_feed__editor__localization__modown__placeholder'),
                },
            },
        },
    }));

    useEffect(() => {
        if (editorJs || !container.current) return;
        const editorOptions: EditorConfig = {
            holder: container.current,
            autofocus,
            placeholder,
            data: editorStateJson || undefined,
            onChange: (api, event) => {
                handleCashtagOnEditorChange(api, event);
                updateHandler(api);
            },
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: true,
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile: (file: File) => {
                                if (!currentUser.isAuth) {
                                    authPopup.login();
                                    return false;
                                }
                                return uploadFile(file, csrfToken);
                            },
                            uploadByUrl: async (url: string) => {
                                return {
                                    success: url ? 1 : 0,
                                    file: {
                                        url,
                                    },
                                };
                            },
                        },
                    },
                },
                embed: Embed,
            },
            i18n: editorOptionsI18n,
        };

        const instance = new EditorJS(editorOptions);

        setEditorJs(instance);
    }, [csrfToken, container.current]);

    useImperativeHandle(
        ref,
        () => container && {
            showAddCashtagDialog: async () => {
                await insertCashtagSymbol(container.current);
            },
            showAddImageDialog: () => {
                editorJs?.blocks.insert('image');
            },
        },
        [editorJs, container],
    );

    return (
        <div>
            <EditorGlobalStyle />
            <Wrapper ref={container} />
            <CashtagsSuggest params={
                {
                    resetSuggest,
                    searchTicker,
                    callback: (ticker) => onSetTicker(ticker),
                    cashtagElement: document.createElement('div'),
                }
            }
            />
        </div>
    );
});

export default Editor;
