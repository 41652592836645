import React, { useMemo, useState } from 'react';

import { PostType } from 'types';
import Dic from 'services/dictionary';
import * as Styled from 'widgets/feed/add-status/components/styled';
import { useEditRecord } from 'widgets/feed/feed/hooks/useEditRecord';
import { StyledIconedText } from 'components/button-icon/styled';
import { ContextActions, MenuItem } from 'components/context-actions';
import { GlobeIcon } from 'components/icons2/GlobeIcon';
import { LockIcon } from 'components/icons2/LockIcon';
import { ShevronDownSmallIcon } from 'components/icons2/ShevronDownSmallIcon';
import { ShevronUpSmallIcon } from 'components/icons2/ShevronUpSmallIcon';
import { PopupWindow2 } from 'components/wt-popup2/popup-window';
import { Title } from 'components/wt-popup2/title';

type PostTypeSelectorProps = {
    type: PostType | null,
    onChangeType: (type: PostType) => void,
    enableSwitch?: boolean,
};

export const PostTypeSelector = ({ type, onChangeType, enableSwitch = false }: PostTypeSelectorProps) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isPopupShown, setIsPopupShown] = useState(false);
    const handleTooltipVisibleChange = ({ isVisible, inTransition }: { isVisible: boolean, inTransition: boolean }) => setIsMenuOpened(isVisible !== inTransition);

    const menu = useMemo(() => {
        return (
            <div>
                <MenuItem
                    text={Dic.word('wt_feed__timeline_status__post_type_premium')}
                    icon={{ component: LockIcon }}
                    onClick={() => { onChangeType(PostType.PREMIUM); setIsPopupShown(false); }}
                />
                <MenuItem
                    text={Dic.word('wt_feed__timeline_status__post_type_ordinary')}
                    icon={{ component: GlobeIcon }}
                    onClick={() => { onChangeType(PostType.ORDINARY); setIsPopupShown(false); }}
                />
            </div>
        );
    }, []);

    const { isEditing } = useEditRecord();

    const typeText = useMemo(() => {
        if (isEditing) {
            return <span>{Dic.word('wt_feed__add_status__editing')}</span>;
        }
        switch (type) {
            case PostType.ORDINARY:
                return (
                    <StyledIconedText
                        text={Dic.word('wt_feed__timeline_status__post_type_ordinary')}
                        icon={enableSwitch ? { gap: '4px', component: GlobeIcon, size: 16 } : undefined}
                    />
                );

            case PostType.PREMIUM:
                return (
                    <StyledIconedText
                        text={Dic.word('wt_feed__timeline_status__post_type_premium')}
                        icon={{ gap: '4px', component: LockIcon, size: 16 }}
                    />
                );

            default:
                return <span>{Dic.word('wt_feed__timeline_status__post_type_select')}</span>;
        }
    }, [type]);

    if (!enableSwitch) {
        return <Styled.PostTypeWrapper>{typeText}</Styled.PostTypeWrapper>;
    }

    return (
        <>
            <Styled.PostTypeSelectorDesktopWrapper>
                <ContextActions
                    tippyContent={menu}
                    placement="bottom-start"
                    onVisibleChange={handleTooltipVisibleChange}
                    buttonContent={(
                        <StyledIconedText
                            text={typeText}
                            iconPos="right"
                            icon={{
                                gap: '5px',
                                size: 16,
                                component: isMenuOpened ? ShevronUpSmallIcon : ShevronDownSmallIcon,
                            }}
                        />
                    )}
                />
            </Styled.PostTypeSelectorDesktopWrapper>
            <Styled.PostTypeSelectorMobileWrapper>
                <StyledIconedText
                    onClick={() => setIsPopupShown(true)}
                    text={typeText}
                    iconPos="right"
                    icon={{
                        gap: '5px',
                        size: 16,
                        component: ShevronDownSmallIcon,
                    }}
                />
            </Styled.PostTypeSelectorMobileWrapper>
            {isPopupShown && (
                <PopupWindow2
                    bgCloseable
                    close={() => setIsPopupShown(false)}
                >
                    <Title>{Dic.word('wt_feed__timeline_status__post_type_select')}</Title>
                    {menu}
                </PopupWindow2>
            )}
        </>
    );
};
