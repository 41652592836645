import {
    Action,
    ActionResult,
    FeedRecordObj,
    FeedRecordType,
    InstrumentData,
    PostType,
    ThunkResultFn,
    VisibilityLevels,
} from 'types';

import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { PublisherFn } from '../../add-status';
import { insertFeedRecord } from './insertFeedRecord';

export type PublishFeedRecordPayloadData = {
    post: FeedRecordObj;
    errorMap?: {
        text: string;
    }[];
};

export const PUBLISH_FEED_RECORD_ACTION_TYPES = createRequestActionTypes(
    'PUBLISH_FEED_RECORD',
);
export function publishFeedRecord(...args: Parameters<PublisherFn>): ThunkResultFn<PublishFeedRecordPayloadData> {
    const [html, json, {
        csrf, owner, postType, generator,
    }] = args;
    let data: {
        html: string,
        json: string,
        csrf: string,
        feedId: number | string | null,
        feedType: number | null,
        groupId: number | null,
        postType: string,
    } = {
        html,
        json,
        csrf,
        feedId: owner?.id || 0,
        feedType: owner?.type || 0,
        groupId: null,
        postType: postType || PostType.ORDINARY,
    };

    // TODO: это грязь какая-то: если уж мы берем овнера из контекста ленты, то овнером
    // страницы инструмента должен быть инструмент
    const InitialData: any = window?.WT?.Page?.initialSettings?.data;
    const instrumentData = <InstrumentData>InitialData?.instrumentData;

    if (instrumentData) {
        data = {
            ...data,
            feedId: instrumentData.id,
            feedType: instrumentData.type,
            groupId: instrumentData.groupId,
        };
    }

    return (dispatch) => {
        let actions = thunkActions({
            types: PUBLISH_FEED_RECORD_ACTION_TYPES,
            payload: {
                request: {
                    method: 'POST',
                    url: UrlProcessor.page('ng_limex_blogpost_create').path(),
                    data,
                    withCredentials: true,
                    // TODO: Fixme!
                    // dmh: без обоих этих заголовков бек выдает html, игнорируя Accept #WTT-9729
                    headers: {
                        'X-Jquery-Json': 'json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                },
            },
        })(dispatch);
        actions = actions.then(
            (action: ActionResult<PublishFeedRecordPayloadData>) => {
                if (
                    action.payload.data.result
                    && !action.payload.data.result.ok
                ) {
                    const errorMessage = action.payload?.data?.result?.exception?.message;
                    const errorMap = action.payload?.data?.errorMap;
                    const errorInfo = errorMap?.map((e) => e.text).join(' ') ?? '';
                    const error = new Error(
                        [errorMessage, errorInfo].filter(Boolean).join(': '),
                    );

                    return Promise.reject(error);
                }
                return Promise.resolve(action);
            },
        );
        actions.then((action: ActionResult<PublishFeedRecordPayloadData>) => {
            const blogpost = action.payload.data.post;
            blogpost.data = { statsUsers: { voters: [], topUsers: [] } };
            blogpost.stats.likes = {
                positive: 0,
                negative: 0,
                userChoice: 0,
            };

            dispatch(insertFeedRecord([{
                id: blogpost.id,
                type: FeedRecordType.PUBLISH_STATUS,
                obj: blogpost,
                url: blogpost.url,
                options: { visLevel: VisibilityLevels.ANY },
                comments: [],
                stats: {
                    commentsCount: 0,
                    commentsCountGeneratedAt: blogpost.date.created,
                    sharesCount: 0,
                    viewsCount: 0,
                    likes: {
                        positive: 0,
                        negative: 0,
                        userChoice: 0,
                    },
                },
                publishedId: blogpost.id,
                generator,
            }], 0));
        });
        return actions;
    };
}
export type PublishFeedRecord = Action<typeof publishFeedRecord>;
