import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { VerifiedIcon } from 'components/icons2/verified-icon';
import { UserPlusIcon } from 'components/icons2/UserPlusIcon';
import { useDictionary } from 'hooks/useDictionary';
import { searchResultClickLog } from '../../../actions';
import store from '../../../store';
import NoResult from './NoResult';
import Userpic from './userpic.js';
import Loading from './Loading';
import { subscribe } from './backend';
import {
    UsersResultSubscribers,
    UsersResultName,
    UsersResultNameWrapper,
    UsersResultDescription,
    UsersResultAvatar,
    UsersResultAddUser,
    UsersResultListWrapper,
    LoadingIcon,
    StyledList,
} from './styled';

const UsersResult = ({
    people,
    log,
    searchInput,
    isLoading,
}) => {
    const dic = useDictionary();
    const [loadingOrSubscribed, setLoadingOrSubscribed] = useState({});
    const dispatch = useDispatch();
    const handleClick = useCallback((person) => {
        dispatch(searchResultClickLog({
            id: person.id,
            type: 'user',
            log_id: log && log.log_id,
            timestamp: new Date().getTime(),
            query: searchInput,
        }));
    }, [
        dispatch,
        log && log.log_id,
        searchInput,
    ]);

    const onSubscribe = async (person) => {
        if(!user?.id) {
            window.WT?.menu?.login();
            return;
        }
        if (loadingOrSubscribed[person.id] !== 'loading') {
            setLoadingOrSubscribed((x) => ({ ...x, [person.id]: 'loading' }));

            const result = await subscribe(person.id);

            if (result?.data?.ok) {
                setLoadingOrSubscribed((x) => ({ ...x, [person.id]: 'subscribed' }));
            } else {
                setLoadingOrSubscribed((x) => ({ ...x, [person.id]: false }));
            }
        }
    };

    const { user } = store.getState();

    const isSubscribeButtonVisible = (person) => {
        if(!user?.id) {
            return person.isFollowable;
        }
        return person.isFollowable && user?.id && (`${user.id}` !== `${person.id}`) && !person.subscribed && loadingOrSubscribed[person.id] !== 'subscribed';
    };

    return (
        isLoading && people.length < 1
            ? <Loading />
            : people.length < 1
                ? <NoResult input={searchInput} />
                : (
                    <StyledList
                        items={people.map((item) => ({ ...item, key: item.id }))}
                        cursorElement={window}
                        className="autotest__search-users-result"
                        onSelect={(person) => handleClick(person)}
                        itemHref={(person) => `/profile/${person.id}/`}
                        renderItem={(person) => (
                            <UsersResultListWrapper>
                                <UsersResultAvatar
                                    title={person.name}
                                >
                                    {person.user_pic ? <img alt={person.name} src={person.user_pic} /> : <Userpic />}
                                </UsersResultAvatar>
                                <UsersResultDescription>
                                    <UsersResultNameWrapper>
                                        <UsersResultName>{person.name}</UsersResultName>
                                        <VerifiedIcon showTooltip={false} isVerifiedAccount={person.isVerified} isVerifiedAuthor={person.isVerifiedAuthor} />
                                    </UsersResultNameWrapper>
                                    <UsersResultSubscribers>
                                        {person.subscribersCount < 1000 ? person.subscribersCount : `${(person.subscribersCount / 1000).toFixed()}K `}
                                        {' '}
                                        {dic.word('wt_menu__search_limex__users_subscribers')}
                                    </UsersResultSubscribers>
                                </UsersResultDescription>
                                {isSubscribeButtonVisible(person) && (
                                    <UsersResultAddUser
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onSubscribe(person);
                                        }}
                                    >
                                        {loadingOrSubscribed[person.id] === 'loading'
                                            ? <LoadingIcon />
                                            : <UserPlusIcon />}
                                    </UsersResultAddUser>
                                )}
                        </UsersResultListWrapper>
                        )}
                    />
                )
    );
};

UsersResult.propTypes = {
    people: PropTypes.array.isRequired,
    searchInput: PropTypes.string.isRequired,
    log: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default UsersResult;
