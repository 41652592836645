import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';

export const IconsWrapper = styled.div`
    padding-left: 4px;
    display: flex;
    align-self: center;
    align-items: center;
    & > * {
        flex: none;
    }
    & > *:not(:first-child) {
        margin-left: -4px;
    }

    fill: ${({ theme }) => theme.label.accent};
    cursor: pointer;
    outline: none;
`;

export const VerifiedIconsTooltip = styled.div`
    padding: 8px 12px;
`;

export const VerifiedIconsTooltipItem = styled.div`
    height: 24px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
`;

export const VerifiedIconName = styled.span`
    ${body14RegularFontStyle};

    ${mediaQuery.lt768} {
        ${caption12RegularFontStyle};
    }
`;
