import React from 'react';

import { FeedContextProvider, FeedContextValue } from 'context/feed';
import { useIncrement } from 'hooks/useIncrement';
import { Feed } from './components';

export type Props = Parameters<typeof Feed>[0] & {
    feedContext: FeedContextValue,
};

export const FeedWithContext = ({ feedContext, ...props }: Props) => {
    const [feedKey, incFeedKey] = useIncrement();
    return (
        <FeedContextProvider initialValue={{ ...feedContext, remountFeed: incFeedKey }}>
            <Feed {...props} key={feedKey} />
        </FeedContextProvider>
    );
};
