import {FeedRecordType, GeneratorType} from 'types';
import { reduceReducers } from 'services/utils/reduce-reducers';
import merge, { mergeByPath } from 'services/utils/merge';
import likePostReducer from './like-post/reducer';
import likeCommentReducer from './like-comment/reducer';
import commentsReducer from './comments/reducer';
import subscribeReducer from './action-subscribe/reducer';
import feedReducer, { handleFetchFeedRecords, emptyFeedState } from './feed/feed-reducer';
import { FeedState } from './types';
import { FetchRecordsPayloadData } from './feed/actions';
import { UPDATE_USER, UpdateUserAction } from './actions';
import { getCommentsAndPath } from './helpers';

type AvailActions = UpdateUserAction;
const commonFeedReducer = (state: FeedState, action: AvailActions) => {
    switch (action.type) {
        case UPDATE_USER: {
            const { payload: { id: userId, ...userData } } = action;
            const newRecords = Object.keys(state.records).reduce((acc, key) => {
                let newRecord = state.records[key];
                if (newRecord.type === FeedRecordType.PUBLISH_STATUS) {
                    // обновляем юзеров в генераторах
                    if (newRecord.generator?.type === GeneratorType.USER && newRecord.generator?.id === userId) {
                        newRecord = mergeByPath(newRecord, 'generator', userData);
                        newRecord = mergeByPath(newRecord, 'obj.author', userData);
                    }
                    const { comments, path } = getCommentsAndPath(state.records[key]);
                    // обновляем юзеров в комментариях
                    const newComments = comments.map((comment) => {
                        if (!(comment.author?.id === userId)) return comment;
                        let newComment = comment;
                        if (userData.displayName) {
                            const replacedContent = comment.content.replace(new RegExp((comment.author.displayName), 'g'), userData.displayName);
                            newComment = merge(newComment, { content: replacedContent });
                        }
                        newComment = mergeByPath(newComment, 'author', userData);
                        return newComment;
                    });
                    newRecord = mergeByPath(newRecord, path, newComments);
                }
                return { ...acc, [key]: newRecord };
            }, {});
            return {
                ...state,
                records: newRecords,
            };
        }
        default:
            return state;
    }
};

export default (rawInitialData: FetchRecordsPayloadData) => {
    const initialState: FeedState = handleFetchFeedRecords(emptyFeedState, rawInitialData);
    return reduceReducers(
        initialState,
        feedReducer,
        likePostReducer,
        likeCommentReducer,
        commentsReducer,
        subscribeReducer,
        commonFeedReducer,
    );
};
