import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { rgba, size as polishedSize } from 'polished';

import { colors } from 'app/styled';
import { AvatarPropTypes } from 'components/avatar/prop-types';

export const SIZES: Record<string, number> = {
    XXS: 21,
    XS: 30,
    S: 34,
    SM: 38,
    M: 48,
    L: 62,
    XL: 80,
    XXL: 158,
    NEW_DESIGN: 56,
};

const shared = css<Required<Pick<AvatarPropTypes, 'size'>>>`
    border-radius: ${ifProp('square', '8px', '50%')};
    display: inline-block;
    ${({ size }) => polishedSize(SIZES[size] || size)}
`;

type RootType = Required<Pick<AvatarPropTypes, 'size' | 'square' | 'bordered'>>;

export const Wrapper = styled.span<RootType>`
    position: relative;
    vertical-align: top;
    overflow: hidden;
    background: ${colors.white};
    ${shared};
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        border: ${ifProp('bordered', `1px solid ${rgba(colors.black, 0.1)}`, 'none')};
    }
`;

export const Link = styled.a<RootType>`
    ${shared};
`;

export const ImgAvatar = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const SvgDefault = styled.svg`
    position: relative;
    top: 14%;
    width: 100%;
    height: 78%;
    fill: ${colors.nobel};
    background: transparent;
`;
