import React, { useCallback } from 'react';

import {
    FeedRecordObjTypes, Id, RatingReactionAction, UserBias,
} from 'types';
import { sendGA } from 'services/google-analytics';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { Like as LikeUI, LikeSizes } from 'components/like';
import { SendReaction } from './actionGenerators';

interface DispatchProps {
    onRatingReaction: SendReaction,
}
interface Props extends DispatchProps {
    objId: Id,
    recordId: Id,
    objType: FeedRecordObjTypes,
    userChoice: UserBias | null,
    count?: number,
    size?: LikeSizes,
}
export const Like = (props: Props) => {
    const {
        recordId, objId, objType, count, userChoice, size, onRatingReaction,
    } = props;

    const pressed = Boolean(userChoice && (userChoice as number) > 0);
    const handleClick = TODOuseActionAfterAuthentication(() => {
        const [action, newChoice]: [RatingReactionAction, UserBias] = pressed ? ['unlike', UserBias.none] : ['like', UserBias.agree];
        onRatingReaction({ objectId: objId, objectType: objType, action }, { newChoice, recordId });
        sendGA('feedRecord', [`like-${Object.keys(FeedRecordObjTypes)[Object.values(FeedRecordObjTypes).indexOf(objType)]}-click`, action]);
    });

    return (
        <LikeUI
            pressed={pressed}
            onClick={handleClick}
            count={count}
            size={size}
            className="autotest__feed-item__like-button"
        />
    );
};
Like.defaultProps = {
    count: 0,
    size: 'L' as LikeSizes,
};
