class Config {
    userToken;
    constructor(config) {
        // setup defaults
        Object.assign(this, {
            user: {},
            facebook: {},
            watchList: {
                // vls: сколь часто обновлять график, в мс @since #WTT-5923
                refreshChart: 60000,
                txChartIframe: {},
            },
            isMobile: false,
            isPhone: false,
            isJust2Trade: false,
            isJust2TradeCom: false,
            csrf: null,
            lcid: null,
            deflcid: 'en',
            realTimeInstrumentInfoThrottling: 1000,
            feedContextActiveTimeout: 30,
            feedColumnMinPostLength: 5,
            searchUrl: null,
            searchLimit: 10,
            postAboutVerifiedAccount: null,
            charts: { server: {} },
            // vls: пользователский токен используется для передачи индификатора сессии, когда third party cookie не передаются
            // браузером. Актуально для сторонних модулей @since #WTT-7905
            userToken: '',
            debug: false,
            mainGroupDomain: typeof window !== 'undefined' ? window.location.origin : '',
            enableCrossPosting: true,
            domReady: false,
            postedStatusBigText: {
                length: 112, // максимальная длина сообщения в статусе, который будет отображен большим шрифтом
                measurementUncertainty: 10, // погрешность в процентах при сравнения длины текста с эталонной
                measurementUncertaintyMin: 15, // минимальное значение погрешности в символах
                measurementUncertaintyMax: 20, // максимальное значение погрешности в символах
            },
            postMessageSafeCheck: true,
        });

        this.setConfig(config);
    }

    setConfig(config) {
        Object.assign(this, config);
    }
}

export default new Config((typeof window !== 'undefined' && window.ProjectConfig) || {});
