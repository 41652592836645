import * as Sentry from '@sentry/browser';

import {
    ActionResult, ThunkResultFn, Person, Id, AnyObject, FeedRecordObjTypes, FeedRecord,
} from 'types';
import { thunkActions } from 'services/create-action-types';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import UrlProcessor from 'services/url-processor';
import { GlobalStateForFeed } from '../../../types';
import { index, CancelActionProcessor } from '../../../helpers';

export type SendLikeRequestData = {
    // eslint-disable-next-line camelcase
    object_id: Id,
    type: FeedRecordObjTypes,
    action: string,
};
export type SendLikeGeneratorData = {
    objectId: Id,
    objectType: FeedRecordObjTypes,
    action: string,
    likeOptions?: AnyObject,
    ts?: number,
};
export type SendLikePayloadData = {
    plus: number,
    minus: number,
}
export type SendLikePayloadPass = {
    newChoice: number,
    recordId: Id,
    currentUser?: Person,
}
const cancelActionProcessor = new CancelActionProcessor<FeedRecord>();
export const sendLikeGenerator = (
    types: string[],
    data: SendLikeGeneratorData,
    pass: SendLikePayloadPass,
): ThunkResultFn<SendLikePayloadData, SendLikePayloadPass, GlobalStateForFeed> => {
    return async (dispatch, getState) => {
        const { objectId, objectType, action: likeAction } = data;
        const { recordId } = pass;
        const {
            cancelation,
            prevSpecialState: prevRecordState,
        } = await cancelActionProcessor.cancelOrBuild(objectId.toString(), getState().widgets.feed.records[index(recordId)]);
        const actions = thunkActions({
            types,
            payload: {
                pass: {
                    ...pass,
                    prevSpecialState: prevRecordState,
                },
                cancelation,
                request: {
                    url: UrlProcessor.page('ng_vote_apply_vote').url(),
                    method: 'POST',
                    data: {
                        object_id: objectId,
                        type: objectType,
                        action: likeAction,
                    },
                    withCredentials: true,
                },
            },
        })(dispatch);
        actions.then((action: ActionResult) => {
            const { payload: { data: payloadData } } = action;
            if (payloadData.result && !payloadData.result.ok) {
                const { message } = payloadData.result;
                Sentry.captureMessage(message);
                notify.error(Dic.word(message));
            }
        }).catch(() => {
            cancelActionProcessor.clear(objectId.toString());
        });
        return actions;
    };
};
export type SendReaction = (data: SendLikeGeneratorData, pass: SendLikePayloadPass) => ReturnType<ReturnType<typeof sendLikeGenerator>>;
