/**
 * Сервис предназначен для работы с дополнительными счетчиками. Пока актуально только для внешних модулей.
 */

const nameToFunctionMap = {};
export const googleAnalytics = {
    /**
     * @param {String} name
     * @param {String} googleAnalyticsFunction
     * @private
     */
    _initGA(name, googleAnalyticsFunction) {
        if (nameToFunctionMap[name]) {
            return;
        }

        nameToFunctionMap[name] = googleAnalyticsFunction;

        if (!window[googleAnalyticsFunction]) {
            this._loadGAScript(googleAnalyticsFunction);
        }
    },

    /**
     * @param {String} googleAnalyticsFunction
     * @private
     */
    _loadGAScript(googleAnalyticsFunction) {
        // vls: код ниже это копипаст инициализации GA счетчика из официальной документации
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/?hl=ru
        /* eslint-disable */
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js',googleAnalyticsFunction);
        /* eslint-enable */
    },

    /**
     *
     * @param {String} name
     * @param {String} trackingId
     * @param {String|undefined} cookieDomain
     * @param {String|undefined} googleAnalyticsFunction
     */
    create(name, trackingId, cookieDomain = 'auto', googleAnalyticsFunction = 'ga') {
        this._initGA(name, googleAnalyticsFunction);

        this._getGA(name)(
            'create',
            trackingId,
            cookieDomain,
            name,
        );
    },

    /**
     * @param {String} name
     * @param {String} field
     * @param {String|Number} value
     */
    set(name, field, value) {
        this._getGA(name)(`${name}.set`, field, value);
    },

    setLogger(logger) {
        this.logger = logger;
    },

    /**
     * @param {String} name
     * @return {Function}
     * @private
     */
    _getGA(name) {
        const gaFunction = nameToFunctionMap[name];
        if (!gaFunction) {
            throw new Error(`The '${name}' counter isn't created`);
        }

        return window[gaFunction];
    },

    /**
     * @param {String} name
     * @param {String} eventCategory
     * @param {String} eventAction
     * @param {String|undefined} eventLabel
     * @param {Number|undefined} eventValue
     */
    sendEvent(name, eventCategory, eventAction, eventLabel, eventValue) {
        const params = {};

        if (this.logger) {
            params.hitCallback = () => {
                const eventParams = [eventCategory, eventAction, eventLabel, eventValue].filter((value) => value);
                this.logger.info(`Detected event to Google Analytics (the counter is '${name}'):`, eventParams);
            };
        }

        this._getGA(name)(`${name}.send`, 'event', eventCategory, eventAction, eventLabel, eventValue, params);
    },
};

const that = {};
export function sendGA(id, tags) {
    const [
        eventAction,
        eventLabel,
        eventValue,
    ] = tags;

    if (!that.initialized) return;

    googleAnalytics.sendEvent(
        that.GA_COUNTER_NAME,
        id,
        eventAction,
        eventLabel,
        eventValue,
    );
}

sendGA.init = function init(params, logger) {
    const {
        GA_COUNTER_NAME, trackingId, cookieDomain, libraryFunction,
    } = params;
    googleAnalytics.create(
        GA_COUNTER_NAME,
        trackingId,
        cookieDomain,
        libraryFunction,
    );
    if (logger) googleAnalytics.setLogger(logger);
    that.GA_COUNTER_NAME = GA_COUNTER_NAME;
    that.initialized = true;
};
