/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import React from "react";
import wtAuthModuleCall from "./services/wtAuthModule";

import store from "./store";
import businessMetrics from "./services/businessMetrics";

import resolveCallBack from "./services/resolveCallBack";

import debug from "./menuDebug";
import { setLang } from "./utils/setLang";
import { getLang } from "./utils/getLang";

import { initializeMenu } from "./utils/initializeMenu";

import { getUserInfoPromise } from "./utils/getUserInfoPromise";
import { updateUser } from "./utils/updateUser";
import { isAuth, getUserInfo } from "./utils/getUserInfo";
import { forceShowWtAuthPopup } from "./utils/forceShowWtAuthPopup";
import {
    getConfig,
    initMountPointAndAnalytics,
    show,
} from "./utils/initMountPointAndAnalytics";

import { LimexMenu } from './components';
import { fillProfile } from './utils/fillProfile';

// eslint-disable-next-line consistent-return
function WtMenuModule() {
    if (!window.WT) {
        window.WT = {};
    }

    const mountPoint = document.getElementById("wt-menu-module");

    /**
     * @param {Object|undefined} displayParams
     * @param {string|undefined} context
     * @param {object} contextParams
     * @private
     */
    const _login = (displayParams, context = undefined, contextParams = {}) => {
        const { callbacks } = store.getState();
        resolveCallBack(callbacks, "clickLoginButton", [
            displayParams,
            context,
            contextParams,
        ]);
    };

    /**
     * @param {Object|undefined} displayParams
     * @param {string|undefined} context
     * @param {object} contextParams
     * @private
     */
    const _registration = (
        displayParams,
        context = undefined,
        contextParams = {}
    ) => {
        const { callbacks } = store.getState();
        resolveCallBack(callbacks, "clickRegisterButton", [
            displayParams,
            context,
            contextParams,
        ]);
    };

    const callbacks = window.WT?.menu?.callbacks || [];
    window.WT.menu = {
        init: initMountPointAndAnalytics,
        getUserInfo,
        getUserInfoPromise,
        auth: wtAuthModuleCall,
        updateUser,
        forceShowWtAuthPopup,
        isUserAuthenticated: isAuth,
        login: _login,
        registration: _registration,
        fillProfile,
        offsetBottom: () => mountPoint.offsetTop + mountPoint.offsetHeight,
        show,
        getConfig,
        setLang,
        getLang,
        metrics: {
            addEventForProject: businessMetrics.addEvent,
        },
        debug: debug(),
        mountPoint,
        callbacks,
    };

    function runCallback(cb) {
        cb();
    }
    window.WT.menu.callbacks.forEach(runCallback);
    window.WT.menu.callbacks = [];
    window.WT.menu.callbacks.push = runCallback;

    initializeMenu();

    return window.WT.menu;
}

export const menu = new WtMenuModule();

export default LimexMenu;
