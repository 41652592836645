import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import {
    body14RegularFontStyle,
    body16SemiboldFontStyle,
    body17SemiboldFontStyle,
    caption12MediumFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';

export const AdWrapper = styled.a`
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg.secondary};
    overflow: hidden;

    &, &:hover {
        color: ${({ theme }) => theme.label.primary};
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
`;

export const Chip = styled.div`
    position: absolute;
    left: 12px;
    top: 12px;
    padding: 4px 8px;
    ${caption12MediumFontStyle};
    background-color: ${({ theme }) => theme.bg.secondary};
    border-radius: 20px;
    pointer-events: none;
`;

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 16px 16px 16px;
`;

export const Headline = styled.div`
    ${body17SemiboldFontStyle};

    ${mediaQuery.lt960} {
        ${body16SemiboldFontStyle};
    }

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const LinkWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;

    & > img {
        width: 16px;
        height: 16px;
    }
`;

export const DisplayLink = styled.span`
    ${body14RegularFontStyle};

    ${mediaQuery.lt960} {
      ${caption12RegularFontStyle};
    }

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
