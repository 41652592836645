import React from 'react';

import { IconSvg, IconComponent } from '.';

export const EyeIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 24 24" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 9.79995C10.7849 9.79995 9.79995 10.7849 9.79995 12C9.79995 13.215 10.7849 14.2 12 14.2C13.215 14.2 14.2 13.215 14.2 12C14.2 10.7849 13.215 9.79995 12 9.79995ZM8.19995 12C8.19995 9.90127 9.90127 8.19995 12 8.19995C14.0986 8.19995 15.8 9.90127 15.8 12C15.8 14.0986 14.0986 15.8 12 15.8C9.90127 15.8 8.19995 14.0986 8.19995 12Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.73378 12C4.45693 13.1942 5.39022 14.6511 6.64341 15.8622C8.03088 17.203 9.76694 18.2 12 18.2C14.233 18.2 15.969 17.203 17.3565 15.8622C18.6097 14.6511 19.543 13.1942 20.2662 12C19.543 10.8057 18.6097 9.3488 17.3565 8.13772C15.969 6.79688 14.233 5.79995 12 5.79995C9.76694 5.79995 8.03088 6.79688 6.64341 8.13772C5.39022 9.3488 4.45693 10.8057 3.73378 12ZM5.53154 6.98718C7.11904 5.45303 9.23296 4.19995 12 4.19995C14.7669 4.19995 16.8809 5.45303 18.4684 6.98718C20.0319 8.49811 21.1265 10.3225 21.8714 11.5641L21.8859 11.5884C22.038 11.8417 22.038 12.1582 21.8859 12.4115L21.8714 12.4358C21.1265 13.6774 20.0319 15.5018 18.4684 17.0127C16.8809 18.5469 14.7669 19.8 12 19.8C9.23296 19.8 7.11904 18.5469 5.53154 17.0127C3.9681 15.5018 2.87351 13.6774 2.12857 12.4358L2.11401 12.4115C1.962 12.1582 1.962 11.8417 2.11401 11.5884L2.12857 11.5641C2.87351 10.3225 3.9681 8.49808 5.53154 6.98718Z" />
    </IconSvg>
);
