import { ThunkAction } from 'redux-thunk';

import {
    Action,
    ActionResult,
    ActionWithPayload,
    AnyObject,
    FeedRecord,
    FeedRecordInstrument,
    FeedRecordType,
} from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import { GlobalStateForFeed } from '../../types';
import { insertFeedRecord } from './insertFeedRecord';

type GetInsertPosition = (resords: FeedRecord[], insertingRecords: FeedRecord[]) => number;
function insertAfterFeedPosition(
    insertingRecords: FeedRecord[],
    getInsertPosition: GetInsertPosition,
): ThunkAction<ReturnType<typeof insertFeedRecord> | undefined, GlobalStateForFeed, AnyObject, ActionWithPayload> {
    return (dispatch, getState) => {
        const state = getState();
        const records = Object.values(state.widgets.feed.records);
        const pos = getInsertPosition(records, insertingRecords);
        // @ts-ignore
        console.log(`Feed news ${insertingRecords[0].tickerNews.instrument.ticker} - ${pos >= 0 ? 'SUCCESS' : 'SKIP'}`);
        if (pos >= 0) return dispatch(insertFeedRecord(insertingRecords, pos + 1));
    };
}

let tickerNewsIdCounter = 1;
const TICKER_NEWS_DAYS = 25;
const TICKER_NEWS_LIMIT = 10;
export type FetchTickerNewsPayloadData = {
    records: FeedRecord[],
    isSubscribed: boolean,
    instrument: FeedRecordInstrument,
};
const FETCH_FEED_TICKER_NEWS_ACTION_TYPES = createRequestActionTypes('FETCH_FEED_TICKER_NEWS');
export function fetchTickerNews(finamIds: number[], lcid: string, getInsertPosition: GetInsertPosition) {
    // @ts-ignore
    return (dispatch) => {
        const request = thunkActions({
            types: FETCH_FEED_TICKER_NEWS_ACTION_TYPES,
            payload: {
                request: {
                    url: UrlProcessor.page('ng_blogpost_instrument_news').path(),
                    params: {
                        instruments: finamIds,
                        days: TICKER_NEWS_DAYS,
                        limit: TICKER_NEWS_LIMIT,
                        lcid,
                        feedContext: 'limex', // av: Указываем контекст загрузки ленты для ссылок @since #WTT-10201
                    },
                    data: {},
                    withCredentials: true,
                },
            },
        }, false)(dispatch);

        return request.then((action: ActionResult<{ records: FetchTickerNewsPayloadData[]; }>) => {
            action.payload.data?.records?.forEach?.((tickerNews: FetchTickerNewsPayloadData) => {
                const { instrument, isSubscribed, records } = tickerNews;
                const tickerNewsId = `tickerNews${tickerNewsIdCounter++}`;
                const tickerNewsRecord: FeedRecord = {
                    id: tickerNewsId,
                    type: FeedRecordType.TICKER_NEWS,
                    tickerNews: {
                        instrument,
                        isSubscribed,
                    },
                };
                const insertingRecords = [tickerNewsRecord];
                records.forEach((record) => {
                    insertingRecords.push({
                        ...record,
                        tickerNews: { tickerNewsId },
                    });
                });
                dispatch(insertAfterFeedPosition(insertingRecords, getInsertPosition));
            });
            return action;
        });
    };
}
export type FetchTickerNews = Action<typeof fetchTickerNews>;
