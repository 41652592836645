import React, {
    useState,
    useEffect,
    useRef,
    RefObject,
} from 'react';

type useImageLoadedReturn = {
    ref: RefObject<HTMLImageElement> | undefined | null,
    isLoaded: boolean,
    hasError: boolean,
    onLoad: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void,
    onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
};

/**
 * Executes a callback whenever locale changes.
 * Callback can return a function to perform cleanup before the change (same as in useEffect).
 *
 * @example
 *
 *  const {
 *         ref, isLoaded, hasError, onLoad, onError,
 *     } = useImageLoaded();
 *
 *      <img
 *         src={srcProp}
 *         alt={altProp}
 *
 *         ref={ref}
 *         loaded={!isLoadingProp && !hasError && isLoaded} // maybe will use for some style
 *         onLoad={onLoad}
 *         nError={onError}
 *       />
 */

const useImageLoaded = (): useImageLoadedReturn => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const ref = useRef<HTMLImageElement>(null);
    const naturalWidth = ref?.current?.naturalWidth || 0;

    const onLoad = () => {
        setIsLoaded(true);
    };

    const onError = () => {
        setHasError(true);
    };

    useEffect(() => {
        if (naturalWidth < 1) {
            onError();
        } else {
            setHasError(false);
        }
    }, [naturalWidth]);

    return {
        ref,
        isLoaded,
        hasError,
        onLoad,
        onError,
    };
};

export default useImageLoaded;
