import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';

import { NoResultContainer, NoResultListWrapper } from './styled';

interface Props {
    input: string;
}

const NoResult: FC<Props> = ({ input }) => {
    const dic = useDictionary();
    return (
        <NoResultContainer>
            <NoResultListWrapper>
                {dic.word('wt_menu__search_limex__no_result_query')}
                {' '}
                <b><span>{input}</span></b>
                {' '}
                {dic.word('wt_menu__search_limex__no_result_not_found')}
            </NoResultListWrapper>
        </NoResultContainer>
    );
};

export default NoResult;
