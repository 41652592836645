import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { searchResultClickLog } from '../../../actions';

import NoResult from './NoResult';
import Loading from './Loading';
import { TickerElement } from './TickerElement';
import { StyledList } from './styled';

const TickersResult = ({
    log,
    instruments,
    searchInput,
    isLoading,
    toggleTickerSubscribed,
}) => {
    const dispatch = useDispatch();
    const lcid = useSelector(({ lcid }) => lcid);

    const handleClick = (x) => {
        dispatch(searchResultClickLog({
            id: x.id,
            type: 'instrument',
            log_id: log && log.log_id,
            timestamp: new Date().getTime(),
            query: searchInput,
        }));
    };

    return (
        isLoading && instruments.length < 1
            ? <Loading />
            : instruments.length < 1
                ? <NoResult input={searchInput} />
                : (
                    <StyledList
                        items={instruments.map((item) => ({ ...item, key: item.id }))}
                        cursorElement={window}
                        className="autotest__search-instruments-result"
                        itemHref={(instrument) => `/$${instrument.ticker.replace('$', '')}?id=${instrument.id}`}
                        onSelect={(instrument) => handleClick(instrument)}
                        renderItem={(instrument) => (
                            <TickerElement
                                key={instrument.id}
                                instrument={instrument}
                                lcid={lcid}
                                toggleTickerSubscribed={toggleTickerSubscribed}
                            />
                        )}
                    />
                )
    );
};

TickersResult.propTypes = {
    log: PropTypes.shape({}).isRequired,
    instruments: PropTypes.array.isRequired,
    searchInput: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toggleTickerSubscribed: PropTypes.func.isRequired,
};

export default TickersResult;
