import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { FeedRecordObj, FeedRecordComment, Person } from 'types';
import { DefaultProductItem } from 'types/product';

import { NotificationItemDataType, NotificationType } from 'widgets/header-notifications/types';
import NotificationTimeAgo from 'widgets/header-notifications/components/NotificationTimeAgo';
import { AvatarIconNotificationType, AvatarPhotoNotificationType } from 'components/notification/types';

import { TriangleExclamationIcon } from 'components/icons2/TriangleExclamationIcon';
import { DocTextMagnifyingGlassIcon } from 'components/icons2/DocTextMagnifyingGlassIcon';
import { LimexIcon } from 'components/icons2/LimexIcon';
import { StarFillIcon } from 'components/icons2/StarFillIcon';
import { MessageIcon } from 'components/icons2/MessageIcon';
import { PenToSquareIcon } from 'components/icons2/PenToSquareIcon';
import { WalletIcon } from 'components/icons2/WalletIcon';
import { HeartIcon } from 'components/icons2/HeartIcon';
import { AtIcon } from 'components/icons2/AtIcon';
import { UserIcon } from 'components/icons2/UserIcon';
import { CheckIcon } from 'components/icons2/CheckIcon';
import { ExclamationMarkCircleIcon } from 'components/icons2/ExclamationMarkCircleIcon';
import { TimesIcon } from 'components/icons2/TimesIcon';
import { Notification as NotificationComponent } from 'components/notification';
import { notificationTextObjType } from './index';

export type NotificationPropsType = {
    item: NotificationItemDataType,
    deleteNotification: () => void,
    notificationTextObj: notificationTextObjType
};

export const Notification = (props: NotificationPropsType) => {
    const {
        item,
        deleteNotification,
        notificationTextObj,
    } = props;

    const theme = useTheme();
    const baseUrl = window.location.origin;

    const getUserTitleByParams = (
        {
            startPhrase, userData, endPhrase, renderResult,
        }:
            {
                startPhrase: string,
                userData: Person,
                endPhrase: string,
                renderResult?: (resultEl: ReactNode) => ReactNode
            },
    ) => {
        const userId = userData?.id;
        const userDisplayName = userData?.displayName;
        const middlePhrase = (userId && userDisplayName) ? (
            <a
                href={`${baseUrl}/profile/${userId}/`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {userDisplayName}
            </a>
        ) : (userDisplayName || '');

        const resultEl = (
            <>
                {`${startPhrase ? `${startPhrase} ` : ''}`}
                <>{middlePhrase}</>
                {middlePhrase ? ' ' : ''}
                {`${endPhrase ? `${endPhrase} ` : ''}`}
            </>
        );

        return renderResult?.(resultEl) || (<span>{resultEl}</span>);
    };

    const renderTitle = (itemData: NotificationItemDataType) => {
        switch (itemData.type) {
            case NotificationType.POST_LIKE: {
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.POST_LIKE].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.POST_LIKE].endPhrase,
                });
            }

            case NotificationType.COMMENT_LIKE:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.COMMENT_LIKE].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.COMMENT_LIKE].endPhrase,
                });

            case NotificationType.POST_COMMENT:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.POST_COMMENT].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.POST_COMMENT].endPhrase,
                });

            case NotificationType.PRODUCT_COMMENT:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.PRODUCT_COMMENT].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.PRODUCT_COMMENT].endPhrase,
                });

            case NotificationType.COMMENT_ANSWERED:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.COMMENT_ANSWERED].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.COMMENT_ANSWERED].endPhrase,
                });

            case NotificationType.MENTION_PUBLISH:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.MENTION_PUBLISH].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.MENTION_PUBLISH].endPhrase,
                });

            case NotificationType.MENTION_COMMENT:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.MENTION_COMMENT].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.MENTION_COMMENT].endPhrase,
                });

            case NotificationType.SUBSCRIBE_TO_YOU:
                return itemData?.user && getUserTitleByParams({
                    startPhrase: notificationTextObj.title[NotificationType.SUBSCRIBE_TO_YOU].startPhrase,
                    userData: itemData.user,
                    endPhrase: notificationTextObj.title[NotificationType.SUBSCRIBE_TO_YOU].endPhrase,
                });

            case NotificationType.SOFT_BAN:
                return notificationTextObj.title[NotificationType.SOFT_BAN].text;

            case NotificationType.PRIVACY_POLICY_CHANGES:
                return notificationTextObj.title[NotificationType.PRIVACY_POLICY_CHANGES].text;

            case NotificationType.WHATS_NEW:
                return notificationTextObj.title[NotificationType.WHATS_NEW].text;

            case NotificationType.MONETIZATION_START:
                return notificationTextObj.title[NotificationType.MONETIZATION_START].text;

            case NotificationType.COMMENT_DELETE_FOUL:
                return notificationTextObj.title[NotificationType.COMMENT_DELETE_FOUL].text;

            case NotificationType.POST_UNPUBLISH_FOUL:
                return notificationTextObj.title[NotificationType.POST_UNPUBLISH_FOUL].text;

            case NotificationType.ACCESS_MONETIZATION_GRANTED:
                return notificationTextObj.title[NotificationType.ACCESS_MONETIZATION_GRANTED].text;

            case NotificationType.PRODUCT_ADDED:
                return notificationTextObj.title[NotificationType.PRODUCT_ADDED].text;

            case NotificationType.PRODUCT_MODERATE_FALL:
                return notificationTextObj.title[NotificationType.PRODUCT_MODERATE_FALL].text;

            case NotificationType.PRODUCT_UNPUBLISH_FOUL:
                return notificationTextObj.title[NotificationType.PRODUCT_UNPUBLISH_FOUL].text;

            default:
                return undefined;
        }
    };

    const renderAvatar = (itemData: NotificationItemDataType): AvatarPhotoNotificationType | AvatarIconNotificationType | undefined => {
        switch (itemData.type) {
            case NotificationType.POST_LIKE:
            case NotificationType.COMMENT_LIKE:
            case NotificationType.POST_COMMENT:
            case NotificationType.PRODUCT_COMMENT:
            case NotificationType.COMMENT_ANSWERED:
            case NotificationType.MENTION_PUBLISH:
            case NotificationType.MENTION_COMMENT:
            case NotificationType.SUBSCRIBE_TO_YOU: {
                if (!itemData?.user) {
                    return undefined;
                }
                return {
                    type: 'image',
                    src: itemData?.user?.image || '#',
                    alt: itemData?.user?.displayName || '',
                };
            }
            case NotificationType.SOFT_BAN: {
                return {
                    type: 'icon',
                    Icon: TriangleExclamationIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };
            }

            case NotificationType.PRIVACY_POLICY_CHANGES: {
                return {
                    type: 'icon',
                    Icon: DocTextMagnifyingGlassIcon,
                    backgroundColor: theme.fillIn.secondary.high,
                };
            }

            case NotificationType.WHATS_NEW: {
                return {
                    type: 'icon',
                    Icon: LimexIcon,
                    backgroundColor: theme.fillIn.primary.mid,
                };
            }

            case NotificationType.MONETIZATION_START: {
                return {
                    type: 'icon',
                    Icon: StarFillIcon,
                    backgroundColor: theme.fillIn.note.mid,
                };
            }

            case NotificationType.COMMENT_DELETE_FOUL: {
                return {
                    type: 'icon',
                    Icon: MessageIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };
            }

            case NotificationType.POST_UNPUBLISH_FOUL: {
                return {
                    type: 'icon',
                    Icon: PenToSquareIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };
            }

            case NotificationType.ACCESS_MONETIZATION_GRANTED: {
                return {
                    type: 'icon',
                    Icon: WalletIcon,
                    backgroundColor: theme.fillIn.primary.mid,
                };
            }

            case NotificationType.PRODUCT_ADDED:
            case NotificationType.PRODUCT_MODERATE_FALL:
            case NotificationType.PRODUCT_UNPUBLISH_FOUL:
            {
                return {
                    type: 'image',
                    src: (itemData?.object as DefaultProductItem)?.image?.original || '',
                    alt: (itemData?.object as FeedRecordObj)?.title || '',
                };
            }
            default:
                return undefined;
        }
    };

    const renderAvatarBadge = (itemData: NotificationItemDataType) => {
        switch (itemData.type) {
            case NotificationType.POST_LIKE:
            case NotificationType.COMMENT_LIKE:
                return {
                    type: 'icon',
                    Icon: HeartIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };

            case NotificationType.POST_COMMENT:
            case NotificationType.PRODUCT_COMMENT:
            case NotificationType.COMMENT_ANSWERED:
            {
                return {
                    type: 'icon',
                    Icon: MessageIcon,
                    backgroundColor: theme.fillIn.secondary.high,
                };
            }

            case NotificationType.MENTION_PUBLISH:
            case NotificationType.MENTION_COMMENT:
            {
                return {
                    type: 'icon',
                    Icon: AtIcon,
                    backgroundColor: theme.fillIn.primary.mid,
                };
            }

            case NotificationType.SUBSCRIBE_TO_YOU: {
                return {
                    type: 'icon',
                    Icon: UserIcon,
                    backgroundColor: theme.fillIn.primary.mid,
                };
            }

            case NotificationType.PRODUCT_ADDED: {
                return {
                    type: 'icon',
                    Icon: CheckIcon,
                    backgroundColor: theme.fillIn.primary.mid,
                };
            }

            case NotificationType.PRODUCT_MODERATE_FALL: {
                return {
                    type: 'icon',
                    Icon: ExclamationMarkCircleIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };
            }

            case NotificationType.PRODUCT_UNPUBLISH_FOUL: {
                return {
                    type: 'icon',
                    Icon: TimesIcon,
                    backgroundColor: theme.fillIn.negative.mid,
                };
            }

            default:
                return undefined;
        }
    };

    const renderMessage = (itemData: NotificationItemDataType) => {
        switch (itemData.type) {
            case NotificationType.POST_LIKE:
            case NotificationType.POST_COMMENT:
            case NotificationType.MENTION_PUBLISH:
                return (itemData?.object as FeedRecordObj)?.displayTitle ? {
                    text: (itemData.object as FeedRecordObj).displayTitle,
                    lineClamp: 2,
                } : undefined;
            case NotificationType.COMMENT_ANSWERED:
            case NotificationType.COMMENT_LIKE:
            case NotificationType.MENTION_COMMENT:
                return (itemData?.object as FeedRecordComment)?.shortContent ? {
                    text: (itemData.object as FeedRecordComment).shortContent || '',
                    lineClamp: 2,
                } : undefined;
            case NotificationType.PRODUCT_COMMENT:
                return (itemData?.object as DefaultProductItem)?.title ? {
                    text: (itemData.object as DefaultProductItem).title,
                    lineClamp: 2,
                } : undefined;
            case NotificationType.SOFT_BAN:
                // TODO ждем реализации бэка - какие параметры передавать для отображения причины, типа ограничения и срока
                return {
                    text: notificationTextObj.message[NotificationType.SOFT_BAN],
                };
            case NotificationType.PRIVACY_POLICY_CHANGES:
                return {
                    text: notificationTextObj.message[NotificationType.PRIVACY_POLICY_CHANGES],
                };
            case NotificationType.WHATS_NEW:
                return {
                    //  TODO ждем реализации бэка -  вставить параметр текста с описанием обновлений
                    text: '',
                };
            case NotificationType.MONETIZATION_START:
                return {
                    text: notificationTextObj.message[NotificationType.MONETIZATION_START],
                };
            case NotificationType.COMMENT_DELETE_FOUL:
                return {
                    // TODO ждем реализации бэка -  вставить параметр текста коммента
                    text: '',
                };
            case NotificationType.POST_UNPUBLISH_FOUL:
                return {
                    // TODO ждем реализации бэка -  вставить параметр текста поста
                    text: '',
                };
            case NotificationType.ACCESS_MONETIZATION_GRANTED:
                return {
                    text: notificationTextObj.message[NotificationType.ACCESS_MONETIZATION_GRANTED],
                };
            case NotificationType.PRODUCT_ADDED:
            case NotificationType.PRODUCT_MODERATE_FALL:
            case NotificationType.PRODUCT_UNPUBLISH_FOUL:
                return {
                    text: (itemData?.object as DefaultProductItem)?.title || '',
                };
            default:
                return undefined;
        }
    };

    const renderActionButton = (itemData: NotificationItemDataType) => {
        switch (itemData.type) {
            // ждем реализацию бэка
            // case NotificationType.SUBSCRIBE_TO_YOU:
            // {
            //     const isSubscribed = itemData?.user?.subscribed || false;
            //
            //     return {
            //         pressed: !isSubscribed,
            //         text: isSubscribed
            //             ? notificationTextObj.actionBtnText[NotificationType.SUBSCRIBE_TO_YOU].subscribed
            //             : notificationTextObj.actionBtnText[NotificationType.SUBSCRIBE_TO_YOU].notSubscribed,
            //         onClick: () => {
            //             alert('action button click');
            //             // nothing
            //         },
            //     };
            // }
            //
            // case NotificationType.PRIVACY_POLICY_CHANGES: {
            //     return {
            //         text: notificationTextObj.actionBtnText[NotificationType.PRIVACY_POLICY_CHANGES],
            //         onClick: () => {
            //             alert('action button click');
            //             // nothing
            //         },
            //     };
            // }
            //
            // case NotificationType.WHATS_NEW: {
            //     return {
            //         text: notificationTextObj.actionBtnText[NotificationType.WHATS_NEW],
            //         onClick: () => {
            //             alert('action button click');
            //             // nothing
            //         },
            //     };
            // }
            //
            // case NotificationType.ACCESS_MONETIZATION_GRANTED: {
            //     return {
            //         text: notificationTextObj.actionBtnText[NotificationType.ACCESS_MONETIZATION_GRANTED],
            //         onClick: () => {
            //             alert('action button click');
            //             // nothing
            //         },
            //     };
            // }
            default:
                return undefined;
        }
    };

    const renderOnClickNotification = ({ event, itemData }: {
        event: React.MouseEvent<HTMLElement>,
        itemData: NotificationItemDataType
    }) => {
        event.stopPropagation();

        let url = '';

        switch (itemData.type) {
            case NotificationType.PRODUCT_ADDED:
            case NotificationType.MENTION_PUBLISH:
            case NotificationType.POST_LIKE:
                url = (itemData?.object as FeedRecordObj | DefaultProductItem)?.url;
                break;

            case NotificationType.COMMENT_ANSWERED:
            case NotificationType.PRODUCT_COMMENT:
            case NotificationType.POST_COMMENT:
                url = (itemData?.action as FeedRecordComment)?.href;
                break;

            case NotificationType.MENTION_COMMENT:
            case NotificationType.COMMENT_LIKE:
                url = (itemData?.object as FeedRecordComment)?.href;
                break;

            case NotificationType.MONETIZATION_START:
                url = `${baseUrl}/cabinet/monetization`;
                break;

            default:
                // ждем реализации бэка других уведомлений
                break;
        }

        if (url) {
            window.location.href = url;
        }
    };

    const timestamp = item?.timestamp;
    const timeAgo = timestamp && (<NotificationTimeAgo timestamp={timestamp} />);

    return (
        <NotificationComponent
            title={renderTitle(item)}
            message={renderMessage(item)}
            avatar={renderAvatar(item)}
            avatarBadge={renderAvatarBadge(item)}
            actionButton={renderActionButton(item)}
            eventTimeText={timeAgo}
            isUnread={item?.readDate === null}
            onClick={(e) => renderOnClickNotification({ event: e, itemData: item })}
            remove={deleteNotification}
            className={`autotest__header-notifications__item autotest__header-notifications__item__${NotificationType.MENTION_COMMENT}`}
        />
    );
};
