import React from 'react';

import sendGA from 'services/utils/send-ga';
import { AvatarDefaultProps } from 'components/avatar/prop-types';
import { Avatar as AvatarUI } from 'components/avatar/avatar';
import { AvatarProps } from './interfaces';

export default class Avatar extends React.PureComponent<AvatarProps> {
    private avatarUIRef = React.createRef<HTMLDivElement | HTMLAnchorElement>();

    private get userId() {
        const { person, id } = this.props;
        const idValue = id || person?.id;
        if (typeof idValue === 'number') return idValue;
        return (idValue) ? parseInt(idValue, 10) : null;
    }

    private handleClickLink = (e: React.MouseEvent<HTMLElement>) => {
        const { onClickLink } = this.props;
        if (!this.userId) return;
        sendGA('profile-info-group', ['finbox', this.userId]);
        if (!onClickLink) return;
        onClickLink(e);
    };

    public render() {
        return (
            <AvatarUI
                {...this.props}
                onClickLink={this.handleClickLink}
                ref={this.avatarUIRef}
                enabledUserTooltip={false}
                userTooltipOptions={{
                    allowCurrentUser: false,
                }}
            />
        );
    }
}

// TS желает от классовых компонентов делать defaultProps в static
// @storybook/react-docgen-typescript-plugin намертво падает при использовании static с defaultProps
// при чем проблема только с классовыми
// так как в будущем мы вовсе не планируем классовых компонентов
// то надо либо переделать этот в функциональный, либо оставить игнор
// @ts-ignore
Avatar.defaultProps = AvatarDefaultProps;

export { AvatarUI };
