import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { useTheme } from 'styled-components';

import { useDictionary } from 'hooks/useDictionary';
import { VerifiedAccountIcon } from 'components/icons2/VerifiedAccountIcon';
import {
    IconsWrapper,
    VerifiedIconName,
    VerifiedIconsTooltip,
    VerifiedIconsTooltipItem,
} from 'components/icons2/verified-icon/styled';
import { Tooltip } from 'components/tooltip';
import { VerifiedAuthorIcon } from 'components/icons2/VerifiedAuthorIcon';

type Props = {
    className?: string;
    showTooltip?: boolean,
    isVerifiedAccount: boolean;
    isVerifiedAuthor: boolean;
}

export function VerifiedIcon(props: Props) {
    const {
        className, showTooltip, isVerifiedAccount, isVerifiedAuthor,
    } = props;
    const size = 16;
    const theme = useTheme();
    const dic = useDictionary();

    const icons = (
        <IconsWrapper className={cc(['verified-icon-link', className])}>
            {isVerifiedAccount && (
                <VerifiedAccountIcon
                    width={size}
                    height={size}
                    fill={theme.label.accent}
                />
            )}
            {isVerifiedAuthor && (
                <VerifiedAuthorIcon
                    width={size}
                    height={size}
                    fill={theme.label.accent}
                />
            )}
        </IconsWrapper>
    );

    return (
        showTooltip ? (
            <Tooltip
                trigger="mouseenter"
                placement="bottom-end"
                content={(
                    <VerifiedIconsTooltip>
                        {isVerifiedAccount && (
                            <VerifiedIconsTooltipItem>
                                <VerifiedAccountIcon
                                    width={size}
                                    height={size}
                                    fill={theme.label.accent}
                                />
                                <VerifiedIconName>
                                    {dic.word('wt_all__verified_account_icon__icon_tooltip')}
                                </VerifiedIconName>
                            </VerifiedIconsTooltipItem>
                        )}
                        {isVerifiedAuthor && (
                            <VerifiedIconsTooltipItem>
                                <VerifiedAuthorIcon
                                    width={size}
                                    height={size}
                                    fill={theme.label.accent}
                                />
                                <VerifiedIconName>
                                    {dic.word('wt_all__verified_author_icon__icon_tooltip')}
                                </VerifiedIconName>
                            </VerifiedIconsTooltipItem>
                        )}
                    </VerifiedIconsTooltip>
                )}
            >
                {icons}
            </Tooltip>
        ) : icons
    );
}

VerifiedIcon.propTypes = {
    className: PropTypes.string,
    showTooltip: PropTypes.bool,
    isVerifiedAccount: PropTypes.bool,
    isVerifiedAuthor: PropTypes.bool,
};

VerifiedIcon.defaultProps = {
    className: '',
    showTooltip: true,
    isVerifiedAccount: false,
    isVerifiedAuthor: false,
};
