import styled, { css } from 'styled-components';
import { mediaQuery } from 'app/styled';

const marginRightDefaultColumn = css`margin-right: 24px;`;

export const Header = styled.header`
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 16px rgba(56, 124, 255, 0.08);
`;
export const Container = styled.div`
    width: 100%;
    max-width: 1400px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 12px 40px;
    border-bottom: 1px solid #E6EFFF;

    @media (max-width:768px) {
        padding: 8px 16px 8px 8px;
    }
`;

const Column = styled.div`
    display: flex;
    align-items: center;
`;
export const LogoColumn = styled(Column)`
    ${marginRightDefaultColumn};
`;
export const MainMenuColumn = styled(Column)`
    ${marginRightDefaultColumn};

    @media (max-width: 768px) {
        display: none;
    }
`;
export const SearchColumn = styled(Column)`
    ${marginRightDefaultColumn};
    width: 320px;

    display: flex !important;
`;
export const ActionButtonColumn = styled(Column)`
    padding-right: 30px;

    @media (max-width: 960px) {
        padding-right: 24px;
    }

    @media (max-width:768px) {
        display: none;
    }

`;

export const HeaderSeparator = styled.div`
    display: flex;
    flex-grow: 1;
`;
// export const MessagesColumn = styled(Column)`
//     padding-right: 22px;
//
//     @media (max-width: 959px) {
//         display: none;
//     }
// `;
// export const NotificationsColumn = styled(Column)`
//     padding-right: 12px;
//
//     @media (max-width: 959px) {
//         display: none;
//     }
// `;
export const NotificationsColumn = styled(Column)`
    padding-right: 8px;

    ${mediaQuery.lt768} {
        padding-right: 4px;
    }
`;
export const LangColumn = styled(Column)`
    margin-right: 8px;

    @media (max-width: 768px) {
        display: none;
    }
`;
export const SearchButtonColumn = styled(Column)`
    display: none;
    padding-right: 8px;
    padding-left: 24px;

    ${mediaQuery.lt768} {
        display: flex;
    }
`;
export const UserMenuColumn = styled(Column)<{ isUserLoggedIn: boolean; }>`
    ${({ isUserLoggedIn }) => !isUserLoggedIn && css`
        @media (max-width: 768px) {
            display: none;
        }
    `}
`;

export const HamburgerColumn = styled(Column)`
    padding-right: 0;

    @media (min-width: 769px) {
        display: none;
    }
`;
