import { useCallback } from 'react';

const DEFAULT_MENU_HEIGHT = 64;
const SCROLL_OFFSET = 16;

export const useScrollToPosition = (topPosition:number | null) => {
    return useCallback(() => {
        if (topPosition && topPosition !== 0) {
            const menuBottom = window.WT?.menu?.offsetBottom?.()
                || DEFAULT_MENU_HEIGHT;
            const windowScrollToPosition = topPosition - (menuBottom + SCROLL_OFFSET);

            window.scrollTo({
                top: windowScrollToPosition,
                behavior: 'smooth',
            });
        }
    }, [topPosition]);
};
