import {
    Action, GeneratorType, Id, ThunkResultFn,
} from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';

import UrlProcessor from 'services/url-processor';

type SubscribeArgs = [
    {
        generatorId: Id;
        csrf: string;
        generatorType?: GeneratorType;
        currentUserId: Id,
        action?: 'subscribe' | 'unsubscribe';
    }
]
export type SubscribePayloadData = {
    status: {
        subscription: boolean;
    };
};
export type SubscribePayloadPass = Omit<SubscribeArgs[0], 'csrf'>;
export const SUBSCRIBE_ACTION_TYPES = createRequestActionTypes('SUBSCRIBE');
export function subscribe(
    data: SubscribeArgs[0],
): ThunkResultFn<SubscribePayloadData, SubscribePayloadPass> {
    const {
        generatorId,
        generatorType,
        action,
        csrf,
        currentUserId,
    } = data;

    return thunkActions({
        types: SUBSCRIBE_ACTION_TYPES,
        payload: {
            request: {
                url: UrlProcessor.page('limex_api_activity_subscription_manage')
                    .params({ generatorId, generatorType: generatorType || GeneratorType.USER })
                    .path(),
                method: action === 'subscribe' ? 'POST' : 'DELETE',
                data: { csrf },
                withCredentials: true,
            },
            pass: {
                generatorId,
                generatorType,
                action,
                currentUserId,
            },
        },
    });
}
export type Subscribe = Action<typeof subscribe>;
