import React from 'react';
import Dic from 'services/dictionary';

import { CloseIcon } from 'components/icons2/CloseIcon';
import {
    Container,
    Title,
    SubTitle,
    StyledButton,
    Content,
    Header,
    CloseButton,
} from './styled';

type TProps = {
    showButtonClose?: boolean;
    onClose?: () => void;
    locatedInModal?: boolean;
    action: () => void,
};

const CallForRegistration = (
    {
        action,
        showButtonClose = false,
        onClose = () => false,
        locatedInModal = false,
    }: TProps,
) => {
    return (
        <Container locatedInModal={locatedInModal}>
            <Content locatedInModal={locatedInModal}>
                <Header>
                    <Title locatedInModal={locatedInModal}>
                        { Dic.word('wt_feed__interest__title_call_for_signup') }
                    </Title>
                    {
                        showButtonClose && (
                            <CloseButton onClick={() => onClose()}>
                                <CloseIcon />
                            </CloseButton>
                        )
                    }
                </Header>
                <SubTitle locatedInModal={locatedInModal}>
                    { Dic.word('wt_feed__interest__subtitle_call_for_signup') }
                </SubTitle>
            </Content>
            <StyledButton size="small" locatedInModal={locatedInModal} onClick={() => action()}>
                { Dic.word('wt_feed__interest__button_signup_text') }
            </StyledButton>
        </Container>
    );
};

export default CallForRegistration;
