import React from 'react';
import { useTheme } from 'styled-components';

import useImageLoaded from 'hooks/useImageLoaded';

import * as Styled from './styled';

import {
    AvatarIconNotificationType,
    AvatarPhotoNotificationType,
} from './types';

type NotificationAvatarPropsType = {
    avatar?: AvatarIconNotificationType | AvatarPhotoNotificationType;
};

export const NotificationAvatar = (props: NotificationAvatarPropsType) => {
    const { avatar } = props;
    const theme = useTheme();

    const {
        ref: avatarImgRef,
        isLoaded: avatarImgIsLoaded,
        hasError: avatarImgHasError,
        onLoad: avatarImgOnLoad,
        onError: avatarImgOnError,
    } = useImageLoaded();

    const AvatarIcon = avatar?.type === 'icon' && avatar?.Icon;

    return avatar?.type === 'image' ? (
        <Styled.AvatarImg
            ref={avatarImgRef}
            src={avatar.src}
            alt={avatar.alt}
            isError={avatarImgHasError}
            isLoaded={avatarImgIsLoaded}
            onLoad={avatarImgOnLoad}
            onError={avatarImgOnError}
        />
    )
        : (
            <Styled.AvatarIconWrapper>
                {
                    AvatarIcon && typeof AvatarIcon === 'function'
                        ? React.createElement(AvatarIcon, {
                            fill: theme.bg.secondary,
                        }, null)
                        : AvatarIcon
                }
            </Styled.AvatarIconWrapper>
        );
};
