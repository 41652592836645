import React from 'react';

import { Wrapper } from './styled';

type Props = {
    children: React.ReactNode | string,
};

export const Title = ({ children }: Props) => {
    return (
        <Wrapper>{children}</Wrapper>
    );
};
