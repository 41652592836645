import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TickersResult from './TickersResult';
import UsersResult from './UsersResult';
import PostsResult from './PostsResult';
import ProductsResult from './ProductsResult';

const SearchResultsElementsWrapper = styled.div`
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    overflow-y: none;
    background: transparent;
`;

const SearchResultsElements = ({
    people,
    posts,
    instruments,
    products,
    log,
    activeTab,
    searchInput,
    cancelSearch,
    isLoading,
    toggleTickerSubscribed,
}) => {
    return (
        <SearchResultsElementsWrapper>
            {{
                instruments: <TickersResult
                    {...{
                        log,
                        searchInput,
                        instruments,
                        isLoading,
                        toggleTickerSubscribed,
                    }}
                />,
                people: <UsersResult
                    {...{
                        log,
                        searchInput,
                        people,
                        isLoading,
                    }}
                />,
                posts: <PostsResult
                    {...{
                        log,
                        searchInput,
                        posts,
                        cancelSearch,
                        isLoading,
                    }}
                />,
                products: <ProductsResult
                    {...{
                        log,
                        searchInput,
                        products,
                        cancelSearch,
                        isLoading,
                    }}
                />,
            }[activeTab]}
        </SearchResultsElementsWrapper>
    );
};

SearchResultsElements.propTypes = {
    people: PropTypes.array.isRequired,
    posts: PropTypes.array.isRequired,
    instruments: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    log: PropTypes.shape({}).isRequired,
    searchInput: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    cancelSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toggleTickerSubscribed: PropTypes.func.isRequired,
};

export default SearchResultsElements;
