import React from 'react';
import PropTypes from 'prop-types';

export function Back({ className }) {
    return (
        <svg className={className} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.87901 13.1378C6.12423 13.3916 6.53236 13.3916 6.78616 13.1378C7.03138 12.8926 7.03138 12.4845 6.78616 12.2398L2.18641 7.64006L17.3649 7.64006C17.7188 7.63949 18 7.35826 18 7.00443C18 6.6506 17.7188 6.36023 17.3649 6.36023L2.18641 6.36023L6.78616 1.76905C7.03138 1.51526 7.03138 1.10656 6.78616 0.861907C6.53236 0.608112 6.12366 0.608112 5.87901 0.861907L0.190348 6.55057C-0.0634455 6.79579 -0.0634455 7.20392 0.190348 7.44857L5.87901 13.1378Z" />
        </svg>
    );
}

Back.propTypes = {
    className: PropTypes.string,
};

Back.defaultProps = {
    className: '',
};
