import { MentionUser } from '../types';

export const detectKey = (key: string, content: string, element: HTMLTextAreaElement): number | null => {
    const { selectionStart } = element;

    const lastLetter = content[selectionStart - 1];
    const previousLetter = selectionStart === 1 ? ' ' : content[selectionStart - 2];

    if (lastLetter === key) {
        if (/\S/i.test(previousLetter)) {
            return null;
        }

        return selectionStart;
    }

    return null;
};

export const searchPhrase = (content: string, position: number, element: HTMLInputElement | HTMLTextAreaElement): string => {
    const { selectionStart } = element;

    return content.substring(position, selectionStart || 0);
};

export const insertText = (content: string, text: string, startPos: number, element: HTMLInputElement | HTMLTextAreaElement) => {
    const { selectionStart } = element;

    const left = content.substring(0, startPos - 1);
    const right = content.substring(selectionStart || 0).trim();

    const caretPosition = left.length + text.length;

    return {
        text: `${left}${text}${right}`,
        callback: () => {
            element.focus();
            element.setSelectionRange(caretPosition, caretPosition);
        },
    };
};

export const mapAddedUsersNicknamesToIdInText = (text: string, userMap: Record<string, MentionUser>) => {
    let result = text;
    Object.values(userMap).forEach((user) => {
        const { displayName, id } = user;
        const slashedItem = displayName.trim().replace(/([^\w])/g, '\\$1');
        result = result.replace(new RegExp(`@${slashedItem}([\\s,.;:]?)`, 'gi'), (match, p1) => {
            return `@${id}${p1}`;
        });
    });

    return result;
};
