import { backendClient } from 'services/backend-client';
import { projectsLinks } from '../../../services/constants';

const { whotradesLink, limexLink } = projectsLinks;

export const sendRequest = (request) => {
    const requestPromise = backendClient({
        ...request,
    });

    return requestPromise.then((payload) => {
        return payload;
    }).catch((error) => {
        return error;
    });
};

export const subscribe = (userId) => {
    return sendRequest({
        url: `${whotradesLink}/capi/activity_stream/subscribe/${userId}/1/json`,
        method: 'POST',
        data: {},
        withCredentials: true,
    });
};

export const subscribeToTicker = ({ instrumentId, isSubscribed, csrf }) => {
    return sendRequest({
        url: `${limexLink}/subscription/${Number(instrumentId)}/5/${isSubscribed ? 'delete' : 'add'}/`,
        method: 'POST',
        data: { csrf },
        withCredentials: true,
    });
};
