import styled from 'styled-components';

import { mediaQuery, labelPrimary } from 'app/styled';

export const FeedColumn = styled.div``;

const wrapperMarginTop = 24;

export const SidebarColumn = styled.div`
    ${mediaQuery.lt768} {
        width: 100%;
    }
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    top: ${(window.WT.menu?.offsetBottom?.() || 0) + wrapperMarginTop}px;
    position: sticky;
    align-self: flex-start;
    z-index: 2;
    ${mediaQuery.lt768} {
        top: ${window.WT.menu?.offsetBottom?.() || 0}px;
    }
`;

export const WatchlistContainer = styled.div``;

export const columns = {
    gteq1400: [760, 312],
    lt1400: [652, 268],
    lt1200: [544, 224],
    lt960: [444, 260],
};
export const columnGap = 24;
const buildColumns = (type: keyof typeof columns) => {
    const [feedWidth, watchlistWidth] = columns[type];
    return `
        grid-template-columns: ${feedWidth}px ${watchlistWidth}px;
        ${WatchlistContainer} {
            width: ${watchlistWidth}px;
        }
    `;
};
export const Wrapper = styled.div`
    display: grid;
    margin: auto;
    box-sizing: border-box;
    grid-template-rows: auto;

    min-height: calc(100% - 64px);
    margin-top: ${wrapperMarginTop}px;

    justify-content: center;
    column-gap: ${columnGap}px;
    color: ${labelPrimary};
    height: 100%;

    ${mediaQuery.gteq1400} { ${buildColumns('gteq1400')} }
    ${mediaQuery.lt1400} { ${buildColumns('lt1400')} }
    ${mediaQuery.lt1200} { ${buildColumns('lt1200')} }
    ${mediaQuery.lt960} {
        ${buildColumns('lt960')}
        font-size: 14px;
        line-height: 20px;
    }
    ${mediaQuery.lt768} {
        display: flex;
        flex-direction: column-reverse;
        padding-right: 4px;
        padding-left: 4px;
        margin-top: 0;
    }
`;
