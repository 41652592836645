import { Action, Id, ThunkResultFn } from 'types';
import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';

export type DeleteFeedRecordPayloadData = {
    errors?: Array<{
        code: string,
        field: string,
        message: string,
    }>
}
export const DELETE_FEED_RECORD_ACTION_TYPES = createRequestActionTypes('DELETE_FEED_RECORD');
export const deleteFeedRecord = (id: Id, csrf: string): ThunkResultFn<DeleteFeedRecordPayloadData> => {
    return thunkActions({
        types: DELETE_FEED_RECORD_ACTION_TYPES,
        payload: {
            request: {
                method: 'DELETE',
                url: UrlProcessor.page('limex_api_blogpost_manage').param('postId', id).path(),
                data: { csrf },
                withCredentials: true,
            },
        },
    });
};
export type DeleteFeedRecord = Action<typeof deleteFeedRecord>;
