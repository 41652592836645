import styled from 'styled-components';
import { mediaQuery } from 'app/styled';

export const DesktopSkeletonWrapper = styled.div`
    ${mediaQuery.lt768} {
        display: none;
    }
`;

export const MobileSkeletonWrapper = styled.div`
    display: none;

    ${mediaQuery.lt768} {
        display: block;
    }
`;
