import store from '../store';
import { ssProvider } from './sessionStorageProvider';

import { menuLogger } from './menuLogger';

export const userCache = {
    set(user) {
        if (window.__GLOBAL_AUTH__) {
            return;
        }
        if (user?.id) {
            const { userCacheTtlSeconds } = store.getState();
            menuLogger.log(`Set userCache ${JSON.stringify(user)} ttl: ${userCacheTtlSeconds}`);
            ssProvider.set('wt_menu_user', user, userCacheTtlSeconds);
        }
    },
    delete() {
        if (window.__GLOBAL_AUTH__) {
            return;
        }
        menuLogger.log('Delete user cache!');
        ssProvider.delete('wt_menu_user');
    },
    get() {
        if (window.__GLOBAL_AUTH__) {
            return;
        }
        menuLogger.log('Get user from cache!');
        return ssProvider.get('wt_menu_user');
    },
};
