import { useMemo } from 'react';
import { useDictionary } from 'hooks/useDictionary';
import { NotificationType } from 'widgets/header-notifications/types';
import { textObjType } from 'widgets/header-notifications/components/index';

export function useGetDicwordTextObj(): textObjType {
    const dic = useDictionary();

    return useMemo(() => ({
        title: dic.word('wt_menu__widget__header_notifications__title_text'),
        emptyText: dic.word('wt_menu__widget__header_notifications__empty_msg'),
        errorContent: {
            text: dic.word('wt_menu__widget__header_notifications__error__text'),
            actionBtnText: dic.word('wt_menu__widget__header_notifications__error__action_btn_text'),
        },
        settingsBtnText: dic.word('wt_menu__widget__header_notifications__settings_btn_text'),
        groupTitles: {
            unread: dic.word('wt_menu__widget__header_notifications__group_title_unread'),
            read: dic.word('wt_menu__widget__header_notifications__group_title_read'),
        },
        notification: {
            title: {
                [NotificationType.POST_LIKE]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.POST_LIKE}`),
                },
                [NotificationType.COMMENT_LIKE]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.COMMENT_LIKE}`),
                },
                [NotificationType.POST_COMMENT]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.POST_COMMENT}`),
                },
                [NotificationType.PRODUCT_COMMENT]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.PRODUCT_COMMENT}`),
                },
                [NotificationType.COMMENT_ANSWERED]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.COMMENT_ANSWERED}`),
                },
                [NotificationType.MENTION_PUBLISH]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.MENTION_PUBLISH}`),
                },
                [NotificationType.MENTION_COMMENT]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.MENTION_COMMENT}`),
                },
                [NotificationType.SUBSCRIBE_TO_YOU]: {
                    startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                    endPhrase: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.SUBSCRIBE_TO_YOU}`),
                },
                [NotificationType.SOFT_BAN]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.SOFT_BAN}`),
                },
                [NotificationType.PRIVACY_POLICY_CHANGES]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.PRIVACY_POLICY_CHANGES}`),
                },
                [NotificationType.WHATS_NEW]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.WHATS_NEW}`),
                },
                [NotificationType.MONETIZATION_START]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.MONETIZATION_START}`),
                },
                [NotificationType.COMMENT_DELETE_FOUL]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.COMMENT_DELETE_FOUL}`),
                },
                [NotificationType.POST_UNPUBLISH_FOUL]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.POST_UNPUBLISH_FOUL}`),
                },
                [NotificationType.ACCESS_MONETIZATION_GRANTED]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.ACCESS_MONETIZATION_GRANTED}`),
                },
                [NotificationType.PRODUCT_ADDED]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.PRODUCT_ADDED}`),
                },
                [NotificationType.PRODUCT_MODERATE_FALL]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.PRODUCT_MODERATE_FALL}`),
                },
                [NotificationType.PRODUCT_UNPUBLISH_FOUL]: {
                    text: dic.word(`wt_menu__widget__header_notifications__item__title__${NotificationType.PRODUCT_UNPUBLISH_FOUL}`),
                },
            },
            message: {
                [NotificationType.SOFT_BAN]: dic.word(`wt_menu__widget__header_notifications__item__msg__${NotificationType.SOFT_BAN}`),
                [NotificationType.PRIVACY_POLICY_CHANGES]: dic.word(`wt_menu__widget__header_notifications__item__msg__${NotificationType.PRIVACY_POLICY_CHANGES}`),
                [NotificationType.MONETIZATION_START]: dic.word(`wt_menu__widget__header_notifications__item__msg__${NotificationType.MONETIZATION_START}`),
                [NotificationType.ACCESS_MONETIZATION_GRANTED]: dic.word(`wt_menu__widget__header_notifications__item__msg__${NotificationType.ACCESS_MONETIZATION_GRANTED}`),
            },
            actionBtnText: {
                [NotificationType.SUBSCRIBE_TO_YOU]: {
                    subscribed: dic.word(`wt_menu__widget__header_notifications__item__action_btn__${NotificationType.SUBSCRIBE_TO_YOU}__subscribed`),
                    notSubscribed: dic.word(`wt_menu__widget__header_notifications__item__action_btn__${NotificationType.SUBSCRIBE_TO_YOU}__not_subscribed`),
                },
                [NotificationType.PRIVACY_POLICY_CHANGES]: dic.word(`wt_menu__widget__header_notifications__item__action_btn__${NotificationType.PRIVACY_POLICY_CHANGES}`),
                [NotificationType.WHATS_NEW]: dic.word(`wt_menu__widget__header_notifications__item__action_btn__${NotificationType.WHATS_NEW}`),
                [NotificationType.ACCESS_MONETIZATION_GRANTED]: dic.word(`wt_menu__widget__header_notifications__item__action_btn__${NotificationType.ACCESS_MONETIZATION_GRANTED}`),
            },
        },
    }), [dic]);
}
