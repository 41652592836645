import React, { useEffect, useRef, useState, useCallback } from 'react';

import { MagnifierIcon } from 'components/icons2/MagnifierIcon';
import { Popover } from 'components/popover';
import { useKeyPress } from 'hooks/useKeyPress';
import { useDictionary } from 'hooks/useDictionary';

import SearchResults from './search-results';

import {
    ResultsContainer,
    SearchInput,
    SearchMagnifierBox,
    SearchStyled,
    SearchWrapper,
    SearchStyledMobile,
    SearchMagnifierBoxMobile,
    LoadingIcon,
    CloseIcon,
    CloseIconWrapper,
} from './styled';
import { useSearchApi } from './use-search-api';

export function Search(props) {
    const dic = useDictionary();
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const {
        fetchData,
        reset,
        toggleTickerSubscribed,
        tickers,
        log,
        people,
        posts,
        products,
        isInstrumentsLoading,
        isPostsLoading,
        isProductsLoading,
        isPeopleLoading,
    } = useSearchApi();

    const {
        isSearchResultsVisible,
        setSearchResultsVisible,
    } = props;

    const [searchInputValue, setSearchInputValue] = useState('');
    const [isInputFocused, setInputFocused] = useState(false);

    const slashPressed = useCallback((e) => {
        const focusedElement = document.activeElement;

        if (!(focusedElement?.isContentEditable || focusedElement?.tagName === 'INPUT' || focusedElement?.tagName === 'TEXTAREA')) {
            e.preventDefault?.();
            inputRef.current?.focus?.();
            setInputFocused(true);
        }
    }, []);

    useKeyPress({ key: '/', onKeyDown: slashPressed });

    const inputValidator = (value) => {
        return /[^\s]/.test(value) || value === '';
    };

    const inputHandler = (event) => {
        const { value } = event.target;
        if (inputValidator(value)) {
            setSearchInputValue(value);
        }
    };

    const cancelSearch = () => {
        setSearchResultsVisible(false);
    };

    const cancelMobileSearch = () => {
        setSearchResultsVisible(false);
        reset();
        setSearchInputValue('');
    };

    const onFocus = () => {
        setInputFocused(true);

        if (searchInputValue.length > 0) {
            setSearchResultsVisible(true);
        }

        inputRef.current?.focus();
    };

    useEffect(() => {
        if (searchInputValue === '') {
            return;
        }
        setSearchResultsVisible(true);
        reset();
        fetchData(searchInputValue);
    }, [searchInputValue]);

    const isLoading = isPostsLoading || isInstrumentsLoading || isPeopleLoading || isProductsLoading;
    const isFirstLoaded = !isPostsLoading || !isInstrumentsLoading || !isPeopleLoading || !isProductsLoading;
    const magnifierIcon = !isFirstLoaded && isSearchResultsVisible
        ? <LoadingIcon />
        : <MagnifierIcon />;

    return (
        <SearchWrapper ref={wrapperRef}>
            <SearchStyled onClick={() => !isInputFocused && onFocus()}>
                <form>
                    <SearchMagnifierBox>
                        {magnifierIcon}
                    </SearchMagnifierBox>
                    <SearchInput
                        ref={inputRef}
                        value={searchInputValue}
                        onChange={inputHandler}
                        onFocus={() => onFocus()}
                        onBlur={() => setInputFocused(false)}
                        placeholder={dic.word('wt_menu__search_limex__placeholder')}
                        isLoading={isLoading}
                    />
                </form>
                {(searchInputValue.length > 0) && isSearchResultsVisible && (
                    <CloseIconWrapper onClick={() => {
                        setSearchInputValue('');
                        inputRef.current?.focus?.();
                    }}
                    >
                        <CloseIcon />
                    </CloseIconWrapper>
                )}
            </SearchStyled>
            <ResultsContainer>
                <Popover
                    isVisible={isSearchResultsVisible}
                    onClose={cancelSearch}
                    include={wrapperRef.current}
                    width="320px"
                >
                    <SearchStyledMobile>
                        <SearchMagnifierBoxMobile>
                            {magnifierIcon}
                        </SearchMagnifierBoxMobile>
                        <SearchInput
                            value={searchInputValue}
                            onChange={inputHandler}
                            onFocus={() => onFocus()}
                            onBlur={() => setInputFocused(false)}
                            placeholder={dic.word('wt_menu__limex_project__search_placeholder')}
                            isLoading={isLoading}
                        />
                        <CloseIconWrapper onClick={cancelMobileSearch}>
                            <CloseIcon />
                        </CloseIconWrapper>
                    </SearchStyledMobile>
                    <SearchResults
                        instruments={tickers}
                        log={log}
                        people={people}
                        posts={posts}
                        products={products}
                        isPeopleLoading={isPeopleLoading}
                        isInstrumentsLoading={isInstrumentsLoading}
                        isProductsLoading={isProductsLoading}
                        isPostsLoading={isPostsLoading}
                        searchInput={searchInputValue}
                        inputRef={inputRef}
                        isInputFocused={isInputFocused}
                        cancelSearch={cancelSearch}
                        isLoading={isLoading}
                        toggleTickerSubscribed={toggleTickerSubscribed}
                    />
                </Popover>
            </ResultsContainer>
        </SearchWrapper>
    );
}
