import React, { useState, useEffect } from 'react';

import Dic from 'services/dictionary';
import notify from 'services/notify';
import authPopup from 'services/auth-popup';
import { tagsStorageCleanup } from 'services/utils/local-storage';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { SubscribedTags, Tag as TTag } from 'widgets/feed/feed/actions';
import Tag from 'components/tag/Tag';
import { CloseIcon } from 'components/icons2/CloseIcon';
// icons
import { ArtIcon } from 'components/icons2/emoji/ArtIcon';
import { BitcoinIcon } from 'components/icons2/emoji/BitcoinIcon';
import { ChartIcon } from 'components/icons2/emoji/ChartIcon';
import { ForexIcon } from 'components/icons2/emoji/ForexIcon';
import { FireIcon } from 'components/icons2/emoji/FireIcon';
import { RUSFlagIcon } from 'components/icons2/emoji/RUSFlagIcon';
import { RocketIcon } from 'components/icons2/emoji/RocketIcon';
import { USAFlagIcon } from 'components/icons2/emoji/USAFlagIcon';
import { ThinkIcon } from 'components/icons2/emoji/ThinkIcon';
import { SheetTopCornIcon } from 'components/icons2/emoji/SheetTopCornIcon';
import { SheetBottomCornIcon } from 'components/icons2/emoji/SheetBottomCornIcon';
import { UmbrelaIcon } from 'components/icons2/emoji/UmbrelaIcon';
import { LightningIcon } from 'components/icons2/emoji/LightningIcon';
import { EarthIcon } from 'components/icons2/emoji/EarthIcon';

import { TBulkSubscription } from '../actions';
import CallForRegistration from '../call-for-registration/CallForRegistration';
import ThankForRegistration from '../thank-for-registration/ThankForRegistration';

import {
    Container, StyledButton, Title, SubTitle, Wrapper, Header, SkipButton, ShowContainer, CloseButton,
} from './styled';

type TProps = {
    tags?: TTag[],
    subscribedTags?: SubscribedTags,
    showButtonClose?: boolean,
    isShowSkipButton?: boolean
    locatedInModal?: boolean,
};

type TDispatchProps = {
    onBulkSubscription: TBulkSubscription,
};

const ImagesList = {
    2: ForexIcon,
    3: FireIcon,
    4: ChartIcon,
    5: BitcoinIcon,
    6: RUSFlagIcon,
    7: USAFlagIcon,
    8: ThinkIcon,
    9: RocketIcon,
    10: SheetTopCornIcon,
    11: SheetBottomCornIcon,
    12: UmbrelaIcon,
    13: ArtIcon,
    14: LightningIcon,
    34: EarthIcon,
};

const Tags = (
    {
        tags,
        subscribedTags,
        onBulkSubscription,
        showButtonClose = true,
        isShowSkipButton = false,
        locatedInModal = false,
    }: TProps & TDispatchProps,
) => {
    const showTags = localStorage.getItem('limex__hide__tags');

    const [selectedTags, setSelectedTags] = useState<TTag[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowThankWindow, setIsShowThankWindow] = useState(false);
    const [isShowCallRegistration, setIsShowCallRegistration] = useState(false);
    const [isShowTags, setIsShowTags] = useState(!showTags);

    const csrf = useCSRFToken();
    const User = useCurrentUser();
    const isAuth = !!User.id;

    const onSelectTag = (tag: TTag) => {
        const doesTheTagExist = selectedTags.find((item: TTag) => item.id === tag.id);

        if (doesTheTagExist) {
            const value = selectedTags.filter((item: TTag) => item.id !== tag.id);
            setSelectedTags(value);
            localStorage.setItem('wt__feed__tags', JSON.stringify(value));
        } else {
            setSelectedTags([...selectedTags, tag]);
            localStorage.setItem('wt__feed__tags', JSON.stringify([...selectedTags, tag]));
        }
    };

    const handleClose = () => {
        setIsShowTags(false);
    };

    const isDisabled = !selectedTags.length;

    useEffect(() => {
        const storage = localStorage.getItem('wt__feed__tags');
        const isNewUser = localStorage.getItem('limex__selected__tags__before__registration');

        if (storage) {
            const tagList = JSON.parse(storage);
            setSelectedTags(tagList);

            if (isNewUser && isAuth) {
                handlerSubmit(tagList).then(() => {
                    tagsStorageCleanup();
                });
                setIsShowThankWindow(true);
            } else {
                const isHide = localStorage.getItem('limex__hide__tags');

                if (!isAuth && isHide) setIsShowCallRegistration(true);
            }
        } else if (subscribedTags?.length === 0) {
            tagsStorageCleanup();
        }
    }, []);

    const openModalRegistration = () => {
        authPopup.registration();
    };

    const handlerSubmit = async (tagList = null) => {
        let generators;
        if (!tagList) {
            generators = selectedTags.map((item) => ({ id: item.id, type: item.type }));
        } else {
            generators = tagList;
        }

        setIsLoading(true);
        if (isAuth) {
            try {
                await onBulkSubscription({ csrf, generators });
            } catch (e) {
                notify.error(e);
            }
        } else {
            localStorage.setItem('limex__selected__tags__before__registration', 'true');
            setIsShowCallRegistration(true);
        }
        localStorage.setItem('limex__hide__tags', 'true');
        handleClose();
        setIsLoading(false);
    };

    return (
        <>
            {
                isShowTags && tags && !subscribedTags?.length && (
                    <Wrapper locatedInModal={locatedInModal}>
                        <div>
                            <Header>
                                <Title>
                                    {Dic.word('wt_feed__interest__tags_title')}
                                </Title>
                                {
                                    showButtonClose && (
                                        <CloseButton onClick={() => handleClose()}>
                                            <CloseIcon />
                                        </CloseButton>
                                    )
                                }
                            </Header>
                            <SubTitle>
                                {Dic.word('wt_feed__interest__tags_subtitle')}
                            </SubTitle>
                            <ShowContainer>
                                <Container locatedInModal={locatedInModal}>
                                    {
                                        tags.map((tag) => {
                                            const img = ImagesList[tag.id] ? ImagesList[tag.id] : null;
                                            return (
                                                <Tag
                                                    key={tag.id}
                                                    size="medium"
                                                    Icon={img}
                                                    text={tag.name}
                                                    isActive={!!selectedTags.find((item: TTag) => item.id === tag.id)}
                                                    onChange={() => onSelectTag(tag)}
                                                    style={{
                                                        marginBottom: '8px',
                                                        marginRight: '8px',
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                </Container>
                            </ShowContainer>
                        </div>
                        <div>
                            <StyledButton
                                locatedInModal={locatedInModal}
                                size="small"
                                isDisabled={isDisabled}
                                isProcessing={isLoading}
                                onClick={() => handlerSubmit()}
                            >
                                {isDisabled ? Dic.word('wt_feed__interest__button_tags_first_state')
                                    : Dic.word('wt_feed__interest__select_button')}
                            </StyledButton>
                            {
                                isShowSkipButton && (
                                    <SkipButton
                                        locatedInModal={locatedInModal}
                                        kind="ghost-secondary"
                                        size="small"
                                        onClick={() => handleClose()}
                                    >
                                        {Dic.word('wt_feed__interest__skip_button')}
                                    </SkipButton>
                                )
                            }
                        </div>
                    </Wrapper>
                )
            }
            {
                isShowCallRegistration && !subscribedTags?.length && (
                    <CallForRegistration
                        action={openModalRegistration}
                        onClose={() => setIsShowCallRegistration(false)}
                    />
                )
            }
            {
                isShowThankWindow && (
                    <ThankForRegistration onClose={() => setIsShowThankWindow(false)} />
                )
            }
        </>
    );
};

export default Tags;
