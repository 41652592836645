import React, { useEffect, useRef, useState } from 'react';

import { FeedRecord, FeedRecordType, Id } from 'types';

import { FeedItemContextProvider } from 'context/feed-item';
import { useScrollToPosition } from 'hooks/useScrollToPosition';
import { useViewed } from 'hooks/useViewed';
import { GalleryInitFunction } from 'components/gallery/Gallery';
import { DeleteFeedRecord, UpdateFeedRecord } from '../actions';
import { useShowFullChange } from '../hooks/useShowFullChange';
import { getFeedItemContextDefaultValue } from '../services/feed-item-context';
import { ItemWrapper } from './styled';
import { PublishStatus } from './items/PublishStatus';
import { TickerNews } from './items/ticker-news';
import { RecommendationsItem } from './items/recommendations';
import { AdsItem } from './items/ads';

interface Props {
    record: FeedRecord,
    onItemViewedChange: (entry: IntersectionObserverEntry, record: FeedRecord) => void,
    onRecordShowFull: (recordId: Id) => void,
    updateFeedRecord?: UpdateFeedRecord,
    deleteFeedRecord?: DeleteFeedRecord,
    className?: string,
    onClickAnyImgInPost?: GalleryInitFunction,
    pinned?: boolean;
}
const FeedItemComponent = (props: Props) => {
    const {
        record, onItemViewedChange, onRecordShowFull, onClickAnyImgInPost, className,
        pinned, deleteFeedRecord, updateFeedRecord,
    } = props;
    const itemRef = useRef<HTMLDivElement>(null);

    const [highlighted, setHighlighted] = useState(pinned);

    // dg: Управление подсветкой закрепленной записи
    useEffect(() => {
        if (pinned) {
            // dg: Время активной подсветки записи. После чего подсветка выключится
            const highlightTime = 12000;
            const timeout = setTimeout(() => setHighlighted(false), highlightTime);
            return () => clearTimeout(timeout);
        }
    }, [pinned]);

    const itemRefOffsetTopPosition = itemRef.current?.offsetTop;
    const scrollToPosition = useScrollToPosition(itemRefOffsetTopPosition || null);
    // Проскролливаемся к закрепленной записи
    useEffect(() => {
        if (pinned && itemRef.current) {
            scrollToPosition();
        }
    }, [pinned, scrollToPosition]);

    useShowFullChange(onRecordShowFull);

    const [isShowRecommendations, setIsShowRecommendations] = useState(true);

    const selectByType = () => {
        switch (record.type) {
            case FeedRecordType.PUBLISH_STATUS:
                return (
                    <PublishStatus
                        record={record}
                        updateFeedRecord={updateFeedRecord}
                        deleteFeedRecord={deleteFeedRecord}
                        onClickAnyImgInPost={onClickAnyImgInPost}
                        highlighted={highlighted}
                        pinned={pinned}
                    />
                );
            case FeedRecordType.TICKER_NEWS:
                return (
                    <TickerNews {...props} />
                );
            case FeedRecordType.RECOMMENDATIONS:
                return isShowRecommendations && (
                    <RecommendationsItem
                        record={record}
                        hide={() => setIsShowRecommendations(false)}
                    />
                );
            case FeedRecordType.ADS:
                return (
                    <AdsItem />
                );
            default:
                return null;
        }
    };

    useViewed(
        itemRef,
        (entry: IntersectionObserverEntry) => {
            onItemViewedChange(entry, record);
        },
        (observerRef) => !observerRef.current,
    );

    const item = selectByType();
    if (!item) return null;
    return (
        <ItemWrapper ref={itemRef} className={`${className} autocheck__feed__item`} data-record-id={record.id}>
            {item}
        </ItemWrapper>
    );
};

export const FeedItem = (props: Props & { showFull?: boolean; }) => {
    const { record, showFull } = props;
    return (
        <FeedItemContextProvider initialValue={getFeedItemContextDefaultValue(record, showFull)} key={record.id}>
            <FeedItemComponent {...props} />
        </FeedItemContextProvider>
    );
};
