import { BUSINESS_METRICS_NEW_VISITOR, BUSINESS_METRICS_ADD_EVENT, BUSINESS_METRICS_RETURN } from './constants.js';

let isAllowSend = false;
let userToken = '';

const localStorageName = 'wt_menu_bmvik';
export const bmvikStorage = {
    save: token => localStorage.setItem(localStorageName, token),
    get: () => localStorage.getItem(localStorageName),
    remove: () => localStorage.removeItem(localStorageName),
};

const sendQueue = [];
const activeSendQueue = [];

const maxDataLayerSize = 200;

function pushEventToDataLayer(project, action, type, additionalParams, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'businessMetric',
        project,
        action,
        type,
        additionalParams,
        value,
    });

    // dg: Ограничиваем количество элементов в массиве
    if (window.dataLayer.length > maxDataLayerSize) {
        window.dataLayer.splice(0, window.dataLayer.length - maxDataLayerSize);
    }
}

function waitAllowSend() {
    return new Promise((resolve) => {
        if (isAllowSend) {
            resolve();
        } else {
            sendQueue.push(resolve);
        }
    });
}

function allowSend() {
    if (isAllowSend) {
        return;
    }

    isAllowSend = true;
    sendQueue.forEach(resolve => resolve());
    sendQueue.length = 0;
}

async function send(url, formData) {
    // dg: преобразуем FormData в queryString @since #WTT-9315
    const query = [...formData.entries()].map(e => [encodeURIComponent(e[0]), encodeURIComponent(e[1])].join('=')).join('&');

    const headers = new Headers();
    if (userToken) {
        headers.set('X-User-Token', userToken);
    }
    const bmvik = bmvikStorage.get();
    if (bmvik) {
        headers.set('X-BMVIK', bmvik);
    }

    const promise = fetch(`${url}?${query}`, {
        credentials: 'include',
        headers,
    });

    activeSendQueue.push(promise);
    promise.finally(() => {
        const index = activeSendQueue.indexOf(promise);
        if (index > -1) {
            activeSendQueue.splice(index, 1);
        }
    });

    promise.then(response => response.json()).then((result) => {
        if (result && result.bmvik) {
            bmvikStorage.save(result.bmvik);
        }
    });

    return promise;
}

async function addMetric(project, event, type, additionalParams, value) {
    pushEventToDataLayer(project, event, type, additionalParams, value);

    const data = new FormData();
    data.append('project', project);
    data.append('action', event);
    data.append('type', type);
    if (Array.isArray(additionalParams)) {
        additionalParams.forEach(param => data.append('additionalParams[]', param));
    }
    if (typeof value !== 'undefined') {
        data.append('value', value);
    }

    await waitAllowSend();

    return send(BUSINESS_METRICS_ADD_EVENT, data);
}

export default {
    allowSend,

    async getActiveSendPromise() {
        if (activeSendQueue.length === 0) {
            return Promise.resolve();
        }

        return Promise.all(activeSendQueue);
    },

    async returnToProject(project) {
        const data = new FormData();
        data.append('project', project);

        await waitAllowSend();

        return send(BUSINESS_METRICS_RETURN, data);
    },

    setUserToken(token) {
        userToken = token;
    },

    setBmvik(value) {
        bmvikStorage.save(value);
    },

    async addNewVisitor(project, url, referer) {
        const data = new FormData();
        data.append('project', project);
        data.append('url', url);
        data.append('referer', referer);

        return send(BUSINESS_METRICS_NEW_VISITOR, data);
    },

    addEvent(project, event, additionalParams) {
        return addMetric(project, event, 'count', additionalParams);
    },

    addTiming(project, event, time, additionalParams) {
        return addMetric(project, event, 'timer', additionalParams, time);
    },
};
