import React, {
    useMemo, useRef, useEffect,
} from 'react';
import * as Sentry from '@sentry/browser';
import noop from 'lodash/noop';
import styled from 'styled-components';
import 'fileapi';

import {FeedRecordComment, FileType, Id} from 'types';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import getMentionMetadata from 'services/utils/get-mentions-metadata';
import UrlProcessor from 'services/url-processor';
import useStateCallback from 'hooks/useStateCallback';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Views } from 'components/wt-field';
import { Button } from 'components/button2';
import { DollarIcon } from 'components/icons2/DollarIcon';

import { AtIcon } from 'components/icons2/AtIcon';
import { ImageIcon } from 'components/icons2/ImageIcon';

import { RootObject, ParentObject, MentionUser } from '../types';
import { CommentsFilters, SaveComment } from '../actions';
import { useCollapse } from '../hooks/useCollapse';
import { Suggestions } from './Suggestions';
import {
    FormWrapper,
    ActionsArea,
    ActionsButtonsGroup,
    WtFieldWrapper,
    ActionsIconWrapper,
    OverflowText,
    PreviewImageWrapper,
    TextAreaWrapper,
    PreviewImage,
    PreviewCloseWrapper,
    LoadingIcon,
    PreviewImageLoader,
    FormAvatar,
    CloseIconWrapper,
} from './styled/Form';
import {
    detectKey,
    insertText,
    mapAddedUsersNicknamesToIdInText,
} from './utils';

const CancelButton = styled(Button)`
    margin-right: 4px;
`;

interface Props {
    recordId: Id,
    saveComment: SaveComment,
    mentionList: MentionUser[],
    fetchMentionList: () => void,
    rootObject?: RootObject,
    parentObject?: ParentObject,
    replyUser?: { replyUserId: Id, replyUserName: string; },
    comment?: Partial<FeedRecordComment>,
    currentComment?: Partial<FeedRecordComment>,
    onSave?: () => void,
    onDismiss?: () => void,
    autofocus?: boolean,
    openedFormId: string | null,
    setOpenedFormId: (x: string | null) => void,
    commentsFilter?: CommentsFilters,
    placeholder?: string,
}

const MAX_CONTENT_LENGTH = 2000;

export const CommentForm = (props: Props) => {
    const {
        recordId, rootObject, parentObject, replyUser, saveComment, comment: propsComment,
        onSave = noop, onDismiss = noop, autofocus, fetchMentionList, mentionList, openedFormId,
        setOpenedFormId, currentComment, commentsFilter,
        placeholder,
    } = props;

    const csrf = useCSRFToken();
    const fieldRef = useRef<HTMLTextAreaElement | null>();
    const [comment, setComment] = useStateCallback<Partial<FeedRecordComment> & { position: number, autocompleteType: null | 'users' | 'cachetags' }>({
        content: '',
        ...propsComment,
        position: 0,
        autocompleteType: null,
    });

    const [image, setImage] = useStateCallback<string | null>(comment?.image ?? null);
    const [imageLoading, setImageLoading] = useStateCallback(false);

    const { rawContent } = comment;
    const mentions = getMentionMetadata(rawContent ?? '');
    const addedUsers = useRef<Record<string, MentionUser>>({});
    const currentUser = useCurrentUser();

    Object.keys(mentions).forEach((name) => {
        if (addedUsers.current) {
            addedUsers.current[name] = {
                displayName: name,
                id: mentions[name],
            };
        }
    });

    const commentHasChanged = () => {
        return Boolean(comment.content?.length) && `${replyUser?.replyUserName},` !== comment.content?.trim();
    };

    const currentFormId = currentComment?.mpath || recordId.toString();
    const collapsed = !(openedFormId && (openedFormId === currentFormId));

    const { close, open } = useCollapse(fieldRef, !commentHasChanged(), fetchMentionList);

    useEffect(() => {
        if (autofocus && fieldRef.current) {
            open();
        }
    }, [autofocus, open]);

    const uploadImage = (e: any) => {
        const imageInput = e.target;
        const [file] = Array.from(window.FileAPI.getFiles(imageInput));
        if (!file) return;

        if (file.size > 25 * window.FileAPI.MB) {
            notify.error(Dic.word('wt__comments__image_too_big', { size: 25 }));
            imageInput.value = null;
            return;
        }

        setImage(null);
        setImageLoading(true);

        window.FileAPI.upload({
            url: UrlProcessor.page('ng_api_v1_file_upload_image').queryArgs({
                guid: '*',
            }).path(),
            data: {
                trueJSON: true,
                csrf,
                type: FileType.ATTACH_BLOGPOST,
            },
            files: { file },
            complete: (err: any, xhr: any) => {
                /** На случай если сессия завершена, но клиент об этом не знает, добавляем обработку 401 статуса */
                if (xhr.status === 401) {
                    setImage(null);
                    setImageLoading(false);
                    imageInput.value = null;
                    window.WT?.menu?.login?.();
                    return;
                }
                let result = xhr.response;
                if (typeof result === 'string') {
                    result = JSON.parse(result);
                }
                const fileLink = result?.files?.[0]?.sizes?.original?.url ?? '';
                if (fileLink) {
                    setImage(fileLink);
                    setImageLoading(false);
                } else {
                    notify.error(Dic.word('personal-site_header__header_file_type_error'));
                    setImageLoading(false);
                }
                imageInput.value = null;
            },
        });
    };

    const onImageUploadClick = () => {
        /** Гости не могут грузить картинки. При клике гостем на загрузку - надо показывать попап авторизации */
        if (!window.WT?.menu?.isUserAuthenticated?.()) {
            window.WT?.menu?.login?.();
            return;
        }
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = false;

        input.onchange = (e: any) => uploadImage(e);
        input.click();
    };

    const resetImage = () => {
        setImage(null);
        setImageLoading(false);
    };

    const resetComment = () => {
        setComment({
            ...comment,
            content: '',
            position: 0,
            autocompleteType: null,
        });
        resetImage();
        close();
        onDismiss();
    };

    const handleSelectMentionUser = (user: MentionUser) => {
        addedUsers.current[user.id] = user;
    };

    const handleSaveComment = () => {
        if (!commentHasChanged()) {
            notify.error(Dic.word('wt_feed__widget_comments__save_invalid_empty'));
            return;
        }
        const { id, content } = comment;
        const text = mapAddedUsersNicknamesToIdInText(content ?? '', addedUsers.current);
        const { rootObjectId, rootObjectType } = rootObject ?? {};
        const { parentObjectId, parentObjectType } = parentObject ?? {};
        const { replyUserId = null, replyUserName = null } = replyUser ?? {};
        saveComment({
            text,
            image: image || '',
            replyUserId,
            replyUserName,
            ...(id ? {
                action: 'edit',
                id,
            } : {
                action: 'add',
                rootObjectId,
                rootObjectType,
                parentObjectId,
                parentObjectType,
            }),
        }, { recordId, commentsFilter }).then((action) => {
            const { payload } = action;
            if (payload.data?.ok) {
                notify.success(Dic.word('wt_feed__widget_comments__save_success'));
                resetComment();
                onSave();
            } else {
                const message = payload.data?.result?.error ?? 'wt_feed__widget_comments__save_error';
                Sentry.captureMessage(message);
                notify.error(Dic.word(message));
            }
        }).catch(() => notify.error(Dic.word('wt_feed__widget_comments__save_error')));
    };

    const handleTextChange = (target: HTMLTextAreaElement) => {
        const text = target.value;

        const detectMentions = detectKey('@', text, target);
        const detectCacheTags = detectKey('$', text, target);

        const type = detectMentions !== null
            ? 'users'
            : (detectCacheTags !== null ? 'cachetags' : null);

        const { selectionStart } = target;
        if (comment.autocompleteType && selectionStart !== null) {
            if (selectionStart < comment.position) {
                setComment({
                    ...comment,
                    content: text,
                    position: 0,
                    autocompleteType: null,
                });
            } else if (type) {
                setComment({
                    ...comment,
                    content: text,
                    position: detectMentions ?? detectCacheTags ?? 0,
                    autocompleteType: type,
                });
            } else {
                setComment({
                    ...comment,
                    content: text,
                });
            }
        } else {
            setComment({
                ...comment,
                content: text,
                position: detectMentions ?? detectCacheTags ?? 0,
                autocompleteType: type,
            });
        }
    };

    const handleMouseDown = () => {
        open();
        setOpenedFormId(currentFormId);
    };

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            handleSaveComment();
        }
    };

    const onActionClick = (key: string) => {
        if (fieldRef.current) {
            const { text, callback } = insertText(comment.content ?? '', key, fieldRef.current.selectionStart + 1, fieldRef.current);

            fieldRef.current.value = text;
            callback();

            handleTextChange(fieldRef.current);
        }
    };

    const fieldProps = useMemo(() => {
        return {
            ref: (node: any) => { fieldRef.current = node; },
            rows: 1,
            onMouseDown: handleMouseDown,
            placeholder: Dic.word(placeholder ?? 'wt_feed__widget_comments__form_placeholder'),
            onKeyDown: handleKeydown,
            onChange: (e: any) => handleTextChange(e.target),
            value: comment.content,
        };
    }, [collapsed, comment]);

    const expandedFormIsShown = !collapsed;

    const saveCommentAfterAuthentication = TODOuseActionAfterAuthentication(handleSaveComment);

    const contentLength = comment?.content?.length || 0;
    const isOverflow = contentLength >= MAX_CONTENT_LENGTH;
    const isValid = contentLength > 0 && !isOverflow;

    return (
        <FormWrapper active={expandedFormIsShown}>
            {!expandedFormIsShown && <FormAvatar src={currentUser.avatar || currentUser.image} />}
            <TextAreaWrapper active={expandedFormIsShown}>
                <Suggestions
                    comment={comment}
                    setComment={setComment}
                    cursorElement={fieldRef.current}
                    mentionList={mentionList}
                    onSelectMentionUser={handleSelectMentionUser}
                />
                <WtFieldWrapper
                    isOverflow
                    wrapperClassName="textarea-wrapper"
                    active={!collapsed}
                    fieldProps={fieldProps}
                    view={Views.styled2}
                    noresize
                    infinityHeight
                />
                {isOverflow && (
                    <OverflowText>
                        {Dic.word('wt_feed__widget_comments__overflow_label', [contentLength - MAX_CONTENT_LENGTH])}
                    </OverflowText>
                )}
                {expandedFormIsShown && (
                    <ActionsArea>
                        <ActionsIconWrapper>
                            <Button kind="ghost-secondary" size="small" icon={ImageIcon} onClick={() => onImageUploadClick()} />
                            <Button kind="ghost-secondary" size="small" icon={AtIcon} onClick={() => onActionClick('@')} />
                            <Button kind="ghost-secondary" size="small" icon={DollarIcon} onClick={() => onActionClick('$')} />
                        </ActionsIconWrapper>
                        <ActionsButtonsGroup>
                            <CancelButton
                                kind="ghost-secondary"
                                size={{ default: 'small', lt480: 'tiny' }}
                                onClick={resetComment}
                            >
                                {Dic.word('wt_feed__widget_comments__new_cancel_comment')}
                            </CancelButton>
                            <Button
                                kind="primary"
                                size={{ default: 'small', lt480: 'tiny' }}
                                onClick={saveCommentAfterAuthentication}
                                isDisabled={!isValid}
                            >
                                {Dic.word('wt_feed__widget_comments__send_comment')}
                            </Button>
                        </ActionsButtonsGroup>
                    </ActionsArea>
                )}
            </TextAreaWrapper>
            {imageLoading && (
                <PreviewImageLoader>
                    <LoadingIcon />
                </PreviewImageLoader>
            )}
            {image && !imageLoading && (
                <PreviewImageWrapper>
                    <PreviewImage src={image} />
                    <PreviewCloseWrapper onClick={resetImage}><CloseIconWrapper size={16} /></PreviewCloseWrapper>
                </PreviewImageWrapper>
            )}
        </FormWrapper>
    );
};

CommentForm.defaultProps = {
    comment: {},
    rootObject: {},
    parentObject: {},
    replyUser: {},
    onSave: noop,
    onDismiss: noop,
    autofocus: false,
};
