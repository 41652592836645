import React from 'react';

import { Id } from 'types';
import { useFeedContext } from 'hooks/useFeedContext';
import { GearIcon } from 'components/icons2/GearIcon';
import { ButtonIconKinds } from 'components/button-icon';

import { ButtonWrapper } from './styled';

type Props = {
    type: 'comment' | 'record';
    id: Id;
};

export const ModerationEditItemButton = (props: Props) => {
    const { type, id } = props;
    const [{ isDeveloper, isBlogAdmin }] = useFeedContext();

    const isButtonShow = isDeveloper || isBlogAdmin;

    const handleClick = () => {
        const url = {
            'record': `/admin/?action=show&entity=BlogPostModeration&id=${id}`,
            'comment': `/admin/?entity=CommentModeration&action=show&menuIndex=0&submenuIndex=6&page=1&id=${id}`,
        }[type];

        window.open(url, '_blank');
    };

    return isButtonShow ? (
        <ButtonWrapper
            icon={{ component: GearIcon }}
            kind={ButtonIconKinds.GhostPrimary}
            onClick={handleClick}
            size="M"
        />
    ) : null;
};
