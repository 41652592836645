import styled from 'styled-components';

import { ButtonIcon } from 'components/button-icon';

export const StyledButtonIcon = styled(ButtonIcon)`
    &:active, &:focus, &:active:focus {
        & > div {
            fill: initial;
            box-shadow: none;
        }
    }
    ${({ theme: { fillIn, label } }) => `
        > div {
            fill: ${fillIn.secondary.low};
        }
        &:hover {
            > div {
                fill: ${label.primary};
            }
        }
    `}
    & svg {
        width: 24px;
        height: 24px;
        margin-left: 0;
        margin-right: 0;
    }
`;
