import React from 'react';

import { ResponsiveMap } from 'app/styled';
import { TagProps, TagSize } from 'components/tag/types';
import * as Styled from './styled';

const initialResponsiveSize: ResponsiveMap<TagSize> = {
    lt960: 'small',
    lt1200: 'medium',
    default: 'large',
};

export const Tag = ({
    text,
    onChange,
    isActive = false,
    style,
    Icon,
    IconRight,
    className,
    size = initialResponsiveSize,
    close,
}: TagProps) => {
    const hasClose = !!close;
    const onlyIcon = !!(Icon && !text && !IconRight && !hasClose);

    return (
        <Styled.Container
            isActive={isActive}
            className={className}
            onClick={() => onChange()}
            style={style}
            size={size}
            hasClose={hasClose}
            IconRight={IconRight}
            Icon={Icon}
            onlyIcon={onlyIcon}
        >
            {
                Icon && (
                    <Styled.IconWrapper
                        pos="left"
                        size={size}
                        onlyIcon={onlyIcon}
                    >
                        <Icon />
                    </Styled.IconWrapper>
                )
            }
            {
                text && (
                    <Styled.Text
                        size={size}
                        Icon={Icon}
                        IconRight={IconRight}
                        hasClose={hasClose}
                    >
                        { text }
                    </Styled.Text>
                )
            }
            {
                IconRight && (
                    <Styled.IconWrapper
                        pos="right"
                        size={size}
                        onlyIcon={onlyIcon}
                    >
                        <IconRight />
                    </Styled.IconWrapper>
                )
            }
            {
                hasClose && (
                    <Styled.IconClose
                        size={16}
                        onClick={() => close()}
                        IconRight={IconRight}
                        sizeTag={size}
                    />
                )
            }
        </Styled.Container>
    );
};

export default Tag;
