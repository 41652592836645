import * as Sentry from '@sentry/browser';

import Dic from 'services/dictionary';
import ProjectConfig from 'services/config';

const _defaultNotify = (type, message, onDebug) => {
    if (typeof App !== 'undefined' && App.ui && App.ui.notify) {
        App.ui.notify[type](message);
    } else if (ProjectConfig.debug) {
        onDebug();
    }
};

let notify = function closure(message, type) {
    switch (type) {
        case 'error':
            _defaultNotify(type, message, () => console.warn(`Notify error: ${message}`));
            break;

        case 'warning':
            _defaultNotify(type, message, () => console.warn(`Notify warning: ${message}`));
            break;

        case 'success':
        default:
            _defaultNotify(type, message, () => console.info(`Notify success: ${message}`));
            break;
    }
};

let customHandler = null;

export default {
    setCustomHandler(handler) {
        customHandler = handler;
    },

    success(message, debugInfo) {
        if (customHandler) {
            customHandler(message, 'success', debugInfo || {});
            return;
        }
        notify(message, 'success');
    },

    warning(message, debugInfo) {
        if (customHandler) {
            customHandler(message, 'warning', debugInfo || {});
            return;
        }
        notify(message, 'warning');
    },

    error(message, debugInfo) {
        if (customHandler) {
            customHandler(message, 'error', debugInfo || {});
            return;
        }
        notify(message, 'error');
    },

    ajaxError(debugInfo) {
        // vls: нет смысла пытаться найти дикворд 'ajax_error', если все равно нельзя вывести сообщение
        if ((typeof App !== 'undefined' && App.ui && App.ui.notify) || customHandler) {
            this.error(Dic.word(ProjectConfig.ajaxErrorDicword || 'ajax_error'), debugInfo);
        }
    },

    setNotifyCallback(callback) {
        notify = callback;
    },
};
