import styled from 'styled-components';

import store from '../store';

export const LimexFixedMenu = styled.div<{ headerHeight: number; }>`
    position: fixed;
    z-index: 302;
    top: 0;
    height: ${(props) => `${props.headerHeight}px`};
    width: 100%;

    @media (max-width:768px) {
        height: 56px;
    }
`;

export const FullHeightContainer = styled.div`
    height: ${() => (store.getState().activeProjectData.headerHeight ? `${store.getState().activeProjectData.headerHeight}px` : '80px')};
    display: flex;
    align-items: center;
`;
