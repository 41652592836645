export enum FeedRecordType {
    PUBLISH_STATUS = 1,
    FORECAST = 2,
    CHANGED_AVATAR = 7,
    JOIN_GROUP = 9,
    SHARED = 11,
    COMMENTED = 13,
    COMMENT_THREAD = 18,
    EGG_GAME = 24,
    TICKER_NEWS = 101,
    RECOMMENDATIONS = 102,
    ADS = 103,
}

export enum FeedRecordObjTypes {
    POST = 3,
    COMMENT = 6,
    GROUP = 2,
    PHOTO = 7,
}

export enum FeedRecordObjBlogpostMask {
    /* eslint no-bitwise: "off" */
    POST = 1 << 0, // 0b000000000001; // 1
    FORECAST = 1 << 1, // 0b000000000010; // 2
    CHART = 1 << 2, // 0b000000000100; // 4
    PORTFOLIO_FOR_SALE = 1 << 3, // 0b000000001000; // 8
    EXPERT_OPINION = 1 << 4, // 0b000000010000; // 16
    EXPERT_OPINION_DOUBLE = 1 << 5, // 0b000000100000; // 32
    CONTEST = 1 << 6, // 0b000001000000; // 64
    PREMIUM = 1 << 7, // 0b000010000000; // 128
    TIMELINE_STATUS = 1 << 8, // 0b000100000000; // 256
    EXTERNAL_SHARING = 1 << 9, // 0b001000000000; // 512
    FORECAST_CHART_AUTO = 1 << 10, // 0b010000000000; // 1024
    BEST_TRADES_STRATEGY = 1 << 11, // 0b100000000000; // 2048
    FORECAST_FAKE_AUTO = 1 << 12, // 0b100000000000; // 4096
    ANY = (1 << 14) - 1, // 0b111111111111; // 16383
}

export enum UserBias {
    none,
    agree,
    disagree = -1,
}

export enum FeedOwnerType {
    UNDEFINED = 0,
    PERSON = 1,
    INSTRUMENT = 5,
    PREMIUM = 36,
}

export enum PostType {
    ORDINARY = 'ordinary',
    PREMIUM = 'premium',
}
