import React, { ReactNode } from 'react';

import * as Styled from './styled/Header.styled';

export type HeaderPropsType = {
    title: string;
    unreadCount?: number | null;
    button?: ReactNode;
};

export const Header = (props:HeaderPropsType) => {
    const { title, unreadCount, button } = props;
    const hasBadge = unreadCount && unreadCount !== 0;

    return (
        <Styled.HeaderWrapper>
            <Styled.TitleCounterBadge>
                <Styled.Title>{title}</Styled.Title>
                {hasBadge ? (
                    <Styled.CounterBadgeWrapper>
                        <Styled.CounterBadgeText>
                            {unreadCount}
                        </Styled.CounterBadgeText>
                    </Styled.CounterBadgeWrapper>
                ) : null}
            </Styled.TitleCounterBadge>
            {button}
        </Styled.HeaderWrapper>
    );
};
