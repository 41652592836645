import get from 'lodash/get';

import {
    ActionRequest, ActionResult, FeedRecord, Id,
} from 'types';
import merge, { mergeByPath } from 'services/utils/merge';
import { FeedState } from '../types';
import { index, getCommentsAndPath } from '../helpers';
import { SendLikeRequestData, SendLikePayloadData, SendLikePayloadPass } from '../_shared/_sub/like/actionGenerators';
import { calculateLikes } from '../_shared/_sub/like/reducerUtils';
import { SEND_RATING_COMMENT_ACTION_TYPES } from './actions';

const [SEND_RATING_COMMENT_REQUEST, , SEND_RATING_COMMENT_FAILURE] = SEND_RATING_COMMENT_ACTION_TYPES;

const getStateWithChoice = (state: FeedState, recordId: Id, commentId: Id, choice: number | undefined, prevRecordState?: FeedRecord) => {
    const record = (choice || choice === 0) ? state.records[index(recordId)] : prevRecordState;
    if (!record) return state;
    const { comments, path } = getCommentsAndPath(record);
    const commentIndex = comments.findIndex((_comment) => _comment.id === commentId);
    if (commentIndex === -1) return state;
    const realLikeCount = typeof choice === 'number' ? comments[commentIndex].realLikeCount + (choice * 2 - 1) : comments[commentIndex].realLikeCount;
    const likes = (choice || choice === 0) ? calculateLikes(comments[commentIndex].stats.likes, choice) : comments[commentIndex].stats.likes;

    const pathToComment = `records.${index(recordId)}.${path}[${commentIndex}]`;
    const newCommentState = merge(get(state, pathToComment), {
        stats: { likes },
        realLikeCount,
    });
    const newCommentsState = [...comments];
    newCommentsState[commentIndex] = newCommentState;
    return mergeByPath(state, `records.${index(recordId)}.${path}`, newCommentsState);
};

type _SendLikePayloadPassWithSpecialState = SendLikePayloadPass & { prevSpecialState: FeedRecord; }

type AvailActionsData = SendLikePayloadData;
type AvailActionsPass = _SendLikePayloadPassWithSpecialState;
type AvailActionsRequestData = SendLikeRequestData;
export default (
    state: FeedState,
    action: ActionRequest<AvailActionsRequestData, AvailActionsPass> | ActionResult<AvailActionsData, AvailActionsPass>,
) => {
    switch (action.type) {
        case SEND_RATING_COMMENT_REQUEST: {
            const { payload: { pass, request: { data } } } = action as ActionRequest<SendLikeRequestData, _SendLikePayloadPassWithSpecialState>;
            if (!data) return state;
            const { object_id: commentId } = data;
            const { newChoice, recordId } = pass;
            return getStateWithChoice(state, recordId, commentId, newChoice);
        }
        case SEND_RATING_COMMENT_FAILURE: {
            const {
                payload: { pass },
                meta: {
                    previousAction: {
                        payload: {
                            request: { data },
                        },
                    },
                },
            } = action as ActionResult<SendLikePayloadData, _SendLikePayloadPassWithSpecialState, SendLikeRequestData>;
            if (!data) return state;
            const { object_id: commentId } = data;
            const { prevSpecialState, recordId } = pass;
            return getStateWithChoice(state, recordId, commentId, undefined, prevSpecialState);
        }
        default:
            return state;
    }
};
