import React, { useMemo } from 'react';
import { CustomArrowProps, Settings } from 'react-slick';

import { ArrowRightMiniIcon } from 'components/icons2/ArrowRightMiniIcon';
import { ArrowLeftMiniIcon } from 'components/icons2/ArrowLeftMiniIcon';

import * as Styled from './styled';

export type SliderItemSettingsType = {
    indent?: number;
    paddingTop?: number;
    paddingBottom?: number;
};

export interface SliderSettingResponsiveInterface {
    breakpoint: number;
    settings: 'unslick' | Omit<Settings, 'responsive'> & {
        item?: SliderItemSettingsType;
    };
}

export type SliderPropsType = Omit<Settings, 'responsive'> & {
    responsive?: SliderSettingResponsiveInterface[] | undefined;
    item?: SliderItemSettingsType;
    width?: number;
    clearDefaultProps?: 'item' | 'all' | 'arrows';
};

function NextArrow(nextArrowProps: CustomArrowProps) {
    const {
        className: nextArrowClassName,
        onClick: nextArrowClick,
    } = nextArrowProps;

    return (
        <Styled.ArrowWrapperRight className={nextArrowClassName}>
            <Styled.ButtonIcon
                onClick={(e) => nextArrowClick && nextArrowClick(e)}
            >
                <ArrowRightMiniIcon />
            </Styled.ButtonIcon>
        </Styled.ArrowWrapperRight>
    );
}

function PrevArrow(prevArrowProps: CustomArrowProps) {
    const {
        className: prevArrowClassName,
        onClick: prevArrowClick,
    } = prevArrowProps;

    return (
        <Styled.ArrowWrapperLeft className={prevArrowClassName}>
            <Styled.ButtonIcon
                onClick={(e) => prevArrowClick && prevArrowClick(e)}
            >
                <ArrowLeftMiniIcon />
            </Styled.ButtonIcon>
        </Styled.ArrowWrapperLeft>
    );
}

const initialProps: SliderPropsType = {
    infinite: false,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
    item: {
        paddingTop: 16,
        paddingBottom: 24,
        indent: 16,
    },
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                item: {
                    paddingTop: 16,
                    paddingBottom: 24,
                    indent: 16,
                },
            },
        },
        {
            breakpoint: 1200,
            settings: {
                item: {
                    paddingBottom: 16,
                },
            },
        },
        {
            breakpoint: 960,
            settings: {
                item: {
                    paddingTop: 12,
                },
            },
        },
    ],
};

export const Slider = (props: SliderPropsType) => {
    const { clearDefaultProps } = props;

    const defaultProps: SliderPropsType = useMemo(() => {
        if (clearDefaultProps === 'all') {
            return {};
        }

        if (clearDefaultProps === 'arrows') {
            return {
                nextArrow: initialProps.nextArrow,
                prevArrow: initialProps.prevArrow,
            };
        }

        const result = { ...initialProps };

        if (clearDefaultProps === 'item') {
            delete result.responsive;
            delete result.item;
        }

        return result;
    }, [clearDefaultProps]);

    const sliderProps: SliderPropsType = { ...defaultProps, ...props };

    return (
        <Styled.Slider
            {...sliderProps}
        />
    );
};
