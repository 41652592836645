import tippy from 'services/tippy';

import pageLight from './light';

const page = {
    ...pageLight,
    block(opts = {}) {
        if (!this.isBlocked()) {
            tippy.hideAll(0);
        }
        pageLight.block(opts);
    },
};

export default page;
