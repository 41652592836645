import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { Lcid } from 'types';
import { baseUrl } from '../../services/constants.js';

interface SearchController {
    value: AbortController | null;
}

export const GeneralSearchController: SearchController = {
    value: null,
};

let GeneralSearchSignal: AbortSignal | null;

export const InstrumentsSearchController: SearchController = {
    value: null,
};

let InstrumentsSearchSignal: AbortSignal | null;

const GENERAL_URL = `${baseUrl}/ng/api/v1/menu/search/`;
const INSTRUMENT_URL = 'https://txprd-wt.just2trade.com/grpc-json/txscreener/v1/search';

const sendRequest = (fullURL: string, signal: AbortSignal, credentials: RequestCredentials = 'include') => fetch(
    fullURL,
    {
        method: 'get',
        credentials,
        signal,
    },
).then((res) => res.json());

export const sendGeneralRequest = (key: string, limit: number | null, lang: Lcid) => {
    GeneralSearchController.value?.abort?.();
    GeneralSearchController.value = new AbortController();
    GeneralSearchSignal = GeneralSearchController.value.signal;
    const url = `${GENERAL_URL}?key=${encodeURIComponent(key)}${limit ? `&limit=${limit}` : ''}&lang=${lang}&timestamp=${new Date().getTime()}`;
    return sendRequest(url, GeneralSearchSignal);
};

export const sendInstrumentRequest = (key: string, limit: number) => {
    InstrumentsSearchController.value?.abort?.();
    InstrumentsSearchController.value = new AbortController();
    InstrumentsSearchSignal = InstrumentsSearchController.value.signal;
    const params = new URLSearchParams();
    if (key.length <= 2) {
        params.append('sort_by_score', 'true');
    }
    if (limit) {
        params.append('limit', `${limit}`);
    }
    const paramsString = params.toString();
    const url = `${INSTRUMENT_URL}/${encodeURIComponent(key)}${paramsString ? `?${paramsString}` : ''}`;
    const credentials = 'omit';
    return sendRequest(url, InstrumentsSearchSignal, credentials);
};
