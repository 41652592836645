import React from 'react';

export default () => (
    <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 312 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="312" height="64" fill="white" />
        <rect x="16" y="16" width="88" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        <rect x="16" y="38" width="56" height="8" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        <rect x="208" y="16" width="72" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        <rect x="232" y="38" width="48" height="8" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        <line y1="63.5" x2="312" y2="63.5" stroke="#E6EFFF" />
    </svg>
);
