import React from 'react';

import Dic from 'services/dictionary';
import { ErrorBlockWrapper } from './styled';

export const ErrorBlock = () => {
    return (
        <ErrorBlockWrapper>
            {Dic.word('wt_feed__error_block__error_text')}
        </ErrorBlockWrapper>
    );
};
