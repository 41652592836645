import React, {
    useLayoutEffect, useRef, useState, useCallback, useEffect,
} from 'react';
import throttle from 'lodash.throttle';

import { FeedRecordInstrument } from 'types/feed-record';
import Dic from 'services/dictionary';
import { instrumentUrl } from 'services/utils/instrument-url';
import Instrument, { INSTRUMENT_STYLE } from 'widgets/instrument2';
import { Wrapper, More, InstrumentWrapper } from './styled';

interface Props {
    instruments: FeedRecordInstrument[],
    autoUpdate: boolean,
}

const INSTRUMENTS_WRAPPER_PADDING = 24;

export const InstrumentsList = (props: Props) => {
    const { instruments, autoUpdate } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const moreRef = useRef<HTMLDivElement>(null);

    const [lastVisibleInstrument, setLastVisibleInstrument] = useState<number | null>(null);
    const [truncateReady, setTruncateReady] = useState<boolean>(false);
    const [showAllInstruments, setShowAllInstruments] = useState<boolean>(false);
    const [multilineable, setMultilineable] = useState<boolean>(false);
    const [bodyWidth, storeBodyWidth] = useState<number>(document.body.offsetWidth);

    useEffect(() => {
        if (showAllInstruments) return;
        const onResize = throttle(() => {
            // не делаем сброс если это изменние по высоте
            if (bodyWidth !== document.body.offsetWidth) {
                setLastVisibleInstrument(null);
                setTruncateReady(false);
                setMultilineable(false);
                storeBodyWidth(document.body.offsetWidth);
            }
        }, 1000);
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [bodyWidth]);

    useLayoutEffect(() => {
        if (!instruments?.length) return;
        // в соответстии с дизайном нам необходимо делать truncate инструментов, а именно
        // показывать инструменты в одну линию до тех пор пока они в неё помещаются + отображаеть "и еще N"
        // код ниже производит вычисления для такого транкейта
        if (!showAllInstruments && wrapperRef?.current && moreRef?.current) {
            const wrapperRect = wrapperRef.current.getBoundingClientRect();
            const wrapperRight = wrapperRect.right;
            const moreWidth = moreRef.current.getBoundingClientRect().width;
            const instrumentsNodes = wrapperRef.current.querySelectorAll('.__instrument');
            if (instrumentsNodes?.[0] && (instrumentsNodes[0].getBoundingClientRect().width + moreWidth < wrapperRect.width)) {
                // находим первый инструмент, который не помещается
                const lastVisibleNodeIndex = Array.from(instrumentsNodes).findIndex((node) => {
                    return node.getBoundingClientRect().right > (wrapperRight - moreWidth - INSTRUMENTS_WRAPPER_PADDING * 2);
                });
                setLastVisibleInstrument(lastVisibleNodeIndex !== -1 ? lastVisibleNodeIndex : instrumentsNodes.length);
                // случаи когда экран настолько узкий, что 1 инструмент с надписью "и еще N" не помещется по ширине
            } else if (instrumentsNodes?.[0]) {
                setLastVisibleInstrument(1);
                setMultilineable(true);
            }
        }
        setTruncateReady(true);
    }, [bodyWidth]);

    const handleMoreClick = useCallback(() => setShowAllInstruments(true), []);

    if (!instruments?.length) return null;
    const instrumentsForRender = (!showAllInstruments && lastVisibleInstrument && lastVisibleInstrument > 0)
        ? instruments.slice(0, lastVisibleInstrument)
        : instruments;
    const hiddenCount = instruments.length - instrumentsForRender.length;
    // '00' - закладываемся под наибильшую ширину, вряд ли инструментов будет 100+
    return (
        <Wrapper ref={wrapperRef} showAllInstruments={showAllInstruments} multilineable={multilineable}>
            {instrumentsForRender.map(({
                id, priceChange, price, dcpl, ticker, finamId,
                currency,
            }) => {
                return (
                    <InstrumentWrapper key={id} className="__instrument">
                        <Instrument
                            style={INSTRUMENT_STYLE.PAINTED3}
                            finamId={finamId}
                            currency={currency}
                            initChangePercent={typeof priceChange === 'number' ? priceChange : parseFloat(priceChange)}
                            initPrice={typeof price === 'number' ? price : parseFloat(price)}
                            initDcpl={parseInt(dcpl as string, 10)}
                            initTicker={ticker}
                            url={instrumentUrl({ ticker, id: finamId })}
                            tooltipEnabled={false}
                            autoUpdate={autoUpdate}
                        />
                    </InstrumentWrapper>
                );
            })}
            {!showAllInstruments && Boolean(!truncateReady || hiddenCount) && (
                <More ref={moreRef} onClick={handleMoreClick}>
                    {Dic.word('wt_feed__widget_instruments__and_more', { count: hiddenCount || '00' })}
                </More>
            )}
        </Wrapper>
    );
};
