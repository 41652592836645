import { ssProvider } from './services/sessionStorageProvider';

const debug = () => {
    const shouldNotReload = () => ssProvider.get('stopReload');

    const shouldNotRunCallbacks = () => ssProvider.get('stopCallbacks');

    return {
        shouldNotReload,
        shouldNotRunCallbacks,
    };
};

export default debug;
