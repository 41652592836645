import { AxiosPromise } from 'axios';

import { FileType, LimexFile } from 'types';
import UrlProcessor from 'services/url-processor';
import { backendClient } from 'services/backend-client';

export const uploadFile = async (file: File, csrf: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('csrf', csrf);
    formData.append('type', `${FileType.ATTACH_BLOGPOST}`);
    const apiUrl = UrlProcessor
        .page('ng_api_v1_file_upload_image')
        .queryArgs({
            guid: '*',
        })
        .path();

    let result = {
        success: 0,
        file: {
            url: '',
        },
    };

    try {
        const { data } = await sendForm(apiUrl, formData);
        const fileLink = data?.files?.[0]?.sizes?.original?.url ?? '';

        result = {
            success: fileLink ? 1 : 0,
            file: {
                url: fileLink,
            },
        };
    } catch (e) {
        console.error(e);
    }

    return result;
};

function sendForm(url: string, data: FormData): AxiosPromise<{ files?: LimexFile[] }> {
    return backendClient({
        url,
        method: 'POST',
        data,
    });
}
