import React from 'react';
import { ReactElementLike } from 'prop-types';
import noop from 'lodash/noop';

import { Props as IconedTextProps } from 'components/iconed-text2';
import {
    ButtonIconWrapper,
    StyledIconedText,
    Size,
    ButtonIconKinds,
} from './styled';

export type Props = {
    className?: string,
    text?: string | number | ReactElementLike,
    icon?: IconedTextProps['icon'],
    size?: Size,
    kind?: ButtonIconKinds,
    pressed?: boolean,
    disabled?: boolean,
    title?: string,
    onClick?: (e: React.SyntheticEvent) => void,
    onMouseDown?: (e: React.SyntheticEvent) => void,
}
export const ButtonIcon = ({
    text = '',
    icon,
    pressed = false,
    disabled = false,
    size = 'L',
    kind = ButtonIconKinds.FillPrimary,
    onClick,
    onMouseDown,
    className,
    title,
}: Props) => {
    return (
        <ButtonIconWrapper
            className={className}
            text={text}
            title={title}
            size={size}
            kind={kind}
            pressed={pressed}
            disabled={disabled}
            onClick={onClick}
            onMouseDown={onMouseDown}
            icon={icon}
            tabIndex={0}
        >
            <div tabIndex={-1}>
                <StyledIconedText
                    text={text}
                    icon={icon && {
                        gap: '0', ...icon,
                    }}
                />
            </div>
        </ButtonIconWrapper>
    );
};
ButtonIcon.defaultProps = {
    className: '',
    text: '',
    icon: null,
    size: 'L',
    kind: ButtonIconKinds.FillPrimary,
    pressed: false,
    disabled: false,
    title: '',
    onClick: noop,
};

export { ButtonIconKinds };
export type { Size as ButtonIconSizes, Props as ButtonIconProps };
