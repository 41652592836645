import React, {
    useEffect, useRef, useState, useMemo,
} from 'react';
import * as Sentry from '@sentry/browser';

import { OutputData } from '@editorjs/editorjs';
import { Id, PostType } from 'types';
import { FeedOwner } from 'context/feed';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { PostTypeSelector } from 'widgets/feed/add-status/components/PostTypeSelector';
import { Button } from 'components/button2';

import { PictureIcon } from 'components/icons2/PictureIcon';
import { DollarIcon } from 'components/icons2/DollarIcon';
import { UserBlock } from 'components/user-block';
import { PublisherFn } from '../types';
import { usePublishStatus } from '../hooks/usePublishStatus';
import { cashtagRegexBase } from '../hooks/useCashtags';
import Editor from './editor';

import {
    AddFeedStatusContainer,
    AddFeedStatusEditorContainer,
    AddFeedCancelButton,
    AddFeedStatusActions,
    AddFeedStatusTopSection,
    AddFeedStatusToolbar,
    AddFeedStatusBottomSection,
    HideOnMobile,
    ShowOnMobileOnly,
    AddFeedStatusAvatar,
    AddFeedStatusPlaceholder,
} from './styled';

type AddFeedStatusProps = {
    publisherFn: PublisherFn;
    owner: FeedOwner
    objId?: Id,
    // текст, который показываем пока редактор не развернут
    stub?: string,
    // текст, который показываем в редакторе если поле не заполнено
    placeholder?: string,
    // отвечает за стартовое значение развернутости (показа) редактора
    isActive?: boolean;
    // вставлять ли курсов в поле редактора сразу как только он появляется
    autofocus?: boolean;
    onActiveChange?: (isActive: boolean) => void,
    postType?: PostType | null,
    enableSwitchPostType?: boolean,
    initRawJson?: string,
};

export const AddFeedStatus = ({
    publisherFn, owner, stub, placeholder, isActive: isActiveProp, onActiveChange, postType: postTypeProp = PostType.ORDINARY, enableSwitchPostType,
    initRawJson, objId, autofocus = true,
}: AddFeedStatusProps) => {
    const defaultEditorState = initRawJson || '';
    // MARK: - Stateful Variables

    const currentUser = useCurrentUser();
    const [isActiveState, setIsActiveState] = useState(false);
    const isActive = useMemo(() => isActiveProp ?? isActiveState, [isActiveProp, isActiveState]);
    const [editorState, setEditorState] = useState(defaultEditorState);
    const [postType, setPostType] = useState(postTypeProp);
    const [editorStateJson, setEditorStateJson] = useState<OutputData | null>(initRawJson ? JSON.parse(initRawJson) : null);

    // MARK: - Handlers

    const activate = () => {
        setIsActiveState(true);
        onActiveChange?.(true);
    };
    const deactivate = () => {
        setIsActiveState(false);
        onActiveChange?.(false);
    };

    const resetAppearanceRef = useRef(() => {
        setEditorState(defaultEditorState);
        setEditorStateJson(null);
        deactivate();
    });

    // MARK: - Publishing State

    const {
        publishStatusState: { isLoading, isSuccess, error },
        publishStatus,
    } = usePublishStatus({
        publisherFn, owner, postType, objId,
    });

    useEffect(() => {
        if (isSuccess) {
            resetAppearanceRef.current();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            const { message } = error as Error;

            if (message) {
                Sentry.captureMessage(message);
                notify.error(message);
            } else {
                notify.error(Dic.word('wt_feed__widget_add_status__on_error'));
            }
        }
    }, [error]);

    const validateAndSend = (json: OutputData, html: string, callback: (statusText: string, statusJson: string) => Promise<unknown>) => {
        if (json.blocks.every((block) => {
            if (block.type === 'paragraph') {
                return !block.data.text
                    .split(new RegExp(`\\$${cashtagRegexBase}`))
                    .filter((group: string) => group && !group.match(/^\s+$/))
                    .length;
            }
            return false;
        })) {
            notify.error(Dic.word('wt_feed__editor__error__chasTag'));
            return;
        }
        callback(html, JSON.stringify(json));
    };

    const publishStatusAfterAuthentication = TODOuseActionAfterAuthentication(
        () => {
            if (editorStateJson && editorState) {
                validateAndSend(editorStateJson, editorState, publishStatus);
            }
        },
    );

    // MARK: - Render

    // TODO: use actual editor instance type
    const editorRef = useRef<any>();

    const actionsElement = (
        <AddFeedStatusActions>
            {/* TODO: Fixme!
                            dmh: пока оставил дикворд от комментов на Cancel,
                            т.к. на старой ленте нет кнопки Cancel в статусе - может быть и у нас не понадобится */}
            <AddFeedCancelButton
                kind="ghost-secondary"
                size="small"
                isDisabled={isLoading}
                onClick={resetAppearanceRef.current}
                className="autotest__add-feed-status__cancel"
            >
                {Dic.word('wt_feed__widget_comments__cancel_comment')}
            </AddFeedCancelButton>
            <Button
                kind="primary"
                size="small"
                isProcessing={isLoading}
                isDisabled={isLoading || !editorState.length || (editorState === defaultEditorState)}
                onClick={publishStatusAfterAuthentication}
                className="autotest__add-feed-status__submit"
            >
                {Dic.word('wt_feed__timeline_status__add_post')}
            </Button>
        </AddFeedStatusActions>
    );

    const currentAvatar: string | null = currentUser?.avatar ? currentUser.avatar : null;

    const avatarProps = {
        responsiveSize: {
            common: 'M',
            lt768: 'S',
        },
    };

    const finalStub = stub || placeholder || Dic.word('wt_feed__timeline_status__placeholder');
    const finalPlaceholder = placeholder || finalStub;

    return (
        <AddFeedStatusContainer>
            <AddFeedStatusEditorContainer isActive={isActive} className="autotest__feed_status__container">
                <AddFeedStatusTopSection>
                    {!isActive && (
                        <AddFeedStatusAvatar
                            className="autotest_profile_feed_status_avatar"
                            person={currentUser}
                            src={currentAvatar}
                            bordered={false}
                            square
                            responsiveSize={{
                                common: 'M',
                                lt768: 'S',
                            }}
                        />
                    )}
                    <div
                        style={{
                            flex: 'auto', position: 'relative', outline: 'none', maxWidth: '100%',
                        }}
                        tabIndex={-1}
                        onFocus={activate}
                        className="autotest__add-status"
                    >
                        {!isActive && <AddFeedStatusPlaceholder>{finalStub}</AddFeedStatusPlaceholder>}
                        {isActive && currentUser?.id && (
                            <UserBlock
                                user={currentUser}
                                avatar={avatarProps}
                                secondLine={<PostTypeSelector enableSwitch={enableSwitchPostType} type={postType} onChangeType={setPostType} />}
                            />
                        )}
                        {
                            isActive && (
                                <Editor
                                    ref={editorRef}
                                    updateState={setEditorState}
                                    editorStateJson={editorStateJson}
                                    updateJson={setEditorStateJson}
                                    autofocus={autofocus}
                                    placeholder={finalPlaceholder}
                                />
                            )
                        }

                    </div>
                </AddFeedStatusTopSection>
                {isActive && (
                    <AddFeedStatusBottomSection>
                        <AddFeedStatusToolbar>
                            <Button
                                kind="ghost-secondary"
                                size="small"
                                icon={PictureIcon}
                                onClick={() => editorRef.current?.showAddImageDialog()}
                            />
                            <Button
                                kind="ghost-secondary"
                                size="small"
                                icon={DollarIcon}
                                onClick={() => editorRef.current?.showAddCashtagDialog()}
                            />
                        </AddFeedStatusToolbar>

                        <HideOnMobile>
                            {actionsElement}
                        </HideOnMobile>
                    </AddFeedStatusBottomSection>
                )}
            </AddFeedStatusEditorContainer>
            <ShowOnMobileOnly>
                {isActive && actionsElement}
            </ShowOnMobileOnly>
        </AddFeedStatusContainer>
    );
};
