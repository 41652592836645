import React, { useEffect, useMemo, useState } from 'react';

import { Id } from 'types';
import { googleAnalytics } from 'services/google-analytics';
import { Search } from 'services/issuesearch';
import { Issue } from 'services/issuesearch/types';
import { instrumentUrl } from 'services/utils/instrument-url';
import { useLocaleId } from 'hooks/useLocaleId';
import { usePageContext } from 'hooks/usePageContext';
import Instrument, { INSTRUMENT_STYLE } from 'widgets/instrument2';
import { Popover, Props as PopoverProps } from 'components/popover';
import { List, Props as ListProps } from 'components/list';

type IssueWithKeyAndFinamId = Issue & { key: string | number, finamId: Id };

type Props = Pick<
    PopoverProps, 'isVisible' | 'onClose' | 'top' | 'left'
> & Pick<
    ListProps<IssueWithKeyAndFinamId>, 'cursorElement' | 'onSelect' | 'maxHeight'
> & {
    searchTicker?: string,
    searchLimit: number,
};

const IssueUI = ({ issue }: { issue: IssueWithKeyAndFinamId }) => {
    const {
        guid, currency, name, ticker, last, change, dcpl, finamId,
    } = issue;
    return (
        <Instrument
            key={guid}
            style={INSTRUMENT_STYLE.PAINTED4}
            currency={currency}
            name={name}
            initTicker={ticker}
            initPrice={parseFloat(last)}
            initChangePercent={parseFloat((change || '').replace('−', '-'))}
            initDcpl={dcpl}
            url={instrumentUrl({ ticker, id: finamId })}
            isSuggestion
            forceReady
        />
    );
};
const renderIssue = (issue: IssueWithKeyAndFinamId) => <IssueUI issue={issue} />;

export const CashtagSuggestion = (props: Props) => {
    const {
        isVisible, onClose, top, left, searchTicker, cursorElement, onSelect, maxHeight, searchLimit,
    } = props;
    const [{ googleAnalytics: { googleAnalyticsId } }] = usePageContext();
    const locale = useLocaleId();
    const search = useMemo(() => {
        return new Search({ ga: googleAnalytics._getGA(googleAnalyticsId) });
    }, []);

    const [issues, setIssues] = useState<IssueWithKeyAndFinamId[]>([]);

    const clearIssues = () => setIssues([]);

    useEffect(() => {
        if (isVisible && searchTicker) {
            search.sendRequest(searchTicker, searchLimit, locale).then((data) => {
                setIssues(data.map((issue: Issue) => ({ ...search.resolveGuid(issue.guid), key: issue.guid })));
            });
        } else {
            setIssues([]);
        }
    }, [isVisible, searchTicker, searchLimit, locale, search]);

    const handleSelect = (issue: IssueWithKeyAndFinamId, cursor?: number, event?: KeyboardEvent) => {
        onSelect?.(issue, cursor, event);
        clearIssues();
    };

    const handleClose = () => {
        onClose?.();
        clearIssues();
    };

    return (
        <Popover
            isVisible={isVisible}
            onClose={handleClose}
            top={top}
            left={left}
            width="100%"
        >
            <List
                items={issues}
                cursorElement={cursorElement}
                onSelect={handleSelect}
                renderItem={(issue: IssueWithKeyAndFinamId) => renderIssue(issue)}
                maxHeight={maxHeight}
                focusFirst
            />
        </Popover>
    );
};
