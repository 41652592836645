import React, { useMemo } from 'react';
import { FeedRecordData } from 'types';
import { useFeedContext } from 'context/feed';
import { AILabel } from 'widgets/feed/moderation-components/styled';

const AI_REASON_KEY = 5000;

export const ModerationAILabel: React.FC<{recordData?: FeedRecordData}> = ({ recordData }) => {
    const isAI = useMemo(() => recordData?.reason?.latestReasonsData && recordData?.reason?.latestReasonsData[AI_REASON_KEY],
        [recordData]);
    const [{ isDeveloper }] = useFeedContext();

    if (!isDeveloper || !isAI) return null;

    return <AILabel title="Post by AI">AI</AILabel>;
};
