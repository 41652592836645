import { Id } from 'types';
import { createMutableContext } from './create-mutable-context';

export type FeedItemContextValue = {
    id: Id,
    /** Показана ли запись целиком */
    showFull: boolean,
    /** Удалена ли запись (с возможнотью восстановления) */
    isDeleted: boolean,
    /** Редактируется ли запись прямо сейчас */
    isEditing: boolean,
};

const { Provider, hook } = createMutableContext<FeedItemContextValue>({
    id: 0,
    showFull: false,
    isDeleted: false,
    isEditing: false,
});

export { Provider as FeedItemContextProvider, hook as useFeedItemContext };
