import React, { FC, useMemo } from 'react';
import times from 'lodash/times';

import { DesktopSkeletonWrapper, MobileSkeletonWrapper } from './styled/Skeleton';

interface ISkeletonProps {
    count?: number;
}

const DEFAULT_SKELETON_NUMBER = 5;

export const Skeleton: FC<ISkeletonProps> = ({ count }) => {
    const numberOfSkeletons = count ? Math.min(count, DEFAULT_SKELETON_NUMBER) : DEFAULT_SKELETON_NUMBER;

    const commentSkeletonItem = (
        <svg viewBox="0 0 656 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="24" y="10" width="40" height="40" rx="8" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="80" y="12" width="72" height="16" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="80" y="36" width="48" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="24" y="64" width="608" height="16" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="24" y="88" width="508" height="16" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        </svg>
    );

    const commentMobileSkeletonItem = (
        <svg viewBox="0 0 375 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16" y="8" width="32" height="32" rx="6" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="60" y="10" width="72" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="60" y="30" width="48" height="8" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="20" y="52" width="335" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
            <rect x="20" y="68" width="271" height="12" rx="2" fill="#A7BDF1" fillOpacity="0.24" />
        </svg>
    );

    const renderDesktopSkeleton = useMemo(() => (
        <DesktopSkeletonWrapper>
            {
                times(numberOfSkeletons, (index) => <div key={`commentDesktopSkeletonItem_${index}`}>{commentSkeletonItem}</div>)
            }
        </DesktopSkeletonWrapper>
    ), [commentSkeletonItem, numberOfSkeletons]);

    const renderMobileSkeleton = useMemo(() => (
        <MobileSkeletonWrapper>
            {
                times(numberOfSkeletons, (index) => <div key={`commentMobileSkeletonItem_${index}`}>{commentMobileSkeletonItem}</div>)
            }
        </MobileSkeletonWrapper>
    ),
    [commentMobileSkeletonItem, numberOfSkeletons]);

    return (
        <>
            {renderDesktopSkeleton}
            {renderMobileSkeleton}
        </>
    );
};
