/* eslint-disable camelcase */
import store from '../store';

export const isAuth = () => {
    const { user } = store.getState();
    return !!user?.id;
};

export const getUserInfo = () => {
    const { user, WTuser } = store.getState();

    if (!user) {
        return null;
    }

    if (!user) {
        return null;
    }

    const {
        id,
        country,
        user_pic,
        name,
        fullname,
        agreement,
        lcid,
        token,
        watchListToken,
        chat_signed_data,
    } = user;

    return {
        id,
        country,
        lcid,
        fullname,
        agreement,
        name,
        brokerLinks: WTuser?.brokerLinks,
        token: isAuth() ? token : null,
        watchListToken,
        avatar: user_pic,
        chatData: chat_signed_data,
        WTuser,
    };
};
