import styled from 'styled-components';
import { size as polishedSize } from 'polished';

import { ResponsiveSize } from 'types';
import { buildByResponsiveSize } from 'app/styled';
import { SIZES } from 'components/avatar/styled';
import CommonAvatar from './avatar2';

const dependantOfSizeFn = ({ size }: { size: string }) => polishedSize(SIZES[size] || size);

type Props = { responsiveSize: ResponsiveSize; };
export const StyledCommonAvatar = styled(CommonAvatar) <Props>`
    ${buildByResponsiveSize(dependantOfSizeFn)}
`;
