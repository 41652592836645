import tippy from 'services/tippy';
import merge from 'lodash/merge';

const onAuthenticatedCallbacks = {};
const onRegisteredCallbacks = {};

const wtAuthListener = (storage, event) => {
    const { context } = event.detail;
    const name = context && context.name || '';

    if (storage[name] === undefined && storage['*'] === undefined) {
        return;
    }

    const sendEvent = (listeners) => (listeners || []).find((callback) => {
        return callback(context, event) === true;
    });

    // vls: отправляем контекст во все коллбэки, но если коллбэк вернет true, значит необходимо прервать отправку
    if (!sendEvent(storage[name])) {
        sendEvent(storage['*']);
    }
};
const pushCallback = (storage, contextName, callback, event) => {
    if (!Object.keys(storage).length) {
        window.addEventListener(event, (event) => {
            wtAuthListener(storage, event);
        });
    }

    if (storage[contextName] === undefined) {
        storage[contextName] = [];
    }

    storage[contextName].push(callback);
};

const authPopup = {
    /**
     * @param {object|undefined} displayParams
     * @param {string|undefined} context
     * @param {object} contextParams
     */
    login(displayParams = undefined, context = undefined, contextParams = {}) {
        if (window.WT && window.WT.menu && window.WT.menu.login) {
            window.WT.menu.login(displayParams, context, contextParams)
        } else {
            console.error('The menu isn\'t loaded or initialized');
        }
    },
    /**
     * @param {object|undefined} displayParams
     * @param {string|undefined} context
     * @param {object} contextParams
     */
    registration(displayParams = undefined, context = undefined, contextParams = {}) {
        if (window.WT && window.WT.menu && window.WT.menu.registration) {
            window.WT.menu.registration(displayParams, context, contextParams)
        } else {
            console.error('The menu isn\'t loaded or initialized');
        }
    },
    /**
     * @param {string} context
     * @param {function} callback
     */
    onAuthenticatedInContext(context, callback) {
        pushCallback(onAuthenticatedCallbacks, context, callback, 'wtAuthAfterAuthentication')
    },
    /**
     * @param {string} context
     * @param {function} callback
     */
    onRegisteredInContext(context, callback) {
        pushCallback(onRegisteredCallbacks, context, callback, 'wtAuthAfterRegistration')
    },
    /**
     * {@deprecated} vls: устарело с переводом всего сайта на WT Auth. Следует использовать this.login() или this.registration()
     */
    show(context = 'short') {
        // vls: вызов попапов регистрации/аутентификации таким методом устарел (хотя работает), стоит переписать на
        // использование WT.menu.auth(). Не забыть что контекст так же необходимо передавать.
        if (typeof App !== 'undefined' && App.eventer && App.eventer.api) {
            tippy.hideAll(0);
            App.eventer.api(`dialoggy#registration:${context}`);
        } else {
            console.error('App.eventer.api is not function!');
        }
    },
    /**
     * {@deprecated} vls: устарело с переводом всего сайта на WT Auth. Следует использовать this.login() или this.registration()
     */
    subscribe(finamId) {
        if (typeof App !== 'undefined' && App.eventer && App.eventer.api) {
            tippy.hideAll(0);
            App.config.api[`dialoggy#registration:instrument-id-${finamId}`] = merge(
                App.config.api['dialoggy#registration:short'],
                {
                    request: {
                        data: {
                            instrument_finam_ids: [finamId],
                        },
                    },
                    onReveal(dialoggy) {
                        const { content } = dialoggy.active.ui;
                        const input = content
                            .find(`#registration_instrument-id-${finamId} .subscribe-to-instrument__fixed`)
                            .clone();
                        input
                            .appendTo(`#login_instrument-id-${finamId} .subscribe-to-instrument__wrap`);
                    },
                },
            );
            App.eventer.api(`dialoggy#registration:instrument-id-${finamId}`);
        } else {
            console.error('App.eventer.api is not function!');
        }
    },
};

export default authPopup;
