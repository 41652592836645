import React from 'react';
import PropTypes from 'prop-types';
import ClipboardService from 'clipboard';

import Dic from 'services/dictionary';
import { ClipboardWrapper, ClipboardIcon } from './styled';

class Clipboard extends React.PureComponent {
    clipboardRef = React.createRef();

    componentDidMount() {
        if (ClipboardService.isSupported()) {
            this.clipboardService = new ClipboardService(this.clipboardRef.current);
            this.clipboardService.on('success', () => {
                this.props.onSuccess();
            });
        }
    }

    componentWillUnmount() {
        if (this.clipboardService) {
            this.clipboardService.destroy();
        }
    }

    render() {
        if (!ClipboardService.isSupported()) return null;
        const { currentValue, customRender } = this.props;
        if (customRender) return customRender(this.clipboardRef);
        return (
            <ClipboardWrapper ref={this.clipboardRef} data-clipboard-text={currentValue}>
                {Dic.word('wt__wt_popup_component__clipboard_label')}
                <ClipboardIcon />
            </ClipboardWrapper>
        );
    }
}

Clipboard.propTypes = {
    currentValue: PropTypes.string,
    onSuccess: PropTypes.func,
    customRender: PropTypes.func,
};

Clipboard.defaultProps = {
    currentValue: '',
    onSuccess: () => {},
    customRender: null,
};

export default Clipboard;
