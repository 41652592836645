import styled, { css } from 'styled-components';

import { mediaQuery, animations, scrollWidth } from 'app/styled';
import {
    body14RegularFontStyle,
    caption12RegularFontStyle,
    body14SemiboldTNumFontStyle,
    caption11RegularFontStyle,
    caption11MediumTNumFontStyle,
    body14MediumFontStyle,
} from 'services/typography';

import { ItemHrefWrapper } from 'components/list/styled';
// @ts-ignore
import { List } from 'components/list';
import { StarIcon } from 'components/icons2/StarIcon';
import { StarFillIcon } from 'components/icons2/StarFillIcon';
import { LoadingRingIcon } from 'components/icons2/LoadingRingIcon';

export const containerWrapperStyle = css`
    margin: 0;
    padding: 0;

    list-style: none;
    a {
        text-decoration: none;
    }
`;

export const avatarStyle = css`
    display: flex;
    flex-shrink: 0;
    margin-right: 8px;

    width: 32px;
    height: 32px;
    border-radius: 8px;

    background-color: ${({ theme }) => theme.fillIn.secondary.muted};

    img {
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
`;

export const TabsWrapper = styled.div`
    padding: 12px 8px 0 8px;

    ${mediaQuery.lt768} {
        padding: 8px 8px 0 8px;
    }
`;

export const LoadingContainer = styled.ul`
    ${containerWrapperStyle}
`;

export const LoadingListWrapper = styled.li`
    display: flex;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
    height: 64px;
    align-items: center;

    padding: 0;
`;

export const NoResultContainer = styled.ul`
    ${containerWrapperStyle};

    padding: 24px 16px 24px 12px;
`;

export const NoResultListWrapper = styled.li`
    padding: 0;
    border: none;

    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.tertiary};
`;

export const PostsResultImageWrapper = styled.div`
    ${avatarStyle}
`;

export const PostsResultDescriptionWrapper = styled.div`
    min-width: 0;
    margin-top: 2px;
    margin-bottom: 2px;
`;

export const PostsResultTitleWrapper = styled.div`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PostsResultDateWrapper = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const ProductsResultImageWrapper = styled.div`
    ${avatarStyle}
`;

export const ProductsResultDescriptionWrapper = styled.div`
    min-width: 0;
    margin-top: 2px;
    margin-bottom: 2px;
`;

export const ProductsResultTitleWrapper = styled.div`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ProductsResultTypeWrapper = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const PostsResultListWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const ProductsResultListWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const TickersResultListWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const UsersResultListWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const TickersResultTicker = styled.div`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};
    margin-bottom: 2px;
`;

export const TickersResultValue = styled.div`
    ${body14SemiboldTNumFontStyle};

    color: ${({ theme }) => theme.label.primary};
    text-align: right;
    margin-bottom: 2px;
`;

export const TickersResultDescription = styled.div`
    min-width: 0px;
    flex-grow: 1;
`;

export const TickersResultExchange = styled.div`
    ${caption11RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-bottom: 2px;
`;

export const TickersResultPrice = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-left: 8px;
`;

export const TickersResultDelta = styled.div`
    ${caption11MediumTNumFontStyle};

    text-align: right;
    margin-bottom: 2px;

    .positiveChange {
        color: ${({ theme }) => theme.label.positive};
    }

    .negativeChange {
        color:${({ theme }) => theme.label.negative};
    }
`;

export const UsersResultAddUser = styled.div`
    display: flex;
    margin-left: auto;
    padding-left: 8px;
    align-items: center;

    color: ${({ theme }) => theme.label.secondary};
`;

export const UsersResultAvatar = styled.div`
    ${avatarStyle}
`;

export const UsersResultDescription = styled.div`
    min-width: 0;
    margin-top: 2px;
    margin-bottom: 2px;
`;

export const UsersResultNameWrapper = styled.div`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};
    display: flex;
    gap: 5px;

    min-width: 0px;
`;

export const UsersResultName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UsersResultSubscribers = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const LoadingIcon = styled(LoadingRingIcon)`
    animation: ${animations.spin} 0.5s infinite ease-in-out;
`;

export const StyledList = styled(List)`
    max-height: 512px;

    ${mediaQuery.lt768} {
        max-height: calc(100vh - 104px);
    }

    & ${ItemHrefWrapper} {
        padding-right: ${16 - scrollWidth}px;
    }
`;

export const FavoriteWrapper = styled.div`
    color: ${({ theme }) => theme.fillIn.secondary.low};
    cursor: pointer;
    margin-lefT: 8px;
`;

const starIconCss = css`
    fill: ${({ theme }) => theme.fillIn.secondary.low};
    &:hover {
        fill: ${({ theme }) => theme.label.primary};
    }
`;

export const StyledStarFillIcon = styled(StarFillIcon)`
    ${starIconCss}
`;

export const StyledStarIcon = styled(StarIcon)`
    ${starIconCss}
`;
