import styled from 'styled-components';

import { colors, mediaQuery } from 'app/styled';
import { body14MediumFontStyle, body17MediumFontStyle } from 'services/typography';

export const UserNameWrapper = styled.span`
    flex: 1 1 auto;
    float: initial;
    overflow: hidden;
    white-space: nowrap;
    display: flex;

    ${body17MediumFontStyle}

    ${mediaQuery.lt480} {
        ${body14MediumFontStyle}
    }
`;

const nameCss = `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    float: left;
    text-decoration: none;
    outline: none;
`;

export const NameLink = styled.a`
    ${nameCss};
    color: ${colors.dodgerDarkBlue};
`;

export const NameSpan = styled.span`
    ${nameCss};
`;
