import {
    ActionRequest, ActionResult, FeedRecordType, GeneratorType,
} from 'types';
import { mergeByPath } from 'services/utils/merge';
import { SUBSCRIBE_ACTION_TYPES, SubscribePayloadData, SubscribePayloadPass } from 'widgets/action-subscribe/actions';
import { FeedState } from '../types';

const [SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILURE] = SUBSCRIBE_ACTION_TYPES;

type AvailActionsData = SubscribePayloadData;
type AvailActionsPass = SubscribePayloadPass;
const updateStateSubscribed = (
    state: FeedState,
    pass: AvailActionsPass,
    needUpdate: (currentSubscribed: boolean) => boolean,
    updateValueFn: (currentSubscribed: boolean) => boolean,
): FeedState => {
    const { generatorId, generatorType } = pass;
    const newRecords = Object.keys(state.records).reduce((acc, key) => {
        let newRecord = state.records[key];
        if (generatorType === GeneratorType.USER) {
            const { generator, type } = newRecord;
            if (generator) {
                if (generator.id === generatorId && generator.type === generatorType) {
                    if (needUpdate(generator.data.subscribed)) {
                        newRecord = mergeByPath(newRecord, 'generator.data', { subscribed: updateValueFn(generator.data.subscribed) });
                    }
                }
            }
            if (type === FeedRecordType.RECOMMENDATIONS) {
                const { users } = newRecord.recommendations || {};
                if (users) {
                    newRecord = mergeByPath(
                        newRecord,
                        'recommendations.users',
                        users.map((user) => {
                            const { subscribed = false } = user?.data || {};
                            if (user.id === generatorId && needUpdate(subscribed)) {
                                return mergeByPath(user, 'data.subscribed', updateValueFn(subscribed));
                            }
                            return user;
                        }),
                    );
                }
            }
        }
        if (generatorType === GeneratorType.INSTRUMENT) {
            const { tickerNews } = newRecord;
            if (tickerNews) {
                if (tickerNews.instrument?.id && tickerNews.instrument.id === generatorId) {
                    if (needUpdate(Boolean(tickerNews.isSubscribed))) {
                        newRecord = mergeByPath(newRecord, 'tickerNews', { isSubscribed: updateValueFn(Boolean(tickerNews.isSubscribed)) });
                    }
                }
            }
        }
        return { ...acc, [key]: newRecord };
    }, {});
    return {
        ...state,
        records: newRecords,
    };
};
export default (
    state: FeedState,
    action: ActionRequest<AvailActionsPass, AvailActionsPass> | ActionResult<AvailActionsData, AvailActionsPass>,
) => {
    switch (action.type) {
        case SUBSCRIBE_REQUEST: {
            const { payload: { pass } } = action as ActionRequest<AvailActionsPass, AvailActionsPass>;
            const { action: backendAction } = pass;
            return updateStateSubscribed(state, pass, () => true, () => backendAction === 'subscribe');
        }
        case SUBSCRIBE_SUCCESS:
        case SUBSCRIBE_FAILURE: {
            const { payload: { data, pass }, error } = action as ActionResult<AvailActionsData, AvailActionsPass>;
            const { action: backendAction } = pass;
            if (error || (data.result && !data.result.ok)) {
                return updateStateSubscribed(
                    state,
                    pass,
                    (currentSubscribed) => (currentSubscribed === (backendAction === 'subscribe')),
                    (currentSubscribed) => !currentSubscribed,
                );
            }
            return state;
        }
        default:
            return state;
    }
};
