import { useEffect, RefObject } from 'react';
// eslint-disable-next-line no-restricted-imports
import { curry } from 'lodash';

import { GalleryInitFunction } from 'components/gallery/Gallery';

export const useClickAnyImgInPost = (
    showFull: boolean,
    contentWrapper: RefObject<HTMLDivElement>,
    thumbnail: RefObject<HTMLImageElement>,
    onClickAnyImgInPost?: GalleryInitFunction,
) => {
    const mapperImages = (collection: HTMLImageElement[]) => {
        return collection.map((item, index) => ({
            index,
            src: item.src,
        }));
    };

    useEffect(() => {
        let images: HTMLCollectionOf<HTMLImageElement> | [] = [];

        if (showFull) {
            if (contentWrapper.current) {
                images = contentWrapper.current.getElementsByTagName('img');

                const imgList = Array.from(images);

                const action = (index: number, e: Event) => {
                    e.preventDefault();

                    if (onClickAnyImgInPost) {
                        onClickAnyImgInPost(index, mapperImages(imgList));
                    }
                };

                const curryAction = curry(action);

                imgList.forEach((item, index) => {
                    item.addEventListener('click', curryAction(index));
                });

                return () => {
                    imgList.forEach((item, index) => {
                        item.removeEventListener('click', curryAction(index));
                    });
                };
            }
        } else if (thumbnail.current) {
            const action = (e: Event) => {
                e.preventDefault();
                if (onClickAnyImgInPost) {
                    onClickAnyImgInPost(0, [{
                        index: 0,
                        src: thumbnail.current?.src ? thumbnail.current.src : '',
                    }]);
                }
            };

            thumbnail.current?.addEventListener('click', action);

            return () => thumbnail.current?.removeEventListener('click', action);
        }

        return () => false;
    }, [showFull, thumbnail, contentWrapper]);
};
