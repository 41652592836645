import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ShowFull = styled.a`
    margin-top: 4px;
    margin-bottom: 12px;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    ${mediaQuery.lt960} {
        margin-bottom: 8px;
    }
`;

export const Wrapper = styled.div`
    word-break: break-word;

    pre {
        white-space: pre-wrap;
    }

    figure {
        max-width: 100% !important;
        height: auto !important;
    }

    .video_wrapper:not(.wrapper_video_provider_tiktok) {
        position: relative;
        padding-bottom: ${100 * (9 / 16)}%;
        object, embed, iframe, div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .blog-posts-item-preview__image, iframe {
        border-radius: 8px;
    }
`;

export const ThumbnailImage = styled.img`
    border-radius: 8px;
    cursor: pointer;
`;
