import React from 'react';

import { GeneratorType } from 'types/feed-record';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { UserCheckIcon } from 'components/icons2/UserCheckIcon';
import { UserPlusIcon } from 'components/icons2/UserPlusIcon';

import { StyledUserSubscribe, StyledInstrumentSubscribe } from './styled';

export interface Props {
    isSubscribed: boolean,
    onClick: () => void,
    generatorType: GeneratorType,
    className: string,
    clickable?: boolean,
    isLoading: boolean,
    buttonProps?: Parameters<typeof ButtonIcon>[0],
}
export const SubscribeButton = (props: Props) => {
    const {
        generatorType, isSubscribed, onClick, className, clickable = true, buttonProps = {},
    } = props;
    const childProps = {
        className, isSubscribed, onClick, clickable, ...buttonProps,
    };

    return (
        generatorType === GeneratorType.USER
            ? (
                <StyledUserSubscribe
                    icon={{ component: isSubscribed ? UserCheckIcon : UserPlusIcon }}
                    kind={ButtonIconKinds.FillSecondary}
                    {...childProps}
                />
            )
            : (
                <StyledInstrumentSubscribe
                    pressed={isSubscribed}
                    {...childProps}
                />
            )
    );
};

SubscribeButton.defaultProps = {
    className: '',
    clickable: true,
    buttonProps: {},
};
