import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';

import { useCSRFToken } from 'hooks/useCSRFToken';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';

import { useWebsocketListener } from 'hooks/useWebsocketListener';
import {
    NotificationItemDataType,
    NotificationItemResponseType,
    NotificationType,
} from 'widgets/header-notifications/types';
import {
    AddInStoreNotificationAction,
    DeleteNotificationAction,
    FetchNotificationAction,
    ReadInStoreNotificationsAction,
    ReadNotificationAction,
} from 'widgets/header-notifications/actions';

import { useGetDicwordTextObj } from 'widgets/header-notifications/constants';
import { LinkButton } from 'components/button2';

import { ButtonBadge } from './ButtonBadge';
import { Content } from './Content';

import * as Styled from './styled/HeaderNotifications.styled';

export type notificationTextObjType = {
        title: {
            [NotificationType.POST_LIKE]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.COMMENT_LIKE]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.POST_COMMENT]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.PRODUCT_COMMENT]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.COMMENT_ANSWERED]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.MENTION_PUBLISH]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.MENTION_COMMENT]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.SUBSCRIBE_TO_YOU]: {
                startPhrase: string,
                endPhrase: string,
            },
            [NotificationType.SOFT_BAN]: {
                text: string,
            },
            [NotificationType.PRIVACY_POLICY_CHANGES]: {
                text: string,
            },
            [NotificationType.WHATS_NEW]: {
                text: string,
            },
            [NotificationType.MONETIZATION_START]: {
                text: string,
            },
            [NotificationType.COMMENT_DELETE_FOUL]: {
                text: string,
            },
            [NotificationType.POST_UNPUBLISH_FOUL]: {
                text: string,
            },
            [NotificationType.ACCESS_MONETIZATION_GRANTED]: {
                text: string,
            },
            [NotificationType.PRODUCT_ADDED]: {
                text: string,
            },
            [NotificationType.PRODUCT_MODERATE_FALL]: {
                text: string,
            },
            [NotificationType.PRODUCT_UNPUBLISH_FOUL]: {
                text: string,
            },
        },
        message: {
            [NotificationType.SOFT_BAN]: string,
            [NotificationType.PRIVACY_POLICY_CHANGES]: string,
            [NotificationType.MONETIZATION_START]: string,
            [NotificationType.ACCESS_MONETIZATION_GRANTED]: string,
        },
        actionBtnText: {
            [NotificationType.SUBSCRIBE_TO_YOU]: {
                subscribed: string,
                notSubscribed: string,
            },
            [NotificationType.PRIVACY_POLICY_CHANGES]: string,
            [NotificationType.WHATS_NEW]: string,
            [NotificationType.ACCESS_MONETIZATION_GRANTED]: string,
        },
    };

export type textObjType = {
    title: string,
    emptyText: string,
    errorContent: {
        text: string,
        actionBtnText: string,
    },
    settingsBtnText: string,
    groupTitles: {
        unread: string,
        read: string,
    },
    notification: notificationTextObjType;
};

type HeaderNotificationsProps = {
    nextCursor: null | string,
    // TODO удалить когда будет готово меню встраиваемое на страницу и получать прямо в виджете
    userChannel?: string,
    readDate: string | null;
    notifications?: [NotificationItemDataType] | [] | null;
    isLoading: boolean;
    isError: boolean;
    unreadCount: number;
    totalCount: number;
    textObj?: textObjType;
    deleteNotification: DeleteNotificationAction;
    readNotifications: ReadNotificationAction;
    fetchNotifications: FetchNotificationAction;
    readInStoreNotifications: ReadInStoreNotificationsAction;
    addInStoreNotification: AddInStoreNotificationAction;
};

export const HeaderNotifications = (props: HeaderNotificationsProps) => {
    const {
        textObj: textObjProp,
        isLoading,
        isError,
        notifications,
        deleteNotification,
        fetchNotifications,
        addInStoreNotification,
        readInStoreNotifications: readInStoreNotificationsProp,
        readNotifications: readNotificationsProp,
        readDate,
        unreadCount,
        totalCount,
        nextCursor,
        userChannel,
    } = props;

    const [isOpened, setIsOpened] = useState(false);

    const hasUnread = unreadCount && unreadCount > 0;

    const csrf = useCSRFToken();

    const isShowBadge = (hasUnread && !isOpened) || false;

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    const dicwordTextObj = useGetDicwordTextObj();

    const textObj = textObjProp || dicwordTextObj;
    const {
        title,
        emptyText,
        errorContent,
        settingsBtnText,
        groupTitles: {
            unread: groupTitleUnread,
            read: groupTitleRead,
        },
        notification: notificationTextObj,
    } = textObj;

    const { sentinelElement } = useInfiniteScroll({
        onListEndReached: () => nextCursor && !isLoading && fetchNotifications(
            { cursor: nextCursor, combineNotifications: true },
        ),
    });

    const readNotifications = () => {
        if (readDate && hasUnread) {
            readNotificationsProp({ csrf, readDate });
        }
    };

    const readInStoreNotifications = () => {
        if (hasUnread) {
            readInStoreNotificationsProp();
        }
    };

    const socketAddNotification = useCallback((event: NotificationItemResponseType) => {
        addInStoreNotification(event);
        if (isOpened && event?.timestamp) {
            readNotificationsProp({ csrf, readDate: event.timestamp });
        }
    }, [isOpened, readNotificationsProp, addInStoreNotification, csrf]);

    useWebsocketListener({
        channelName: userChannel,
        eventName: 'new_notification',
        callback: socketAddNotification,
    });

    const socketNotificationsRead = useCallback(() => {
        if (!isOpened) {
            readInStoreNotifications();
        }
    }, [readInStoreNotifications, isOpened]);

    useWebsocketListener({
        channelName: userChannel,
        eventName: 'notifications_read',
        callback: socketNotificationsRead,
    });

    useEffect(() => {
        if (isOpened) {
            readNotifications();
        } else {
            readInStoreNotifications();
        }
    }, [isOpened]);

    return (
        <>
            <Styled.ContextActionsGlobalStyle isOpened={isOpened} />
            <Styled.ContextActions
                hideOnScroll={false}
                tippyContent={(
                    <Content
                        title={title}
                        emptyText={emptyText}
                        errorContent={errorContent}
                        isLoading={isLoading}
                        isError={isError}
                        headerButton={(
                            <LinkButton
                                href="/cabinet/notifications"
                                kind="ghost-secondary"
                                size="tiny"
                                className="autotest__header-notifications__settings-link"
                            >
                                {settingsBtnText}
                            </LinkButton>
                        )}
                        totalCount={totalCount}
                        sentinelElement={sentinelElement}
                        notifications={notifications}
                        groupTitles={{
                            unread: groupTitleUnread,
                            read: groupTitleRead,
                        }}
                        deleteNotification={(id) => deleteNotification({ id, csrf })}
                        fetchNotifications={fetchNotifications}
                        notificationTextObj={notificationTextObj}
                    />
                )}
                interactive
                buttonContent={(
                    <ButtonBadge
                        isShowBadge={isShowBadge}
                        isActive={isOpened}
                    />
                )}
                onVisibleChange={({ isVisible }) => {
                    if (isVisible) {
                        setIsOpened(true);
                    } else {
                        setIsOpened(false);
                    }
                }}
                className="autotest__header-notifications__context-actions"
                buttonClassName="autotest__header-notifications__context-actions-btn"
            />
        </>
    );
};
