import {
    Action,
    Id,
    ThunkResultFn,
} from 'types';

import { createRequestActionTypes, thunkActions } from 'services/create-action-types';
import UrlProcessor from 'services/url-processor';
import {
    GlobalStateHeaderNotifications,
    NotificationItemResponseType,
} from 'widgets/header-notifications/types';

export type FetchNotificationsPayloadData = {
    ok: boolean;
    nextCursor: string | null;
    readDate: string;
    notifications: null | [NotificationItemResponseType];
    unreadCount: number;
    totalCount: number;
};

export type _FetchNotificationsPayloadPassWithSpecialState = {
    combineNotifications: boolean;
};
export type FetchNotificationsActionArgs = {
    cursor?: string | undefined,
    combineNotifications?: boolean // true = соединяет данные store и данных с запроса получения уведомлений
};
export const FETCH_NOTIFICATIONS_ACTIONS_TYPES = createRequestActionTypes('HEADER_NOTIFICATIONS_FETCH');
export const fetchNotifications = (args?:FetchNotificationsActionArgs): ThunkResultFn<FetchNotificationsPayloadData, _FetchNotificationsPayloadPassWithSpecialState, GlobalStateHeaderNotifications> => {
    return (
        thunkActions({
            types: FETCH_NOTIFICATIONS_ACTIONS_TYPES,
            payload: {
                request: {
                    url: UrlProcessor.page('limex_api_notification_list').path(),
                    method: 'GET',
                    params: {
                        cursor: args?.cursor || null,
                    },
                },
                pass: {
                    combineNotifications: args?.combineNotifications || false,
                },
            },
        })
    );
};

export type ReadNotificationsPayloadData = {
    readCount: number,
};
export type ReadNotificationsActionArgs = {
    readDate: string;
    csrf: string;
}
export const READ_NOTIFICATIONS_ACTIONS_TYPES = createRequestActionTypes('HEADER_NOTIFICATIONS_READ');
export const readNotifications = (
    { readDate, csrf }: ReadNotificationsActionArgs,
): ThunkResultFn<ReadNotificationsPayloadData, unknown, GlobalStateHeaderNotifications> => (
    thunkActions({
        types: READ_NOTIFICATIONS_ACTIONS_TYPES,
        payload: {
            request: {
                url: UrlProcessor.page('limex_api_notification_read').path(),
                method: 'POST',
                data: {
                    readDate,
                    csrf,
                },
            },
        },
    })
);

export type AddInStoreNotificationPayloadData = {
    payload: NotificationItemResponseType,
};
export const ADD_IN_STORE_NOTIFICATION_ACTION = 'widgets/HEADER_NOTIFICATIONS_ADD_IN_STORE_NOTIFICATION';
export const addInStoreNotification = (payload: NotificationItemResponseType) => {
    return {
        type: ADD_IN_STORE_NOTIFICATION_ACTION,
        payload,
    };
};
export type ReadInStoreNotificationPayloadData = {
    payload: NotificationItemResponseType,
};
export const READ_IN_STORE_NOTIFICATIONS_ACTION = 'widgets/HEADER_NOTIFICATIONS_READ_IN_STORE';
export const readInStoreNotifications = () => {
    return {
        type: READ_IN_STORE_NOTIFICATIONS_ACTION,
    };
};

export type DeleteNotificationPayloadData = {
    deleted: boolean,
};
export type _DeleteNotificationPayloadPassWithSpecialState = {
    id: Id;
};
export type DeleteNotificationActionArgs ={
    id: Id;
    csrf: string;
};
export const DELETE_NOTIFICATION_ACTIONS_TYPES = createRequestActionTypes('HEADER_NOTIFICATIONS_DELETE');
export const deleteNotification = (
    { id, csrf }: DeleteNotificationActionArgs,
): ThunkResultFn<DeleteNotificationPayloadData, _DeleteNotificationPayloadPassWithSpecialState, GlobalStateHeaderNotifications> => {
    return thunkActions({
        types: DELETE_NOTIFICATION_ACTIONS_TYPES,
        payload: {
            request: {
                url: UrlProcessor.page('limex_api_notification_manage').param('notificationId', id).path(),
                method: 'DELETE',
                data: {
                    csrf,
                },
            },
            pass: {
                id,
            },
        },
    });
};

export type ReadNotificationAction = Action<typeof readNotifications>;
export type DeleteNotificationAction = Action<typeof deleteNotification>;
export type FetchNotificationAction = Action<typeof fetchNotifications>;
export type AddInStoreNotificationAction = Action<typeof addInStoreNotification>;
export type ReadInStoreNotificationsAction = Action<typeof readInStoreNotifications>;
