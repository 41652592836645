import {
    css,
    keyframes,
    FlattenInterpolation,
    ThemedStyledProps,
} from 'styled-components';

import { Theme } from 'services/theme';

export * from './styled-legacy';

// MARK: - Responsive Layout Utilities

export type CSSLiteral<Props = unknown> = FlattenInterpolation<ThemedStyledProps<Props, Theme>>;

export type ResponsiveBreakpoint = keyof typeof mediaQuery;

export type ResponsiveMap<T> = Partial<Record<ResponsiveBreakpoint, T>> & {
    default: T;
};
export type MediaQueriesMap = Record<ResponsiveBreakpoint, string>;

/**
 * Media queries fragments for every breakpoint
 * @example
 * const style = `
 *   width: 100px;
 *
 *   ${mediaQuery.lt960} {
 *     width: 70px;
 *   }
 *
 *   ${mediaQuery.lt768} {
 *     width: 50px;
 *   }
 * `;
 */
export const mediaQuery = {
    // НЕ менять порядок ключей, порядок важен для reduceBreakpoints
    gteq1400: '@media (min-width: 1400px)',
    lt1400: '@media (max-width: 1399px)',
    lt1200: '@media (max-width: 1199px)',
    lt960: '@media (max-width: 959px)',
    lt768: '@media (max-width: 767px)',
    lt480: '@media (max-width: 479px)',
} as const;

export const scrollWidth = 8;

/**
 * For every breakpoint in the `responsiveMap`, it will wrap the result of a call to the `styleCallback`
 * into the corresponding media query, concatenating the resulting CSS.
 *
 * @example
 * const responsiveSize = {
 *   lt960: 'small',
 *   lt1200: 'big',
 *   default: 'large'
 * };
 *
 * const cssWithMediaQueries = reduceBreakpoints(responsiveSize, (size) => {
 *   switch(size) {
 *      case 'small':
 *         return css`width: 5px;`;
 *      case 'big':
 *         return css`width: 10px;`;
 *      case 'large':
 *         return css`width: 15px;`;
 *   }
 * });
 */
export function reduceBreakpoints<ResponsiveValue extends string | number>(
    sizeOrSizeMap: ResponsiveValue | ResponsiveMap<ResponsiveValue>,
    styleCallback: (value: ResponsiveValue) => CSSLiteral | string,
) {
    if (typeof sizeOrSizeMap !== 'object') {
        // Handle single size as argument
        return styleCallback(sizeOrSizeMap);
    }

    // Handle size map as argument
    const sizeMap = sizeOrSizeMap;
    const entries = Object.entries(sizeMap);
    if (!entries.length) {
        throw new Error(
            'An empty responsive size map was provided. '
            + 'You must at least provide the "default" property.',
        );
    }

    const mediaBreakpoints = Object.keys(mediaQuery) as ResponsiveBreakpoint[];
    const breakpoints = ['default', ...mediaBreakpoints] as const;

    // For every breakpoint in the sizeMap, execute stylePerBreakpoint,
    // and concatenate the results
    return breakpoints.reduce((result, breakpoint) => {
        const value = sizeMap[breakpoint];
        if (!value) {
            return result;
        }

        const stylePerBreakpoint = styleCallback(value) ?? '';
        const mediaStyle = breakpoint === 'default'
            ? stylePerBreakpoint
            : css`
                ${mediaQuery[breakpoint]} {
                    ${stylePerBreakpoint}
                }
            `;

        return css`${result}\n${mediaStyle}`;
    }, css`` as CSSLiteral);
}

/**
 * For every breakpoint and its value in the `responsiveMap`,
 * it will wrap the styles at `stylesMap[value]` with the corresponding media query, concatenating the resulting CSS.
 *
 * @example
 * const stylesMap = {
 *   small: css`width: 5px;`,
 *   big: css`width: 10px;`,
 *   large: css`width: 15px;`
 * }
 *
 * const responsiveSize = {
 *   lt960: 'small',
 *   lt1200: 'big',
 *   default: 'large'
 * };
 *
 * const cssWithMediaQueries = matchBreakpoints(responsiveSize, stylesMap);
 */
export function matchBreakpoints<T extends string | number>(
    sizeOrSizeMap: T | ResponsiveMap<T>,
    stylesMap: Record<T, CSSLiteral | string>,
) {
    return reduceBreakpoints(sizeOrSizeMap, (value) => stylesMap[value]);
}

export const boxShadowLikeBorder = (width: string, color: string) => `inset 0 0 0 ${width} ${color}`;

// MARK: - Animation Utilities

export const animations = {
    pulse: keyframes`
        0% { opacity: 0.1; }
        50% { opacity: 1; }
        100% { opacity: 0.1; }
    `,
    pulsar: keyframes`
        0% { transform:scale3d(1, 1, 1); }
        50% { transform: scale3d(0.95, 0.95, 1); }
        100% { transform: scale3d(1, 1, 1); }
    `,
    spin: keyframes`
        100% { transform: rotate(360deg); }
    `,
} as const;

// MARK: - Style Resets

/** CSS for resetting the default browser styles for the <button /> element */
export const buttonResetStyle = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

/** CSS for resetting the default browser styles for the <a /> element */
export const anchorResetStyle = css`
    color: inherit;
    text-decoration: inherit;
`;

/** CSS for resetting the default browser styles for the <li />/<ul /> elements */
export const listResetStyle = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

/** CSS for resetting the default browser styles for the <p /> element */
export const paragraphResetStyle = css`
    padding: 0;
    margin: 0;
`;

/** CSS for resetting the default browser styles for the <h1,2... /> elements */
export const headerResetStyle = css`
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: auto;
`;

export const labelPrimary = ({ theme }: { theme: Theme }) => theme.label.primary;
export const labelSecondary = ({ theme }: { theme: Theme }) => theme.label.secondary;
