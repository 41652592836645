import compareObjects from '../services/compareObjects';
import sendGA from '../../../services/utils/send-ga';
import {
    GET_USER_API,
    BUSINESS_METRICS_SEARCH_CLICK,
    projectsLinks,
    PROCESS_BUID_METRICS_API,
} from '../services/constants';
import { wtAuthModuleIsAuthenticationOrRegistrationProcess } from '../services/wtAuthModule';
import {
    saveUserToken,
    loadUserToken,
    removeUserToken,
} from '../services/userToken';
import { bmvikStorage } from '../services/businessMetrics';
import { menuLogger } from '../services/menuLogger';
import resolveCallBack from '../services/resolveCallBack';
import { userCache } from '../services/handleUserCaching';
import { wtUserMetricsGet } from '../services/wtUserMetrics';

const { whotradesLink } = projectsLinks;

export const windowReload = (caller = null) => {
    menuLogger.log(`🔃 Reload page ${caller ? `🎲 Coused by: ${caller}` : ''}`);
    menuLogger.log('🔗 RELOAD TRACE');
    menuLogger.trace();

    if (window.WT.menu.debug.shouldNotReload()) {
        menuLogger.log('🛑🛑🛑 Reload cancelled');
        return;
    }

    const [baseUrl, search] = window.location.href.split('?');
    const newSearch = search ? `?${search
        .split('&')
        .filter((str) => (!str.startsWith('wt-auth-popup') && !str.startsWith('wt-auth-code') && !str.startsWith('wt-auth-email')))
        .join('&')}` : '';
    window.location.href = `${baseUrl}${newSearch}`;
};

export const windowRedirect = (url, caller = null) => {
    menuLogger.log(`⏭️ Redirect page to ${url} ${caller ? `🎲 Caused by: ${caller}` : ''}`);
    menuLogger.log('🔗 REDIRECT TRACE');
    menuLogger.trace();

    if (window.WT.menu.debug.shouldNotReload()) {
        menuLogger.log('🛑🛑🛑 Redirect cancelled');
        return;
    }
    window.location.href = url;
};

export const setForceLoadingOfWtAuth = () => ({
    type: 'SET_FORCE_LOADING_OF_WT_AUTH',
});

export const setLanguage = (language) => {
    if (window.WT.auth) {
        window.WT.auth.changeLang(language);
    }

    return {
        type: 'SET_LANGUAGE',
        language,
    };
};

export function setServerLanguage(locale) {
    return (dispatch, getState) => {
        const {
            user,
            callbacks,
        } = getState();

        const headers = new Headers();
        let userToken = user && user.token;
        if (!userToken) {
            userToken = loadUserToken();
        }
        if (userToken) {
            headers.set('X-User-Token', userToken);
        }
        return fetch(
            `${whotradesLink}/language/switcher/json?lcid=${locale}`,
            {
                credentials: 'include',
                headers,
            },
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                menuLogger.log('server lang set');
                resolveCallBack(callbacks, 'langSwitchCallback', [locale]);
                window.location.reload();
            })
            .catch(() => menuLogger.error('Failed to set language'));
    };
}

export const setUser = (user) => {
    if (user) {
        saveUserToken(user.token);
    }

    return {
        type: 'SET_USER',
        user,
    };
};

export const setWTUser = (WTuser) => ({
    type: 'SET_WT_USER',
    WTuser,
});

export const setUserLoading = (loadingStatus) => ({
    type: 'SET_USER_LOADING',
    loadingStatus,
});

export const setSearchActiveTab = (searchActiveTab) => ({
    type: 'SET_SEARCH_ACTIVE_TAB',
    searchActiveTab,
});

export const setUserFetchRetryCount = (userFetchRetryCount) => ({
    type: 'SET_USER_FETCH_RETRY_COUNT',
    userFetchRetryCount,
});

export const setUserFetchingStatus = (isUserFetching) => ({
    type: 'SET_USER_FETCHING_STATUS',
    isUserFetching,
});

export const logOuted = () => () => {
    menuLogger.log('Logout execution, reset user cache token and txVariant');
    removeUserToken();
    userCache.delete();
};

function actionsOnUserChanges(userDiff, prevUser, nextUser) {
    return (dispatch, getState) => {
        if (!userDiff) {
            return;
        }
        // применить дифф если надо
        // колбеки из дефолт конфига
        const {
            areEqual,
            diff,
        } = userDiff;

        const { callbacks } = getState();

        // если token хоть как-то изменился #WTT-8022
        if (!areEqual && diff.indexOf('token') !== -1) {
            menuLogger.log('📝 onAfterTokenChange to be called | USER DIFF: ', userDiff);
            resolveCallBack(callbacks, 'onAfterTokenChange');
        }

        // если id превратился в null - тогда да, Logout
        if (diff.indexOf('id') !== -1 && nextUser.id === null) {
            menuLogger.log('📝 onAfterLogout to be called | USER DIFF: ', userDiff);
            dispatch(logOuted());
            resolveCallBack(callbacks, 'onAfterLogout');
            return;
        }

        // пользователь тот же самый, просто обновились некоторые данные у него, к примеру аватарка
        if (!areEqual && diff.indexOf('id') === -1) {
            menuLogger.log('📝 onAfterUserUpdate to be called | USER DIFF: ', userDiff);
            resolveCallBack(callbacks, 'onAfterUserUpdate', [diff]);
            return;
        }

        // если id был число и стал другим числом (но приходит там стринг) onAfterUserChange
        const isNumOrString = (value) => typeof value === 'number' || typeof value === 'string';
        if (!areEqual && diff.indexOf('id') !== -1 && isNumOrString(prevUser.id) && isNumOrString(nextUser.id)) {
            menuLogger.log('📝 onAfterUserChange to be called | USER DIFF: ', userDiff);
            resolveCallBack(callbacks, 'onAfterUserChange');
            return;
        }

        // если id был null и стал числом - тогда onAfterAuthentication
        if (!areEqual && diff.indexOf('id') !== -1 && prevUser.id === null && isNumOrString(nextUser.id)) {
            menuLogger.log('📝 onAfterAuthentication to be called | USER DIFF: ', userDiff);
            resolveCallBack(callbacks, 'onAfterAuthentication', [false, {
                userId: nextUser.id,
                csrf: nextUser.csrf,
                token: nextUser.token,
                hasConnectedSocialNetworks: nextUser.hasConnectedSocialNetworks,
            }, undefined]);
        }
    };
}

export function searchResultClickLog(payload) {
    return async () => {
        sendGA('wt-menu', ['search-result-click', payload.type]);
        const url = new URL(BUSINESS_METRICS_SEARCH_CLICK);
        Object.keys(payload).forEach((param) => url.searchParams.set(param, payload[param]));
        await fetch(
            url, {
                credentials: 'include',
                method: 'GET',
            },
        );
    };
}

export function processBuidMetrics() {
    return async (dispatch, getState) => {
        const { user } = getState();
        const currentUrl = window.location.href;

        menuLogger.log('It\'s processing BUID metrics for URL', currentUrl, 'and project', 'limex');

        const headers = new Headers({
            'Content-Type': 'application/json',
        });

        let userToken = user && user.token;
        if (!userToken) {
            userToken = loadUserToken();
        }
        if (userToken) {
            headers.set('X-User-Token', userToken);
        }

        const userMetrics = await wtUserMetricsGet();
        const buid = await userMetrics.getBuid();

        const request = await fetch(PROCESS_BUID_METRICS_API, {
            credentials: 'include',
            method: 'POST',
            headers,
            body: JSON.stringify({
                currentUserUrl: currentUrl,
                referrer: document.referrer,
                activeProject: 'limex',
                buid,
            }),
        });

        let response = {};
        if (request.ok) {
            response = await request.json();
        }

        if (!response?.result?.ok) {
            menuLogger.error('It couldn\'t process BUID metrics', request, response);
        }
    };
}

const userWtComparableFields = [
    'id',
    'country',
    'lcid',
    'fullname',
    'name',
    'token',
    'user_pic',
];

export const setLastUserFetchTime = (time) => ({
    type: 'SET_LAST_USER_FETCH_TIME',
    time,
});

export const setUserRefetchResetTimeSeconds = (time) => ({
    type: 'SET_USER_REFETCH_RESET_TIME',
    time,
});

export function fetchUser(changeLoaduserState = true) {
    return async (dispatch, getState) => {
        // fetchUser может дёргаться в разных местах и при разных условиях, в зависимости от проекта и других факторов,
        // но одновременно должен выполняться только один запрос юзера, иначе возможно ошибки @WTT-7797
        if (getState().isUserFetching) {
            if (!getState().user
            && (Date.now() - getState().lastUserFetchTime) / 1000 > getState().userRefetchResetTimeSeconds) {
                /* если юзер уже фетчился до этого но всё он ещё null,
                  при этом статус не менялся уже слишком долго,
                  значит упало с неотловленной ошибкой, это обычно делает
                  модуль txAuth, при рефетче надо его отключить и сбросить статус
                  WTT-857  WTT-8584 */
                window.WT.menu.debug.useTxAuth(false);
                dispatch(setUserFetchingStatus(false));
                menuLogger.log('⚠️⚠️⚠️ txAuth fails so it was disabled');
            } else {
                menuLogger.debug('wt-menu trottling');
                return;
            }
        }

        dispatch(setUserFetchingStatus(true));
        dispatch(setLastUserFetchTime(Date.now()));

        if (changeLoaduserState) {
            dispatch(setUserLoading(true));
        }

        try {
            const {
                user,
            } = getState();
            let {
                txUserToken,
            } = getState();
            // 2. сюда в пейлоад токен глобалюзера
            const headers = new Headers({
                'Content-Type': 'application/json',
            });

            if (wtAuthModuleIsAuthenticationOrRegistrationProcess() && txUserToken) {
                txUserToken = null;
                menuLogger.log('It\'s going to omit the sending of TX token because WT Auth is processing authentication or registration');
            }

            let userToken = user && user.token;
            if (!userToken) {
                userToken = loadUserToken();
            }
            if (userToken) {
                headers.set('X-User-Token', userToken);
            }
            const bmvik = bmvikStorage.get();
            if (bmvik) {
                headers.set('X-BMVIK', bmvik);
            }

            let newWtUser;

            try {
                newWtUser = await window.WT.menu.getUserInfoPromise();
            } catch (e) {
                console.error(e);
                newWtUser = {};
            }

            // 3. он вернёт WT юзера скоректированного на основе токена глобал юзера
            // которого надо посетить и сравнить с предыдущим и на основе этого вызвать колбеки
            const currentWtUser = () => {
                const state = getState();
                return state.WTuser || state.user || null;
            };

            // ### таким образом колбеки всегда один раз вызывваются,
            //  два юзера (ги и вт) синхронизированны на беке
            const leaveOnlyWtFieldsFromList = (obj) => userWtComparableFields.reduce((acc, curr) => acc = { ...acc, ...{ [curr]: obj[curr] } }, {});
            const prevWtUser = currentWtUser();
            const prevWtUserComparable = prevWtUser ? leaveOnlyWtFieldsFromList(prevWtUser) : null;
            const newWtUserComparable = leaveOnlyWtFieldsFromList(newWtUser);
            const WtUserDiff = prevWtUserComparable ? compareObjects(prevWtUserComparable, newWtUserComparable) : null;

            dispatch(setWTUser(newWtUser));

            // 4. потом сетим активнеоо юзера на основе того, что пришло от эндпоинта, который позаботился о синхронизации юзеров.
            dispatch(setUser(newWtUser));
            userCache.set(newWtUser);
            // 5. вызываем коллбеки, когда активный юзер уже новый, таким образом, если в колбеке понадобится юзер, он будет новым.
            dispatch(actionsOnUserChanges(WtUserDiff, prevWtUser, newWtUser));
            dispatch(setUserFetchRetryCount(0));

            // vls: если на беке не получилось поставить лок при синхронизации с TX Auth, то нужно повторить запрос,
            // но чуть позже (соседний запрос уже выполняет синхронизацию)
            if (newWtUser.reloadLater) {
                menuLogger.log(`It couldn't acquire the TX Auth lock. It will repeat the request after ${newWtUser.reloadLater} ms`);
                setTimeout(
                    () => { dispatch(fetchUser()); },
                    newWtUser.reloadLater,
                );
            }
        } catch (error) {
            const {
                userFetchRetryCount,
                userFetchRetryMaxCount,
            } = getState();
            if (userFetchRetryCount < userFetchRetryMaxCount) {
                // fs: делаем несколько попыток сходить за юзером, если не получается #WTT-8179
                menuLogger.log(`Trying to refetch. Attempt ${userFetchRetryCount} from ${userFetchRetryMaxCount}`);
                dispatch(setUserFetchingStatus(false));
                dispatch(setUserFetchRetryCount(userFetchRetryCount + 1));
            } else {
                menuLogger.error(`Failed user fetch ${error}, try to check if this endpoint works correctly: ${GET_USER_API} `);
            }
        } finally {
            dispatch(setUserLoading(false));
            dispatch(setUserFetchingStatus(false));
        }
    };
}

export const whotradesLoguotRequest = () => () => {
    window.WT.menu.forceShowWtAuthPopup('SignOut');
};
