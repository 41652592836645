import { FeedRecordStatsLikes } from 'types';

export const calculateLikes = (oldLikes: FeedRecordStatsLikes, newChoice: number) => {
    const likes = { ...oldLikes };
    const type = (cond: boolean) => (cond ? 'positive' : 'negative');
    if (likes.userChoice) {
        if (newChoice) {
            likes[type(newChoice > 0)] += 1;
            likes[type(newChoice < 0)] -= 1;
        } else {
            likes[type(likes.userChoice > 0)] -= 1;
        }
    } else {
        likes[type(newChoice > 0)] += 1;
    }
    likes.userChoice = newChoice;
    return likes;
};
