import styled from 'styled-components';

export const AnchorStyled = styled.div`
    position: relative;
`;

export const BoxStyled = styled.div<{ left?: number; right?: number; width?: number; maxWidth?: boolean }>`
    position: absolute;
    left: ${({ left }) => (typeof left === 'number' ? `${left}px` : 'auto')};
    right: ${({ right }) => (typeof right === 'number' ? `${right}px` : 'auto')};
    top: calc(100% + 22px);
    z-index: 999;
    overflow: hidden;
    width: ${({ width, maxWidth }) => {
        if (maxWidth) {
            return '100%';
        }
        if (width) {
            return `${width}px`;
        }
        return 'auto';
    }};
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 3px;
    border-radius: 4px;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

export const Spinner = styled.div`
    width: 30px;
    height: 30px;
    margin-top: 24px;
    transform: translate(-50%,-50%);
    &.small {
        margin-top: 10px;
        width: 16px;
        height: 16px;
        margin-bottom: -8px;
    }

    ::before {
        border: 3px solid rgba(0,0,0,.2);
    }

    ::after {
        border: 3px solid transparent;
        border-top-color: #2f4f4f;
        animation: spinner .6s linear;
        animation-iteration-count: infinite;
    }

    ::before, ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-sizing: border-box;
    }
`;
