import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';
import { size as polishedSize } from 'polished';

import { reduceBreakpoints, boxShadowLikeBorder, buttonResetStyle } from 'app/styled';
import { Theme } from 'services/theme';
import IconedText, { Props as IconedTextProps } from 'components/iconed-text2';

export enum ButtonIconKinds {
    FillPrimary = 1,
    FillSecondary,
    GhostPrimary,
    GhostSecondary,
}

const buttonColorMap = (theme: Theme, kind: ButtonIconKinds): {
    [key in '_default' | 'hover' | 'pressed' | 'focus' | 'activeFocus' | 'disabled']: Record<string, string>
} => {
    const {
        fillIn, label,
    } = theme;
    return ({
        [ButtonIconKinds.FillPrimary]: {
            _default: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay16,
                color: label.secondaryActive,
            },
            pressed: {
                bg: fillIn.primary.overlay32,
                color: label.accent,
            },
            focus: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: fillIn.primary.overlay32,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: fillIn.secondary.overlay16,
                color: label.inactive,
            },
        },
        [ButtonIconKinds.FillSecondary]: {
            _default: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay16,
                color: label.secondaryActive,
            },
            pressed: {
                bg: fillIn.secondary.muted,
                color: label.primary,
            },
            focus: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: fillIn.secondary.muted,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: fillIn.secondary.overlay16,
                color: label.inactive,
            },
        },
        [ButtonIconKinds.GhostPrimary]: {
            _default: {
                bg: 'none',
                color: label.secondary,
            },
            hover: {
                bg: 'none',
                color: label.secondaryActive,
            },
            pressed: {
                bg: 'none',
                color: label.accent,
            },
            focus: {
                bg: 'none',
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: 'none',
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: 'none',
                color: label.inactive,
            },
        },
        [ButtonIconKinds.GhostSecondary]: {
            _default: {
                bg: 'none',
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay24,
                color: label.secondaryActive,
            },
            pressed: { // active
                bg: fillIn.secondary.muted,
                color: label.primary,
            },
            focus: { // focused
                bg: 'none',
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: { // active focused
                bg: fillIn.secondary.muted,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: 'none',
                color: label.inactive,
            },
        },
    }[kind]);
};

const buttonIconSizeMap = {
    L: {
        height: 40,
        indent: 8,
        iconSize: 24,
        textPadding: 12,
        fontSize: 17,
        lineHeight: 24,
    },
    M: {
        height: 32,
        indent: 4,
        iconSize: 24,
        textPadding: 8,
        fontSize: 14,
        lineHeight: 20,
    },
    S: {
        height: 24,
        indent: 4,
        iconSize: 16,
        textPadding: 8,
        fontSize: 12,
        lineHeight: 16,
    },
};
const colorFill = (color: string) => `
    color: ${color};
    fill: ${color};
`;

const dependantOfSize = ({
    height, indent, iconSize, textPadding, fontSize, lineHeight,
}: typeof buttonIconSizeMap.L, textIsGiven: boolean) => {
    return css`
        height: ${height}px;
        min-width: ${height}px;
        > div {
            padding-top: ${indent}px;
            padding-bottom: ${indent}px;
            padding-right: ${textIsGiven ? textPadding : 0}px;
            font-size: ${fontSize}px;
            line-height: ${lineHeight}px;
            height: 100%;
            ${ifNotProp('icon', `
                padding-left: ${textIsGiven ? textPadding : 0}px;
            `)}

            ${ifNotProp('icon', `
                padding-left: ${textIsGiven ? textPadding : 0}px;
            `)}
        }
        .fa {
            font-size: ${iconSize}px;
        }
        svg {
            ${polishedSize(iconSize)};
        }
        .fa, svg {
            margin-left: ${indent}px;
            margin-right: ${indent}px;
        }
    `;
};

export type SimpleSize = 'L' | 'M' | 'S';
export type Size = SimpleSize | { default: SimpleSize, lt768?: SimpleSize, lt960?: SimpleSize; }
const buildSizes = ({ text, size }: {
    size: Size,
    text: IconedTextProps['text'],
}) => {
    const textIsGiven = Boolean(text) || text === 0;
    return reduceBreakpoints(size, (_size) => dependantOfSize(buttonIconSizeMap[_size], textIsGiven));
};

export const buttonIconKindStyle = () => css`
    ${(
        {
            kind = ButtonIconKinds.FillPrimary,
            theme,
        }: { size: Size, kind: ButtonIconKinds, theme: Theme },
    ) => {
        const {
            _default, hover, pressed, focus, activeFocus, disabled,
        } = buttonColorMap(theme, kind);
        const pressedCss = `
            background: ${pressed.bg};
            ${colorFill(pressed.color)};
            border-radius: 8px;
        `;
        return css`
            > div {
                ${colorFill(_default.color)};
                background: ${_default.bg};
            }
            ${ifNotProp('pressed', `
                &:hover {
                    > div {
                        background: ${hover.bg};
                        ${colorFill(hover.color)};
                    }

                }
            `)}
            &:focus {
                > div {
                    ${ifNotProp('pressed', css`
                        background: ${focus.bg};
                    `)}
                    ${colorFill(focus.color)};
                    box-shadow: ${focus.boxShadow};
                    border-radius: 8px;
                }
            }
            &:active {
                > div {
                    ${pressedCss}
                }
            }
            > div {
                ${ifProp('pressed', pressedCss)}
            }
            &:focus:active {
                > div {
                    background: ${activeFocus.bg};
                    box-shadow: ${activeFocus.boxShadow};
                    border-radius: 8px;
                }
            }
            &:disabled {
                pointer-events: none;
                cursor: default;
                > div {
                    background: ${disabled.bg};
                    ${colorFill(disabled.color)};
                }
            }
        `;
    }}
`;

export const ButtonIconWrapper = styled.button<{
    size: Size,
    kind: ButtonIconKinds,
    pressed: boolean,
    disabled: boolean,
    text: IconedTextProps['text'],
    icon: IconedTextProps['icon'],
}>`
    ${buttonResetStyle};
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    ${buttonIconKindStyle}
    ${buildSizes}
    > div {
        display: flex;
        flex-grow: 1;
        outline: none;
    }
`;

export const StyledIconedText = styled(IconedText)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;
