import React from 'react';

import { useDictionary } from 'hooks/useDictionary';
import isElementActive from '../../services/isElementActive';

import { MENU_ITEMS } from './constants';
import * as Styled from './MainMenu.styles';

interface Props {
    className?: string;
}

export function MainMenu({ className }: Props) {
    const dic = useDictionary();
    const currentUrl = window?.location?.pathname || '/';

    return (
        <Styled.List className={className}>
            {MENU_ITEMS.map((menuItem) => {
                const isActiveMenuItem = isElementActive(currentUrl, menuItem.href);
                const listItemClassNames = ['MainMenuListItem', isActiveMenuItem && 'active'].filter((i) => i).join(' ');

                return (
                    <Styled.ListItem
                        key={`${menuItem.href}${menuItem.dicWord}`}
                        className={listItemClassNames}
                    >
                        <Styled.Link
                            href={menuItem.href}
                            isActive={isActiveMenuItem}
                            className={menuItem.className}
                        >
                            {dic.word(menuItem.dicWord)}
                        </Styled.Link>
                    </Styled.ListItem>
                );
            })}
        </Styled.List>
    );
}
