import store from '../store';

export const getLang = () => new Promise((resolve) => {
    const resolveLang = () => {
        const { lcid } = store.getState();
        if (lcid) {
            resolve(lcid);
            return true;
        }
        return false;
    };

    if (!resolveLang()) {
        const unsubscribe = store.subscribe(() => {
            if (resolveLang()) {
                unsubscribe();
            }
        });
    }
});
