import React, { useCallback, useState } from 'react';

import { GeneratorType, Id } from 'types';
import Dic from 'services/dictionary';
import { useFeedContext } from 'hooks/useFeedContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { ActionSubscribe, ActionSubscribeRenderProps } from 'widgets/action-subscribe';
import { PopupWindow2 } from 'components/wt-popup2/popup-window';
import { Title } from 'components/wt-popup2/title';

import { Button } from 'components/button2';
import * as Styled from './styled/PlugBlock';

export type PlugBlockTypeProps = {
    className?: string;
    subscribed?: boolean;
    authorId: Id,
};

export const PlugBlock = (props: PlugBlockTypeProps) => {
    const {
        className,
        authorId,
        subscribed = false,
    } = props;

    const [{ remountFeed }] = useFeedContext();

    const [isRenderPopupWindow, setIsRenderPopupWindow] = useState(false);
    const closePopup = () => {
        remountFeed();
        setIsRenderPopupWindow(false);
    };

    const currentUser = useCurrentUser();

    const subscribeRender = useCallback((subscribeProps: ActionSubscribeRenderProps) => {
        const { onClick: onClickSubscribeBtn, isSubscribed, className: classNameSubscribeBtn } = subscribeProps;

        const getButtonSubscribeClassName = () => [
            classNameSubscribeBtn,
            'autotest__profile-page__feed-premium__item-not-graduate__plug-block__subscribe-button',
            `autotest__profile-page__feed-premium__item-not-graduate__plug-block__subscribe-button-${isSubscribed ? '' : 'not-'}subscribed`,
        ].filter((c) => c).join(' ');

        return (
            <Styled.Button
                className={getButtonSubscribeClassName()}
                size={{
                    default: 'medium',
                    lt768: 'tiny',
                }}
                kind="secondary"
                onClick={() => {
                    if (currentUser.id) {
                        setIsRenderPopupWindow(true);
                    }
                    onClickSubscribeBtn();
                }}
            >
                {Dic.word('wt_feed__widget__premium_not_granted__subscribe_text_btn')}
            </Styled.Button>
        );
    }, [currentUser]);

    return (
        <>
            <Styled.Card
                className={
                    [
                        className,
                        'autotest__profile-page__feed-premium__item-not-graduate__plug-block',
                    ].filter((c) => c).join(' ')
                }
            >
                <Styled.Wrapper>
                    <Styled.Content>
                        <div>
                            <Styled.LockIcon />
                        </div>
                        <Styled.Text>
                            {Dic.word('wt_feed__widget__premium_not_granted__plug_text')}
                        </Styled.Text>
                        <Styled.SecondaryText>
                            {Dic.word('wt_feed__widget__premium_not_granted__plug_secondary_text')}
                        </Styled.SecondaryText>
                        <ActionSubscribe
                            className=""
                            data={{ generatorId: authorId, generatorType: GeneratorType.PREMIUM_POST }}
                            isSubscribed={subscribed}
                            customRender={subscribeRender}
                        />
                    </Styled.Content>
                </Styled.Wrapper>
            </Styled.Card>
            {isRenderPopupWindow && (
                <PopupWindow2
                    bgCloseable
                    close={closePopup}
                >
                    <Title>{Dic.word('wt_feed__widget__premium_not_granted__popup_title')}</Title>
                    <Styled.PopupContent>{Dic.word('wt_feed__widget__premium_not_granted__free_period')}</Styled.PopupContent>
                    <Button kind="primary" onClick={closePopup}>{Dic.word('wt_feed__widget__premium_not_granted__goto_feed_btn')}</Button>
                </PopupWindow2>
            )}
        </>
    );
};
