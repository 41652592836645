import { connect } from 'react-redux';

import { Like as LikeComponent } from '../_shared/_sub/like';
import { sendCommentRatingReaction } from './actions';

const mapDispatchToProps = {
    onRatingReaction: sendCommentRatingReaction,
};

export const LikeComment = connect(
    null,
    mapDispatchToProps,
)(LikeComponent);
