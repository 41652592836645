import React, { useMemo } from 'react';

import { ContextActionCopyLink } from 'widgets/context-action-copy-link';
import { ButtonIconKinds, ContextActions } from 'components/context-actions';
import ContextActionEdit from './ContextActionEdit';
import ContextActionDelete from './ContextActionDelete';

type Props = {
    url: string,
    canEdit: boolean,
    canDelete: boolean,
    onDelete: () => void,
}

export const ContextActionsRecord = (props: Props) => {
    const { url, canEdit, canDelete, onDelete } = props;
    const tippyContent = useMemo(() => {
        return (
            <div>
                <ContextActionCopyLink url={url} />
                {canEdit && <ContextActionEdit />}
                {canDelete && <ContextActionDelete onClick={onDelete} />}
            </div>
        );
    }, [canEdit, canDelete, onDelete]);

    return (
        <ContextActions tippyContent={tippyContent} kind={ButtonIconKinds.GhostSecondary} />
    );
};
