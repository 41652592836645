import React from 'react';

import {
    FeedRecordGenerator,
    FeedRecordObj,
    FeedRecordOptions,
} from 'types/feed-record';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { ActionSubscribe } from 'widgets/action-subscribe';
import { UserBlock } from 'components/user-block';
import { ButtonIconKinds } from 'components/button-icon';
import { useAllowedActions } from '../hooks/useAllowedActions';
import { useSubscribe } from '../hooks/useSubscribe';
import { useTimeAgo } from '../hooks/useTimeAgo';
import { useIsOwner } from '../../feed/hooks/useIsOwner';
import { LockBlock } from './LockBlock';
import { ContextActionsRecord } from './ContextActions';

import * as Styled from './styled';

const avatarProps = { responsiveSize: { common: 'M', lt960: 'S' } };

interface Props {
    obj: FeedRecordObj,
    isPremium: boolean,
    generator: FeedRecordGenerator,
    options: FeedRecordOptions,
    url: string,
    onDelete: () => void,
}
export const Author = (props: Props) => {
    const {
        obj: { author, date },
        url,
        isPremium,
        generator,
        options: { visLevel },
        onDelete,
    } = props;

    const { isFollowable } = generator;

    const { subscribed } = generator?.data || {};
    const { showSubscribe } = useSubscribe(subscribed);
    const { handleClickTimeAgo, timeAgo, localeTimeString } = useTimeAgo(date);
    const isOwner = useIsOwner(author);
    const { isAllowedActions } = useAllowedActions(date, author);

    const currentUser = useCurrentUser();

    return (
        <Styled.Wrapper>
            <UserBlock
                user={author}
                avatar={avatarProps}
                secondLine={(
                    <>
                        <Styled.TimeAgoLink
                            href={url}
                            target="_blank"
                            onClick={handleClickTimeAgo}
                            title={localeTimeString}
                        >
                            <Styled.TimeAgo>
                                {timeAgo}
                            </Styled.TimeAgo>
                        </Styled.TimeAgoLink>
                        <LockBlock
                            isPremium={isPremium}
                            visLevel={visLevel}
                        />
                    </>
                )}
            />
            <Styled.Actions>
                {currentUser.isSame(generator.id) && isFollowable && showSubscribe && (
                    <ActionSubscribe
                        data={{ generatorId: generator.id, generatorType: generator.type }}
                        isSubscribed={subscribed}
                        clickable={!subscribed}
                        buttonProps={{ kind: ButtonIconKinds.GhostSecondary }}
                    />
                )}
                <ContextActionsRecord
                    url={url}
                    canEdit={isOwner && isAllowedActions}
                    canDelete={isOwner}
                    onDelete={onDelete}
                />
            </Styled.Actions>
        </Styled.Wrapper>
    );
};
