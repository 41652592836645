import {
    FeedRecordComment,
    FeedRecordObj,
    GlobalStateForWidget,
    Id,
    Person,
} from 'types';
import { DefaultProductItem } from 'types/product';
import { FetchNotificationsPayloadData } from './actions';

export type NotificationsState = Omit<FetchNotificationsPayloadData, 'ok'> & {
    isLoading: boolean;
    isError: boolean;
};

type WidgetState = {
    notifications: NotificationsState,
};

export type GlobalStateHeaderNotifications = GlobalStateForWidget<WidgetState>;

export enum NotificationType {
    POST_LIKE = 'blogpost_like',
    POST_COMMENT = 'blogpost_comment',
    POST_UNPUBLISH_FOUL = 'blogpost_delete_foul',

    // --- ANSWER_LIKE = 'ANSWER_LIKE', ---

    COMMENT_LIKE = 'comment_like',
    COMMENT_ANSWERED = 'comment_answer',
    COMMENT_DELETE_FOUL = 'comment_delete_foul',

    MENTION_PUBLISH = 'mention_in_post',
    MENTION_COMMENT = 'mention_in_comment',
    // --- MENTION_ANSWER = 'MENTION_ANSWER', ---

    PRODUCT_ADDED = 'product_add',
    PRODUCT_MODERATE_FALL = 'product_moderate_fall',
    PRODUCT_UNPUBLISH_FOUL = 'product_delete_foul',

    PRODUCT_COMMENT = 'product_comment',

    SUBSCRIBE_TO_YOU = 'user_subscription',
    SOFT_BAN = 'user_banned',
    PRIVACY_POLICY_CHANGES = 'privacy_policy_changes',
    WHATS_NEW = 'whats_new',
    MONETIZATION_START = 'monetization_start',
    ACCESS_MONETIZATION_GRANTED = 'access_monetization_granted',
}

export type NotificationGroupItemDataType = Array<NotificationItemDataType> | [];

export type NotificationItemResponseType = {
    id?: Id;
    uniqueKey?: string;
    type?: NotificationType;
    timestamp?: string;
    readDate?: null | string;
    user?: Person;
    // TODO типы должны резолвиться в соответствии со значением свойства type
    object?: FeedRecordObj | FeedRecordComment | DefaultProductItem | null
    action?: FeedRecordComment | null; // надо будет добавлять как будет готов бэк
};

export type NotificationItemDataType = NotificationItemResponseType;
