import React from 'react';

import { IconSvg, IconComponent } from './index';

export const CopyColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z" fill="#A7BDF1" fillOpacity="0.16" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8 4.13336C10.0637 4.13336 9.4667 4.73032 9.4667 5.4667V6.00003H10.5334V5.4667C10.5334 5.31942 10.6528 5.20003 10.8 5.20003L13.1334 5.20003V8.00006C13.1334 8.6628 13.6706 9.20006 14.3334 9.20006H17.1334V14.3334C17.1334 14.4806 17.014 14.6 16.8667 14.6H15.6667V15.6667H16.8667C17.6031 15.6667 18.2 15.0697 18.2 14.3334V8.88761C18.2 8.60471 18.0877 8.3334 17.8876 8.13336L14.2 4.44578C14 4.24574 13.7287 4.13336 13.4458 4.13336H10.8ZM16.3792 8.13339L14.2 5.95428V8.00006C14.2 8.0737 14.2597 8.13339 14.3334 8.13339H16.3792Z" fill="#7486AA" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.77912 7.13336L7.13336 7.13337C6.39699 7.13337 5.80003 7.73032 5.80003 8.4667V16.3334C5.80003 17.0697 6.39699 17.6667 7.13337 17.6667H13.2C13.9364 17.6667 14.5334 17.0697 14.5334 16.3334V11.8876C14.5334 11.6047 14.421 11.3334 14.2209 11.1334L10.5334 7.44578C10.3333 7.24574 10.062 7.13336 9.77912 7.13336ZM7.13337 8.20004L9.4667 8.20003V11.0001C9.4667 11.6628 10.004 12.2001 10.6667 12.2001H13.4667V16.3334C13.4667 16.4806 13.3473 16.6 13.2 16.6H7.13337C6.98609 16.6 6.8667 16.4806 6.8667 16.3334V8.4667C6.8667 8.31943 6.98609 8.20004 7.13337 8.20004ZM10.5334 11.0001V8.95428L12.7125 11.1334H10.6667C10.5931 11.1334 10.5334 11.0737 10.5334 11.0001Z" fill="#7486AA" />
    </IconSvg>
);
