import styled from 'styled-components';

import { ButtonBadgePropsType } from 'widgets/header-notifications/components/ButtonBadge';

import { Button as ButtonComponent } from 'components/button2';

export const Button = styled(ButtonComponent)<{isActive: boolean}>`
    ${({ isActive, theme }) => isActive && `
        background-color: ${(isActive ? theme.fillIn.secondary.muted : 'inherit')};

        &:hover, &:focus {
            box-shadow: none;
            color: ${theme.label.primary};
        }
    `}
`;

export const Wrapper = styled.div`
    position: relative;
`;

const minWidthHeight = 0;
const maxWidthHeight = 15;
const borderSize = 3;
const transitionDuration = 360;

export const BadgeWrapper = styled.div`
    position: absolute;
    width: ${maxWidthHeight}px;
    height: ${maxWidthHeight}px;
    border-radius: 50%;
    top: 4px;
    right: 4px;
    pointer-events: none;
`;

export const Badge = styled.div<Required<ButtonBadgePropsType>>`
    display: block;
    box-sizing: border-box;
    ${({ isShowBadge }) => {
        const widthHeight = (isShowBadge ? maxWidthHeight : minWidthHeight);
        const marginTopLeft = (isShowBadge ? 0 : ((maxWidthHeight - (borderSize * 2)) / 2));
        return `
            width: ${widthHeight}px;
            height: ${widthHeight}px;
            margin-top: ${marginTopLeft}px;
            margin-left: ${marginTopLeft}px;
        `;
    }};
    background-color: ${({ theme, isShowBadge }) => (isShowBadge ? theme.fillIn.primary.mid : 'transparent')};
    border: ${borderSize}px solid ${({ theme, isActive }) => (isActive ? theme.fillIn.secondary.muted : theme.bg.secondary)};
    border-radius: 50%;
    transition: margin-left ${transitionDuration}ms,
                margin-top ${transitionDuration}ms,
                width ${transitionDuration}ms,
                height ${transitionDuration}ms,
                top ${transitionDuration}ms,
                right ${transitionDuration}ms,
                background-color ${transitionDuration}ms;
`;
