import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const FireIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path d="M5.55625 6.36406C5.46718 7.31406 5.40468 8.99531 5.96562 9.71094C5.96562 9.71094 5.70156 7.86406 8.06875 5.54687C9.02187 4.61406 9.24218 3.34531 8.90937 2.39375C8.72031 1.85469 8.375 1.40937 8.075 1.09844C7.9 0.915625 8.03437 0.614062 8.28906 0.625C9.82968 0.69375 12.3266 1.12187 13.3875 3.78437C13.8531 4.95312 13.8875 6.16094 13.6656 7.38906C13.525 8.17344 13.025 9.91719 14.1656 10.1313C14.9797 10.2844 15.3734 9.6375 15.55 9.17188C15.6234 8.97813 15.8781 8.92969 16.0156 9.08438C17.3906 10.6484 17.5078 12.4906 17.2234 14.0766C16.6734 17.1422 13.5687 19.3734 10.4844 19.3734C6.63125 19.3734 3.56406 17.1688 2.76875 13.1781C2.44843 11.5672 2.61093 8.37969 5.09531 6.12969C5.27968 5.96094 5.58125 6.11094 5.55625 6.36406Z" fill="url(#paint0_radial_125_13643)" />
        <path d="M11.8923 12.0969C10.472 10.2688 11.1079 8.18281 11.4564 7.35156C11.5032 7.24219 11.3782 7.13906 11.2798 7.20625C10.6689 7.62188 9.4173 8.6 8.83448 9.97656C8.04542 11.8375 8.10167 12.7484 8.56886 13.8609C8.85011 14.5313 8.52355 14.6734 8.35948 14.6984C8.20011 14.7234 8.05323 14.6172 7.93605 14.5063C7.60011 14.1859 7.35792 13.7688 7.24229 13.3188C7.21729 13.2219 7.09073 13.1953 7.03292 13.275C6.59542 13.8797 6.36886 14.85 6.35792 15.5359C6.32354 17.6563 8.07511 19.375 10.1939 19.375C12.8642 19.375 14.8095 16.4219 13.2751 13.9531C12.8298 13.2344 12.411 12.7641 11.8923 12.0969Z" fill="url(#paint1_radial_125_13643)" />
        <defs>
            <radialGradient id="paint0_radial_125_13643" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.72126 19.422) rotate(-179.751) scale(11.0293 18.0969)">
                <stop offset="0.3144" stopColor="#FF9800" />
                <stop offset="0.6616" stopColor="#FF6D00" />
                <stop offset="0.9715" stopColor="#F44336" />
            </radialGradient>
            <radialGradient id="paint1_radial_125_13643" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.3407 8.4467) rotate(90.5787) scale(11.54 8.68475)">
                <stop offset="0.2141" stopColor="#FFF176" />
                <stop offset="0.3275" stopColor="#FFF27D" />
                <stop offset="0.4868" stopColor="#FFF48F" />
                <stop offset="0.6722" stopColor="#FFF7AD" />
                <stop offset="0.7931" stopColor="#FFF9C4" />
                <stop offset="0.8221" stopColor="#FFF8BD" stopOpacity="0.804" />
                <stop offset="0.8627" stopColor="#FFF6AB" stopOpacity="0.529" />
                <stop offset="0.9101" stopColor="#FFF38D" stopOpacity="0.2088" />
                <stop offset="0.9409" stopColor="#FFF176" stopOpacity="0" />
            </radialGradient>
        </defs>
    </EmojiIconSvg>
);
