import React, { useCallback } from 'react';

import { GeneratorType, Person } from 'types';
import { correctedHumanString } from 'services/utils/corrected-human-string';
import Dic from 'services/dictionary';
import { ActionSubscribe, ActionSubscribeRenderProps } from 'widgets/action-subscribe';
import { Avatar } from 'components/avatar2';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import {
    Wrapper, Name, Subscribers, ButtonWrapper,
} from './styled/User';

type Props = {
    user: Person
};

export const User = (props: Props) => {
    const {
        user: {
            id, displayName, images, counters, url, isVerified, data,
        },
    } = props;
    const { subscribed = false } = data || {};
    const subscribersText = correctedHumanString(
        'wt_profile__widget_header_user__followers',
        [counters?.followers || 0],
    );
    const subscribeRender = useCallback((subscribeProps: ActionSubscribeRenderProps) => {
        const { onClick, isSubscribed, className } = subscribeProps;
        const getButtonSubscribeClassName = () => [
            className,
            'autotest__profile-page__header-user-subscribe-button',
            `autotest__profile-page__header-user-subscribe-button-${isSubscribed ? '' : 'not-'}subscribed`,
        ].filter((c) => c).join(' ');

        return (
            <ButtonIcon
                className={getButtonSubscribeClassName()}
                onClick={onClick}
                size="M"
                kind={ButtonIconKinds.FillPrimary}
                pressed={!isSubscribed}
                text={Dic.word(
                    `wt_feed__item_recommendations__subscribe${
                        isSubscribed ? 'd' : ''
                    }_btn`,
                )}
            />
        );
    }, []);
    return (
        <Wrapper>
            <Avatar responsiveSize={{ common: '41px' }} src={images?.medium} />
            <Name url={url} displayName={displayName} isVerified={isVerified} />
            <Subscribers>{subscribersText}</Subscribers>
            <ButtonWrapper>
                <ActionSubscribe
                    data={{ generatorId: id, generatorType: GeneratorType.USER }}
                    isSubscribed={subscribed}
                    customRender={subscribeRender}
                />
            </ButtonWrapper>
        </Wrapper>
    );
};
