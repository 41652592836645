import React, { ReactNode, useRef } from 'react';
import * as Sentry from '@sentry/react';

import notify from 'services/notify';
import Dic from 'services/dictionary';

type Props = {
    children?: ReactNode;
}

export const ErrorBoundary = (props: Props) => {
    const { children } = props;
    const lastErrorTime = useRef<number | null>(null);
    const countErrors = useRef(0);
    return (
        <Sentry.ErrorBoundary
            fallback={({ resetError }) => {
                if (!lastErrorTime.current || ((lastErrorTime.current - new Date().getTime()) / 1000 > 2)) {
                    countErrors.current = 0;
                }
                if (countErrors.current < 5) {
                    resetError();
                    notify.error(Dic.word('wt_all__notification__common_error'));
                }
                lastErrorTime.current = new Date().getTime();
                countErrors.current++;
                return <></>;
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
