export function syncUser(token: string | null = null) {
    return new Promise<void>((resolve) => {
        const img = document.createElement('img');
        img.onload = () => { resolve(); };
        img.onerror = () => { resolve(); };
        img.src = `https://${window.location.host}/ng/api/v1/internal/sync/`;
        if (token) {
            img.src += `?token=${encodeURIComponent(token)}`;
        }
        img.style.cssText = 'display:none;';
        document.body.appendChild(img);
    });
}

export function syncUserLogout() {
    const img = document.createElement('img');
    img.src = `https://${window.location.host}/ng/api/v1/internal/logout/`;
    img.style.cssText = 'display:none;';
    document.body.appendChild(img);
}
