import styled, { css } from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import IconedText from 'components/iconed-text2';
import { MenuItemKind, Props, StateMap } from './types';

export const StyledIconedText = styled(IconedText)`
    display: flex;
    align-items: center;
    ${body14RegularFontStyle};
    color: ${({ theme: { label } }) => label.primary};
`;

const color = (colorText: string, colorIcon: string) => `
    ${StyledIconedText} {
        color: ${colorText};
        svg {
            fill: ${colorIcon};
            color: ${colorIcon};
        }
    }
`;

const colorMap = ({ fillIn, label }: Theme, kind: MenuItemKind): StateMap => {
    const kindMap: Record<MenuItemKind, StateMap> = {
        primary: {
            hover: `background: ${fillIn.primary.overlay16}`,
            isSelected: `
                background: ${fillIn.positive.overlay24};
                ${color(label.accent, label.accent)};
            `,
        },
        secondary: {
            hover: `background: ${fillIn.secondary.overlay16}`,
            isSelected: `
                background: ${fillIn.secondary.overlay24};
                ${color(label.primary, label.primary)};
            `,
        },
    };
    return kindMap[kind];
};

const colors = ({
    theme, isSelected, kind,
}: { theme: Theme } & WrapperProps) => {
    const kindMap = colorMap(theme, kind);
    const hover = css`
        &:hover {
            ${kindMap.hover}
        }
    `;
    if (isSelected) {
        return css`
            ${hover}
            ${kindMap.isSelected}
        `;
    }
    return css`
        ${hover}
    `;
};

type WrapperProps = Required<Pick<Props, 'disabled' | 'isSelected' | 'kind'>>;
export const MenuItemWrapper = styled.span<WrapperProps>`
    height: 40px;
    padding: 0 16px 0 12px;
    cursor: pointer;
    display: flex;
    pointer-events: all;
    ${body14RegularFontStyle};
    ${({ theme: { label }, disabled }) => `
        ${color(label.primary, label.secondary)};
        ${disabled ? `
            pointer-events: none;
            ${color(label.inactive, label.inactive)}
        ` : ''};
    `};
    ${colors}
`;
