import React, { ReactNode, ReactElement } from 'react';

export type IconProps = {
    size?: number;
    width?: number;
    height?: number;
    fill?: string;
};

export type IconComponent = (props: IconProps) => ReactElement;

type IconSvgProps = IconProps & {
    children: ReactNode;
    viewBox?: string;
    className?: string;
};

/**
 * Base component for all SVG icons.
 *
 * NB! Don't use it directly - only inside specific icon components.
 *
 * @example
 * import { IconSvg, IconComponent } from '.';
 *
 * export const SpecificIcon: IconComponent = (props) => (
 *   <IconSvg {...props}>
 *     <path d="..." />
 *   </IconSvg>
 * );
 */
export const IconSvg = ({
    children,
    viewBox = '0 0 24 24',
    size = 24,
    height,
    width,
    className,
    fill,
}: IconSvgProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || size}
            height={height || size}
            viewBox={viewBox}
            className={className}
            fill={fill || 'currentColor'}
        >
            {children}
        </svg>
    );
};

export const AnyIcon = (props: { component: IconComponent } & IconProps) => {
    return <props.component {...props} />;
};

export const EmojiIconSvg = (props: IconSvgProps) => {
    const {
        className: classNameProp,
        children,
    } = props;
    const className = ['emoji-svg', classNameProp].filter((cn) => cn).join(' ');

    return (
        <IconSvg
            fill="none"
            {...props}
            className={className}
        >
            { children }
        </IconSvg>
    );
};
