import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { backendClient } from 'services/backend-client';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const REQUEST_ACTIONS_SUFFIXES = [REQUEST, SUCCESS, FAILURE];

/**
 * Creates action types list
 * @param {string} name
 * @param {string[]} suffixes
 * @returns {string[]}
 */
function createActionTypes(name, suffixes) {
    return suffixes.map((suffix) => `widgets/${name}_${suffix}`);
}

export function createRequestActionTypes(name) {
    return createActionTypes(name, REQUEST_ACTIONS_SUFFIXES);
}

export default createActionTypes;

export const Cancelation = () => {
    return {
        source: axios.CancelToken.source(),
        outdated: false,
    };
};

export const thunkActions = (rawAction, useDispatch = true) => {
    return (dispatch) => {
        const { request, pass = {}, cancelation } = rawAction.payload;
        const previousAction = {
            type: rawAction.types[0],
            ...rawAction,
            payload: {
                ...(rawAction.payload || {}),
                pass,
            },
        };
        if (useDispatch) dispatch(previousAction);

        const requestPromise = backendClient({
            ...request,
            ...(cancelation ? { cancelToken: cancelation.source.token } : {}),
        });

        return requestPromise.then((payload) => {
            const action = {
                type: rawAction.types[1],
                payload: {
                    ...payload,
                    pass,
                },
                meta: { previousAction },
            };

            return useDispatch ? dispatch(action) : action;
        }).catch((error) => {
            Sentry.captureException(error);
            const action = {
                type: rawAction.types[2],
                error: error.toString(),
                pureError: error,
                payload: {
                    data: { },
                    ...(error.response || {}),
                    pass,
                },
                meta: { previousAction },
            };
            return (useDispatch && !axios.isCancel(error)) ? Promise.reject(dispatch(action)) : Promise.reject(action);
        }).finally(() => {
            if (cancelation) cancelation.outdated = true;
        });
    };
};
