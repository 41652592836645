import {ActionRequest, ActionResult, FeedRecordComment, FeedRecordObjTypes} from 'types';
import { mergeByPath } from 'services/utils/merge';
import produce from 'immer';

import { FeedState } from '../types';
import { index, getCommentsAndPath } from '../helpers';

import {
    FETCH_COMMENTS_ACTION_TYPES, FetchCommentsPayloadData, FetchCommentsPayloadPass,
    SAVE_COMMENT_ACTION_TYPES, SaveCommentPayloadData, SaveCommentPayloadPass, SaveCommentRequestData,
    DELETE_COMMENT_ACTION_TYPES, RESTORE_COMMENT_ACTION_TYPES, DeleteCommentPayloadPass, RestoreCommentPayloadPass,
    PUBLISHED_COMMENT_UNMARK, commentsFilters, CHANGE_COMMENTS_FILTER, ChangeCommentsFilterData
} from './actions';

const [, FETCH_COMMENTS_SUCCESS] = FETCH_COMMENTS_ACTION_TYPES;
const [, SAVE_COMMENT_SUCCESS] = SAVE_COMMENT_ACTION_TYPES;
const [, DELETE_COMMENT_SUCCESS] = DELETE_COMMENT_ACTION_TYPES;
const [, RESTORE_COMMENT_SUCCESS] = RESTORE_COMMENT_ACTION_TYPES;

type AvailActionsData = FetchCommentsPayloadData | SaveCommentPayloadData | ChangeCommentsFilterData;
type AvailActionsPass = FetchCommentsPayloadPass | SaveCommentPayloadPass;
type AvailActionsRequestData = SaveCommentRequestData;
export default (
    state: FeedState,
    action: ActionRequest<AvailActionsRequestData> | ActionResult<AvailActionsData, AvailActionsPass, AvailActionsRequestData>,
) => {
    switch (action.type) {
        case CHANGE_COMMENTS_FILTER: {
            const { payload: { data: { recordId } } } = action as ActionResult<ChangeCommentsFilterData>;
            const record = state.records[index(recordId)];
            const { path } = getCommentsAndPath(record);

            return produce(state, (draft) => {
                if (path === 'obj.comments' && draft.records[index(recordId)].obj) {
                    // @ts-ignore
                    draft.records[index(recordId)].obj.comments = [];
                } else {
                    draft.records[index(recordId)].comments = [];
                }
            });
        }
        case FETCH_COMMENTS_SUCCESS: {
            const {
                payload: { pass: { recordId }, data: { comments } },
            } = action as ActionResult<FetchCommentsPayloadData, FetchCommentsPayloadPass>;
            const record = state.records[index(recordId)];
            const { comments: oldComments, path } = getCommentsAndPath(record);
            const newComments = comments.filter(comment => !oldComments.find((oldComment: FeedRecordComment) => oldComment.id === comment.id))

            return produce(state, (draft) => {
                if (path === 'obj.comments') {
                    draft.records[index(recordId)].obj?.comments.push(...newComments);
                } else {
                    draft.records[index(recordId)].comments?.push(...newComments);
                }
            });
        }
        case SAVE_COMMENT_SUCCESS: {
            const {
                payload: { pass: { recordId, commentsFilter }, data: { comment } }, meta: { previousAction },
            } = action as ActionResult<SaveCommentPayloadData, SaveCommentPayloadPass, SaveCommentRequestData>;
            if (!comment) return state;
            const saveType = previousAction.payload.request.data?.comment?.action;
            if (saveType === 'add' || saveType === 'edit') {
                const record = state.records[index(recordId)];
                const { comments, path } = getCommentsAndPath(record);
                const isPost = record.obj?.type === FeedRecordObjTypes.POST;
                const commentsCount = isPost ? record?.obj?.commentsCount : record.stats?.commentsCount;
                let newComments = [...comments];

                if (saveType === 'add') {
                    if (comment.parentId === comment.rootId) {
                        if (commentsFilter === commentsFilters.recent || commentsFilter === commentsFilters.rated) {
                            newComments = [comment, ...newComments];
                        } else {
                            newComments = [...newComments, comment];
                        }
                    } else {
                        // на уровне прототипа вставляем ответ сразу за родительским комментарием пока не
                        // появится больше инфорамации об уровнях вложенности и сортировках
                        // как вариант: можно всегда добавлять ответ в конец, а потом сортировать по данным из pass
                        const insertIndex = newComments.findIndex((_comment) => +_comment.id === +comment.parentId) + 1;
                        newComments.splice(insertIndex, 0, comment);
                    }
                } else {
                    const commentIndex = comments.findIndex((_comment) => _comment.id === comment.id);
                    newComments[commentIndex] = comment;
                }
                const withComments = mergeByPath(state, `records.${index(recordId)}.${path}`, newComments);
                const withCommentsAndFlag = mergeByPath(
                    withComments,
                    `records.${index(recordId)}.obj.isUserCommented`,
                    true,
                );
                return saveType === 'add'
                    ? mergeByPath(
                        mergeByPath(
                            withCommentsAndFlag,
                            `records.${index(recordId)}.${isPost ? 'obj.commentsCount' : 'stats.commentsCount'}`,
                            (commentsCount ?? 0) + 1,
                        ),
                        `records.${index(recordId)}.publishedId`,
                        comment.id,
                    )
                    : withComments;
            }
            return state;
        }
        case DELETE_COMMENT_SUCCESS: {
            const {
                payload: { pass: { recordId, commentId } },
            } = action as ActionResult<never, DeleteCommentPayloadPass, never>;

            const record = state.records[index(recordId)];
            const { comments, path } = getCommentsAndPath(record);

            const newComments = comments.map((x) => (x.id === commentId ? { ...x, isLocalDeleted: true } : x));

            return mergeByPath(state, `records.${index(recordId)}.${path}`, newComments);
        }
        case RESTORE_COMMENT_SUCCESS: {
            const {
                payload: { pass: { recordId, commentId } },
            } = action as ActionResult<never, RestoreCommentPayloadPass, never>;

            const record = state.records[index(recordId)];
            const { comments, path } = getCommentsAndPath(record);

            const newComments = comments.map((x) => (x.id === commentId ? { ...x, isLocalDeleted: false } : x));

            return mergeByPath(state, `records.${index(recordId)}.${path}`, newComments);
        }
        case PUBLISHED_COMMENT_UNMARK: {
            const {
                payload,
            } = action as any;

            return mergeByPath(state, `records.${index(payload.recordId)}.publishedId`, null);
        }
        default:
            return state ?? { records: {} };
    }
};
