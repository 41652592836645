import { useEffect, useLayoutEffect, useRef } from 'react';

import { Id } from 'types';
import { useShowFull } from '../hooks/useShowFull';

export const useShowFullChange = (callback: (recordId: Id) => void) => {
    const { recordId, showFull } = useShowFull();
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        return callbackRef.current(recordId);
    }, [showFull]);
};
