import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Icon, IconProps } from './Icon';

const PADDING = 4;
export const ButtonStyled = styled.span<{ size: number; }>`
    position: relative;
    display: block;
    cursor: pointer;
    padding: ${`${PADDING}px`};
    width: ${({ size }) => `${size + PADDING * 2}px`};
    height: ${({ size }) => `${size + PADDING * 2}px`};
    text-align: center;
`;

export const Badge = styled.span`
    position: absolute;
    height: 10px;
    width: 10px;
    background: #387CFF;
    border-radius: 50%;
    top: 1px;
    right: 1px;
    border: 2px solid #FFF;
    z-index: 10;
`;

interface IconButtonProps extends IconProps{
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    showBadge?: boolean;
}

export const IconButton = forwardRef<HTMLSpanElement, IconButtonProps>((props, ref) => {
    const {
        onClick,
        name,
        size = 16,
        color = 'black',
        showBadge = false,
        ...iconProps
    } = props;

    const clickHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <ButtonStyled
            onClick={clickHandler}
            size={size}
            ref={ref}
            {...iconProps /* eslint-disable-line react/jsx-props-no-spreading */}
        >
            {showBadge && <Badge />}
            <Icon name={name} size={size} color={color} />
        </ButtonStyled>
    );
});
