import React, { useState, useEffect, useCallback } from 'react';
import { Instance } from 'tippy.js';
import { TippyProps } from '@tippy.js/react';

import { Tooltip } from 'components/tooltip';
import { ButtonIcon, ButtonIconKinds, ButtonIconProps } from 'components/button-icon';
import { EllipsisVIcon } from 'components/icons2/EllipsisVIcon';
import { Wrapper, ButtonIconWrapper, ContentWrapper } from './styled';

export { MenuItem } from 'components/menu-item';
export { ButtonIconKinds };

type Props = Pick<ButtonIconProps, 'kind' | 'size'> &
    Pick<TippyProps, 'placement' | 'interactive' | 'appendTo'> & {
    tippyContent: TippyProps['content'],
    className?: string,
    hideOnScroll?: boolean,
    buttonClassName?: string,
    buttonContent?: React.ReactChild,
    onVisibleChange?: (partialState: { isVisible: boolean, inTransition: boolean }) => void,
}
export const ContextActions = (props: Props) => {
    const {
        tippyContent,
        className,
        kind,
        size,
        buttonClassName,
        hideOnScroll = true,
        buttonContent,
        placement,
        // всегда скрывать при клике если явно не задано обратное
        // если понадобится частичный interactive, то можно добавить проп контролируемый проп isVisible
        interactive = false,
        onVisibleChange = () => {
            // nothing
        },
        appendTo = 'parent',
    } = props;

    const [instance, setInstance] = useState<Instance>();

    useEffect(() => {
        const onScroll = () => hideOnScroll && instance?.hide();

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [instance, hideOnScroll]);

    const [buttonPressed, setButtonPressed] = useState(false);

    const handleShowHide = useCallback(({ isVisible, inTransition }: { isVisible: boolean; inTransition: boolean; }) => {
        onVisibleChange({ isVisible, inTransition });
        setButtonPressed(!isVisible);
    }, [onVisibleChange]);

    return (
        <Wrapper className={className} tabIndex={-1}>
            <Tooltip
                onMount={setInstance}
                content={<ContentWrapper>{tippyContent}</ContentWrapper>}
                placement={placement}
                trigger="click"
                interactive={interactive}
                onShow={() => handleShowHide({ isVisible: false, inTransition: true })}
                onShown={() => onVisibleChange({ isVisible: true, inTransition: false })}
                onHide={() => handleShowHide({ isVisible: true, inTransition: true })}
                onHidden={() => onVisibleChange({ isVisible: false, inTransition: false })}
                appendTo={appendTo}
            >
                <ButtonIconWrapper tabIndex={-1}>
                    {buttonContent || (
                        <ButtonIcon
                            icon={{ component: EllipsisVIcon }}
                            kind={kind}
                            size={size}
                            className={buttonClassName}
                            pressed={buttonPressed}
                        />
                    )}
                </ButtonIconWrapper>
            </Tooltip>
        </Wrapper>
    );
};

ContextActions.defaultProps = {
    className: '',
    kind: ButtonIconKinds.FillSecondary,
    size: 'L',
    placement: 'bottom-end',
    onVisibleChange: () => {
        // nothing
    },
};
