import { useState } from 'react';
import EditorJS, { API, OutputData } from '@editorjs/editorjs';

import { useNonClosableRefState, useNonClosableRef } from 'hooks/useUnclosureRef';

const getDataBlock = (api: null | InstanceType<typeof EditorJS> | API, state: OutputData) => {
    if (api) {
        const indexCurrentBlock = api.blocks.getCurrentBlockIndex();

        const block = api.blocks.getBlockByIndex(indexCurrentBlock);

        const indexBlock = state?.blocks.findIndex((el) => el.id === block?.id);

        if (indexBlock >= 0) {
            return state?.blocks[indexBlock]?.data;
        }

        const prevBlock = indexCurrentBlock - 1;

        api?.caret.setToBlock(prevBlock, 'end');

        return state?.blocks[prevBlock]?.data;
    }
    return null;
};

export const cashtagRegexBase = '[\\w\\d\\-А-Яа-я]+';

export const useCashtags = (
    editorJs: null | InstanceType<typeof EditorJS>,
    updateHandler: (api: { saver: API['saver'] }) => (Promise<void> | undefined),
) => {
    const [resetSuggest, setResetSuggest] = useState(false);
    const [searchTickerRef, searchTicker, setSearchTicker] = useNonClosableRefState('');

    const editorJsRef = useNonClosableRef(editorJs);

    const onSetTicker = (ticker: string) => {
        if (editorJs) {
            editorJs.save().then((prevState) => {
                const data = getDataBlock(editorJs, prevState);

                if (data) {
                    // мутация prevState
                    data.text = data.text
                        .replace(new RegExp(`\\$${cashtagRegexBase}`, 'g'), (match: string) => match.toUpperCase())
                        .replace(`$${searchTickerRef.current.toUpperCase()}`, `$${ticker} `);
                    editorJs.render({
                        ...prevState,
                    }).then(() => {
                        updateHandler(editorJs);
                    }).then(() => {
                        editorJs.caret.setToBlock(editorJs.blocks.getCurrentBlockIndex(), 'end');
                    });
                }
            });
        }
    };

    const currentBlockIndex = () => Math.max(editorJsRef.current?.blocks.getCurrentBlockIndex() || 0, 0);

    const getCurrentBlock = () => editorJsRef.current?.blocks.getBlockByIndex(currentBlockIndex());

    const insertCashtagSymbol = async (editorNode: HTMLElement | null) => {
        const sel = window.getSelection();
        if (editorJs) {
            const prevState = await editorJs.save();
            const isInEditorNode = sel && sel.type !== 'None' && editorNode?.contains(sel.anchorNode);
            const currentIndex = isInEditorNode ? currentBlockIndex() : prevState?.blocks.length - 1;
            const currentBlock = prevState?.blocks[currentIndex];
            const data = currentBlock?.data;
            const offset = isInEditorNode ? sel.focusOffset : data.text.length;

            if (currentBlock?.id && data) {
                // мутация prevState
                data.text = `${data.text.substring(0, offset)}$${data.text.substring(offset, data.text.length)}`;
                editorJs.blocks.update(currentBlock.id, data);
                editorJs.caret.setToBlock(currentIndex, 'default', offset + 1);
            } else {
                editorJs.blocks.insert('paragraph', { text: '$' }, undefined, currentIndex, true, true);
                editorJs.caret.setToBlock(currentIndex, 'end');
            }
        }
    };

    const handleCashtagOnEditorChange = (api: API, event: CustomEvent) => {
        const sel = window.getSelection();
        const reset = () => {
            setSearchTicker('');
        };
        if (sel?.type !== 'None') {
            if (!sel?.focusOffset) return reset();
            const currentBlock = getCurrentBlock();
            if (!currentBlock) return reset();
            let inspectString = currentBlock.holder.innerText;
            let cashtagStart;
            for (let i = sel.focusOffset - 1; i >= 0; i--) {
                if (inspectString[i] === '$') {
                    cashtagStart = i;
                    break;
                }
                if (inspectString[i] === ' ') break;
            }
            if (!cashtagStart && cashtagStart !== 0) return reset();
            inspectString = inspectString.substring(cashtagStart);
            const cashtag = inspectString.match(new RegExp(`(?<=\\$)${cashtagRegexBase}`))?.[0];
            if (!cashtag) return reset();
            if (event.type === 'block-changed') {
                setSearchTicker(cashtag);
            }
        }
    };

    return {
        resetSuggest,
        searchTicker,
        onSetTicker,
        insertCashtagSymbol,
        handleCashtagOnEditorChange,
    };
};
