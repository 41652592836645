/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
export const baseUrl = process.env.BASE_URL;
export const staticUrl = process.env.STATIC_URL;
export const globalAuthStaticUrl = process.env.GLOBAL_AUTH_STATIC_URL;
export const GET_USER_API = `${baseUrl}/ng/api/v1/internal/user/data/`;
export const PROCESS_BUID_METRICS_API = `${baseUrl}/ng/api/v1/internal/user/process-buid-metrics/`;
export const BUSINESS_METRICS_NEW_VISITOR = `${baseUrl}/ng/api/v1/internal/metrics/business/new_visitor`;
export const BUSINESS_METRICS_ADD_EVENT = `${baseUrl}/ng/api/v1/internal/metrics/business/add_event`;
export const BUSINESS_METRICS_RETURN = `${baseUrl}/ng/api/v1/internal/metrics/business/return`;
export const BUSINESS_METRICS_SEARCH_CLICK = `${baseUrl}/ng/api/v1/internal/search/click/`;

export const projectsLinks = process.env.PROJECTS_LINKS;
export const projectsTemplateLinks = process.env.PROJECTS_TEMPLATE_LINKS;
export const socketUrl = `wss://${projectsLinks.whotradesLink.replace(/^(?:https?:\/\/)/img, '')}/websockets`;
export const instrumentExchangeAcronymsMap = INSTRUMENT_EXCHANGE_ACRONYMS_MAP;

export const screenSizes = {
    minimumWidth: '660px',
    extraSmall: '1000px',
    small: '1160px',
    medium: '1388px',
    // в аккаунте много длинного текста, особенно в русской версии, глобально надо менять либо дизайн, пока в виде особого кейса #WTT-7779
    wideAccountsExtraSmall: '1050px',
    wideAccountsMedium: '1450px',
};
