import React from 'react';

import { UserBlockProps } from './types';
import {
    Wrapper,
    AvatarLink,
    Details,
    StyledUserName,
    SecondLine,
    AvatarWrapper,
} from './styled';

export const UserBlock = React.memo(({
    user,
    avatar,
    secondLine,
    autotestPrefix = '',
    className = '',
    size = 'regular',
    buildUrl,
}: UserBlockProps) => {
    const url = user.url ?? (user.id !== undefined ? (buildUrl?.(user.id) || `/profile/${user.id}/`) : undefined);
    return (
        <Wrapper className={className} size={size}>
            <AvatarLink
                className={`autotest__marketplace${autotestPrefix}__avatar_link`}
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                <AvatarWrapper
                    src={user.image}
                    {...avatar}
                />
            </AvatarLink>
            <Details>
                <StyledUserName
                    url={url}
                    isVerified={user.isVerified}
                    isVerifiedAuthor={user.isVerifiedAuthor}
                    displayName={user.displayName || ''}
                    autotestClass={`autotest__marketplace_${autotestPrefix}__user_name`}
                    useDct
                />
                <SecondLine>{secondLine}</SecondLine>
            </Details>
        </Wrapper>
    );
});
