/** @deprecated use widgets/formatting instead of */
export const formatPriceCurrencyByLocale = (price, currency, dcpl = 2, lcid) => {
    const locale = (lcid === 'ru') ? 'ru' : 'en';
    if (currency && currency !== 'UNKNOWN') {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: dcpl,
            maximumFractionDigits: dcpl,
        });

        return formatter.format(price);
    }

    const formatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: dcpl,
        maximumFractionDigits: dcpl,
    });
    return formatter.format(price);
};
