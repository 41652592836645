import styled from 'styled-components';

import { mediaQuery, labelPrimary, labelSecondary } from 'app/styled';

import { body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';
import { LockIcon } from 'components/icons2/LockIcon';
import { postSidePadding } from '../../styled';

export const Wrapper = styled.div`
    display: flex;
    ${postSidePadding}
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`;

export const Name = styled.a`
    font-weight: 500;
    color: ${labelPrimary};
    text-decoration: none;
`;

export const SecondLine = styled.div`
    display: flex;
    align-items: center;
`;

export const VisibilityLevelLockIcon = styled(LockIcon)`
    fill: ${labelSecondary};

    &:hover {
        cursor: pointer;
    }
`;

export const LockBlockTooltipWrapper = styled.div`
    > div {
        display: flex;
    }
`;

export const LockBlockContentWrapper = styled.div`
    padding: 8px 0;
    border-radius: 8px;
`;

export const LockBlockContent = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 12px;
`;

export const LockBlockText = styled.div`
    margin-left: 8px;
    ${body14RegularFontStyle};
`;

export const TimeAgo = styled.div`
    ${body14RegularFontStyle}

    ${mediaQuery.lt480} {
        ${caption12RegularFontStyle}
    }
`;

export const TimeAgoLink = styled.a`
    text-decoration: none;
    margin-right: 4px;
    color: ${labelSecondary};

    &:hover {
        color: ${labelSecondary};
    }
`;

export const Actions = styled.div`
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    & > * {
        margin-left: 8px;
    }
`;

export const AvatarLink = styled.a`
    display: flex;
    align-items: center;
`;
