import React from 'react';

import { IconSvg, IconComponent } from '.';

export const VerifiedAccountIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="7.5" fill="#0AA461" stroke="white" />
        <path d="M11.6639 5.38246C11.9013 5.60015 11.9174 5.96915 11.6997 6.20663L7.68928 10.5816C7.58177 10.6989 7.43102 10.7672 7.27195 10.7707C7.11288 10.7741 6.9593 10.7124 6.8468 10.5999L5.02388 8.77702C4.79608 8.54922 4.79608 8.17987 5.02388 7.95207C5.25169 7.72426 5.62103 7.72426 5.84884 7.95207L7.24095 9.34418L10.8397 5.41829C11.0574 5.1808 11.4264 5.16476 11.6639 5.38246Z" fill="white" />
    </IconSvg>
);
