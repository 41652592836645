import { useCallback } from 'react';

import authPopup from 'services/auth-popup';

import { useCurrentUser } from './useCurrentUser';

/**
 * Wraps a function so that the login popup will be shown for the unauthenticated user,
 * before the function is invoked.
 *
 * @example
 * const buyProduct = () => { ... };
 * const buyProductAfterAuthentication = useActionAfterAuthentication(buyProduct);
 */
// TODO: perform the action after authentication success
export function TODOuseActionAfterAuthentication<
    ActionFnReturnType,
    ActionFnArgs extends unknown[]
>(actionFn: (...args: ActionFnArgs) => ActionFnReturnType): (...args: ActionFnArgs) => ActionFnReturnType | undefined {
    const currentUser = useCurrentUser();

    return useCallback(
        (...args: ActionFnArgs) => {
            if (currentUser.isAuth) {
                return actionFn(...args);
            }

            authPopup.login();

            return undefined;
        },
        [actionFn, currentUser.isAuth],
    );
}
