import React from 'react';
import PropTypes from 'prop-types';

import { CurrencySigns } from 'services/utils/currencies';
import {
    Ticker, TickerWrapperLink, Price, PriceDiff,
} from './styled/painted3-instrument';

export const Painted3Instrument = (props) => {
    const {
        changePercent, ticker, price, dcpl, percentDcpl, as,
        url, title, onClick, showPrice, className, currency,
    } = props;
    const isGrow = changePercent > 0;
    const isZero = changePercent === 0;
    return (
        <TickerWrapperLink
            as={as}
            href={url}
            target="_blank"
            title={title}
            onClick={onClick}
            className={className}
        >
            <Ticker>{ticker}</Ticker>
            {showPrice && (
                <Price>
                    {price.toFixed(dcpl)}
                    &thinsp;
                    {CurrencySigns[currency] || ''}
                </Price>
            )}
            <PriceDiff isGrow={isGrow} isZero={isZero}>
                <span>▲</span>
                {changePercent.toFixed(percentDcpl).replace('-', '')}
                %
            </PriceDiff>
        </TickerWrapperLink>
    );
};

Painted3Instrument.defaultProps = {
    onClick: () => {},
    className: '',
    currency: '',
    changePercent: null,
    price: null,
    dcpl: null,
    ticker: '',
    finamId: null,
    willClose: '',
    title: '',
    url: '',
    showPrice: true,
    percentDcpl: 2,
    as: 'a',
    tooltipEnabled: true,
};

Painted3Instrument.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    percentDcpl: PropTypes.number,
    showPrice: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    changePercent: PropTypes.number,
    ticker: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.number,
    dcpl: PropTypes.number,
    finamId: PropTypes.number,
    willClose: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    as: PropTypes.string,
    tooltipEnabled: PropTypes.bool,
};
