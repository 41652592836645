import { css } from 'styled-components';

import { boxShadowLikeBorder } from 'app/styled';
import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import {
    ButtonKind, ButtonWrapperProps, SizeMap, StateMap,
} from '../types';

function getIconPaddingCompensation(direction: 'left' | 'right') {
    return ({ iconPos }: ButtonWrapperProps) => {
        // Subtract optical compensation from the icon's lateral padding
        return iconPos === direction
            ? 'calc(var(--padding-horizontal) - var(--padding-icon-compensation))'
            : 'var(--padding-horizontal)';
    };
}

/** Button kind-dependent CSS */
const getStateMap = (theme: Theme, kind: ButtonKind): StateMap => {
    const {
        fillIn, label, focusOutline, shadow, staticColors,
    } = theme;
    const kindMap: Record<ButtonKind, StateMap> = {
        primary: {
            default: `
                background: ${fillIn.primary.mid};
                color: ${staticColors.white};
                box-shadow: ${shadow.button};
            `,
            hover: `
                color: ${staticColors.white};
                background: ${fillIn.primary.high};
            `,
            focus: `
                color: ${staticColors.white};
                background: ${fillIn.primary.mid};
                box-shadow: ${focusOutline.accent};
            `,
            active: `
                color: ${staticColors.white};
                background: ${fillIn.primary.highest};
            `,
            disabled: `
                background: ${fillIn.secondary.overlay16};
                color: ${label.inactive};
            `,
        },
        secondary: {
            default: `
                background: ${fillIn.secondary.overlay16};
                color: ${label.secondary};
            `,
            hover: `
                background: ${fillIn.secondary.overlay24};
                color: ${label.secondaryActive};
            `,
            focus: `
                box-shadow: ${focusOutline.accent};
                color: ${label.secondary};
            `,
            active: `
                background: ${fillIn.secondary.overlay32};
                color: ${label.primary};
            `,
            disabled: `
                background: ${fillIn.secondary.overlay16};
                color: ${label.inactive};
            `,
        },
        outline: {
            default: `
                background: none;
                color: ${label.accent};
                box-shadow: ${boxShadowLikeBorder('1px', fillIn.primary.mid)};
            `,
            hover: `
                background: ${fillIn.primary.overlay8};
                color: ${label.accent};
            `,
            focus: `
                box-shadow:
                    ${boxShadowLikeBorder('1px', fillIn.primary.mid)},
                    ${focusOutline.accent};
                color: ${label.accent};
            `,
            active: `
                color: ${label.accentHighest};
                background: ${fillIn.primary.overlay16};
                box-shadow: ${boxShadowLikeBorder('1px', fillIn.primary.highest)};
            `,
            disabled: `
                background: none;
                color: ${label.inactive};
                border: 1px solid ${fillIn.secondary.overlay16};
            `,
        },
        'ghost-primary': {
            default: `
                color: ${label.accent};
            `,
            hover: `
                color: ${label.accentHigh};
            `,
            focus: `
                box-shadow: ${focusOutline.accent};
                color: ${label.accent};
            `,
            active: `
                color: ${label.accent};
            `,
            disabled: `
                color: ${label.inactive};
            `,
        },
        'ghost-secondary': {
            default: `
                color: ${label.secondary};
            `,
            hover: `
                color: ${label.secondaryActive};
            `,
            focus: `
                color: ${label.secondary};
                box-shadow: ${focusOutline.accent};
            `,
            active: `
                color: ${label.primary};
            `,
            disabled: `
                color: ${label.inactive};
            `,
        },
        cancel: {
            default: `
                color: ${label.negative};
            `,
            hover: `
                color: ${label.negativeHigh};
            `,
            focus: `
                color: ${label.negative};
                box-shadow: ${fillIn.negative.overlay24};
            `,
            active: `
                color: ${label.negativeHighest};
            `,
            disabled: `
                color: ${label.inactive};
            `,
        },
        static: {
            default: `
                color: ${staticColors.whiteAlpha56};
            `,
            hover: `
                color: ${staticColors.whiteAlpha72};
            `,
            focus: `
                color: ${staticColors.whiteAlpha56};
                box-shadow: ${fillIn.positive.overlay24};
            `,
            active: `
                color: ${staticColors.white};
            `,
            disabled: `
                color: ${staticColors.whiteAlpha16};
            `,
        },
    };
    return kindMap[kind];
};

/** Button size-dependent CSS */
const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --padding-vertical: 16px;
        --padding-horizontal: 32px;
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --padding-vertical: 12px;
        --padding-horizontal: 24px;
    `,
    small: css`
        ${buttonSmallFontStyle};
        --padding-vertical: 8px;
        --padding-horizontal: 16px;
    `,
    tiny: css`
        ${buttonSmallFontStyle};
        --padding-vertical: 4px;
        --padding-horizontal: 12px;
    `,
};

const paddingLeftRightStyle = ({ icon, text, isProcessing }: ButtonWrapperProps) => {
    const hasIconAndText = icon && text;
    const hasOnlyIcon = (icon && !text) || (isProcessing && !text);
    const hasOnlyText = !icon && text;

    if (hasIconAndText) {
        return css`
            padding-left: ${getIconPaddingCompensation('left')};
            padding-right: ${getIconPaddingCompensation('right')};
        `;
    }

    if (hasOnlyIcon) {
        return `
            padding-left: var(--padding-vertical);
            padding-right: var(--padding-vertical);
        `;
    }

    if (hasOnlyText) {
        return `
            padding-left: var(--padding-horizontal);
            padding-right: var(--padding-horizontal);
        `;
    }

    return undefined;
};

const contentContainerStyle = ({ icon, text, iconPos }: ButtonWrapperProps) => {
    const hasIconAndText = icon && text;
    const hasOnlyIcon = icon && !text;
    const hasOnlyText = !icon && text;

    if (hasIconAndText) {
        return iconPos === 'right'
            ? `
                grid-template-columns: min-content min-content;
                grid-template-areas: "content icon";
            ` : `
                grid-template-columns: min-content min-content;
                grid-template-areas: "icon content";
            `;
    }

    if (hasOnlyIcon) {
        return `
            grid-template-columns: min-content;
            grid-template-areas: "icon";
        `;
    }

    if (hasOnlyText) {
        return `
            grid-template-columns: min-content;
            grid-template-areas: "content";
        `;
    }

    return undefined;
};

export const typeStandard = {
    wrapperStyle: css<ButtonWrapperProps>`
        --padding-icon-compensation: ${({ icon, text }) => (icon && text ? '2px' : '0px')};
    `,
    sizeMap,
    paddingLeftRightStyle,
    getStateMap,
    contentContainerStyle,
};
