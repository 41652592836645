/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Lcid } from 'types';
import { CurrencyCode } from 'types/currency';
import { sendGeneralRequest, InstrumentsSearchController, GeneralSearchController } from '../../components/search/apiCaller';
import { MenuState } from '../../types/interfaces';

export interface SearchLog {
    log_id: number;
}

const DEFAULT_LOG_VALUE: SearchLog = { log_id: 0 };

export interface SearchPerson {
    id: number;
    isFollowable: boolean;
    subscribed: boolean;
}

export interface SearchTicker {
    id: number;
    ticker: string;
    subscribed: boolean;
    currency: CurrencyCode;
    last: number;
    exchangeName: string;
    name: string;
    ename: string;
}

export interface SearchPost {
    id: number;
    url: string;
    date: string;
    displayTitle: string;
}

export interface SearchProduct {
    blogPostObjId: number;
    productId: number;
    productType: string;
    productUrl: string;
    url: string;
    title: string;
}

export function useSearchApi() {
    const lcid = useSelector<MenuState, Lcid>((state) => state.lcid);

    const [tickers, setTickers] = useState<SearchTicker[]>([]);
    const [people, setPeople] = useState<SearchPerson[]>([]);
    const [posts, setPosts] = useState<SearchPost[]>([]);
    const [products, setProducts] = useState<SearchProduct[]>([]);
    const [log, setLog] = useState<SearchLog>(DEFAULT_LOG_VALUE);

    const [isPeopleLoading, setPeopleLoading] = useState(false);
    const [isInstrumentsLoading, setInstrumentsLoading] = useState(false);
    const [isPostsLoading, setPostsLoading] = useState(false);
    const [isProductsLoading, setProductsLoading] = useState(false);

    const toggleTickerSubscribed = (id: number) => {
        const newTickers = tickers.reduce((result: SearchTicker[], ticker: SearchTicker) => {
            return [
                ...result,
                { ...ticker, ...(ticker.id === id ? { subscribed: !ticker.subscribed } : {}) },
            ];
        }, []);
        setTickers(newTickers);
    };

    const reset = () => {
        setTickers([]);
        setPeople([]);
        setPosts([]);
        setProducts([]);
        setPostsLoading(false);
        setPeopleLoading(false);
        setProductsLoading(false);
        setInstrumentsLoading(false);
        InstrumentsSearchController.value?.abort?.();
        GeneralSearchController.value?.abort?.();

        setLog(DEFAULT_LOG_VALUE);
    };

    const fetchData = (inputValue: string) => {
        setInstrumentsLoading(true);
        setPostsLoading(true);
        setPeopleLoading(true);
        setProductsLoading(true);

        sendGeneralRequest(inputValue, null, lcid)
            .then(({ result, ok, error }) => {
                if (!ok) {
                    setPostsLoading(false);
                    setPeopleLoading(false);
                    setProductsLoading(false);
                    setPeople([]);
                    setPosts([]);
                    setProducts([]);
                    setTickers([]);
                    setLog(DEFAULT_LOG_VALUE);

                    setInstrumentsLoading(false);
                    setTickers([]);

                    throw new Error(error);
                }

                const {
                    instruments = [],
                    people = [],
                    posts = [],
                    products = [],
                    log = {},
                } = result;

                if (Array.isArray(instruments) && instruments.length > 0) {
                    setInstrumentsLoading(false);
                    setTickers(instruments);
                }

                setPostsLoading(false);
                setPeopleLoading(false);
                setProductsLoading(false);
                setPeople(people);
                setPosts(posts);
                setProducts(products);
                setLog(log);
            })
            .catch((error) => {
                console.log('sendGeneralRequest error', error);
            });
    };

    return {
        fetchData,
        reset,
        toggleTickerSubscribed,
        isPeopleLoading,
        isInstrumentsLoading,
        isPostsLoading,
        isProductsLoading,
        tickers,
        people,
        posts,
        products,
        log,
    };
}
