import React from 'react';
import { User } from 'services/user';

export const guestUser = new User({
    id: 0,
    type: 0,
    lcid: 'en',
    displayName: '',
});

export const CurrentUserContext = React.createContext<User>(guestUser);
export const CurrentUserProvider = CurrentUserContext.Provider;
