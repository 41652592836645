import {
    FeedRecordInstrument, Person, Id, FeedOwnerType,
} from 'types';
import { createMutableContext } from './create-mutable-context';

export type FeedOwner = Person | FeedRecordInstrument;

export type FeedContextValue = {
    /** Контекст для запроса данных ленты */
    source: 'feed';

    /** определяет, является ли пользолватель разработчиком */
    isDeveloper?: boolean;

    /** определяет, является ли пользователь администратором */
    isBlogAdmin?: boolean;

    /** Владелец данной ленты */
    owner: FeedOwner;

    /** Подпись текущего юзера для отправки статистики */
    websocketUserSign: string;

    /** Id закрепленной записи */
    pinnedRecordId?: Id;

    /** Развернута ли по умолчанию закрепленная запись */
    pinnedRecordShowMore?: boolean;

    /** Сменит ключ у корневого компонента ленты тем сам произойдет ремаунт */
    remountFeed: () => void,
};

const feedDefaultValue: FeedContextValue = {
    source: 'feed',
    owner: {
        id: 0,
        type: FeedOwnerType.PERSON,
        displayName: '',
        lcid: 'en',
    },
    websocketUserSign: '',
    remountFeed: () => {
        // nothing
    },
};

const { Provider, hook } = createMutableContext<FeedContextValue>(
    feedDefaultValue,
);

export { Provider as FeedContextProvider, hook as useFeedContext };
