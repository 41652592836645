import { connect } from 'react-redux';

import { FeedRecordType, FeedRecord } from 'types';
import { mergeByPath } from 'services/utils/merge';
import { GlobalStateForFeed } from './types';
import {
    fetchRecords,
    updateFeedRecord,
    deleteFeedRecord,
    setToInit,
    fetchTickerNews,
    publishFeedRecord,
} from './feed/actions';
import { FeedWithContext } from './feed';

const buildTickerNews = (records: Record<string, FeedRecord>): FeedRecord[] => {
    let tickerNews: FeedRecord[] = [];
    let tickerNewsRecord: FeedRecord | null;
    return Object.keys(records).reduce((acc: FeedRecord[], key) => {
        const record = records[key];
        if (record.type === FeedRecordType.TICKER_NEWS) {
            let oldTickerNewsRecord = null;
            if (tickerNewsRecord) {
                oldTickerNewsRecord = mergeByPath(tickerNewsRecord, 'tickerNews', { records: tickerNews }) as FeedRecord;
                tickerNews = [];
            }
            tickerNewsRecord = { ...record };
            return [...acc, ...(oldTickerNewsRecord ? [oldTickerNewsRecord] : [])];
        } if (tickerNewsRecord && (record.tickerNews?.tickerNewsId === tickerNewsRecord.id)) {
            tickerNews.push(record);
            return acc;
        } if (tickerNewsRecord) {
            tickerNewsRecord = mergeByPath(tickerNewsRecord, 'tickerNews', { records: tickerNews }) as FeedRecord;
            const result = [...acc, tickerNewsRecord];
            tickerNewsRecord = null;
            tickerNews = [];
            return result;
        }
        return [...acc, record];
    }, []);
};

export type FeedContainerMapStateOwnPropsType = {
    showTickerNews?: boolean;
    showAdds?: boolean;
    filterRecords?: (records: FeedRecord[]) => FeedRecord[],
    boundary?: string | null,
};

const mapStateToProps = (state: GlobalStateForFeed, ownProps: FeedContainerMapStateOwnPropsType) => {
    const { feed } = state?.widgets || {};
    const {
        filterRecords, boundary: ownBoundary, showTickerNews, showAdds,
    } = ownProps;

    if (!feed) return {};

    const {
        records,
        boundary,
        boundaryRecordId,
        tags,
    } = feed;

    let recordsForRender = buildTickerNews(records);
    if (!showAdds) recordsForRender = recordsForRender.filter((record) => record.type !== FeedRecordType.ADS);
    const resultRecords = filterRecords?.(recordsForRender) || recordsForRender;

    return ({
        records: resultRecords,
        boundary: ownBoundary ?? boundary,
        boundaryRecordId,
        showTickerNews,
        tags,
    });
};

const mapDispatchToProps = {
    fetchRecords,
    updateFeedRecord,
    deleteFeedRecord,
    publishFeedRecord,
    setToInit,
    fetchTickerNews,
};

export const FeedContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedWithContext);
