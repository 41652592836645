import ReactDOM from 'react-dom';
import React, { ReactElement } from 'react';
import { Notify, NotifyProps } from 'components/notify';

export class NotifyProcess {
    private currentTimout: ReturnType<typeof setTimeout> | null = null;

    public mountPoint: Element;

    private renderWrapper;

    public delay: number;

    constructor(mountPoint?: Element, delay?: number, renderWrapper = (reactElement: ReactElement) => reactElement) {
        this.delay = delay || 5000;
        this.renderWrapper = renderWrapper;
        this.mountPoint = mountPoint || document.createElement('div');
        if (!mountPoint) document.body.append(this.mountPoint);
    }

    public show(props: string | NotifyProps) {
        const text = typeof props === 'string' ? props : props.text;
        this.clearTimeout();
        ReactDOM.render(
            this.renderWrapper(
                <Notify
                    text={text}
                    close={this.handleClose}
                    onMouseEnter={this.clearTimeout}
                    onMouseLeave={this.startTimeout}
                />,
            ),
            this.mountPoint,
        );
        this.startTimeout();
    }

    public handleClose = () => {
        ReactDOM.unmountComponentAtNode(this.mountPoint);
    }

    private startTimeout = () => {
        this.currentTimout = setTimeout(this.handleClose, this.delay);
    }

    private clearTimeout = () => {
        if (this.currentTimout) clearTimeout(this.currentTimout);
    }
}
