import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { animations, mediaQuery } from 'app/styled';
import { body14MediumFontStyle, body14RegularFontStyle } from 'services/typography';
import { WtField } from 'components/wt-field';
import { LoadingRingIcon } from 'components/icons2/LoadingRingIcon';
import { CloseIcon } from 'components/icons2/CloseIcon';
import { Avatar } from 'components/avatar2';

export const LoadingIcon = styled(LoadingRingIcon)`
    color: ${({ theme }) => theme.label.accent};
    animation: ${animations.spin} 0.5s infinite ease-in-out;
`;

export const FormWrapper = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;

    margin-top: 16px;
    margin-bottom: 16px;

    ${mediaQuery.lt480} {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    ${ifProp('active', css`
        flex-direction: column;
        align-items: flex-start;
    `)};
`;

export const TextAreaWrapper = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;

    ${ifProp('active', css`
        padding: 16px 16px 16px 8px;
        border: 1px solid ${({ theme }) => theme.fillIn.primary.mid};
        border-radius: 8px;

        ${mediaQuery.lt480} {
            padding-bottom: 12px;
            min-height: 32px !important;
        }

        .textarea-wrapper {
            padding-left: 8px;
            padding-right: 0;
            border-radius: 0;
        }

        textarea {
            border-radius: 0;
        }

        &:hover {
            textarea {
                background-color: unset;
            }
        }
    `)};

    .textarea-wrapper {
        display: flex;
        margin: 0;
    }
`;

export const OverflowText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-top: 12px;

    margin-bottom: 32px;

    ${mediaQuery.lt480} {
        margin-bottom: 24px;
    }

    color: ${({ theme }) => theme.label.tertiary};
    ${body14RegularFontStyle}
`;

export const ActionsArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
`;

export const ActionsButtonsGroup = styled.div`
    display: flex;
`;

export const WtFieldWrapper = styled(WtField) <{ isOverflow: boolean }>`
    padding: 0;

    caret-color: ${({ theme }) => theme.label.accent};
    overflow-x: hidden;

    ${(props) => {
        if (!props.active) {
            return `
                    padding: 8px 16px 8px 16px;
                    min-height: 40px !important;

                    ${mediaQuery.lt480} {
                        padding: 6px 12px 6px 12px;
                        min-height: 32px !important;
                    }
                `;
        }

        if (props.isOverflow) {
            return 'margin-bottom: 0px;';
        }

        return `
                margin-bottom: 32px;

                ${mediaQuery.lt480} {
                    margin-bottom: 24px;
                }
            `;
    }}

    ${mediaQuery.lt480} {
        ${body14RegularFontStyle}
    }

    box-shadow: none;
    --active-box-shadow: none;
`;

export const UsersItemWrapper = styled.div`
    ${body14MediumFontStyle};

    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ActionsIconWrapper = styled.div`
    display: flex;
    gap: 8px;

    ${mediaQuery.lt480} {
        gap: 0;
    }
`;

export const imageStyle = `
    width: 64px;
    height: 64px;

    ${mediaQuery.lt480} {
        width: 48px;
        height: 48px;
    }

    border-radius: 4px;
`;

export const PreviewImageWrapper = styled.div`
    position: relative;
    display: flex;
    margin-top: 18px;
    margin-bottom: 16px;
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};

    ${imageStyle};
`;

export const PreviewImage = styled.img`
    ${imageStyle};
    object-fit: contain;
`;

export const PreviewImageLoader = styled.div`
    ${imageStyle};

    margin-top: 18px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};
`;

export const PreviewCloseWrapper = styled.div`
    position: absolute;
    right: -12px;
    top: -12px;
    background-color: ${({ theme }) => theme.fillIn.secondary.mid};
    cursor: pointer;
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.label.inverse};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CloseIconWrapper = styled(CloseIcon)`
    fill: ${({ theme }) => theme.label.inverse};
`;

export const FormAvatar = styled(Avatar)`
    width: 40px;
    height: 40px;
    margin-right: 12px;

    ${mediaQuery.lt480} {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
`;
