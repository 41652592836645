export function hamburger({ color = 'black' }) {
    return `
        <svg viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.199951 1.60005C0.199951 1.15822 0.558123 0.800049 0.999951 0.800049H17C17.4418 0.800049 17.7999 1.15822 17.7999 1.60005C17.7999 2.04188 17.4418 2.40005 17 2.40005H0.999951C0.558123 2.40005 0.199951 2.04188 0.199951 1.60005Z"
                fill="${color}"
            />
            <path
                d="M0.199951 6.00007C0.199951 5.55825 0.558123 5.20007 0.999951 5.20007H17C17.4418 5.20007 17.7999 5.55825 17.7999 6.00007C17.7999 6.4419 17.4418 6.80007 17 6.80007H0.999951C0.558123 6.80007 0.199951 6.4419 0.199951 6.00007Z"
                fill="${color}"
            />
            <path
                d="M0.999951 9.6001C0.558123 9.6001 0.199951 9.95827 0.199951 10.4001C0.199951 10.8419 0.558123 11.2001 0.999951 11.2001H17C17.4418 11.2001 17.7999 10.8419 17.7999 10.4001C17.7999 9.95827 17.4418 9.6001 17 9.6001H0.999951Z"
                fill="${color}"
            />
        </svg>
    `;
}
