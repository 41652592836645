import React, { ReactNode, useMemo } from 'react';

import { Id } from 'types';
import PullScroll from 'hocs/PullScroll';
import {
    NotificationGroupItemDataType,
    NotificationItemDataType,
} from 'widgets/header-notifications/types';

import { Notification as NotificationComponent } from 'components/notification';

import { ErrorContent } from './ErrorContent';
import { EmptyContent } from './EmptyContent';
import { GroupBlock } from './GroupBlock';
import { Notification as HeaderNotification } from './Notification';
import { Header } from './Header';

import * as Styled from './styled/Content.styled';
import { notificationTextObjType } from './index';

interface NotificationsDataType {
    unread: NotificationGroupItemDataType;
    read: NotificationGroupItemDataType;
}

export const defaultContentData: NotificationsDataType = {
    unread: [],
    read: [],
};

export type ContentPropsType = {
    notifications?: [NotificationItemDataType] | [] | null;
    emptyText: string;
    title: string;
    isLoading: boolean;
    isError: boolean;
    totalCount: number;
    headerButton?: ReactNode;
    errorContent: {
        text: string,
        actionBtnText: string,
    }
    groupTitles: {
        unread: string;
        read: string;
    },
    sentinelElement: ReactNode;
    deleteNotification: (id: Id) => void;
    fetchNotifications: () => void;
    notificationTextObj: notificationTextObjType;
};

const Body = PullScroll(Styled.Body, { overflow: 'auto' });

export const Content = (props: ContentPropsType) => {
    const {
        emptyText,
        title,
        isLoading,
        isError,
        totalCount,
        headerButton,
        notifications,
        groupTitles,
        deleteNotification,
        fetchNotifications,
        sentinelElement,
        errorContent,
        notificationTextObj,
    } = props;

    const initialData = {
        ...defaultContentData,
    };

    const dataByGroup: NotificationsDataType = (notifications?.length && notifications.reduce(
        (
            current: NotificationsDataType,
            item: NotificationItemDataType,
        ): NotificationsDataType => {
            if (item.readDate === null) {
                return {
                    ...current,
                    unread: (current.unread && current.unread?.length > 0) ? [...current.unread, item] : [item],
                };
            }

            return {
                ...current,
                read: (current.read && current.read?.length > 0) ? [...current.read, item] : [item],
            };
        },
        initialData,
    )) || initialData;

    const unreadCount = dataByGroup.unread.length;
    const count = dataByGroup.read.length + unreadCount;
    const isEmpty = !count || (count && count < 1);

    const renderLoadingSkeleton = (length = 4) => (
        Array.from(Array(length).keys()).map((num) => (
            <Styled.NotificationItemWrapper key={num}>
                <NotificationComponent isLoading />
            </Styled.NotificationItemWrapper>
        )));

    const renderNotification = (item: NotificationItemDataType) => {
        const idItem = item?.id;

        return (
            <Styled.NotificationItemWrapper
                key={idItem}
            >
                <HeaderNotification
                    item={item}
                    deleteNotification={() => idItem && deleteNotification(idItem)}
                    notificationTextObj={notificationTextObj}
                />
            </Styled.NotificationItemWrapper>
        );
    };

    const nextResponseCountNotifications = useMemo(() => {
        const leftCount = totalCount - count;
        const maxCountOnResponse = 10;

        if (leftCount <= 0) {
            return 0;
        }

        return (leftCount >= maxCountOnResponse) ? maxCountOnResponse : leftCount;
    }, [totalCount, count]);

    const notificationsListByGroup = (
        <Styled.NotificationsContentWrapper isLoading={isLoading}>
            {isEmpty && isLoading
                ? renderLoadingSkeleton()
                : (
                    <Styled.GroupBlockWrapper>
                        <>
                            {dataByGroup.unread.length > 0 && (
                                <GroupBlock title={groupTitles.unread}>
                                    {dataByGroup.unread.map((item) => renderNotification(item))}
                                </GroupBlock>
                            ) }
                            {dataByGroup.read.length > 0 && (
                                <GroupBlock title={groupTitles.read}>
                                    {dataByGroup.read.map((item) => renderNotification(item))}
                                </GroupBlock>
                            )}
                            {sentinelElement}
                            {(nextResponseCountNotifications > 0) && renderLoadingSkeleton(nextResponseCountNotifications)}
                        </>
                    </Styled.GroupBlockWrapper>
                )}
        </Styled.NotificationsContentWrapper>
    );

    const renderBody = () => {
        if (isError) {
            return (
                <ErrorContent
                    text={errorContent.text}
                    actionBtnText={errorContent.actionBtnText}
                    fetchNotifications={fetchNotifications}
                />
            );
        }

        if (!isLoading && isEmpty) {
            return <EmptyContent>{emptyText}</EmptyContent>;
        }

        return notificationsListByGroup;
    };

    return (
        <Styled.Wrapper>
            <Header
                title={title}
                unreadCount={unreadCount}
                button={headerButton}
            />
            <Body>
                { renderBody() }
            </Body>
        </Styled.Wrapper>

    );
};
