import React from 'react';

import { FeedRecordObj } from 'types';
import { PlugBlock } from './PlugBlock';

import * as Styled from './styled';

interface Props {
    obj: FeedRecordObj
}

export const ContentPremiumNotGranted = (
    {
        obj: {
            shortContent,
            author,
        },
    }: Props,
) => {
    const secureShortContent = shortContent.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');

    return (
        <>
            <Styled.Content dangerouslySetInnerHTML={{ __html: secureShortContent }} />
            <PlugBlock
                authorId={author.id}
                subscribed={author.subscribed || false}
            />
        </>
    );
};
