import React, { useMemo } from 'react';

import { FeedRecordComment, Id } from 'types';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { RootObject, ParentObject, MentionUser } from '../types';
import { SaveComment } from '../actions';
import { CommentForm } from './Form';
import { ReplyFormWrapper } from './styled/Comment';

interface Props {
    recordId: Id,
    rootObject: RootObject,
    saveComment: SaveComment,
    comment: FeedRecordComment,
    mentionList: MentionUser[],
    fetchMentionList: () => void,
    openedFormId: string | null,
    setOpenedFormId: (x: string | null) => void,
}
export const ReplyComment = (props: Props) => {
    const {
        recordId, rootObject, saveComment, mentionList, fetchMentionList,
        comment, openedFormId, setOpenedFormId,
    } = props;

    const { author, id } = comment;
    const currentUser = useCurrentUser();

    const commentForForm = useMemo(() => {
        return author ? { content: currentUser.id === author.id ? '' : `${author.displayName}, ` } : undefined;
    }, []);

    const replyUser = useMemo(() => {
        return author ? { replyUserId: author.id, replyUserName: author.displayName } : undefined;
    }, []);

    const parentObject = useMemo<ParentObject>(() => {
        return { parentObjectId: id, parentObjectType: 'comment' };
    }, []);

    return (
        <ReplyFormWrapper>
            <CommentForm
                autofocus
                recordId={recordId}
                rootObject={rootObject}
                parentObject={parentObject}
                replyUser={replyUser}
                saveComment={saveComment}
                onDismiss={() => setOpenedFormId(null)}
                comment={commentForForm}
                currentComment={comment}
                mentionList={mentionList}
                fetchMentionList={fetchMentionList}
                openedFormId={openedFormId}
                setOpenedFormId={setOpenedFormId}
            />
        </ReplyFormWrapper>
    );
};
