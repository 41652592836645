import { setForceLoadingOfWtAuth } from '../actions';
import store from '../store';
import wtAuthModuleCall from '../services/wtAuthModule';

export const forceLoadWtAuth = <T>(callback: (auth: T) => void, showSpinner: boolean) => {
    store.dispatch(setForceLoadingOfWtAuth());
    wtAuthModuleCall((auth: { initIfNeed: (showSpinner: boolean) => Promise<T> }) => {
        auth.initIfNeed(showSpinner).then((_auth) => callback(_auth));
    });
};
