import styled, { css } from 'styled-components';
import { size } from 'polished';
import { ifProp, ifNotProp } from 'styled-tools';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';
import {
    Wrapper as PopupWindow1Wrapper,
    Window,
    CloseButton,
    Body,
    CloseContainer,
    Content,
} from 'components/wt-popup/popup-window/styled';
import { Wrapper as TitleWrapper } from '../title/styled';

export const paddingTop = 24;
export const paddingSide = 24;
export const paddingSideMobile = 16;
export const paddingTopMobile = 8;
export const paddingBottomMobile = 16;
const swiperHeight = 20;

export const Swiper = styled.div`
    height: ${swiperHeight}px;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
        content: '';
        width: 63px;
        height: 4px;
        border-radius: 20px;
        background-color: ${({ theme }) => theme.fillIn.secondary.muted};;
    }
    ${mediaQuery.lt768} {
        display: flex;
    }
`;

export const Wrapper = styled.div<{ isMobileFullscreen: boolean, isVisible: boolean }>`
    ${ifNotProp('isVisible', 'display: none;')}
    ${body14RegularFontStyle};
    ${mediaQuery.lt768} {
        ${caption12RegularFontStyle}
    }
    ${Window} {
        display: flex;
    }
    ${PopupWindow1Wrapper} {
        width: 464px;
        min-width: 464px;
        height: auto;
        border-radius: 12px;

        min-height: auto;
        align-items: flex-start;
        ${mediaQuery.lt768} {
            min-width: 100%;
            margin-bottom: 0;
            border-radius: 12px 12px 0 0;
            ${ifProp('isMobileFullscreen', `
                border-radius: 0;
            `)}
        }
    }
    ${TitleWrapper} {
        ${mediaQuery.lt768} {
            ${ifProp('isMobileFullscreen', css`
                box-shadow: ${({ theme }) => theme.shadow.headerWeb};
                padding: 14px 15% 14px ${paddingSideMobile}px;
                margin: 0 -${paddingSideMobile}px;
                max-width: unset;
            `)}
        }
    }
    ${Body} {
        height: auto;
        min-height: auto;
        padding: ${paddingTop}px ${paddingSide}px;
        width: 100%;
        box-sizing: border-box;
        ${mediaQuery.lt768} {
            padding: ${paddingTopMobile + swiperHeight}px ${paddingSideMobile}px ${paddingBottomMobile}px;
            ${ifProp('isMobileFullscreen', `
                height: var(--100vh);
                padding-top: 0;
            `)}
        }
    }
    ${CloseContainer} {
        width: 100%;
        height: 0;
    }
    ${CloseButton} {
        top: ${paddingTop}px;
        right: ${paddingSide}px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        ${size(40)};
        & > svg {
            fill: ${({ theme }) => theme.label.secondary};
        }
        ${mediaQuery.lt768} {
            top: ${swiperHeight}px;
            right: ${paddingSideMobile / 2}px;
            ${ifProp('isMobileFullscreen', `
                top: 8px;
            `)}
        }
    }
    ${Content} {
        font: inherit;
    }
`;

// добавляет скролл дочерниму столбику с overflow-y без ограничения его по высоте
// столбик может быть не первым дочерним элементом
export const ScrollableRows = styled.div`
    display: flex;
    flex-direction: column;
    max-height: calc(var(--100vh) - ${paddingTop * 2}px);
    ${mediaQuery.lt768} {
        max-height: calc(var(--100vh) - ${paddingBottomMobile}px);
    }
`;
