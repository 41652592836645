import React from 'react';

import { IconSvg, IconComponent } from './index';

export const VKColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z" fill="#5C81B0" />
        <path d="M12.7145 17C7.24792 17 4.12992 13.2462 4 7H6.73829C6.82823 11.5846 8.84692 13.5265 10.4459 13.9269V7H13.0244V10.954C14.6034 10.7838 16.2622 8.98198 16.8218 7H19.4003C18.9705 9.44244 17.1717 11.2442 15.8925 11.985C17.1717 12.5856 19.2205 14.1572 20 17H17.1617C16.5521 15.0981 15.0332 13.6266 13.0244 13.4264V17H12.7145Z" fill="white" />
    </IconSvg>
);
