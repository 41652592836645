import styled from 'styled-components';

import { body14MediumFontStyle, caption12RegularFontStyle } from 'services/typography';
import { UserName } from 'components/user-name2';

export const Wrapper = styled.div`
    width: 172px;
    height: 179px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.bg.secondary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
`;

export const Name = styled(UserName)`
    margin-top: 10px;
    max-width: 100%;
    ${body14MediumFontStyle};
    > a {
        color: ${({ theme }) => theme.label.primary};
    }
`;

export const Subscribers = styled.span`
    ${caption12RegularFontStyle};
    margin-top: 4px;
    color: ${({ theme }) => theme.label.secondary};
`;

export const ButtonWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
    button, span {
        display: block;
        width: 100%;
    }
`;
