import styled from 'styled-components';

import { AnyIcon } from 'components/icons2';

const getGap = ({ gap, iconPos }: { gap: string, iconPos: 'left' | 'right' }) => {
    return `margin-${iconPos === 'left' ? 'right' : 'left'}: ${gap}`;
};

export const Inner = styled.span`
    flex-grow: 0;
    flex-shrink: 0;
`;

export const StyledIcon = styled(AnyIcon)<{ gap: string, iconPos: 'left' | 'right' }>`
    ${getGap};
    position: relative;
`;
