import React from 'react';

import { FeedRecordInstrument } from 'types/feed-record';
import { InstrumentsList } from './InstrumentsList';

interface Props {
    instruments: FeedRecordInstrument[],
}
export const Instruments = (props: Props) => {
    const { instruments } = props;

    return (
        <InstrumentsList instruments={instruments} autoUpdate />
    );
};
