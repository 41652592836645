import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ImageIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.46923 11.6933L3.5 15.5L2.50049 14.2506L7.66855 10.2849C8.16551 9.8873 8.87725 9.90797 9.3503 10.3337L13.5072 14.0749L16.647 11.3275C17.1333 10.902 17.8584 10.8983 18.3491 11.3189L21.5203 14.0371L20.4791 15.2519L17.5051 12.7027L14.3604 15.4543C13.8651 15.8878 13.1239 15.8826 12.6347 15.4423L8.46923 11.6933Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.6 5.6H5C4.2268 5.6 3.6 6.2268 3.6 7V16.6C3.6 17.3732 4.2268 18 5 18H18.6C19.3732 18 20 17.3732 20 16.6V7C20 6.2268 19.3732 5.6 18.6 5.6ZM5 4C3.34315 4 2 5.34314 2 7V16.6C2 18.2569 3.34315 19.6 5 19.6H18.6C20.2569 19.6 21.6 18.2569 21.6 16.6V7C21.6 5.34315 20.2569 4 18.6 4H5Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.75 9.7C14.2747 9.7 14.7 9.27467 14.7 8.75C14.7 8.22533 14.2747 7.8 13.75 7.8C13.2253 7.8 12.8 8.22533 12.8 8.75C12.8 9.27467 13.2253 9.7 13.75 9.7ZM13.75 11C14.9926 11 16 9.99264 16 8.75C16 7.50736 14.9926 6.5 13.75 6.5C12.5074 6.5 11.5 7.50736 11.5 8.75C11.5 9.99264 12.5074 11 13.75 11Z" />
    </IconSvg>
);
