import styled from 'styled-components';
import { caption12MediumFontStyle } from 'services/typography';

export const GroupTitleWrapper = styled.div`
    display: flex;
    padding: 14px 16px 10px;
`;

export const GroupBlock = styled.div`
    position: relative;
`;

export const Content = styled.div`
`;

export const Title = styled.span`
    ${caption12MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    word-break: break-word;
`;
