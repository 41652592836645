import { StatsService } from './stats';
import { delay, throttle } from 'lodash';

// dg: Время отображения записи на странице, после которой начинается отсчет времени "чтения" записи
const TIMELINE_STATS_RECORD_START_READ_DELAY = 0;

export class StatsViewedProcessor {
    recordsIds = new Set();

    statsService = new StatsService('feed', TIMELINE_STATS_RECORD_START_READ_DELAY);

    _sendInterval = null;

    _method = null;

    add(id) {
        this.recordsIds.add(id);
        this.statsService.setVisibleRecords([...this.recordsIds]);
        delay(this._flushAndSend, 100);
    }

    delete(id) {
        this.recordsIds.delete(id);
        this.statsService.setVisibleRecords([...this.recordsIds]);
        delay(this._flushAndSend, 100);
    }

    startSend(method) {
        if (!this._sendInterval) {
            this._method = method;
            this._sendInterval = setInterval(() => {
                this._flushAndSend();
            }, 1000 * 60);
        }
    }

    stopSend() {
        this._method = null;
        clearInterval(this._sendInterval);
    }

    onRecordShowFull(recordId) {
        this.statsService.setRecordShowMore(recordId);
    }

    _flushAndSend = throttle(() => {
        if (this._method) {
            const data = this.statsService.flush();
            if (data.length) {
                this._method(data);
            }
        }
    }, 2000);
}

export default new StatsViewedProcessor();
