import store from '../store';
import { menuLogger } from './menuLogger';

const callbackLogger = (callbackList, callbackName, args) => {
    menuLogger.log(`🚀 Running callback ${callbackName} `);

    if (args.length > 0) {
        menuLogger.log('🌮 arguments');
        args.map(arg => menuLogger.log(arg));
    }

    menuLogger.log('📼 callback code');
    menuLogger.log(`${callbackList[callbackName]}`);


    menuLogger.log('🔗 CALLBACK TRACE');
    menuLogger.trace();
};


const resolveCallBack = (callbacksList, key, args = []) => {
    if (!key) {
        return () => {};
    }

    callbackLogger(callbacksList, key, args);

    if (window.WT.menu.debug.shouldNotRunCallbacks()) {
        return menuLogger.log('🚧🚧🚧 Callback was blockd!');
    }

    if (typeof key === 'function') {
        return key(...args, { store });
    }
    // todo попробовать чтобы args был не массивом, а объектом, тогда будет консистентней, это изменит сигнатуру resolveCallBack поэтому должно выполняться в отдельном таске.
    return callbacksList[key] ? callbacksList[key](...args, { store }) : callbacksList.defaultCallback;
};
export default resolveCallBack;
