import styled from 'styled-components';

import { body17RegularFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    margin: 82px auto 0;
    width: 100%;
    text-align: center;
`;

export const Text = styled.div`
    ${body17RegularFontStyle};
    color: ${({ theme }) => theme.label.placeholder};
`;
