import React, {
    useMemo, useCallback, useRef, useEffect,
} from 'react';
import noop from 'lodash/noop';

import { FeedRecordObj } from 'types';
import { sendGA } from 'services/google-analytics';
import Dic from 'services/dictionary';
import { GalleryInitFunction } from 'components/gallery/Gallery';
import { useShowFull } from '../feed/hooks/useShowFull';
import { useThumbnail } from './useThumbnail';
import { useClickAnyImgInPost } from './useClickAnyImgInPost';
import { ShowFull, Wrapper, ThumbnailImage } from './styled';

interface Props {
    obj: FeedRecordObj,
    onClickAnyImgInPost?: GalleryInitFunction,
    showComments?: (x?: boolean) => void,
}

export const ContentHtmlParsed = (
    {
        obj: { content, shortContent, media },
        onClickAnyImgInPost, showComments,
    }: Props,
) => {
    const { showFull, setShowFull } = useShowFull();
    const [finalContent, firstImg] = useMemo(() => {
        const secureContent = content.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');
        return [
            showFull ? secureContent : shortContent,
            (media || []).filter((item) => item.height && item.width)[0],
        ];
    }, [showFull, shortContent, content, media]);

    const handleClickShowFull = useCallback(() => {
        setShowFull(true);
        showComments?.(true);
        sendGA('feedRecord', ['show-full-click']);
    }, [setShowFull]);

    const contentWrapper = useRef<HTMLDivElement>(null);
    const thumbnail = useRef<HTMLImageElement>(null);

    const {
        widthThumbnail,
        heightThumbnail,
        showIfSizeSatisfies,
    } = useThumbnail(firstImg);

    useClickAnyImgInPost(showFull, contentWrapper, thumbnail, onClickAnyImgInPost);

    return (
        <>
            <Wrapper dangerouslySetInnerHTML={{ __html: finalContent }} ref={contentWrapper} />
            {!showFull && (
                <ShowFull
                    onClick={handleClickShowFull}
                    className="autotest__show-full-button"
                >
                    {Dic.word('wt_feed__widget_content_html__see_full')}
                </ShowFull>
            )}
            {!showFull && firstImg && showIfSizeSatisfies && (
                <div>
                    <ThumbnailImage
                        src={firstImg.source}
                        width={widthThumbnail}
                        height={heightThumbnail}
                        alt="Thumbnail image"
                        ref={thumbnail}
                    />
                </div>
            )}
        </>
    );
};
ContentHtmlParsed.defaultProps = {
    onShowFull: noop,
};
