import type { InitialPageSettings } from 'services/init-page';
import { createMutableContext } from './create-mutable-context';

export type PageContextValueType = InitialPageSettings<unknown, Record<string, never>>['pageContext'];

const pageContextDefaultValue: PageContextValueType = {
    csrf: '',
    ads: {
        ip: '',
        ua: '',
    },
    googleAnalytics: {
        trackingId: '',
        googleAnalyticsId: '',
    },
};

const { Provider, hook } = createMutableContext<PageContextValueType>(pageContextDefaultValue);

export {
    Provider as PageContextProvider,
    hook as usePageContext,
};
