import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';

import { sentryClient } from 'services/sentry';

// MARK: - Types

export type UseLoadMoreSettings<Page> = {
    /** A function that checks if the next page exists using the information from the last page */
    hasNextPageFn: (lastPage?: Page) => boolean;

    /** An async function that resolves with the next page using the information from the last page */
    fetchNextPageFn: (lastPage?: Page) => Promise<Page | undefined>;
};

export type UseLoadMoreState<Page> = {
    /** A list of loaded pages */
    pages: Page[];

    /** True if the next page is loading */
    isLoading: boolean;

    /** Error from the last page retrieval */
    error?: Error;

    /** True if the next page exists */
    hasNextPage: boolean;
};

type SetState<Page> = (reduceState: (oldState: UseLoadMoreState<Page>) => UseLoadMoreState<Page>) => void;

// MARK: - Hook

/**
 * Handles the "load more items" (aka "infinite list") logic using an external state.
 * You can use `useLoadMoreExternal.makeInitialState` to prepare the initial state for this hook.
 *
 * NB! This hook purposely handles only the loading logic itself,
 * and provides a `loadNextPage` function that can be used to load the next page.
 * It's up to you to decide when to call this function to fetch the next page - e.g. on button click, on scroll, etc.
 * The only exception to this is the initial mount -`loadNextPage` will be called for you if the initial state is empty.
 */
export const useLoadMoreExternal = <Page>(
    externalState: UseLoadMoreState<Page>,
    setExternalState: SetState<Page>,
    settings: UseLoadMoreSettings<Page>,
) => {
    // We use this lock to allow only sequential requests
    const concurrentLockRef = useRef(false);

    const settingsRef = useRef(settings);
    const useStateRef = useRef([externalState, setExternalState] as const);

    useLayoutEffect(() => {
        settingsRef.current = settings;
        useStateRef.current = [externalState, setExternalState];
    });

    const loadNextPage = useCallback(async () => {
        // Don't make a request if there's already one
        if (concurrentLockRef.current) {
            return undefined;
        }

        concurrentLockRef.current = true;

        const [state, setState] = useStateRef.current;

        // Don't fetch the next page if there's none
        if (!state.hasNextPage) {
            return undefined;
        }

        setState((oldState) => ({
            ...oldState,
            isLoading: true,
        }));

        const { fetchNextPageFn, hasNextPageFn } = settingsRef.current;
        const lastPage = state.pages[state.pages.length - 1];

        return fetchNextPageFn(lastPage)
            .then((nextPage) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    error: undefined,
                    pages: nextPage ? [...prevState.pages, nextPage] : prevState.pages,
                    hasNextPage: hasNextPageFn(nextPage),
                }));
            })
            .catch((err) => {
                sentryClient.captureException(err);
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    error: err,
                }));
            })
            .finally(() => {
                concurrentLockRef.current = false;
            });
    }, []);

    const initialStateRef = useRef(externalState);
    const initialLoadNextPageRef = useRef(loadNextPage);

    // If the initial state is empty, load the next page on the first render
    useEffect(() => {
        if (!initialStateRef.current.pages.length) {
            initialLoadNextPageRef.current();
        }
    }, []);

    return loadNextPage;
};

// MARK: - Initial State

export const makeInitialState = <Page>(settings: {
    initialPage?: Page,
    hasNextPageFn: UseLoadMoreSettings<Page>['hasNextPageFn'],
}): UseLoadMoreState<Page> => {
    const { initialPage, hasNextPageFn } = settings;
    return {
        pages: initialPage ? [initialPage] : [],
        isLoading: false,
        hasNextPage: hasNextPageFn?.(initialPage) ?? false,
    };
};

useLoadMoreExternal.makeInitialState = makeInitialState;
