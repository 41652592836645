import React, { MouseEventHandler } from 'react';

import { VerifiedIcon } from 'components/icons2/verified-icon';
import { NameLink, NameSpan, UserNameWrapper } from './styled';

type Props = {
    url?: string,
    displayName: string,
    autotestClass?: string,
    isVerified?: boolean,
    isVerifiedAuthor?: boolean,
    onClick?: MouseEventHandler,
    className?: string,
    useDct?: boolean,
}

export function UserName(props: Props) {
    const {
        url,
        displayName,
        isVerified,
        isVerifiedAuthor,
        autotestClass,
        onClick,
        className,
        useDct,
    } = props;

    return (
        <UserNameWrapper className={`${className} adm__post-author`}>
            {url ? (
                <NameLink
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={autotestClass}
                    title={displayName}
                    onClick={onClick}
                >
                    {displayName}
                </NameLink>
            ) : <NameSpan title={displayName}>{displayName}</NameSpan>}
            <VerifiedIcon
                showTooltip={useDct}
                isVerifiedAccount={Boolean(isVerified)}
                isVerifiedAuthor={Boolean(isVerifiedAuthor)}
            />
        </UserNameWrapper>
    );
}

UserName.defaultProps = {
    url: undefined,
    className: '',
    autotestClass: '',
    isVerified: false,
    isVerifiedAuthor: false,
    useDct: true,
    onClick: () => {},
};
