import styled from 'styled-components';

import { h4MediumFontStyle } from 'services/typography';

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const Title = styled.div`
  ${h4MediumFontStyle};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
