import ProjectConfig from 'services/config';

class UrlMap {
    constructor(urlMapData) {
        if (!(urlMapData instanceof Object)) {
            throw new TypeError('Argument "urlMapData" must be an associative array (Object).');
        }

        this._urlMapData = urlMapData;
    }

    getPageData(pageName) {
        if (pageName == null || typeof pageName !== 'string') {
            throw new TypeError('Argument "pageName" must be a string with page name.');
        }

        const pageData = this._urlMapData[pageName];

        if (typeof pageData === 'undefined') {
            throw new Error(`The key "${pageName}" isn't present in the URL map`);
        }

        if (!(pageData.w instanceof Array)) {
            pageData.w = [];
        }
        if (!(pageData.r instanceof Object)) {
            pageData.r = {};
        }

        return pageData;
    }
}

class Page {
    constructor(pageData, forceAbsoluteUrls = false) {
        if (!(pageData instanceof Object && pageData.w instanceof Array && pageData.r instanceof Object)) {
            throw new TypeError('Incorrect type for argument "pageData".');
        }

        this._pageData = pageData;
        this._params = {};
        this._queryArgs = [];
        this._hash = null;
        this._forceAbsoluteUrls = forceAbsoluteUrls;
    }

    param(name, value, { encode = true } = {}) {
        if (typeof name !== 'string') {
            throw new TypeError('Incorrect type for argument "name".');
        }
        if (value == null) {
            throw new TypeError('Incorrect type for argument "value".');
        }

        if (this._pageData.r[name] !== true) {
            throw new Error(`Param "${name}" doesn't exists in page required words.`);
        }

        const stringValue = `${value}`;
        this._params[name] = encode ? window.encodeURIComponent(stringValue) : stringValue;

        return this;
    }

    params(params) {
        if (!(params instanceof Object)) {
            throw new TypeError('Incorrect type for argument "params".');
        }

        Object.keys(params).forEach(name => this.param(name, params[name]));

        return this;
    }

    hash(value) {
        if (value == null) {
            throw new TypeError('Incorrect type for "value".');
        }

        this._hash = String(value);

        return this;
    }

    queryArg(name, value) {
        if (name == null || value == null) {
            throw new TypeError(`Two arguments are required, ${arguments.length} given.`);
        }

        this._queryArgs.push([String(name), window.encodeURIComponent(String(value))]);

        return this;
    }

    queryArgs(args) {
        if (!(args instanceof Object)) {
            throw new TypeError('Incorrect type for argument "args".');
        }

        Object.keys(args).forEach(name => this.queryArg(name, args[name]));

        return this;
    }

    relativePath() {
        let path = '/';

        path += this._pageData.w.map((word) => {
            let folder;
            if (this._pageData.r[word] === true) {
                if (this._params[word] == null) {
                    throw new Error(`Param "${word}" is required.`);
                }
                folder = this._params[word];
            } else {
                folder = word;
            }

            return String(folder).replace(/^\s*(.+)\s*$/, '$1');
        }).join('/');

        // Process query arguments
        if (this._queryArgs.length > 0) {
            path += (/(\.\w+$)|(\/$)/i).test(path) ? '?' : '/?';
            path += this._queryArgs.map(queryArg => queryArg.join('=')).join('&');
        }

        // Process hash
        if (this._hash !== null) {
            path += '#';
            path += this._hash;
        }

        return path;
    }

    path() {
        if (this._forceAbsoluteUrls) {
            return this.url();
        }

        return this.relativePath();
    }

    url() {
        return `${ProjectConfig.mainGroupDomain}${this.relativePath()}`;
    }
}

class UrlProcessor {
    constructor() {
        this._urlMap = null;
        // dg: Возвращать всегда абсолютные адреса при вызове метода Page.path(). Метод Page.url() всегда возвращает абсолютный адрес
        this._forceAbsoluteUrls = false;
    }

    setUrlMap(urlMap) {
        this._urlMap = new UrlMap(urlMap);
    }

    getUrlMap() {
        if (!this._urlMap) {
            this.setUrlMap(window.urlMap || {});
        }

        return this._urlMap;
    }

    page(pageName) {
        return new Page(this.getUrlMap().getPageData(pageName), this._forceAbsoluteUrls);
    }

    forceAbsoluteUrls(force = true) {
        this._forceAbsoluteUrls = force;
    }
}


export default new UrlProcessor();
