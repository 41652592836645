import React from 'react';
import { IconComponent, IconSvg } from 'components/icons2/index';

export const VerifiedAuthorIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="7.5" fill="#FFCB30" stroke="white" />
        <path
            d="M8 4.45015L8.98726 6.82381L11.5498 7.02925L9.59743 8.7017L10.1939 11.2023L8 9.8623L5.80608 11.2023L6.40257 8.7017L4.45017 7.02925L7.01274 6.82381L8 4.45015Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 4C8.18183 4 8.3458 4.10939 8.41563 4.27728L9.2973 6.39708L11.5858 6.58055C11.7671 6.59508 11.9218 6.71722 11.9779 6.89015C12.0341 7.06308 11.9808 7.25283 11.8427 7.37112L10.0991 8.8647L10.6318 11.0979C10.674 11.2748 10.6056 11.4596 10.4585 11.5665C10.3114 11.6734 10.1144 11.6813 9.95928 11.5865L8 10.3898L6.04072 11.5865C5.88555 11.6813 5.68859 11.6734 5.54149 11.5665C5.39439 11.4596 5.32603 11.2748 5.36822 11.0979L5.90092 8.8647L4.15732 7.37112C4.01923 7.25283 3.96586 7.06308 4.02205 6.89015C4.07824 6.71722 4.23295 6.59508 4.41419 6.58055L6.7027 6.39708L7.58437 4.27728C7.6542 4.10939 7.81817 4 8 4ZM8 5.62231L7.42837 6.99668C7.36354 7.15254 7.21697 7.25903 7.04871 7.27252L5.56496 7.39147L6.69542 8.35983C6.82362 8.46964 6.8796 8.64195 6.84044 8.80614L6.49506 10.254L7.76536 9.47814C7.90941 9.39015 8.09059 9.39015 8.23464 9.47814L9.50494 10.254L9.15956 8.80614C9.1204 8.64195 9.17638 8.46964 9.30458 8.35983L10.435 7.39147L8.95129 7.27252C8.78303 7.25903 8.63646 7.15254 8.57163 6.99668L8 5.62231Z"
            fill="white"
        />
    </IconSvg>
);
