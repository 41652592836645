import React, {
    ReactNode,
    MouseEventHandler,
    useRef,
    useState,
    useEffect,
    useLayoutEffect,
} from 'react';
import { ThemeProvider } from 'styled-components';
import { MenuPerson } from 'types';
import { lightTheme } from 'services/theme/light';
import { tagsStorageCleanup } from 'services/utils/local-storage';
import { useClickOutside } from 'hooks/useClickOutside';
import { useWindowResize } from 'hooks/useWindowResize';

import { useDictionary } from 'hooks/useDictionary';
import { IconComponent } from 'components/icons2';
import { Button } from 'components/button2';
import { UserIcon } from 'components/icons2/UserIcon';
import { GearIcon } from 'components/icons2/GearIcon';

import resolveCallback from '../../services/resolveCallBack';
import googleAnalyticsService from '../../services/googleAnalytics';
import wtSocket from '../../services/wtSocket';

import * as Styled from '../mobile-menu/styles';
import {
    UserDropdownAvatarButton,
    UserDropdownAbsoluteContainer,
    UserDropdownRelativeReference,
    AvatarRow,
    AvatarRowFullName,
    ButtonRow,
    Divider,
    MenuAnchorItem,
    MenuItemIconContainer,
    UserDropdownContainer,
    UserMenuAvatar,
    UserDropdownAvatar,
    MenuItemsRow,
    MenuMobileButton,
    MobileUserDropDownContainer,
    DesktopButtonsRow,
    MobileButtonsRow,
    LoginButton,
} from './styled';

export type UserMenuProps = {
    user?: MenuPerson;
    callbacks: unknown[];
    onFetchUser: (changeLoaduserState?: boolean) => void;
    mode?: string;
};

const MOBILE_BREAKPOINT = 320;

export const UserMenu = ({ user, callbacks, onFetchUser }: UserMenuProps) => {
    const dic = useDictionary();
    const onFetchUserRef = useRef(onFetchUser);
    const callbacksRef = useRef(callbacks);
    const [dropdownIsShown, setDropdownIsShown] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        onFetchUserRef.current = onFetchUser;
        callbacksRef.current = callbacks;
    });

    useEffect(() => {
        resolveCallback(callbacksRef.current, 'onUserMenuVisibleChanged', [
            { isVisible: dropdownIsShown },
        ]);
    }, [dropdownIsShown]);

    useEffect(() => {
        wtSocket.add({
            name: 'event_logout',
            action: onFetchUserRef.current,
        });
    }, []);

    useWindowResize(({ width }) => {
        // dmh: Это грязный хак, чтобы попапы не интерферировали между собой
        // См. https://jira.finam.ru/browse/WTT-10166
        if (width <= MOBILE_BREAKPOINT && !isMobile) {
            setIsMobile(true);
        } else if (width > MOBILE_BREAKPOINT && isMobile) {
            setIsMobile(false);
        }
    });

    const toggleShowDropdown: MouseEventHandler = (e) => {
        e.preventDefault();
        setDropdownIsShown((prevDropdownIsShown) => !prevDropdownIsShown);
    };

    const hideDropdown = () => {
        setDropdownIsShown(false);
    };

    const logout = () => {
        tagsStorageCleanup();
        resolveCallback(callbacks, 'clickLogoutButton', ['wtMenu']);
        hideDropdown();
    };

    const signIn = () => {
        googleAnalyticsService.sendEvent('wt-menu', 'click-sign-in');
        resolveCallback(callbacks, 'clickLoginButton', [null, 'wtMenu', {}]);
        hideDropdown();
    };

    const signUp = () => {
        googleAnalyticsService.sendEvent('wt-menu', 'click-sign-up');
        resolveCallback(callbacks, 'clickRegisterButton', [null, 'wtMenu', {}]);
        hideDropdown();
    };

    if (!user?.id) {
        const renderMobileButtons = () => (
            <MobileButtonsRow>
                <MenuMobileButton
                    isMobile={isMobile}
                    size="medium"
                    onClick={signUp}
                    kind="primary"
                    className="autotest__menu__sign-up"
                >
                    {dic.word('wt_menu_signup')}
                </MenuMobileButton>
                <MenuMobileButton
                    isMobile={isMobile}
                    onClick={signIn}
                    size="medium"
                    kind="secondary"
                    className="autotest__menu__sign-in"
                >
                    {dic.word('wt_menu_signin')}
                </MenuMobileButton>
            </MobileButtonsRow>
        );

        const renderDesktopButtons = () => {
            return (
                <DesktopButtonsRow>
                    <Button
                        autoClass="autotest__menu-sign-up"
                        kind="outline"
                        size="small"
                        onClick={signUp}
                        isActive
                    >
                        {dic.word('wt_menu_signup')}
                    </Button>
                    <LoginButton
                        kind="ghost-secondary"
                        size="small"
                        autoClass="autotest__menu-sign-un"
                        onClick={signIn}
                    >
                        {dic.word('wt_menu_signin')}
                    </LoginButton>
                </DesktopButtonsRow>
            );
        };

        return (
            <ThemeProvider theme={lightTheme}>
                <MenuItemsRow>
                    {renderMobileButtons()}
                    {renderDesktopButtons()}
                </MenuItemsRow>
            </ThemeProvider>
        );
    }

    const menuContent = (
        <>
            <AvatarRow className="autotest__user-menu-name">
                <UserDropdownAvatar square src={user.avatar} />
                <AvatarRowFullName>{user.fullname}</AvatarRowFullName>
            </AvatarRow>
            <Divider />
            <MenuItem
                className="autotest__user-menu-profile"
                icon={UserIcon}
                href={`/profile/${user.id}`}
                onClick={hideDropdown}
            >
                {dic.word('wt_menu_user_view_profile')}
            </MenuItem>
            <MenuItem
                className="autotest__user-menu-settings"
                icon={GearIcon}
                href="/cabinet"
                onClick={hideDropdown}
            >
                {dic.word('wt_menu_user_settings')}
            </MenuItem>
            <Divider />
            <ButtonRow>
                <Button
                    block
                    className="autotest__user-menu-logout"
                    size="small"
                    kind="secondary"
                    onClick={logout}
                >
                    {dic.word('wt_menu_exit')}
                </Button>
            </ButtonRow>
        </>
    );

    return (
        <ThemeProvider theme={lightTheme}>
            <div>
                <UserDropdownAvatarButton
                    type="button"
                    onClick={toggleShowDropdown}
                >
                    <UserMenuAvatar square src={user?.avatar} />
                </UserDropdownAvatarButton>
                {isMobile ? (
                    <MobileUserDropDownContainer>
                        <Styled.Popover
                            isVisible={dropdownIsShown}
                            right={-15}
                            close={hideDropdown}
                        >
                            <Styled.HeaderOnlyMobile>
                                <Styled.HeaderOnlyMobileTitle>
                                    {dic.word(
                                        'wt_menu__limex_project__mobile_header_title__profile',
                                    )}
                                </Styled.HeaderOnlyMobileTitle>
                                <Styled.IconButton
                                    isOpened={dropdownIsShown}
                                    name="close"
                                    size={14}
                                    onClick={hideDropdown}
                                />
                            </Styled.HeaderOnlyMobile>
                            <Styled.Overlay onClick={hideDropdown} />
                            <Styled.Box>{menuContent}</Styled.Box>
                        </Styled.Popover>
                    </MobileUserDropDownContainer>
                ) : (
                    <UserDropdownRelativeReference>
                        {dropdownIsShown && (
                            <UserDropdown onClose={hideDropdown}>
                                {menuContent}
                            </UserDropdown>
                        )}
                    </UserDropdownRelativeReference>
                )}
            </div>
        </ThemeProvider>
    );
};

type UserDropdownProps = {
    children: ReactNode | ReactNode[];
    onClose: () => void;
};

const UserDropdown = ({ children, onClose }: UserDropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useClickOutside(dropdownRef, (e) => {
        onClose();
        e.stopPropagation();
    });

    return (
        <UserDropdownAbsoluteContainer
            ref={dropdownRef}
            className="autotest__user-menu"
        >
            <UserDropdownContainer>{children}</UserDropdownContainer>
        </UserDropdownAbsoluteContainer>
    );
};

type MenuItemProps = {
    children: string;
    href: string;
    icon?: IconComponent;
    className?: string;
    onClick?: MouseEventHandler;
};

const MenuItem = ({
    children,
    icon: Icon,
    href,
    className,
    onClick,
}: MenuItemProps) => {
    return (
        <MenuAnchorItem href={href} className={className} onClick={onClick}>
            {Icon && (
                <MenuItemIconContainer>
                    <Icon size={24} />
                </MenuItemIconContainer>
            )}
            {children}
        </MenuAnchorItem>
    );
};
