import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { rgba } from 'polished';

import { colors } from 'app/styled';
import detectDevice from 'services/detectDevice';

export const Wrapper = styled.div`
    position: relative;

    flex-grow: 1;

    &:last-child {
        margin-bottom: 0
    }
`;

export const pullScrollProcess = ({ usePullScroll }) => {
    if (usePullScroll) {
        return `
            overflow: hidden;
            // to make work PgUp and PgDn buttons
            ${detectDevice.isChrome() && `
                overflow: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
            `}
        `;
    }
    return '';
};

const verticalPadding = 7;
const borderWidth = 1;
const normalLineHeightKoef = 18 / 13;
export const Field = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    padding: ${verticalPadding}px 10px;
    border: ${borderWidth}px solid ${colors.silver};
    overflow: hidden;
    border-radius: 4px;
    -webkit-appearance: none;
    ${pullScrollProcess}

    ${({ as, rows, fontSize }) => {
        // FF не умеет правильно расчитывать высоту textarea на основе переданных rows без overflow: hidden
        // известный баг: https://bugzilla.mozilla.org/show_bug.cgi?id=33654
        // однако если мы будем использвать overflow: hidden, то не будет работать скролл #WTT-8922
        return (detectDevice.isFirefox() && (as === 'textarea') && rows)
            ? `height: ${normalLineHeightKoef * fontSize * rows + 2 * (verticalPadding + borderWidth)}px;`
            : '';
    }}

    ${ifProp('active', `
        border-color: ${colors.navyBlue};
        box-shadow: 0 0 8px 2px ${rgba(colors.navyBlue, 0.5)};
    `)}
`;
