import { ActionRequest, ActionResult, FeedRecord, Id } from 'types';
import { mergeByPath } from 'services/utils/merge';
import { index } from '../helpers';
import { FeedState } from '../types';
import { SendLikeRequestData, SendLikePayloadData, SendLikePayloadPass } from '../_shared/_sub/like/actionGenerators';
import { calculateLikes } from '../_shared/_sub/like/reducerUtils';
import { SEND_RATING_POST_ACTION_TYPES } from './actions';

const [SEND_RATING_POST_REQUEST, , SEND_RATING_POST_FAILURE] = SEND_RATING_POST_ACTION_TYPES;

const getStateWithChoice = (state: FeedState, recordId: Id, ...valArgs: [number] | [undefined, FeedRecord]) => {
    const [choice, prevRecordState] = valArgs;
    const record = prevRecordState || state.records[index(recordId)];
    if (!record?.obj) return state;
    const likes = prevRecordState ? record.obj.stats.likes : calculateLikes(record.obj.stats.likes, choice as number);
    return mergeByPath(state, `records.${index(recordId)}.obj.stats.likes`, likes);
};

type _SendLikePayloadPassWithSpecialState = SendLikePayloadPass & { prevSpecialState: FeedRecord; }

type AvailActionsData = SendLikePayloadData;
type AvailActionsPass = _SendLikePayloadPassWithSpecialState;
type AvailActionsRequestData = SendLikeRequestData;
export default (
    state: FeedState,
    action: ActionRequest<AvailActionsRequestData, AvailActionsPass> | ActionResult<AvailActionsData, AvailActionsPass>,
) => {
    switch (action.type) {
        case SEND_RATING_POST_REQUEST: {
            const { payload: { pass } } = action as ActionRequest<SendLikeRequestData, _SendLikePayloadPassWithSpecialState>;
            const { newChoice, recordId } = pass;
            return getStateWithChoice(state, recordId, newChoice);
        }
        case SEND_RATING_POST_FAILURE: {
            const { payload: { pass } } = action as ActionResult<SendLikePayloadData, _SendLikePayloadPassWithSpecialState>;
            const { prevSpecialState, recordId } = pass;
            return getStateWithChoice(state, recordId, undefined, prevSpecialState);
        }
        default:
            return state;
    }
};
