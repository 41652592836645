import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';

import page from 'services/page';
import { PopupWindow } from 'components/wt-popup/popup-window';
import { Wrapper, ScrollableRows, Swiper } from './styled';

export { ScrollableRows };

export type Props = {
    children: React.ReactNode | Element | string,
    close?: (event?: React.MouseEvent) => void,
    bgCloseable?: boolean,
    hasCloseButton?: boolean,
    isMobileFullscreen?: boolean,
    isVisible?: boolean,
    className?: string,
    portalTo?: HTMLElement | null,
};

export const PopupWindow2 = (props: Props) => {
    const {
        children,
        close = () => {},
        bgCloseable = false,
        hasCloseButton = true,
        isMobileFullscreen = false,
        isVisible = true,
        className = '',
        portalTo,
    } = props;

    useEffect(() => {
        if (!isVisible) {
            return () => {
            // nothing
            };
        }
        page.block({ requireScroll: true });
        return () => page.unblock();
    }, [isVisible]);

    useEffect(() => {
        const handleEscKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') close?.();
        };
        document.addEventListener('keydown', handleEscKeydown);
        return () => document.removeEventListener('keydown', handleEscKeydown);
    }, [close]);

    const result = (
        <Wrapper isMobileFullscreen={isMobileFullscreen} isVisible={isVisible} className={className}>
            <PopupWindow
                onClose={close}
                bgCloseable={bgCloseable}
                closeButton={hasCloseButton}
            >
                {!isMobileFullscreen && <Swiper />}
                {children}
            </PopupWindow>
        </Wrapper>
    );
    if (portalTo) return ReactDOM.createPortal(result, portalTo);
    return result;
};
