export enum VisibilityLevels {
    NONE = -2,
    SELF= 0,
    FRIEND= 1,
    ANY = 9,
}

export interface FeedRecordOptions {
    visLevel: VisibilityLevels,
}
