import React, { useMemo } from 'react';

import { useIsOwner } from '../feed/hooks/useIsOwner';
import { useIsPremiumExtend } from '../feed/hooks/useIsPremiumExtend';
import { Author } from '../author';
import { Instruments } from '../instruments';
import { ActivityContent } from '../activity-content';
import { Footer } from '../footer';
import { Comments } from '../comments';
import {
    PropsAuthor, PropsTitle, PropsInstruments, PropsHtmlParsedContent, PropsFooter, PropsComments, PropsChildren,
} from './types';
import { Wrapper, Title } from './styled';

type Props = PropsAuthor
    | PropsTitle
    | PropsHtmlParsedContent
    | PropsInstruments
    | PropsFooter
    | PropsComments
    | PropsChildren;

const defaultClassName = '';

// Это комопнент-конструктор.
// В нем должно быть минимальное количество логики
// если надо скрыть какую-либо часть, то это надо делать снаружи через пропсы
export const Activity = (props: Props) => {
    const {
        className,
        children,
        record,
        author,
        title,
        instruments,
        htmlParsedContent,
        footer,
        comments,
    } = props;

    const {
        id, data, obj, url, stats, generator, options,
    } = record || {};

    const isOwner = useIsOwner(record);

    const { isPremium, isPremiumGranted } = useIsPremiumExtend(record);
    const wrapperClassName = useMemo(
        () => [className, 'autotest__feed-item'].filter((c) => c).join(' '),
        [className],
    );

    if (!obj) {
        return (
            <Wrapper className={wrapperClassName}>
                {children}
            </Wrapper>
        );
    }

    return (
        <Wrapper className={wrapperClassName}>
            {
                author && url && generator && options && (
                    <Author
                        obj={obj}
                        url={url}
                        isPremium={isPremium}
                        generator={generator}
                        options={options}
                        onDelete={author.onDelete}
                    />
                )
            }
            {title && obj.title && <Title>{obj.title}</Title>}
            {htmlParsedContent && id && (
                <ActivityContent
                    obj={obj}
                    onClickAnyImgInPost={htmlParsedContent.onClickAnyImgInPost}
                    showComments={footer?.onClickComments}
                    isPremiumGranted={isPremiumGranted}
                    isPremium={isPremium}
                    isOwner={isOwner}
                />
            )}

            {instruments && <Instruments instruments={obj.instruments} />}
            {footer && stats && id && <Footer recordId={id} recordData={data} obj={obj} stats={stats} url={url} onClickComments={footer.onClickComments} />}
            {record && stats && id && <Comments recordId={id} showComments={comments} />}
            {children}
        </Wrapper>
    );
};

export { Wrapper as ActivityWrapper };

Activity.defaultProps = {
    className: defaultClassName,
};
