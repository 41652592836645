import React from 'react';

import { Issue } from 'services/issuesearch/types';
import { CashtagSuggestion } from 'widgets/cashtag-suggestion';
import { SuggestionProps } from '../types';
import { insertText } from './utils';

type Props = SuggestionProps;

export const SuggestionCashtags = (props: Props) => {
    const {
        comment, setComment, phrase, top, left, cursorElement, onClose,
    } = props;

    const handleSelect = (issue: Issue) => {
        if (cursorElement) {
            const { text, callback } = insertText(comment.content ?? '', `$${issue.ticker} `, comment.position, cursorElement);

            setComment({
                ...comment,
                content: text,
                position: 0,
                autocompleteType: null,
            });
            callback();
        }
    };
    const visible = comment.autocompleteType === 'cachetags' && phrase;

    return (
        <CashtagSuggestion
            searchLimit={8}
            searchTicker={phrase || ''}
            isVisible={!!visible}
            left={left}
            top={top}
            onSelect={handleSelect}
            onClose={onClose}
            cursorElement={cursorElement}
            maxHeight="192px"
        />
    );
};
