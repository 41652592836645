import { FeedRecord } from 'types';

export type InsertRecordPayloadData = {
    records: FeedRecord[],
    pos: number,
};
export const INSERT_FEED_RECORDS_ACTION = 'widgets/INSERT_FEED_RECORDS';
export function insertFeedRecord(records: FeedRecord[], pos = 0) {
    return {
        type: INSERT_FEED_RECORDS_ACTION,
        payload: {
            records,
            pos,
        },
    };
}
