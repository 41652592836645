import { useMemo } from 'react';

const urlHashCommentPrefix = '#comment-';

export const useUrlHashIdComment = () => {
    return useMemo(() => {
        const hasHashCommentInUrl = window.location?.hash.includes(urlHashCommentPrefix);
        const hashCommentId = hasHashCommentInUrl ? window.location?.hash.split(urlHashCommentPrefix)?.[1] : undefined;
        return { hashCommentId };
    }, []);
};
