let blocked = false;
let originalStyle = {};

const page = {
    block(opts = {}) {
        if (!blocked) {
            const pageWrapper = document.querySelector('.page__in');
            const element = pageWrapper || document.body;
            const { requireScroll } = opts;

            if (element) {
                originalStyle = {
                    top: element.style.top,
                    position: element.style.position,
                    width: element.style.width,
                    overflowY: element.style.overflowY,
                };

                element.style.top = `-${window.pageYOffset}px`;
                element.style.position = 'fixed';
                if (!element.style.width) {
                    element.style.width = '100%';
                }
                if (requireScroll) {
                    element.style.overflowY = 'scroll';
                }

                if (element === pageWrapper) {
                    window.scrollTo(0, 0);
                }

                blocked = true;
            }
        }
    },

    unblock() {
        if (blocked) {
            const pageWrapper = document.querySelector('.page__in');
            const element = pageWrapper || document.body;

            const top = -parseInt(element.style.top, 10);

            Object.assign(element.style, originalStyle);

            window.scrollTo(0, top);

            blocked = false;
        }
    },

    isBlocked() {
        return blocked;
    },
};

export default page;
