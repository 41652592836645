import styled, { css } from 'styled-components';

import { matchBreakpoints, anchorResetStyle } from 'app/styled';
import { Theme } from 'services/theme';
import { Counter } from 'components/indicator/counter';

import type { TabsType } from '../props';
import { itemStandard } from './itemStandard';
import { itemButtons } from './itemButtons';
import { paddingLeftRightStyle, TabsListItemProps } from './index';

export const kitByType = (type: TabsType) => ({
    standard: itemStandard,
    buttons: itemButtons,
}[type]);

function typeSpecificItemStyles({
    type, isActive, theme,
}: TabsListItemProps & { theme: Theme }) {
    const styleMap = kitByType(type).tabItemStyles;

    if (isActive) {
        return css`
            ${styleMap.common};
            color: ${theme.label.primary};
            ${styleMap.active}
        `;
    }

    return styleMap.common;
}

function typeSpecificAnchorStyles({
    type, isActive, theme,
}: TabsListItemProps & { theme: Theme }) {
    const styleMap = kitByType(type).anchorStyles(theme);

    if (isActive) {
        return css`
            ${styleMap.common}
            color: ${theme.label.primary};
            ${styleMap.active}
            &:focus {
                outline: none;

            }
        `;
        // ${styleMap.activeFocused}
    }

    return css`
        ${styleMap.common};
        @media (hover: hover) {
            &:hover {
                ${styleMap.hover};
            }
        }
        &:focus {
            outline: none;

        }
    `;
    // ${styleMap.inactiveFocused}
}

function gridSettings({
    icon, title, iconPos, count,
}: TabsListItemProps) {
    const hasIconAndText = icon && title;
    const hasOnlyIcon = icon && !title;
    const hasOnlyText = !icon && title;
    const contentTemplateAreas = `content${(count || count === 0) ? ' counter' : ''}`;

    if (hasIconAndText) {
        return css`
            grid-template-columns: min-content min-content;
            grid-template-areas: "${iconPos === 'left' ? `icon ${contentTemplateAreas}` : `${contentTemplateAreas} icon`}";
        `;
    }

    if (hasOnlyIcon) {
        return css`
            grid-template-columns: min-content;
            grid-template-areas: "icon";
        `;
    }

    if (hasOnlyText) {
        return css`
            grid-template-columns: min-content;
            grid-template-areas: "${contentTemplateAreas}";
        `;
    }

    return undefined;
}

function setVariables({ size, type }: TabsListItemProps) {
    const { sizeMap } = kitByType(type);
    return matchBreakpoints(size || 'large', sizeMap);
}

export const TabsListItem = styled.li<TabsListItemProps>`
    ${setVariables}
    ${typeSpecificItemStyles};

    display: flex;
    margin: 0 4px;
`;

export const TabsListItemAnchor = styled.div<TabsListItemProps>`
    ${anchorResetStyle}
    ${typeSpecificAnchorStyles}
    ${gridSettings}

    cursor: pointer;
    position: relative;
    ${paddingLeftRightStyle};
    display: grid;
    grid-template-rows: 1fr;
    // TODO проверить везде
    grid-auto-flow: column;
    gap: 8px;
    align-content: center;
    justify-items: stretch;
    align-items: center;
    white-space: nowrap;

    border-radius: 6px;


    svg {
        grid-area: icon;
    }
`;

export const TabTitleContainer = styled.span`
    grid-area: content;
`;

export const StyledCounter = styled(Counter)`
    grid-area: counter;
`;
