import UrlProcessor from 'services/url-processor';
import ProjectConfig from 'services/config';

function makeParamsHash(key, params) {
    let hash = `${key}:${Object.keys(params).length}`;

    // example: _key1_value1_key2_value2
    hash += Object.keys(params).map(key => ['', key, params[key]].join('_')).join();

    return hash;
}

function normalizeParams(params) {
    let normalizedParams = {};

    if (params) {
        if (params instanceof Array) {
            // make named args
            params.forEach((param, index) => { normalizedParams[index] = param; });
        } else if (typeof params === 'string') {
            normalizedParams[0] = params;
        } else if (params instanceof Object) { // mars: последний т.к. в js всё объекты
            normalizedParams = params;
        }
    }

    // dg: Приводим аргумент is_male к виду, поддерживаемому сервисом dct
    if (normalizedParams.is_male !== undefined) {
        if (normalizedParams.is_male !== 'f') {
            normalizedParams.is_male = (normalizedParams.is_male) ? 't' : 'f';
        }
    }

    return normalizedParams;
}

function replaceNamedArgsWithValues(templateString, params) {
    let resultString = templateString;

    Object.keys(params).forEach((key) => {
        resultString = resultString.replace(
            new RegExp(`{\\$${key}}`, 'g'),
            params[key],
        );
        resultString = resultString.replace(
            new RegExp(`\\$${key}(?=:)`, 'g'),
            params[key],
        );
    });

    return resultString;
}

function correctGender(dic, isMale) {
    // dz: считаем что все гендерное оформлено как {{он|она}}
    const regexp = /{{([^}]*)\|([^}]*)}}*/g;

    return dic.replace(regexp, (isMale || isMale == null) ? '$1' : '$2');
}

function vsprintf(text, replacements) {
    let index = 0;
    return text.replace(/%./g, () => replacements[index++]);
}

function correctDeclension(text) {
    const regExp = /((?:\d+)?)(\D*?){(\d*:*\s*)([^{}]*)\|([^{}]*)\|([^{}]*)}/gi;

    return text.replace(regExp, (match, num, delimiter, quantity, one, some, more) => {
        let rightWord;
        let count = parseInt(num, 10);
        if (quantity.trim()) {
            count = parseInt(quantity, 10);
        }
        count %= 100;

        if (count >= 5 && count <= 20) {
            rightWord = more;
        } else {
            count %= 10;
            if (count === 1) {
                rightWord = one;
            } else if (count >= 2 && count <= 4) {
                rightWord = some;
            } else {
                rightWord = more;
            }
        }

        return num + delimiter + rightWord;
    });
}

export class Dictionary {
    constructor(lcid) {
        this._cache = {};
        this._asyncCache = {};
        this.lcid = lcid;
    }

    clone(lcid) {
        const newDic = new Dictionary(lcid);
        newDic.setDicwords(this._dictionary);
        return newDic;
    }

    setDicwords(dicwords) {
        this._dictionary = dicwords;
    }

    _getDicword(lcid, key) {
        if (!this._dictionary) {
            this.setDicwords(window.Dic || {});
        }

        if (!this._dictionary[lcid]) {
            return undefined;
        }

        if (!this._dictionary[lcid][key]) {
            return undefined;
        }

        return this._dictionary[lcid][key];
    }

    word(key, params, lcid) {
        let dicValue;
        dicValue = this._getDicword(lcid || this.lcid || ProjectConfig.lcid, key);
        if (typeof dicValue === 'undefined') {
            dicValue = this._getDicword(ProjectConfig.deflcid, key);

            if (typeof dicValue === 'undefined') {
                // dmh: опция - не ходить за диквордом по API если он не найден, а просто вернуть ключ @since WTT-9808
                if (params && params.ignoreMissing) {
                    return key;
                }

                // fetch text from server
                return this.fetchTextSync(key, normalizeParams(params));
            }
        }

        if (!dicValue) {
            return '';
        }

        if (!params) {
            return dicValue;
        }

        if (params instanceof Array) {
            dicValue = vsprintf(dicValue, params);
        } else if (typeof params === 'string') {
            dicValue = vsprintf(dicValue, [params]);
        } else if (params instanceof Object) { // mars: последний т.к. в js всё объекты
            if (Object.hasOwnProperty.call(params, 'is_male')) {
                let isMale = params.is_male;
                if (typeof isMale === 'string') {
                    isMale = (isMale !== 'false' && isMale !== 'f'); // если пол женский isMale = false или f, по дефолту будет мужской пол (для строк)
                }
                dicValue = correctGender(dicValue, isMale);
            }

            dicValue = replaceNamedArgsWithValues(dicValue, params);
        }

        return correctDeclension(dicValue);
    }

    fetchTextSync(key, params = {}) {
        // calculating params hash
        const paramsHash = makeParamsHash(key, params);

        // lookup in cache
        if (!(paramsHash in this._cache)) {
            // perfoming ajax request
            const xhr = new XMLHttpRequest();
            xhr.open('GET', UrlProcessor.page('dic_text').param('key', key).queryArgs(params).url(), false);
            xhr.send();

            if (xhr.status === 404) {
                return `not-found: ${key}`;
            }


            try {
                // parse JSON & get text
                const data = JSON.parse(xhr.responseText);

                if (data.result.text?.substring(0, 3) === '???') {
                    console.error(`Не найден дикворд: ${key}`);
                }
                // save to cache and return the result
                this._cache[paramsHash] = data.result.text;
            } catch (err) {
                console.error(key, 'Error while parsing DIC service response', err);
                return key;
            }
        }

        return this._cache[paramsHash];
    }

    fetchTextAsync(key, params = {}, callbackSuccess = undefined, callbackError = undefined) {
        // calculating params hash
        const paramsHash = makeParamsHash(key, params);

        // lookup in cache
        if (!(paramsHash in this._asyncCache)) {
            this._asyncCache[paramsHash] = fetch(UrlProcessor.page('dic_text').param('key', key).queryArgs(params).url())
                .then(response => response.json())
                .then(data => data.result.text);
        }

        return this._asyncCache[paramsHash].then(callbackSuccess, callbackError);
    }
}

export const Dic = new Dictionary();
export default Dic;
