import { useCallback } from 'react';
import { Id, PostType } from 'types';
import { FeedOwner } from 'context/feed';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useAsyncFn, UseAsyncFnState } from 'hooks/useAsyncFn';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { PublisherFn } from '../types';

// MARK: - Types

type UsePublishStatus = (settings: {
    publisherFn: PublisherFn,
    owner: FeedOwner,
    postType?: PostType | null,
    objId?: Id,
}) => UsePublishStatusResult;

type UsePublishStatusResult = {
    publishStatusState: PublishStatusState,
    publishStatus: (statusText: string, statusJson: string) => Promise<unknown>;
};

type PublishStatusState = UseAsyncFnState<unknown, unknown>;

// MARK: - Hook

/** Handles the logic of publishing user status to the feed page */
export const usePublishStatus: UsePublishStatus = ({
    publisherFn, owner, postType, objId,
}) => {
    const csrf = useCSRFToken();
    const generator: any = useCurrentUser();

    const wrappedPublisherFn = useCallback((statusText: string, statusJson: string) => {
        const publishSettings = {
            csrf, owner, postType: postType || PostType.ORDINARY, generator, id: objId,
        };
        return publisherFn(statusText, statusJson, publishSettings);
    }, [publisherFn, owner, postType, csrf]);

    const [publishStatusState, publishStatus] = useAsyncFn(wrappedPublisherFn);

    return {
        publishStatusState,
        publishStatus,
    };
};
