import React from 'react';
import { TippyProps } from '@tippy.js/react';

import tippyService from 'services/tippy';
import { StyledTippy } from './styled';

let defaultWasSet = false;
if (!defaultWasSet) {
    tippyService.setDefaults(tippyService.defaultOptions);
    defaultWasSet = true;
}

export type TooltipProps = Omit<TippyProps, 'theme'>;

export const Tooltip = (props: TooltipProps) => {
    const { children, appendTo = 'parent' } = props;

    return (
        <StyledTippy
            appendTo={appendTo}
            arrow={false}
            {...props}
        >
            { children }
        </StyledTippy>
    );
};
