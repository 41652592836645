import businessMetrics from '../services/businessMetrics';
import store from '../store';
import { updateUser } from './updateUser';
import { getUserInfoPromise } from './getUserInfoPromise';

const getUserInfoOriginal = () => {
    const { user } = store.getState();

    return user;
};

export const initBusinessMetrics = async (project: string) => {
    const user = await getUserInfoPromise();
    updateUser(user);

    if (!getUserInfoOriginal().metricKey) {
        await businessMetrics.addNewVisitor(project, document.URL, document.referrer);

        if (window && window.performance && window.performance.timing) {
            if (window.performance.timing.domContentLoadedEventEnd) {
                const pageLoadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
                businessMetrics.addTiming('traffic', 'visitor', pageLoadTime / 1000, ['page_load']);
            } else {
                document.addEventListener('DOMContentLoaded', () => {
                    businessMetrics.addTiming('traffic', 'visitor', window.performance.now() / 1000, ['page_load']);
                }, false);
            }
        }
    }

    businessMetrics.allowSend();

    const { id: userId, lastVisit } = getUserInfoOriginal(); // eslint-disable-line no-use-before-define
    if (userId) {
        if (!lastVisit || ((Date.now() / 1000) - lastVisit) > 5 * 60) {
            businessMetrics.returnToProject(project);
        }
    }
};
