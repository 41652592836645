export default (id, tags) => {
    if (id === undefined) {
        return;
    }

    if (typeof App === 'undefined' || !App.eventer) {
        return;
    }

    App.eventer.fire('logger.analytics', {
        id,
        config: { tags },
    });
};
