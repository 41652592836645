import React from 'react';

import { Props } from './types';
import { MenuItemWrapper, StyledIconedText } from './styled';

export type { Props };

export const MenuItem = (props: Props) => {
    const {
        icon,
        disabled = false,
        isSelected = false,
        className = '',
        onClick,
        kind = 'primary',
        ...restProps
    } = props;

    return (
        <MenuItemWrapper
            disabled={disabled}
            isSelected={isSelected}
            className={className}
            onClick={onClick}
            kind={kind}
        >
            <StyledIconedText
                icon={icon && {
                    size: 24, gap: '12px', ...icon,
                }}
                {...restProps}
            />
        </MenuItemWrapper>
    );
};
