import {menuLogger} from './menuLogger';

class GoogleAnalyticsService {
    constructor() {
        this.gaResolver = null;
        this.queue = [];
    }

    get trackerName() {
        let trackerName = '';
        if (typeof window?.ga?.getAll === 'function') {
            const all = window.ga.getAll();
            if (Array.isArray(all)) {
                const ga = all[0];
                if (ga && typeof ga === 'object' && typeof ga.get === 'function') {
                    const name = ga.get('name');
                    if (name && typeof name === 'string') {
                        trackerName = name;
                    }
                }
            }
        }
        return trackerName;
    }

    registerGoogleAnalytics(gaResolver) {
        this.gaResolver = gaResolver;
        this._processQueue();
    }

    sendEvent(category, action, label, value) {
        this.queue.push({method: 'send', args: ['event', category, action, label, value]});
        this._processQueue();
    }

    setParam(param, value) {
        this.queue.push({method: 'set', args: [param, value]});
        this._processQueue();
    }

    _processQueue() {
        if (!this.gaResolver) {
            return;
        }

        const ga = this.gaResolver();
        if (typeof ga !== 'function') {
            return;
        }

        this.queue.forEach(({method, args}) => {
            const gaMethod = this.trackerName
                ? `${this.trackerName}.${method}`
                : method;

            menuLogger.log('Call GoogleAnalytics from menu', [gaMethod, ...args]);
            ga(gaMethod, ...args);
        });

        this.queue = [];
    }
}

export default new GoogleAnalyticsService();
