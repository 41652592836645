import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const Wrapper = styled.div`
    min-height: 264px;
    max-height: 560px;
    width: 320px;
    display: flex;
    flex-direction: column;

    ${mediaQuery.lt768} {
        height: 100%;
        width: 100%;
        max-height: unset;
        max-width: unset;
    }
`;

export const Body = styled.div`
    position: relative;
    flex-grow: 1;
`;

export const NotificationItemWrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.divider.primary};
    }
`;

export const NotificationsContentWrapper = styled.div<{isLoading?: boolean}>`
    ${({ isLoading }) => isLoading && 'cursor: wait;'};
`;

export const GroupBlockWrapper = styled(NotificationItemWrapper)``;
