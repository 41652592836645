import Dic from 'services/dictionary';

export const deletedUserTagToText = (text: string) => {
    const div = document.createElement('div');
    div.innerHTML = text;

    div.querySelectorAll('a[data-mention-person-id], a[data-reply-to-person-id]').forEach((link) => {
        if (!!link.getAttribute('data-person-is-deleted'))
            div.replaceChild(document.createTextNode(Dic.word('wt_feed__widget_comments__user_deleted')), link);
    });

    return div.innerHTML;
}
