import React, { SyntheticEvent } from 'react';
import { ReactElementLike } from 'prop-types';
import noop from 'lodash/noop';

import { IconComponent, IconProps } from 'components/icons2';

import { StyledIcon, Inner } from './styled';

export interface Props {
    text: string | number | ReactElementLike,
    className?: string,
    icon?: IconProps & {
        component: IconComponent,
        gap?: string,
        top?: number,
        className?: string,
    },
    iconPos?: 'left' | 'right',
    title?: string,
    onClick?(e: SyntheticEvent): void,
}
const IconedText = ({
    className, icon, text, title, iconPos = 'left', onClick,
}: Props) => {
    const { gap = '4px' } = icon || {};

    const renderedIcon = icon && <StyledIcon {...icon} gap={gap} iconPos={iconPos} />;

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <Inner className={className} title={title} onClick={onClick}>
            {iconPos === 'left' && renderedIcon}
            {text}
            {iconPos === 'right' && renderedIcon}
        </Inner>
    );
};

IconedText.defaultProps = {
    className: '',
    title: '',
    iconPos: 'left',
    icon: null,
    onClick: () => noop,
};

export default React.memo(IconedText);
