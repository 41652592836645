import React, { useCallback } from 'react';

import { FeedOwnerType, FeedRecord, PostType } from 'types';

import { useFeedContext } from 'hooks/useFeedContext';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { TagsComponent } from 'widgets/feed/tags-feed';
import { usePublisherFn } from 'widgets/feed/feed/hooks/usePublisherFn';
import { FeedPropsInterface } from '../types';

import { useStats } from '../hooks/useStats';
import { useFetchRecords } from '../hooks/useFetchRecords';
import { useTickerNews } from '../hooks/useTickerNews';
import { useGallery } from '../hooks/useGallery';
import { AddFeedStatus } from '../../add-status';

import { FeedWrapper, ItemsList } from './styled';
import { ErrorBlock } from './ErrorBlock';
import { FeedItem } from './FeedItem';
import { Loading } from './items/Loading';

export const Feed = (props: FeedPropsInterface) => {
    const {
        records = [],
        boundary: initialBoundary,
        showAddFeedStatus,
        showTickerNews = true,
        addFeedStatusStub,
        addFeedStatusPlaceholder,
        fetchTickerNews,
        isAddFeedStatusActive,
        onAddFeedStatusActiveChange,
        renderNoData,
        tags,
        deleteFeedRecord,
        updateFeedRecord,
        publishFeedRecord,
    } = props;

    const currentUser = useCurrentUser();
    const [{
        source,
        owner,
        websocketUserSign,
        pinnedRecordId,
        pinnedRecordShowMore,
    }] = useFeedContext();

    const tagsList = tags?.tags;
    const subscribedTags = tags?.subscribedTags;
    const isFeedPage = source === 'feed';
    // dg: Разрешено ли менять тип поста в редакторе. Сейчас разрешено только на странице ленты @since #WTT-10826
    // @ts-ignore
    const enableSwitchPostType = isFeedPage && owner?.id && currentUser?.isSame(owner.id) && owner.isVerifiedAuthor;
    const addFeedStatusPostType = (owner?.type === FeedOwnerType.PREMIUM) ? PostType.PREMIUM : PostType.ORDINARY;

    const handleCreateFeedRecord = usePublisherFn(publishFeedRecord);

    const {
        onIntersection,
        onRecordShowFull,
    } = useStats({ userId: currentUser.id, userSign: websocketUserSign, source }, Boolean(currentUser.isAuth));

    const { onItemViewedChange } = useTickerNews(fetchTickerNews, showTickerNews);

    const handleItemViewedChange = useCallback((entry: IntersectionObserverEntry, record: FeedRecord) => {
        onIntersection(entry, record);
        if (entry.isIntersecting && !record.tickerNews?.tickerNewsId) {
            onItemViewedChange(record);
        }
    }, [onIntersection, onItemViewedChange]);

    const { sentinelElement, isFeedLengthError, isLoading } = useFetchRecords({ source, owner, props });
    const { handlerOpenGallery, galleryElement } = useGallery();

    return (
        <FeedWrapper className="autocheck__feed">
            {
                isFeedPage && (
                    <TagsComponent tags={tagsList} subscribedTags={subscribedTags} />
                )
            }
            {showAddFeedStatus && (
                <AddFeedStatus
                    publisherFn={handleCreateFeedRecord}
                    stub={addFeedStatusStub}
                    placeholder={addFeedStatusPlaceholder}
                    owner={owner}
                    isActive={isAddFeedStatusActive}
                    onActiveChange={onAddFeedStatusActiveChange}
                    enableSwitchPostType={enableSwitchPostType}
                    postType={addFeedStatusPostType}
                />
            )}
            <ItemsList>
                {renderNoData?.({ isLoading, records, boundary: initialBoundary })}
                {isFeedLengthError && !isLoading && <ErrorBlock />}
                {records.map((record) => {
                    const pinned = pinnedRecordId?.toString() === record?.id.toString();

                    return (
                        <FeedItem
                            key={record.id}
                            record={record}
                            onItemViewedChange={handleItemViewedChange}
                            onRecordShowFull={onRecordShowFull}
                            onClickAnyImgInPost={handlerOpenGallery}
                            pinned={pinned}
                            showFull={pinned && pinnedRecordShowMore}
                            updateFeedRecord={updateFeedRecord}
                            deleteFeedRecord={deleteFeedRecord}
                        />
                    );
                })}
                <Loading visible={isLoading} />
                {sentinelElement}
                {galleryElement}
            </ItemsList>
        </FeedWrapper>
    );
};
Feed.defaultProps = {
    records: [],
    // не выставлять дефолтове значение для boundary !
    // опираемся на то что оно может быть undefined
    // boundary: '',
    boundaryRecordId: '',
    showAddFeedStatus: true,
};
